import Create from './ProjectCreate';
import Edit from './ProjectEdit';
import List from './ProjectList';
import Show from './ProjectShow';
import ProjectIcon from '@mui/icons-material/Work';
import React from 'react';

const resourceName = 'Project';
const resourceNamePlural = 'Projects';

const project = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    show: (props) => (
        <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    icon: ProjectIcon,
};

export default project;
