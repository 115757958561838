import React from 'react';
import { ImageField, useRecordContext } from 'react-admin';

const ImageFieldInput = () => {
    const record = useRecordContext();
    if (record?.name) {
        return <ImageField record={record} source="url" title="name" />;
    }
    return <ImageField record={record} source= "src" title="title" />
};

export default ImageFieldInput;
