import { DefaultActions, DefaultBulkActions } from './DefaultActions';
import StateUpdateAction from './StateUpdateAction';
import React from 'react';
import ExportAction from './ExportAction';
import { useListContext, useResourceDefinition } from 'react-admin';

export const StateExportActions = ({ stateResource, ...props }) => {
    const resource = useResourceDefinition();
    const { filterValues } = useListContext();
    return (
        <DefaultActions {...props}>
            <ExportAction resource={resource.name} filterValues={filterValues} />
            <StateUpdateAction stateResource={stateResource} resource={resource.name} filterValues={filterValues} {...props} />
            {props.children}
        </DefaultActions>
    );
};

export const StateExportBulkActions = ({ stateResource, ...props }) => {
    const resource = useResourceDefinition();
    const { filterValues } = useListContext();
    return (
        <DefaultBulkActions {...props}>
            <ExportAction resource={resource.name} filterValues={filterValues} />
            <StateUpdateAction stateResource={stateResource} resource={resource.name} {...props} />
            {props.children}
        </DefaultBulkActions>
    );
};
