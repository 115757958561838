import React from 'react';
import { Resource, defaultTheme } from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import { StyledEngineProvider } from '@mui/material';
import dataProvider from './dataProvider';
import addUploadFeature from './addUploadFeature';
import project from './project';
import worker from './worker';
import workerNote from './worker/note';
import workerSkillEstimation from './worker/skill-estimation';
import workerReferral from './worker/referral';
import projectRole from './project/role';
import projectDocumentation from './project/documentation';
import paymentInvoice from './payment/invoice';
import paymentCostCenter from './payment/cost-center';
import client from './client';
import cluster from './cluster';
import contractProject from './contract/project';
import contractWorker from './contract/worker';
import contractTemplate from './template';
import timelog from './timelog';
import timelogTags from './timelog/tags';
import webhook from './webhook/index';
import tool from './tool';
import Dashboard from './dashboard/Dashboard';
import Layout from './base/Layout';
import { lightTheme, darkTheme } from './base/Theme';
import authProvider from './auth';
import AdblockWarning from './base/AdblockWarning';
import permission from './permission/index';
import position from './position/index';
import holidays from './worker/holidays';
import tags from './worker/tags/index';
import workerDepartments from './worker/departments/index';
import workerWorkload from './worker/workload';
import projectWizard from './project/wizard';
import { QueryClient } from 'react-query';

const App = () => {
    const uploadCapableDataProvider = addUploadFeature(dataProvider());

    // Temporary fix as react-query is making superfluous refetch and making the edited form values to reset,
    // refetchOnWindowFocus is already false in ra edit forms according to react-admin docs but not working
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <StyledEngineProvider injectFirst>
            <Admin
                disableTelemetry={true}
                queryClient={queryClient}
                loginPage={false}
                dashboard={Dashboard}
                authProvider={authProvider}
                layout={Layout}
                dataProvider={uploadCapableDataProvider}
                theme={lightTheme}
                lightTheme={lightTheme}
                darkTheme={darkTheme}
            >
                {/* Put nested resources (i.e. projects/role) above the main resource (i.e. projects), to avoid routing conflicts */}
                {(permissions) => {
                    return (
                        <>
                            <Resource
                                name="users/workers/invitations"
                                options={{ label: 'Workload', parent: 'users/worker' }}
                                {...workerWorkload}
                            />
                            ,
                            <Resource name="invitations/planned" />,
                            <Resource
                                name="projects/role"
                                options={{ label: 'Roles', parent: 'users/worker' }}
                                {...projectRole}
                            />
                            ,
                            <Resource name="projects/wizard" {...projectWizard} />,
                            <Resource name="projects/invitations" />,
                            <Resource name="projects/documentation" {...projectDocumentation} />
                            ,
                            <Resource
                                name="clusters"
                                options={{ label: 'Clusters', parent: 'projects' }}
                                {...cluster(permissions)}
                            />
                            ,
                            <Resource
                                name="users/client"
                                options={{ label: 'Clients', parent: 'projects' }}
                                {...client}
                            />
                            ,
                            <Resource
                                name="contracts/projects"
                                options={{ label: 'Contracts', parent: 'projects' }}
                                {...contractProject}
                            />
                            ,
                            <Resource
                                name="projects"
                                options={{ label: 'Projects' }}
                                {...project}
                            />
                            ,
                            <Resource
                                name="payment/invoices"
                                options={{ label: 'Invoices' }}
                                {...paymentInvoice(permissions)}
                            />
                            ,
                            <Resource
                                name="cost-centers"
                                options={{
                                    label: 'Cost Centers',
                                    parent: 'payment/invoices',
                                }}
                                {...paymentCostCenter}
                            />
                            ,
                            <Resource
                                name="users/workers/skill-estimation"
                                {...workerSkillEstimation}
                            />
                            ,
                            <Resource name="users/workers/notes" {...workerNote} />,
                            <Resource
                                name="users/worker"
                                options={{ label: 'Workers' }}
                                {...worker}
                            />
                            ,
                            <Resource
                                name="users/workers/referral"
                                options={{ label: 'Referral', parent: 'users/worker' }}
                                {...workerReferral}
                            />
                            ,
                            <Resource
                                name="contracts/workers"
                                options={{ label: 'Contracts', parent: 'users/worker' }}
                                {...contractWorker}
                            />
                            ,
                            <Resource
                                name="timelogs"
                                options={{ label: 'Timelogs', parent: 'users/worker' }}
                                {...timelog}
                            />
                            ,
                            <Resource
                                name="vacation/public-holidays"
                                options={{ label: 'Public Holidays', parent: 'users/worker' }}
                                {...holidays(permissions)}
                            />
                            ,
                            <Resource
                                name="users/workers/tags"
                                options={{ label: 'Worker Tags', parent: 'users/worker' }}
                                {...tags(permissions)}
                            />
                            ,
                            <Resource
                                name="timelog-tags"
                                options={{ label: 'Timelog Tags', parent: 'users/worker' }}
                                {...timelogTags(permissions)}
                            />
                            ,
                            <Resource
                                name="workers/departments"
                                options={{
                                    label: 'Worker Departments',
                                    parent: 'users/worker',
                                }}
                                {...workerDepartments(permissions)}
                            />
                            ,
                            <Resource
                                name="tools"
                                options={{ label: 'Skills', parent: 'users/worker' }}
                                {...tool(permissions)}
                            />
                            ,
                            <Resource
                                name="users/group"
                                options={{
                                    label: 'Permissions',
                                    parent: 'users/worker',
                                }}
                                {...permission(permissions)}
                            />
                            ,
                            <Resource
                                name="templates"
                                options={{ label: 'Templates' }}
                                {...contractTemplate}
                            />
                            ,
                            <Resource
                                name="webhooks"
                                options={{ label: 'Webhooks' }}
                                {...webhook(permissions)}
                            />
                            ,
                            <Resource
                                name="users/positions"
                                options={{ label: 'Positions', parent: 'users/worker' }}
                                {...position(permissions)}
                            />
                            ,
                            <Resource name="actions" />,
                            <Resource name="content-types" />,
                            <Resource name="countries" />,
                            <Resource name="users" />,
                            <Resource name="templates/types" />,
                            <Resource name="jira-issues" />,
                            <Resource name="projects/controlling" />,
                            <Resource name="projects/tasks" options={{ label: 'Backlog Items' }} />
                            ,
                            <Resource name="projects/stage" />,
                            <Resource name="projects/versions" />,
                            <Resource name="contracts/projects/versions" />,
                            <Resource name="projects/role/versions" />,
                            <Resource name="users/supervision-role" />,
                            <Resource name="payment/invoices/versions" />,
                            <Resource name="users/utilization-target" />,
                            <Resource name="users/weekly-billable-target" />,
                            <Resource name="users/overhead-factor" />,
                            <Resource name="users/versions" />,
                            <Resource
                                name="users/client/address"
                                options={{ label: 'Client Address' }}
                            />
                            ,
                            <Resource name="users/client/versions" />,
                            <Resource name="users/worker/reviewers" />,
                            <Resource name="users/worker/versions" />,
                            <Resource name="users/workers/tags/versions" />,
                            <Resource name="users/group/versions" />,
                            <Resource name="users/todos" />,
                            <Resource name="timelogs/versions" />,
                            <Resource name="worker-tools" />,
                            <Resource name="users/workers/skill-estimation-entry," />,
                            <Resource name="users/workers/enrollment-certificates" />,
                            <Resource name="enums/project/ProjectType" />,
                            <Resource name="enums/project/ProjectState" />,
                            <Resource name="enums/project/ProjectClassification" />,
                            <Resource name="enums/project/ProjectInvitationState" />,
                            <Resource name="enums/project/ProjectResponsibilityType" />,
                            <Resource name="enums/project/ProjectRoleSkillEstimation" />,
                            <Resource name="enums/payment/CostCenterCategory" />,
                            <Resource name="enums/payment/InvoiceState" />,
                            <Resource name="enums/payment/InvoiceType" />,
                            <Resource name="enums/payment/InvoiceCategory" />,
                            <Resource name="enums/payment/LineItemCategory" />,
                            <Resource name="enums/contract/ProjectClientContractState" />,
                            <Resource name="enums/contract/ProjectClientContractType" />,
                            <Resource name="enums/contract/ProjectClientContractBillingType" />,
                            <Resource name="enums/contract/ProjectWorkerContractType" />,
                            <Resource name="enums/contract/TypeOfSalary" />,
                            <Resource name="enums/worker/UserType" />,
                            <Resource name="enums/worker/WorkerProfileState" />,
                            <Resource name="enums/worker/WorkerReferralStatus" />,
                            <Resource name="enums/worker/WorkerNoteType" />,
                            <Resource name="enums/worker/WorkLocation" />,
                            <Resource name="enums/timelog/TimeLogState" />,
                            <Resource name="enums/timelog/TimeLogType" />,
                            <Resource name="enums/tool/WorkerToolSource" />,
                            <Resource name="hooksevents" />,
                        </>
                    );
                }}
            </Admin>
            <AdblockWarning />
        </StyledEngineProvider>
    );
};
export default App;
