import React, { useState } from 'react';
import { SnackbarContent, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        borderRadius: 0,
    },
    content: {
        width: '100vw',
        maxWidth: '100vw',
        backgroundColor: '#383E4E',
        borderRadius: 0,
    },
}));

const AdblockWarning = () => {
    const [hasNoAddBlock, setHasNoAddBlock] = useState(window.noAdBlock === true);
    const classes = useStyles();

    return (
        <Snackbar
            open={!hasNoAddBlock}
            onClose={() => setHasNoAddBlock(true)}
            classes={{
                root: classes.root,
            }}
        >
            <SnackbarContent
                message={
                    <span>
                        Please disable your adblocker for this site to make sure all features are
                        functional
                    </span>
                }
                classes={{
                    root: classes.content,
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => setHasNoAddBlock(true)}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

export default AdblockWarning;
