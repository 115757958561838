import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useRecordContext} from "react-admin";
import {Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '2rem',
        maxWidth: '70rem',
        margin: '0 auto',
        "& table": {
          width: '100%',
        },
        "& th": {
          background: theme.palette.primary.main,
          color: "#fff",
        },
        "& .text-right": {
          textAlign: 'right',
        }
    }
}));

const ContractDetails = () => {
    const record = useRecordContext();
    const classes = useStyles();
    return <div className={classes.container}>
        <Typography variant="h4" gutterBottom>Project Plan</Typography>
        <div dangerouslySetInnerHTML={{__html: record.client_contract.body}}/>
        {record.client_contract.pricing_html && <>
          <Typography variant="h4" gutterBottom>Pricing</Typography>
          <div dangerouslySetInnerHTML={{__html: record.client_contract.pricing_html}}/>
        </>}
        {record.client_contract.legal_overwrite && <>
          <Typography variant="h4" gutterBottom>Legal Clauses</Typography>
          <div dangerouslySetInnerHTML={{__html: record.client_contract.legal_text}}/>
        </>}
    </div>;
};

export default ContractDetails;
