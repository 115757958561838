import { AuthProvider } from 'react-admin';
import { apiUrl, httpClient } from '../dataProvider';
import { setUserDepartment, setUserPosition } from '../posthog';
import { ADMIN_ROLE } from './index';
import userManager from './oidcUserManager';

export const setPermissions = async () => {
    return httpClient(`${apiUrl}/users/me`).then((res) => {
        const groups: any = [];
        const user = res?.json;
        const department = user?.worker_profile?.department;

        if (user?.is_superuser) groups.push(ADMIN_ROLE);
        if (user?.position) setUserPosition(user.position);
        if (department) {
            groups.push(department);
            setUserDepartment(department);
        }

        localStorage.setItem('platform_groups', JSON.stringify(groups));
        return groups;
    });
};

const aadAuthProvider: AuthProvider = {
    login: () => Promise.resolve(),
    checkError: (error: any) => {
        return Promise.resolve(error);
    },
    checkAuth: async () => {
        const user = await userManager.getUser();
        return user
            ? Promise.resolve()
            : Promise.reject(new Error('User is not authenticated'));
    },
    logout: () => {
        localStorage.clear();
        return userManager.signoutRedirect();
    },
    getIdentity: async () => {
        const user = await userManager.getUser();
        return user ? Promise.resolve(user as any) : Promise.reject();
    },
    // authorization
    getPermissions: (params) => {
        const groups = localStorage.getItem('platform_groups');
        
        if (groups) {
            return Promise.resolve(JSON.parse(groups));
        }
        return setPermissions();
    },
};

export default aadAuthProvider;
