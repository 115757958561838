import React from 'react';
import { TextInput, number } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';

const formatCurrency = (value) => {
    /**
     * Turn 1234.56 (format coming from the API) into 1234,56 (German number formatting)
     * as displayed value in the CurrencyInput
     */
    const valueStr = value == null ? '' : String(value);
    return valueStr.replace(/\.(\d+)?$/, ',$1');
};

const parseCurrency = (value) => {
    /**
     * Reverse the formatting function to get a number that's understood by the API
     *
     * Also handles numbers with thousands separators like 129.392,73
     * Every other format is left untouched, to avoid messing with user input too much
     *
     * @type {string}
     */
    if (value.match(/^\d{1,3}(\.\d{3})*(,\d+)?$/)) {
        // Only leave the decimal separator
        value = value.replaceAll('.', '');
    }
    return value.replace(/,(\d+)$/, '.$1');
};

const CurrencyInput = ({ validate = [], ...props }) => {
    // Add number validation to validate
    if (validate instanceof Array) {
        validate.push(number());
    } else if (validate) {
        validate = [number(), validate];
    } else {
        validate = [number()];
    }

    return (
        <TextInput
            format={(v) => formatCurrency(v)}
            parse={(v) => parseCurrency(v)}
            InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            validate={validate}
            {...props}
        />
    );
};

export default CurrencyInput;
