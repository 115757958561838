import React from 'react';
import { Datagrid, Pagination, ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { currencyFormatter } from '../../../base/fields/CurrencyField';

const CurrencyField = () => {
    const record = useRecordContext();
    return (
        <>
            <Typography variant="body1">
                {currencyFormatter.format(record.amount_discounted_net_signed)}
            </Typography>
        </>
    );
};

export default function InvoiceList(props) {
    const record = useRecordContext();
    return (
        <ReferenceManyField
            pagination={<Pagination />}
            record={record}
            label="Projects"
            reference="payment/invoices"
            target="client_contract.id"
            filter={{
                client_contract: record?.client_contract?.id,
                type__in: ['R', 'C'],
            }}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="public_id" label="Bookkeeping ID" />
                <CurrencyField label="Volume" />
                <TextField label="State" source="state_label" />
            </Datagrid>
        </ReferenceManyField>
    );
}
