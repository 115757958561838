import { makeStyles } from '@mui/styles';
import { useStore, useListContext } from 'react-admin';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import WorkloadCreateDialog from '../table/WorkloadCreateDialog';
import { workloadGraphColors } from '../useWorkloadStyles';
import { RowData, useWorkloadState } from '../WorkloadContext';
import { clamp, getPlannedWeeklyHoursByDateTime, getWeekRangeFromFilterValues, isFuture, useWorkloadDialogState } from '../workloadUtils';
import WorkloadTooltip from './WorkloadTooltip';

type WorkloadGraphItemProps = {
    row: RowData
}

export type WorkloadGraphPayload = {
    name: string
    start: DateTime
    diff: number
    totalPlanned: number,
    totalTracked: number,
    absences: number
    planned: number
    plannedRemaining: number
    trackedNormal: number,
    trackedOver: number
}

const useStyles = makeStyles({
    clickable: {
        cursor: 'pointer !important'
    }
})

const WorkloadGraphItem: React.FC<WorkloadGraphItemProps> = ({ row }) => {
    const { isOpen, selectedWeeklyHours, selectedStartDate, closeDialog, openDialog } = useWorkloadDialogState()
    const classes = useStyles()
    const { availability, isProject } = useWorkloadState()
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming
    const { filterValues } = useListContext();
    const weekRange = filterValues && getWeekRangeFromFilterValues(filterValues);
    const hideTracked = String(filterValues?.hide_tracked) === 'true'

    const getPlanned = (planned: number, isDateFuture : boolean) => {
        return isDateFuture ? planned : 0;
    }

    const getPlannedRemainig = (planned : number, tracked : number, isDateFuture : boolean) => {
        return !isDateFuture ? clamp(planned - tracked, 0, planned) : 0;
    }

    const getTrackedNormal = (planned : number, tracked : number, isDateFuture : boolean) => {
        return !isDateFuture ? clamp(tracked, 0, planned) : 0;
    }

    const getTrackedOver = (planned: number, tracked: number, isDateFuture: boolean, trackedRatio: number) => {
        return !isDateFuture && trackedRatio > 1 ? tracked - planned : 0;
    }

    const dataForRow: WorkloadGraphPayload[] = useMemo(() => {
        const data: WorkloadGraphPayload[] = [];
        [...Array(weekCount)].forEach((_, i) => {
            const start = weekRange.start.plus({ week: i });
            const weekNumber = start.weekNumber;
            const planned = getPlannedWeeklyHoursByDateTime(row, start);
            const tracked = Number(row.tracked.find((t) => t.week === weekNumber)?.hours || 0);
            const trackedRatio = tracked / planned
            const isDateFuture = isFuture(start)
            const absences =
                availability?.reduce(
                    (acc, value) =>
                        value.id === row.worker
                            ? acc +
                            value.absences.reduce(
                                (acc2, val2) =>
                                    val2.week === weekNumber ? acc2 + Number(val2.hours) : acc2,
                                0
                            )
                            : acc,
                    0
                ) || 0;

            const weekData: WorkloadGraphPayload = {
                name: `KW ${weekNumber}`,
                start,
                diff: tracked - planned,
                totalPlanned: planned,
                totalTracked: tracked,
                absences,
                planned: getPlanned(planned, isDateFuture),
                plannedRemaining: getPlannedRemainig(planned, tracked, isDateFuture),
                trackedNormal: getTrackedNormal(planned, tracked, isDateFuture),
                trackedOver: getTrackedOver(planned, tracked, isDateFuture, trackedRatio),
            }

            data.push(weekData);
        });
        return data;
    }, [weekRange, weekCount, row, availability]);

    return (
        <>
            <ResponsiveContainer height={140} width="100%">
                <BarChart
                    className={classes.clickable}
                    barCategoryGap="20%"
                    data={dataForRow}
                    margin={{ left: -40, top: 10, bottom: 10 }}
                    onClick={(state: any) => {
                        if (state.activePayload?.length) {
                            openDialog(state.activePayload[0].payload.start.toISODate(), state.activePayload[0].payload.totalPlanned)
                        }
                    }}
                >
                    <defs>
                        <pattern id="striped" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(45)">
                            <line x1="5" y="0" x2="5" y2="10" stroke="var(--color-primary)" opacity="0.75" stroke-width="10" />
                            <line x1="0" y="0" x2="0" y2="10" stroke="rgba(0, 0, 0, 0.1)" stroke-width="4" />
                        </pattern>
                    </defs>
                    <Tooltip
                        isAnimationActive={false}
                        content={WorkloadTooltip}
                        wrapperStyle={{ zIndex: 999 }}
                    />
                    <CartesianGrid strokeDasharray="3 3" opacity={0.4} />
                    <XAxis dataKey="name" hide />
                    <YAxis
                        width={40}
                        domain={[0]}
                        strokeWidth={1}
                        axisLine={false}
                        tickSize={3}
                        opacity={0.7}
                        tick={{ fontSize: 12, fill: '#8D97A0' }}
                        ticks={[16, 32, 48]}
                    />

                    {isProject && (
                        <Bar
                            type="step"
                            name="Absences"
                            stackId="a"
                            unit="h"
                            dataKey="absences"
                            fill={workloadGraphColors.absences}
                            isAnimationActive={false}
                        />
                    )}
                    {hideTracked ? <Bar
                        stackId="a"
                        type="step"
                        name="Total Planned"
                        unit="h"
                        dataKey="totalPlanned"
                        fill={workloadGraphColors.plannedNormal}
                        isAnimationActive={false}
                    /> : [
                        <Bar
                            type="step"
                            stackId="a"
                            dataKey="trackedNormal"
                            name="Tracked Normal"
                            unit="h"
                            fill={workloadGraphColors.trackedNormal}
                            isAnimationActive={false}
                            key="trackedNormal"
                        />,
                        <Bar
                            stackId="a"
                            type="step"
                            name="Planned"
                            unit="h"
                            dataKey="planned"
                            fill={workloadGraphColors.plannedNormal}
                            isAnimationActive={false}
                            key="planned"
                        />,
                        <Bar
                            stackId="a"
                            type="step"
                            name="Planned Remaining"
                            unit="h"
                            dataKey="plannedRemaining"
                            fill={workloadGraphColors.plannedRemaining}
                            isAnimationActive={false}
                            key="plannedRemaining"
                        />,
                        <Bar
                            type="step"
                            stackId="a"
                            dataKey="trackedOver"
                            name="Tracked Over Planned"
                            unit="h"
                            fill={workloadGraphColors.trackedOver}
                            isAnimationActive={false}
                            key="trackedOverPLanned"
                        />]}
                </BarChart>
            </ResponsiveContainer>
            <WorkloadCreateDialog
                id={row.id}
                project={row.project}
                worker={row.worker}
                project_end_date={row.project_end_date}
                project_name={row.project_name}
                start_date={selectedStartDate}
                worker_name={row.worker_name}
                onCloseDialog={closeDialog}
                open={isOpen}
                weekly_hours={selectedWeeklyHours}
            />
        </> 
    );
};

export default WorkloadGraphItem;
