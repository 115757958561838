import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { useRecordContext } from 'react-admin';

const useNoteStyles = makeStyles({
    root: {
        background: '#fafafa',
        padding: '1rem',
    },
});

const NoteContent = () => {
    const record = useRecordContext();
    const classes = useNoteStyles();

    return (
        <div className={classes.root}>
            <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: record.description || '' }}
            />
        </div>
    );
};

export default NoteContent;
