import React from 'react';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

const WorkerProfileLink = (props) => {
    const record = useRecordContext();
    return record.worker_profile ? (
        <ReferenceField {...props} source="worker_profile.id" reference="users/worker" link="show">
            <TextField source="label" />
        </ReferenceField>
    ) : (
        <TextField {...props} source="name" />
    );
};

export default WorkerProfileLink;
