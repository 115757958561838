import React from 'react';
import * as PropTypes from 'prop-types';
import { apiUrl, httpClient } from '../../../../../dataProvider';
import AtlassianConnectContent from '../AtlassianConnectContent';
import useCustomNotify from '../../../../useCustomNotify';

const ProjectConnectDialogContent = ({ jiraProjects, platformProject, onFinished }) => {
    const notify = useCustomNotify();

    const handleConnect = async (selectedProject) => {
        const jiraOptions = {
            method: 'PATCH',
            body: JSON.stringify({
                jira_key: selectedProject.key,
                project_id: platformProject.id,
                slug: platformProject.slug,
            }),
        };

        try {
            await httpClient(`${apiUrl}/jira-projects/jira`, jiraOptions);
            notify(`Jira is being connected.`);
        } catch (e) {
            notify(`Error when connecting Jira project`, { type: 'warning' });
        }

        onFinished();
    };

    return (
        <AtlassianConnectContent
            dialogTitle="Connect to an existing Jira Project"
            onConnect={handleConnect}
            options={jiraProjects}
        />
    );
};

ProjectConnectDialogContent.propTypes = {
    jiraProjects: PropTypes.array.isRequired,
    platformProject: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
};

export default ProjectConnectDialogContent;
