import * as React from "react";
import { ReactNode } from "react";
import { RaRecord, useTranslate } from "react-admin";

/**
 * Renders children (Inputs) or a step label according to the passed `intent` prop thanks to the React Multipass pattern
 * @see https://marmelab.com/blog/2018/10/18/react-render-context-pattern.html
 *
 * To be used as child of an <WizardForm> element.
 *
 * @param {string} basePath Optional.
 * @param {Record} record Optional.
 * @param {string} resource Optional.
 * @param {string} variant Optional.
 * @param {margin} margin Optional.
 * @param {intent} intent Optional. "step" for step inputs display or "label" for step label display
 * @param {string} label Optional. Label of the step (used inside the stepper)
 */
export const WizardFormStep = ({
  basePath,
  record,
  resource,
  variant,
  margin,
  children,
  intent,
  label,
}: WizardFormStepProps) => {
  const translate = useTranslate();

  if (intent === "label") {
    return <span>{translate(label, { _: label })}</span>;
  }

  return (
    <>
      {React.Children.map(
        children as any,
        (child: React.ReactElement) =>
          child &&
          React.cloneElement(child, {
            basePath,
            record,
            resource,
            variant,
            margin,
          })
      )}
    </>
  );
};

export interface WizardFormStepProps {
  children: ReactNode;
  basePath?: string;
  record?: RaRecord;
  resource?: string;
  variant?: "standard" | "outlined" | "filled";
  margin?: "none" | "normal" | "dense";
  intent?: "step" | "label";
  label: string;
}
