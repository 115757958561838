import { Box } from '@mui/material';
import { useRecordContext, ReferenceField, TextField } from 'react-admin';
import React from 'react';
import OverviewTableItem from './OverviewTableItem';
import { currencyFormatter } from '../../../base/fields/CurrencyField';

const OverviewTable: React.FC = () => {
    const record = useRecordContext();
    const isAgile = record?.client_contract?.type === 'agile';
    const isSP = record?.client_contract?.type === 'sp';
    const isServices = record?.client_contract?.type === 'services';
    const isTMContract = record?.client_contract.type === 'consultancy';

    return (
        <Box p={4} mt={2} bgcolor="rgba(120, 120, 120, 0.15)" borderRadius="24px">
            <Box display="grid" gap="2rem" gridTemplateColumns="repeat(4, 1fr)">
                <OverviewTableItem
                    label="Development Value"
                    value={currencyFormatter.format(
                        record?.client_contract?.net_development_cost || 0
                    )}
                />
                <OverviewTableItem
                    label="Additional Expenses"
                    value={currencyFormatter.format(
                        record?.client_contract?.additional_expenses || 0
                    )}
                />
                <OverviewTableItem
                    label="Travel Expenses"
                    value={currencyFormatter.format(record?.client_contract?.travel_expenses || 0)}
                />
                <OverviewTableItem
                    label="Discount"
                    value={currencyFormatter.format(record?.client_contract?.deduction_value || 0)}
                />
                <OverviewTableItem
                    label="Net Value"
                    value={currencyFormatter.format(
                        record?.client_contract?.net_value_override ||
                        record?.client_contract?.initial_net_value ||
                        0
                    )}
                    helperText={"Net value is calculated by multiplying total hours with the safety factor and the hourly rate. Additional and travel expenses are added on top." }
                />
                <OverviewTableItem
                    label="Contract Type"
                    value={
                        <ReferenceField
                            reference="enums/contract/ProjectClientContractType"
                            link={false}
                            source="client_contract.type"
                        >
                            <TextField source="label" variant="subtitle1" />
                        </ReferenceField>
                    }
                />
                {isSP && (
                    <>
                        <OverviewTableItem
                            label="Total Story Points"
                            value={record?.client_contract?.total_story_points || 0}
                        />
                        <OverviewTableItem
                            label="Story Point Rate"
                            value={currencyFormatter.format(
                                record?.client_contract?.story_point_rate || 0
                            )}
                        />
                    </>
                )}
                {isTMContract && (
                    <>
                        <OverviewTableItem
                            label="Total Days"
                            value={`${record?.client_contract?.estimated_days || 0} days`}
                        />
                    </>
                )}
                {(isAgile || isServices) && (
                    <>
                        <OverviewTableItem
                            label="Total Hours"
                            value={`${record?.client_contract?.estimated_hours || 0} hours`}
                        />
                        <OverviewTableItem
                            label="Hourly Rate"
                            value={currencyFormatter.format(
                                record?.client_contract?.hourly_rate || 0
                            )}
                        />

                    </>
                )}
                <OverviewTableItem
                    label="Safety"
                    value={record.client_contract.safety}
                />
                <OverviewTableItem
                    label="Valid Until"
                    value={record?.client_contract?.deadline}
                />
            </Box>
        </Box>
    );
};

export default OverviewTable;
