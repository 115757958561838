import { Box } from '@mui/material';
import React from 'react';

type InfoCardProps = {
    icon: React.ReactNode;
    color?: string;
    textColor?: string
    border?: string
    children: Array<React.ReactElement>
};

const InfoCard: React.FC<InfoCardProps> = ({ icon, color = '#51C68D', border = 'transparent', textColor = 'white', children }) => {
    return (
        <Box p={4} bgcolor={color} borderRadius="24px" mr={2} flex={1} border={border} color={textColor}>
            {icon}
            {children}
        </Box>
    );
};

export default InfoCard;
