import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  WizardFormStep,
  WizardFormStepProps,
} from "../wizard-form/WizardFormStep";
import React, { Children } from "react";
import { useFormContext } from "react-hook-form";
import ExtensionContractStep from "./ExtensionContractStep";
import FullAsideContent from "./FullAsideContent";
import FullMainContent from "./FullMainContent";

const useStyles = makeStyles({
  borderedBox: {
    padding: "1rem",
    paddingRight: "2rem",
    borderRight: "1px solid #ececec",
  },
});

export const ContractLayout: React.FC<any> = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Box display="grid" gap="2rem" gridTemplateColumns="6fr 3fr">
      <Box gridColumn="span 2">
        {Children.map(
          props.mainSection,
          (child: React.ReactElement) =>
            child && React.cloneElement(child, { ...props })
        )}
      </Box>
      <Box className={classes.borderedBox}>
        {Children.map(
          props.leftSection,
          (child: React.ReactElement) =>
            child && React.cloneElement(child, { ...props })
        )}
      </Box>
      <Box p="1rem">
        {Children.map(
          props.asideSection,
          (child: React.ReactElement) =>
            child && React.cloneElement(child, { ...props })
        )}
      </Box>
    </Box>
  );
};

const ContractStep: React.FC<
  Omit<WizardFormStepProps, "label" | "children">
> = (props) => {
  const { getValues } = useFormContext();
  const values = getValues();

  return (
    <WizardFormStep {...props} label="Contract">
      {values.project_type === "extension" ? (
        <ExtensionContractStep />
      ) : (
        <ContractLayout
          leftSection={<FullMainContent />}
          asideSection={<FullAsideContent />}
        />
      )}
    </WizardFormStep>
  );
};

export default ContractStep;
