import React from 'react';
import Info from '@mui/icons-material/Info';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleForm, TextInput, useRedirect, required } from 'react-admin';
import BaseCreate from '../base/views/BaseCreate';
import useCustomNotify from '../base/useCustomNotify';

const DOCS_LINK =
    'https://motius.atlassian.net/wiki/spaces/MPD/pages/8102838319/User+Creation+Workflow';

const useStyles = makeStyles((theme) => ({
    aside: {
        width: '30%',
        margin: '0 1rem',
        padding: 16,
        alignSelf: 'start',
        display: 'flex',
        alignItems: 'center',
    },
    infoText: {
        marginLeft: '0.5rem',
    },
    container: {
        maxWidth: theme.breakpoints.values.md,
    },
}));

const Aside = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.aside}>
            <Info color="primary" />
            <Typography className={classes.infoText} variant="subtitle2">
                Please read the documentation about user creation process{' '}
                <Typography component="a" color="secondary" href={DOCS_LINK} target="_blank">
                    here.
                </Typography>
            </Typography>
        </Paper>
    );
};

const GuestWorkerCreate = (props) => {
    const redirect = useRedirect();
    const notify = useCustomNotify();
    const classes = useStyles();

    return (
        <BaseCreate
            {...props}
            title="Create Guest User"
            resource="users/guest-worker"
            mutationOptions={{
                onSuccess: () => {
                    notify('Element created');
                    redirect('/users/worker');
                },
            }}
            aside={<Aside />}
            className={classes.container}
        >
            <SimpleForm variant="outlined" margin="normal">
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput source="email" type="email" validate={required()} />
                <TextInput
                    source="motius_email"
                    type="email"
                    helperText="This field is optional. Please fill it if you need a Motius email for this guest user and contact support."
                />
            </SimpleForm>
        </BaseCreate>
    );
};

export default GuestWorkerCreate;
