import React from 'react';
import onlyAdminViews from '../../auth/onlyAdminViews';

import Create from './WorkerTagCreate';
import Edit from './WorkerTagEdit';
import List from './WorkerTagList';

const resourceName = 'Worker Tag';
const resourceNamePlural = 'Worker Tags';

const tags = (permissions) =>
    onlyAdminViews(permissions, {
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default tags;
