import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import ContentCopy from '@mui/icons-material/Add';
import useCustomNotify from '../../base/useCustomNotify';

const CopyTimelogLinkButton = ({ ...props }) => {
    const record = useRecordContext();
    const notify = useCustomNotify();
    const onClick = (e) => {
        e.stopPropagation();
        const ta = document.createElement('textarea');
        ta.style.position = 'fixed';
        ta.style.top = 0;
        ta.style.left = 0;
        ta.style.width = '2em';
        ta.style.height = '2em';

        ta.textContent = `https://timetracking.motius.de/timelogs/edit/${record.id}`;
        document.body.appendChild(ta);
        ta.select();
        document.execCommand('copy');
        notify('Link copied to clipboard.');
        document.body.removeChild(ta);
    };

    return <Button label="Copy Link" onClick={onClick} children={<ContentCopy />}></Button>;
};

export default CopyTimelogLinkButton;
