import React from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import dataProvider from '../../../dataProvider';

const InvitationRoleField = (props) => {
    const { formData } = props;
    const { setValue } = useFormContext();

    const handleRoleChange = (role_id) => {
        /*
        #MP-2685 Selecting a role sets the role’s customer rate on the invitation
        */
        if (role_id) {
            dataProvider()('GET_ONE', `projects/role`, { id: role_id }).then((result) => {
                setValue('hourly_rate', result.data.hourly_rate);
            });
        }
    };

    return (
        <ReferenceInput
            source="role"
            reference="projects/role"
            filter={{ project: formData.project }}
        >
            <AutocompleteInput
                optionText="name"
                onChange={handleRoleChange}
                label="Role"
                allowEmpty
            />
        </ReferenceInput>
    );
};

export default InvitationRoleField;
