import React from 'react';
import {
    SimpleShowLayout,
    ShowController,
    DateField,
    NumberField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextField,
} from 'react-admin';
import Chip from '@mui/material/Chip';
import BaseShowView from '../base/views/BaseShowView';
import StateDialog from '../base/StateDialog';
import VersionShow from '../base/show/VersionShow';
import HtmlField from '../base/show/HtmlField';

const JiraIssueChip = (props) => {
    return props.record ? (
        <Chip
            style={{ marginRight: '0.5rem' }}
            label={props.record.jira_key}
            component="a"
            href={`${process.env.REACT_APP_ATLASSIAN_URL}/browse/${props.record.jira_key}`}
            target="_blank"
            rel="noopener noreferrer"
            clickable
        />
    ) : null;
};

const TimelogShow = ({ ...props }) => {
    return (
        <ShowController {...props}>
            {(controllerProps) => (
                <BaseShowView {...props} {...controllerProps}>
                    {controllerProps.record && (
                        <SimpleShowLayout>
                            <StateDialog
                                orientation="horizontal"
                                record={controllerProps.record}
                                basePath="/timelogs"
                                recordLabel={controllerProps.record.id}
                            />
                            <ReferenceField label="Worker" source="worker" reference="users/worker">
                                <TextField source="label" />
                            </ReferenceField>
                            <ReferenceField label="Project" source="project" reference="projects">
                                <TextField source="label" />
                            </ReferenceField>
                            <ReferenceArrayField
                                label="Tags"
                                source="tags"
                                reference="timelog-tags"
                                sx={{ display: 'flex' }}
                            >
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <ReferenceArrayField
                                label="Jira Issues"
                                source="jira_issues"
                                reference="jira-issues"
                                sx={{ display: 'flex' }}
                            >
                                <SingleFieldList linkType={false}>
                                    <JiraIssueChip
                                        source="jira_key"
                                        record={controllerProps.record}
                                    />
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <DateField source="created" label="Created Date" showTime />
                            <DateField source="date" label="Date" />
                            <NumberField source="duration_hours" label="Duration (hours)" />
                            <HtmlField source="comment" label="Comment" />
                            <VersionShow
                                reference="timelogs/versions"
                                label="Event Log"
                                {...controllerProps}
                            />
                        </SimpleShowLayout>
                    )}
                </BaseShowView>
            )}
        </ShowController>
    );
};

export default TimelogShow;
