import React, { useEffect } from 'react';
import {
    AutocompleteArrayInput,
    Filter,
    List,
    NumberInput,
    ReferenceArrayInput,
    useStore,
    useListContext
} from 'react-admin';
import WorkerWorkloadTable from './WorkerWorkloadTable';
import WorkerWorkloadGraph from './WorkerWorkloadGraph';
import Workload, { getDefaultWeekRange } from '../../base/workload/Workload';
import AutocompleteCustomArrayInput from '../../base/input/AutocompleteCustomArrayInput';
import { DateInputField } from '../../base/input/DateInput';
import { useLocation, useNavigate } from 'react-router-dom';

const WorkerWorkloadFilter = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setFilters, filterValues } = useListContext();

    useEffect(() => {
        const searchValues = new URLSearchParams(location.search)
        let filters = {}
        searchValues.forEach((value, key) => {
            filters[key] = value === 'true' ? true : value === 'false' ? false : value;
        })

        if (Object.keys(filters).length > 0) {
            setFilters(filters, null);
        }
    }, [])

    useEffect(() => {
        navigate({
            search: new URLSearchParams(filterValues).toString(),
        }, { replace: true });
    }, [filterValues]);

    return (
        <Filter {...props}>
            <ReferenceArrayInput reference="users/worker" source="id__in" alwaysOn>
                <AutocompleteCustomArrayInput
                    optionText="label"
                    label="Worker"
                    style={{ minWidth: 200 }}
                />
            </ReferenceArrayInput>
            <ReferenceArrayInput reference="workers/departments" source="department__in" alwaysOn>
                <AutocompleteArrayInput
                    optionText="name"
                    label="Department"
                    style={{ minWidth: 200 }}
                />
            </ReferenceArrayInput>
            <ReferenceArrayInput reference="users/positions" source="position__in" alwaysOn>
                <AutocompleteArrayInput
                    optionText="name"
                    label="Position"
                    filterToQuery={(text) => ({ name__icontains: text })}
                    style={{ minWidth: 200 }}
                />
            </ReferenceArrayInput>
            <ReferenceArrayInput reference="users/workers/tags" source="tags__in" alwaysOn>
                <AutocompleteArrayInput
                    optionText="name"
                    label="Tags"
                    filterToQuery={(text) => ({ name__icontains: text })}
                    style={{ minWidth: 200 }}
                />
            </ReferenceArrayInput>
            <DateInputField
                label="Available From"
                format="yyyy-MM-dd"
                source="available_from"
                alwaysOn
            />
            <NumberInput label="Available Hours" source="available_for" step={1} alwaysOn />
        </Filter>
    )
};

const WorkerWorkloadList = (props) => {
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming

    return (
        <List
            disableSyncWithLocation
            {...props}
            filterDefaultValues={{
                ...getDefaultWeekRange(weekCount),
                upcoming: true,
                outboarded: false,
                non_billable: true,
            }}
            sort={{ order: 'DESC', field: 'id' }}
            filters={<WorkerWorkloadFilter />}
            perPage={10}
            actions={null}
        >
            <Workload
                tableView={<WorkerWorkloadTable />}
                graphView={<WorkerWorkloadGraph />}
                defaultHideDetails={true}
            />
        </List>
    );
};

export default WorkerWorkloadList;
