import * as PropTypes from 'prop-types';
import React from 'react';
import { useRecordContext } from 'react-admin';

const CountField = ({ source }) => {
    const record = useRecordContext();
    return <span>{source in record ? record[source].length : '-'}</span>;
};

CountField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
};

CountField.defaultProps = {
    sortable: false,
};

export default CountField;
