import React from 'react';
import List from './ReferralList';
import Edit from './ReferralEdit';

const resourceName = 'Referral';
const resourceNamePlural = 'Referrals';

const referral = {
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default referral;
