import React from 'react';
import { Avatar } from '@mui/material';
import { Link, useRecordContext } from 'react-admin';
import { linkFilter } from '../base/fields/TextFilterLinkField';

export const ClusterField = () => {
    const record = useRecordContext();
    return (
        record && (
            <Avatar
                style={{
                    backgroundColor: '#' + record.color,
                    width: 24,
                    height: 24,
                    fontSize: 10,
                    marginRight: 4,
                }}
            >
                {record.code}
            </Avatar>
        )
    );
};

export const ClusterLinkField = ({ record, filterName, filterBasePath = null, basePath }) => {
    return record ? (
        <Link
            to={`${filterBasePath ? filterBasePath : basePath}?${linkFilter(record, filterName, [
                record.id,
            ])}`}
            onClick={(e) => e.stopPropagation()}
        >
            <ClusterField record={record} />
        </Link>
    ) : null;
};
