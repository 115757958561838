import React from 'react';
import {
    NumberField,
    Datagrid,
    EditButton,
    ReferenceField,
    TextField,
    DeleteButton,
} from 'react-admin';
import SearchFilter from '../base/SearchFilter';
import BaseList from '../base/views/BaseList';
import { DefaultActions, DefaultBulkActions } from '../base/actions/DefaultActions';
import MergeToolAction from '../base/actions/MergeToolAction';
import BooleanTrueField from '../base/fields/BooleanTrueField';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

export const ToolActions = ({ stateResource, children, ...props }) => (
    <DefaultActions {...props}>
        <MergeToolAction {...props} />
    </DefaultActions>
);

export const ToolBulkActions = ({ stateResource, children, ...props }) => (
    <DefaultBulkActions {...props}>
        <MergeToolAction {...props} />
    </DefaultBulkActions>
);

const CustomAction = () => (
    <>
        <EditButton label="" />
        <DeleteButton undoable={false} label="" />
    </>
)

const ToolList = (props) => (
    <BaseList
        {...props}
        sort={{ field: 'role_count', order: 'DESC' }}
        bulkActionButtons={<ToolBulkActions />}
        actions={<ToolActions />}
        filters={<SearchFilter />}
        hasCreate={true}
    >
        <EditableDatagrid rowClick="show" actions={<CustomAction />}>
            <ShowElementLink source="name" obj="tools" />
            <TextField source="keyword_regex" />
            <ReferenceField
                source="source"
                allowEmpty={true}
                reference="enums/tool/WorkerToolSource"
                link={false}
            >
                <TextField source="label" />
            </ReferenceField>
            <BooleanTrueField source="user_created" />
            <BooleanTrueField source="default_evaluated" />
            <NumberField source="worker_estimation_count" />
            <NumberField source="role_count" />

        </EditableDatagrid>
    </BaseList>
);

export default ToolList;
