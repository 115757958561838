import React, { useMemo } from "react";
import { useGetList, useListContext, useStore } from "react-admin";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { workloadGraphColors } from "../useWorkloadStyles";
import { RowData, useWorkloadState } from "../WorkloadContext";
import {
  clamp,
  getPlannedWeeklyHoursByDateTime,
  getWeekRangeFromFilterValues,
  isFuture,
} from "../workloadUtils";
import WorkloadTooltip from "./WorkloadTooltip";

export const TOTAL_ROW: RowData = {
  worker_name: "Total",
  project_name: "Total",
  id: 0,
  planned_hours: [],
  project: 0,
  project_end_date: "",
  state: 0,
  state_label: "",
  tracked: [],
  worker: 0,
};

type WorkloadGraphTotalRowProps = {
  plannedData: RowData[];
};

const WorkloadGraphTotalRow: React.FC<WorkloadGraphTotalRowProps> = ({
  plannedData,
}) => {
  const { isProject, availability } = useWorkloadState();
  const [weekCount] = useStore("workload.weekCount", 10); // For zooming
  const { filterValues } = useListContext();
  const weekRange = getWeekRangeFromFilterValues(filterValues);
  const hideTracked = String(filterValues.hide_tracked) === "true";
  const { data: contractData } = useGetList(
    `contracts/workers`,
    {
      pagination: undefined,
      sort: undefined,
      filter: {
        active: true,
        worker: plannedData[0]?.worker,
        start_date__lte: weekRange.start.toISODate(),
        end_date__gte: weekRange.start.toISODate(),
      },
    },
    { enabled: !isProject && !!plannedData[0].worker }
  );

  const getPlanned = (planned: number, isDateFuture: boolean) => {
    return isDateFuture ? planned : 0;
  }

  const getPlannedRemainig = (planned: number, tracked: number, isDateFuture: boolean) => {
    return !isDateFuture ? clamp(planned - tracked, 0, planned) : 0;
  }

  const getTrackedNormal = (planned: number, tracked: number, isDateFuture: boolean) => {
    return !isDateFuture ? clamp(tracked, 0, planned) : 0;
  }

  const getTrackedOver = (planned: number, tracked: number, isDateFuture: boolean) => {
    return !isDateFuture && tracked / planned > 1 ? tracked - planned : 0;
  }

  const totalData = useMemo(() => {
    return [...Array(weekCount)].map((_, i) => {
      const workerId = plannedData[0]?.worker;
      const start = weekRange.start.plus({ week: i });
      const isDateFuture = isFuture(start);
      const weekNumber = start.weekNumber;
      const planned = plannedData.reduce(
        (acc, row) => acc + getPlannedWeeklyHoursByDateTime(row, start),
        0
      );
      const tracked = plannedData.reduce(
        (acc, row) =>
          acc +
          Number(row.tracked.find((t) => t.week === weekNumber)?.hours || 0),
        0
      );
      const absences =
        availability?.reduce(
          (acc, row) =>
            row.id === workerId
              ? acc +
              row.absences.reduce(
                (acc2, val2) =>
                  val2.week === weekNumber ? acc2 + Number(val2.hours) : acc2,
                0
              )
              : acc,
          0
        ) || 0;

      return {
        name: `KW ${weekNumber}`,
        start,
        diff: tracked - planned,
        totalPlanned: planned,
        totalTracked: tracked,
        absences,
        planned: getPlanned(planned, isDateFuture),
        plannedRemaining: getPlannedRemainig(planned, tracked, isDateFuture),
        trackedNormal: getTrackedNormal(planned, tracked, isDateFuture),
        trackedOver: getTrackedOver(planned, tracked, isDateFuture),
      };
    });
  }, [plannedData, weekCount, weekRange, availability]);

  return (
    <ResponsiveContainer height={150} width="100%">
      <ComposedChart
        barCategoryGap="5%"
        data={totalData}
        margin={{ left: -40, top: 20, bottom: 20 }}
      >
        <defs>
          <pattern
            id="striped"
            patternUnits="userSpaceOnUse"
            width="10"
            height="10"
            patternTransform="rotate(45)"
          >
            <line
              x1="5"
              y="0"
              x2="5"
              y2="10"
              stroke="var(--color-primary)"
              opacity="0.75"
              stroke-width="10"
            />
            <line
              x1="0"
              y="0"
              x2="0"
              y2="10"
              stroke="rgba(0, 0, 0, 0.1)"
              stroke-width="4"
            />
          </pattern>
        </defs>
        <Tooltip
          isAnimationActive={false}
          content={WorkloadTooltip}
          wrapperStyle={{ zIndex: 999 }}
        />
        <CartesianGrid strokeDasharray="3 3" opacity={0.7} />
        <XAxis dataKey="name" hide />
        <YAxis
          width={40}
          domain={[0]}
          strokeWidth={1}
          axisLine={false}
          tickSize={3}
          opacity={0.7}
          tick={{ fontSize: 12, fill: "#8D97A0" }}
          ticks={[16, 32, 48, 64]}
        />
        {!isProject && (
          <Bar
            type="step"
            name="Absences"
            stackId="a"
            unit="h"
            dataKey="absences"
            fill={workloadGraphColors.absences}
            isAnimationActive={false}
          />
        )}
        {hideTracked ? (
          <Bar
            stackId="a"
            type="step"
            name="Total Planned"
            unit="h"
            dataKey="totalPlanned"
            fill={workloadGraphColors.plannedNormal}
            isAnimationActive={false}
          />
        ) : (
          [
            <Bar
              type="step"
              stackId="a"
              dataKey="trackedNormal"
              name="Tracked"
              unit="h"
              fill={workloadGraphColors.trackedNormal}
              isAnimationActive={false}
              key="tracked"
            />,
            <Bar
              stackId="a"
              type="step"
              name="Planned"
              unit="h"
              dataKey="planned"
              fill={workloadGraphColors.plannedNormal}
              isAnimationActive={false}
              key="planned"
            />,
            <Bar
              stackId="a"
              type="step"
              name="Planned Remaining"
              unit="h"
              dataKey="plannedRemaining"
              fill={workloadGraphColors.plannedRemaining}
              isAnimationActive={false}
              key="planRemaining"
            />,
            <Bar
              type="step"
              stackId="a"
              dataKey="trackedOver"
              name="Tracked Over Planned"
              unit="h"
              fill="#DE6B48"
              isAnimationActive={false}
              key="trackedOverPlanned"
            />,
          ]
        )}
        {!isProject &&
          contractData &&
          Object.values(contractData).length > 0 && (
            <ReferenceLine
              strokeWidth={2}
              stroke="black"
              style={{ outline: "1px solid white" }}
              y={Object.values(contractData)[0]?.weekly_hours}
            />
          )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default WorkloadGraphTotalRow;
