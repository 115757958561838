import React from 'react';
import { Typography } from '@mui/material';
import { FormDataConsumer, TextField } from 'react-admin';
import CheckboxInput from '../../../base/input/CheckboxInput';

export const RoleStateInput = () => {
    return (
        <FormDataConsumer>
            {({ formData, ...rest }) =>
                formData && formData.is_staffed ? (
                    <>
                        <CheckboxInput disabled source="is_public" label="Open For Applications" />
                        <Typography variant="caption">Project is staffed.</Typography>
                    </>
                ) : (
                    <CheckboxInput source="is_public" label="Open For Applications" />
                )
            }
        </FormDataConsumer>
    );
};

export const RoleStateField = ({ label = 'State', ...props }) => {
    const isStafed = props && props.record ? props.record.is_staffed : false;
    const isPublic = props && props.record ? props.record.is_public : false;

    if (isStafed) {
        return <TextField record={{ state: 'Staffed' }} source="state" />;
    }

    return (
        <TextField
            record={{ state: isPublic ? 'Open For Applications' : 'Not Public' }}
            source="state"
        />
    );
};
