import PositionForm from './PositionForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const PositionEdit = (props) => (
    <BaseEdit {...props}>
        <PositionForm location={props.location} />
    </BaseEdit>
);

export default PositionEdit;
