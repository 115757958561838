import React from 'react';
import {
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ReferenceField,
    SelectArrayInput,
    ReferenceArrayInput,
    DateField,
} from 'react-admin';
import BaseList from '../../base/views/BaseList';

const PublicHolidayFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name__icontains" label="Name" alwaysOn />
        <ReferenceArrayInput
            source="location__in"
            reference="enums/worker/WorkLocation"
            label="Location"
        >
            <SelectArrayInput optionText="label" label="Location" />
        </ReferenceArrayInput>
    </Filter>
);

const PublicHolidayList = (props) => {
    return (
        <BaseList {...props} filters={<PublicHolidayFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <ReferenceField source="location" reference="enums/worker/WorkLocation">
                    <TextField source="label" />
                </ReferenceField>
                <DateField source="start_date" />
                <DateField source="end_date" />
            </Datagrid>
        </BaseList>
    );
};

export default PublicHolidayList;
