import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { ShowProps, SimpleForm, useRecordContext, Edit } from "react-admin";
import { useNavigate } from "react-router-dom";
import PanelLabel from "../../base/show/PanelLabel";
import VersionShow from "../../base/show/VersionShow";
import ApprovedContent from "./components/ApprovedContent";
import ContractShowStepper from "./components/ContractShowStepper";
import ContractShowToolbar from "./components/ContractShowToolbar";
import OverviewHeader from "./components/OverviewHeader";
import OverviewTable from "./components/OverviewTable";
import ReviewedFormContent from "./components/ReviewedFormContent";

const ProjectContractLayout = (props: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (record) {
      const contractState = record.client_contract?.state;
      if (contractState === "draft") navigate(`/projects/wizard/${record.id}`);
    }
  }, [record, navigate]);

  return (
    <SimpleForm {...props} toolbar={<ContractShowToolbar />} redirect="show">
      <ContractShowStepper />
      {/* @ts-ignore */}
      <Grid container spacing={4} fullWidth>
        <Grid item xs={7}>
          <Box px={4} my={4}>
            <OverviewHeader />
          </Box>
          <OverviewTable />
        </Grid>
        <Grid item xs={5}>
          {["reviewed", "verbal_confirmation"].includes(record.client_contract.state) ? (
            <ReviewedFormContent />
          ) : null}
          {["approved", "billed", "paid"].includes(
            record.client_contract.state
          ) ? (
            <ApprovedContent />
          ) : null}
        </Grid>
      </Grid>
      <PanelLabel>Versions</PanelLabel>
      <VersionShow
        reference="contracts/projects/versions"
        label="Versions"
        record={{ id: record.client_contract?.id }}
      />
    </SimpleForm>
  );
};

const ProjectContractShow = (props: ShowProps) => {
  return (
    <>
      <Edit
        {...props}
        mutationMode="pessimistic"
        actions={false}
        title={"Show Project Contract"} /* hides the show button */
        redirect="show"
      >
        <ProjectContractLayout />
      </Edit>
    </>
  );
};

export default ProjectContractShow;
