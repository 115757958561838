import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles({
    list: {
        paddingLeft: '1rem',
        fontSize: '0.75rem',
    },
    item: {
        marginTop: '0.5rem',
        '& > strong': { marginRight: '0.5rem' },
        '& em': {
            fontWeight: 'bold',
        },
    },
    icon: {
        fontSize: '1rem',
        marginLeft: '0.5rem',
        marginBottom: '0.25rem',
    },
});

const HighlightTooltip = ({ record }) => {
    const classes = useStyles();

    const getTitle = () => {
        return (
            <ul className={classes.list}>
                {Object.entries(record.highlight).map((entry) => {
                    return (
                        <li className={classes.item} key={classes.item}>
                            <strong>{entry[0]}:</strong>
                            <span dangerouslySetInnerHTML={{ __html: entry[1] }} />
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <Tooltip title={getTitle()}>
            <InfoIcon className={classes.icon} color="secondary" />
        </Tooltip>
    );
};

export default HighlightTooltip;
