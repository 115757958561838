import Create from './PositionCreate';
import Edit from './PositionEdit';
import List from './PositionList';
import Show from './PositionShow';
import React from 'react';
import onlyAdminViews from '../auth/onlyAdminViews';

const resourceName = 'Position';
const resourceNamePlural = 'Positions';

const position = (permissions) =>
    onlyAdminViews(permissions, {
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        show: (props) => (
            <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default position;
