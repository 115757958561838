import React from 'react';
import { EditButton, TextField } from 'react-admin';
import BooleanTrueField from '../../base/fields/BooleanTrueField';
import SearchFilter from '../../base/SearchFilter';
import BaseList from '../../base/views/BaseList';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const CostCenterList = (props) => (
    <BaseList filters={<SearchFilter />} {...props}>
        <EditableDatagrid rowClick="show" actions={<EditButton label="" />}>
            <ShowElementLink source="name" obj="cost-centers" />
            <TextField source="public_id" />
            <BooleanTrueField source="internal" />
        </EditableDatagrid>
    </BaseList>
);

export default CostCenterList;
