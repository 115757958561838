import React, { useEffect, useState } from 'react';
import { AppLocationContext } from '@react-admin/ra-navigation';
import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import Menu from './Menu';
import MyAppBar from './AppBar';
import { CustomBreadcrumb } from '../Breadcrumb';
import { ReactQueryDevtools } from 'react-query/devtools';
import { apiUrl, httpClient } from '../dataProvider';
import makeStyles from '@mui/styles/makeStyles';

export const adminVersion = process.env.REACT_APP_ADMIN_VERSION;

const useStyles = makeStyles((theme) => ({
    footer: {
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        padding: 6,
        backgroundColor: theme.palette.background.default,
        alignContent: 'center',
        fontWeight: '400',
        display : 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: '20px'
    },
}));

const MyLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => {
    const classes = useStyles();
    const [backendVersion, setBackendVersion] = useState('');
    useEffect(() => {
        httpClient(`${apiUrl}/service-versions`)
            .then((response) => response.json)
            .then((data) => setBackendVersion(data['platform-backend']))
            .catch((error) => console.log(error));
    }, []);

    return (
        <AppLocationContext>
            <Layout {...props} menu={Menu} appBar={MyAppBar} breadcrumb={CustomBreadcrumb} />
            <ReactQueryDevtools />
            <div className={classes.footer}>
                <span>© Motius {new Date().getFullYear()}</span>
                <span>Admin: {adminVersion}</span>
                <span>Service: {backendVersion}</span>
            </div>
        </AppLocationContext>
    );
};

export default MyLayout;
