import { Edit } from 'react-admin';
import React from 'react';
import ContractRedirect from './components/ContractRedirect';

const ProjectClientContractEdit = (props) => {
    return <Edit {...props}>
        <ContractRedirect />
    </Edit>
}

export default ProjectClientContractEdit;
