import React from 'react';
import { Button, Link, useListContext, useRecordContext } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import BookIcon from '@mui/icons-material/Book';
import ContractField from './ContractField';

const useStyles = makeStyles({
    spacingTop: {
        margin: '1em 0',
    },
});

export const AddContractButton = ({ target, basePath }) => {
    const record = useRecordContext();
    const classes = useStyles();
    return (
        <Button
            className={classes.spacingTop}
            color="primary"
            component={Link}
            variant="contained"
            to={{
                pathname: `${basePath}/create`,
                search: `?${target}=${record.id}`,
            }}
            label="Create a Contract"
        >
            <BookIcon />
        </Button>
    );
};

const ContractList = (props) => {
    const { data } = useListContext();
    const { id, target, basePath } = props;
    const classes = useStyles();
    return (
        <div className={classes.spacingTop}>
            {data &&
                data.map((record) => (
                    <div key={record.id}>
                        <ContractField record={record} basePath={basePath} />
                    </div>
                ))}
            {data && !data.length && (
                <AddContractButton record={{ id }} basePath={basePath} target={target} />
            )}
        </div>
    );
};

ContractList.defaultProps = {
    data: {},
    ids: [],
};

export default ContractList;
