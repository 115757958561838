import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import React from 'react';
import { NumberField, TextField } from 'react-admin';

const BacklogEditableGrid = ({ isStoryPointContract, ...props }: any) => {
    return (
        <EditableDatagrid {...props}>
            <TextField source="name" />
            {isStoryPointContract ? (
                <TextField source="epic_name" />
            ) : (
                <TextField source="milestone_name" />
            )}
            {isStoryPointContract ? (
                <NumberField textAlign="left" source="story_points" />
            ) : (
                <NumberField textAlign="left" source="initial_hours" label="Hours" />
            )}
        </EditableDatagrid>
    );
};

export default BacklogEditableGrid;
