import React from 'react';
import { Button } from 'react-admin';
import * as PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const DefaultDialogContent = (props) => {
    const { helperText, loading, handleClick } = props;
    return (
        <div>
            <DialogContent>{helperText}</DialogContent>
            <DialogActions>
                <Button onClick={handleClick} disabled={loading} label="Confirm">
                    {loading && <CircularProgress size={20} />}
                </Button>
            </DialogActions>
        </div>
    );
};

DefaultDialogContent.propTypes = {
    helperText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default DefaultDialogContent;
