import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button  }  from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Close, Save } from '@mui/icons-material';
import { 
    BooleanInput, 
    ImageInput,
    SimpleForm, 
    TextInput, 
    required, 
    useDataProvider, 
    useRefresh,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    SaveButton,
    Toolbar,
} from 'react-admin'
import ImageFieldInput from '../base/input/ImageFieldInput';
import Grid from "@mui/material/Grid";
import useCustomNotify from '../base/useCustomNotify';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    fullWidth: { width: '100%' },
    grid: {
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr', 
        gap: '20px',
    },
    spaceLeft: {
        marginLeft: '1rem',
    },
    align: {
        marginBottom: '4px',
    },
    hideDropZone: {
        '& .RaFileInput-dropZone': {
            display: 'none',
        },
    },
});

const ClientCreateDialog = () => {
    const [open, setOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useCustomNotify();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ToolActions = () => {
        return (
            <Toolbar>
                <Button onClick={handleClose} variant='outlined'>
                    Cancel
                </Button>
                <SaveButton onSubmit={handleSave} className={classes.spaceLeft}/>
            </Toolbar>
        )
    }
        

    const handleSave = async (data) => {
        
        const clientData = {
            name: data.name,
            name_short: data.name_short,
            vat_number: data.vat_number,
            is_internal: data.is_internal || false,
            logo: data.logo,
        };

        let clientAddressData = {
            address: data.address,
            city: data.city,
            postal_code: data.postal_code,
            country: data.country,
            email: data.email,
        };

        try{
            const response = await dataProvider.create('users/client',  {data: clientData});

            clientAddressData.client = response.data.id;
            await dataProvider.create('users/client/address', { data: clientAddressData });

            refresh();
            handleClose();
        }
        catch (error){
            notify(error.message, { type: 'error' });
        }        
    };

    const handleOnChange = (files) => {
        console.log("files")
        console.log(files)
        if (files !== null) {
            setImageSelected(true);
        } else {
            setImageSelected(false);
        }
    
    }

    return (
        <div className={classes.root}>
            <Button variant="contained" color="primary" onClick={handleClickOpen} >
                <Add className={classes.align}/>
                Add New Client
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Create Client
                </DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={<ToolActions />} onSubmit={handleSave} >
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextInput source="name" label="Full Name" validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <ImageInput 
                                    className={imageSelected ? classes.hideDropZone : ''}
                                    source="logo"
                                    label="Logo"
                                    accept="image/*"
                                    multiple={false} placeholder={"Click to upload image"} 
                                    onChange={handleOnChange}
                                >
                                    <ImageFieldInput />
                                </ImageInput>
                            </Grid>
                        </Grid>
                        <p variant='text'>Address</p>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextInput source="address" validate={[required()]} label="Street & Nr " />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="city" validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <NumberInput source="postal_code" validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <ReferenceInput source="country" reference="countries">
                                    <AutocompleteInput
                                        optionText="name"
                                        optionValue="code"
                                        label="Country"
                                        filterToQuery={(text) => ({ name: text })}
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                        <Button
                            className={classes.fullWidth}
                            color="primary"
                            onClick={() => setShowDetails(!showDetails)}
                        >
                            {showDetails ? 'Hide' : 'Show Details'}
                        </Button>
                        {showDetails &&
                            <>
                                <TextInput source="name_short" label="Short Name" />
                                <TextInput source="vat_number" label="VAT Number" />
                                <TextInput source="email" />
                                <BooleanInput source="is_internal" label="Internal" />
                            </>
                        }
                    </SimpleForm> 
                </DialogContent>                
            </Dialog>
        </div>
    );
};

export default ClientCreateDialog;