import React, { Fragment, useEffect, useState } from 'react';
import { Button, useRefresh, useListContext } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import dataProvider, { apiUrl, convertFilterParams, httpClient } from '../../dataProvider';
import { stringify } from 'query-string';
import CreateIcon from '@mui/icons-material/DoneAll';
import LinearProgress from '@mui/material/LinearProgress';
import useCustomNotify from '../useCustomNotify';

const StateUpdateAction = (props) => {
    const { selectedIds, total } = useListContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const { useDescription } = props;
    const { filterValues } = useListContext();
    const isFilteringIds =
        selectedIds.length > 0 || (filterValues.id__in && filterValues.id__in.length > 0);
    const notify = useCustomNotify();
    const refreshView = useRefresh();

    useEffect(() => {
        const { stateResource } = props;
        dataProvider()('GET_LIST', stateResource).then((result) => {
            setStates(result.data);
        });
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = (transition) => {
        const { resource } = props;
        let { filterValues } = props;
        if (selectedIds.length > 0) {
            // Selected IDs should overwrite filters (when selecting items in bulk)
            // Otherwise no IDs are selected and all (filtered) items should be updated
            filterValues = { id__in: selectedIds };
        }
        const url = `${apiUrl}/${resource}/state/${transition.name}?${stringify(
            convertFilterParams(filterValues)
        )}`;
        setLoading(true);
        httpClient(url, { method: 'POST' })
            .then((data) => {
                setOpen(false);
                const { changed, unchanged } = data.json;
                notify(
                    `${changed} states updated${unchanged !== 0
                        ? `, ${unchanged} could not be updated because some conditions were not met`
                        : ''
                    }`
                );
                refreshView();
            })
            .catch((error) => {
                notify(error.message ?? 'An error occurred', { type: 'error' });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            <Button onClick={handleOpen} disabled={!states || states.length === 0} {...props}>
                <CreateIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">{`Bulk state update`}</DialogTitle>
                <div>
                    {loading && <LinearProgress variant="query" />}
                    {!isFilteringIds && (
                        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {total ? (
                                <Typography
                                    variant="body1"
                                    color="error"
                                >{`You are updating ${total} objects`}</Typography>
                            ) : (
                                <Typography variant="body1" color="error">
                                    You are updating all (filtered) objects
                                </Typography>
                            )}
                        </DialogContent>
                    )}
                    <List>
                        {states &&
                            states.map(
                                (state) =>
                                    state.label !== 'Staffed' &&
                                    state.state_transitions &&
                                    Object.values(state.state_transitions).map((transition) =>
                                        transition.name !==
                                            'rollback_role_to_open_for_application' ? (
                                            <ListItem
                                                disabled={loading}
                                                button
                                                onClick={() => handleUpdate(transition)}
                                                key={transition.id}
                                            >
                                                <ListItemText
                                                    primary={transition.custom.button_name}
                                                    secondary={
                                                        useDescription
                                                            ? transition.custom.description
                                                            : null
                                                    }
                                                />
                                            </ListItem>
                                        ) : null
                                    )
                            )}
                    </List>
                </div>
            </Dialog>
        </Fragment>
    );
};

StateUpdateAction.defaultProps = {
    label: 'State Update',
    selectedIds: [],
};

export default StateUpdateAction;
