import * as React from 'react';
import {
    Stepper,
    StepperProps,
    StepButton,
    StepLabel,
    Step,
} from '@mui/material';

/**
 * Progress component rendering a stepper on top of the wizard
 *
 * @prop {number} currentStep Current selected step index
 * @prop {Function} onStepClick Action called when a step is clicked
 * @prop {React.ReactElement[]} steps Array of step elements
 */
export const WizardProgress = ({
    currentStep,
    onStepClick,
    steps,
    ...rest
}: WizardFormProgressProps) => {
    const handleStepClick = (index: number) => (): void => onStepClick!(index);

    return (
        <Stepper activeStep={currentStep} {...rest}>
            {steps!.map((step, index) => {
                const label = React.cloneElement(step, { intent: 'label' });

                return (
                    <Step key={step.key}>
                        {!onStepClick ? (
                            <StepLabel>{label}</StepLabel>
                        ) : (
                            <StepButton onClick={handleStepClick(index)}>
                                {label}
                            </StepButton>
                        )}
                    </Step>
                );
            })}
        </Stepper>
    );
};

WizardProgress.defaultProps = {
    currentStep: 0,
    steps: [],
};

export type WizardFormProgressProps = Omit<
    StepperProps,
    'activeStep' | 'children'
> & {
    currentStep?: number;
    onStepClick?: (index: number) => void;
    steps?: React.ReactElement[];
};
