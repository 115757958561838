import { useRecordContext, useRedirect } from 'react-admin';
import React, { useEffect, useMemo } from 'react';
import ContractStep from './components/ContractStep';
import CustomWizardToolbar from './components/CustomWizardToolbar';
import OverviewStep from './components/OverviewStep';
import ProjectStep from './components/ProjectStep';
import ProjectTypeRadioInput from './components/ProjectTypeRadioInput';
import { WizardFormInput } from './wizard-form';
import { useNavigate, useLocation, useResolvedPath } from 'react-router-dom';

const SHOW_STATES = ['approved', 'billed', 'paid']

const ProjectContractWizardForm = (props: any) => {
    const record = useRecordContext()
    const redirect = useRedirect()
    const navigate = useNavigate()
    const location = useLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useMemo(() => new URLSearchParams(location.search), [])
    const formRootPathname = useResolvedPath("").pathname ?? location.pathname;

    const defaultValues = useMemo(() => ({
        client_contract: {},
        project_type: search.get('project_type'),
        predecessor: search.has('predecessor') ? Number(search.get('predecessor')) : null,
        parent: search.has('parent') ? Number(search.get('parent')) : null,
    }), [search]);

    useEffect(() => {
        const doesHaveTabInfo = location.pathname.split('/').length > 4

        if (doesHaveTabInfo && search.get('project_type')) {
            navigate(formRootPathname.replace('/#', '') + '/' + 1)
            search.delete('project_type')
        }

        // if there is no tab information navigate to overview
        if (!doesHaveTabInfo && record && record.client_contract?.id) {
            navigate(formRootPathname.replace('/#', '') + '/' + 2)
        }

        // If it is past approved, redirect to show view
        if (record && SHOW_STATES.includes(record.client_contract?.state)) {
            redirect('show', '/projects/wizard', record.id)
        }

    }, [navigate, formRootPathname, location.pathname, record, redirect])

    return (
        <WizardFormInput
            {...props}
            defaultValues={defaultValues}
            variant="outlined"
            margin="normal"
            mutationMode="pessimistic"
            toolbar={CustomWizardToolbar}
        >
            {!record?.id && <ProjectTypeRadioInput />}
            <ProjectStep />
            <ContractStep />
            <OverviewStep />
        </WizardFormInput>
    );
};

export default ProjectContractWizardForm;
