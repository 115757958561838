import DocumentationForm from './DocumentationForm';
import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';

const DocumentationEdit = (props) => (
    <BaseEdit {...props}>
        <DocumentationForm location={props.location} />
    </BaseEdit>
);

export default DocumentationEdit;
