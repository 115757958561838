import ClusterForm from './ClusterForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const ClusterEdit = (props) => (
    <BaseEdit {...props} redirect="list">
        <ClusterForm />
    </BaseEdit>
);

export default ClusterEdit;
