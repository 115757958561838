import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    TextInput,
    SimpleForm,
    required,
    FormDataConsumer,
} from 'react-admin';
import React from 'react';
import SlugInput from '../base/input/SlugInput';

const ClusterForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" label="Name" validate={[required()]} />
        <FormDataConsumer>
            {({ formData }) => <SlugInput source="slug" field="name" />}
        </FormDataConsumer>
        <TextInput source="code" label="2-letter Code" validate={[required()]} />
        <TextInput source="color" label="Hex Color Code" validate={[required()]} />
        <ReferenceInput source="owner" reference="users">
            <AutocompleteInput
                label="Owner"
                optionText="name"
                helperText="Only users with team profiles are shown"
                filter={{ is_active: true, worker_profile__isnull: false }}
                validate={[required()]}
            />
        </ReferenceInput>
        <TextInput
            source="role_name"
            label="Role Name"
            helperText="What would you call somebody working in this cluster?"
            validate={[required()]}
        />
        <ReferenceArrayInput source="tools" reference="tools">
            <AutocompleteArrayInput
                label="Tools"
                shouldRenderSuggestions={(val) => {
                    return val.trim().length > 0;
                }}
                optionText="name"
            />
        </ReferenceArrayInput>
    </SimpleForm>
);

export default ClusterForm;
