import Create from './ProjectClientContractCreate';
import Edit from './ProjectClientContractEdit';
import List from './ProjectClientContractList';
import React from 'react';

const resourceName = 'Project Contract';
const resourceNamePlural = 'Project Contracts';

const clientContracts = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default clientContracts;
