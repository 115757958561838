import React, { useMemo } from 'react';
import WorkloadGraph from '../../base/workload/graph/WorkloadGraph';
import { useWorkloadState } from '../../base/workload/WorkloadContext';
import { sortRowsByName } from '../../base/workload/workloadUtils';

const WorkerWorkloadGraph = ({ weekRange, defaultHideDetails }) => {
    const { data: plannedData } = useWorkloadState();

    const groupedData = useMemo(() => {
        const grouped = new Map();
        plannedData.forEach((item) => {
            const existingItems = grouped.get(item.worker) || [];
            grouped.set(item.worker, [...existingItems, item]);
        });
        return Array.from(grouped.values());
    }, [plannedData]);

    const sortedGroupedData = groupedData.map((eachWorkerWorkload) =>
        [...eachWorkerWorkload].sort(sortRowsByName)
    );

    return sortedGroupedData.map((grouped) => (
        <WorkloadGraph
            key={grouped[0].worker}
            plannedData={grouped}
            weekRange={weekRange}
            defaultHideDetails={defaultHideDetails}
        />
    ));
};

export default WorkerWorkloadGraph;
