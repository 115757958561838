import React from 'react';
import { Logout, MenuItemLink, UserMenu as UserMenuRA } from 'react-admin';
import SettingsIcon from '@mui/icons-material/AccountCircle';
import getUser from '../auth/getUser';

const MyCustomLogout = () => <Logout />;

// TODO where to redirect for AAD
const UserMenu = (props) => (
    <UserMenuRA {...props}>
        <MenuItemLink
            to="https://myaccount.microsoft.com"
            primaryText={getUser().preferred_username}
            leftIcon={<SettingsIcon />}
        />
        <MyCustomLogout />
    </UserMenuRA>
);

export default UserMenu;
