import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import getUser from '../../../../../auth/getUser';
import { apiUrl, httpClient } from '../../../../../dataProvider';
import TaskSelector from './TaskSelector';
import AtlassianCreateContent from '../AtlassianCreateContent';
import useCustomNotify from '../../../../useCustomNotify';

const ProjectCreateDialogContent = ({ jiraProjects, platformProject, onFinished }) => {
    const notify = useCustomNotify();
    const [tasksToCreate, setTasksToCreate] = useState(null);

    const handleCreate = async (key, name) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                project: {
                    id: platformProject.id,
                    name_internal: name || platformProject.name_internal,
                    slug: platformProject.slug,
                },
                user_email: getUser().email,
                jira_key: key,
                tasks: tasksToCreate,
            }),
        };

        try {
            await httpClient(`${apiUrl}/jira-projects/jira`, options);
            notify(`Create request is successfull. Jira project will be created.`);
            onFinished();
        } catch (e) {
            notify(`Could not create Jira project`, { type: 'warning' });
        }
    };

    return (
        <AtlassianCreateContent
            keyLabel="Jira Project"
            existingOptions={jiraProjects}
            onCreate={handleCreate}
            platformProject={platformProject}
        >
            <TaskSelector projectId={platformProject.id} setSelectedRows={setTasksToCreate} />
        </AtlassianCreateContent>
    );
};

ProjectCreateDialogContent.propTypes = {
    jiraProjects: PropTypes.array.isRequired,
    platformProject: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
};

export default ProjectCreateDialogContent;
