import WorkerSkillEstimationForm from './WorkerSkillEstimationForm';
import React from 'react';
import Typography from '@mui/material/Typography';
import { List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import BaseEdit from '../../base/views/BaseEdit';

export const Aside = () => (
    <div style={{ width: 300, margin: '1em' }}>
        <Typography variant="title">Estimation</Typography>
        <List component="nav">
            <ListItem>
                <ListItemText
                    primary="Insufficient / Untrained"
                    secondary="Talent consistently under-performs and does not meet expectations, only has very basic technical understanding"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary="Weak / Novice"
                    secondary="Talent barely meets expectations, major improvement needed, can barely work on simple tasks"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary="Average / Competent"
                    secondary="Talent mostly meets expectations, can work on simple tasks without help"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary="Good / Proficient"
                    secondary="Talent meets expectations, demonstrated good technical understanding but still needs to look things up frequently"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary="Great / Adept"
                    secondary="Talent exceeds expectations, can do complex technical work with minimal external help or references"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary="Excellent / Expert"
                    secondary="Talent goes beyond what is expected, understands the tool on a fundamental level"
                />
            </ListItem>
        </List>
    </div>
);

const WorkerSkillEstimationEdit = (props) => (
    <BaseEdit aside={<Aside />} {...props}>
        <WorkerSkillEstimationForm location={props.location} />
    </BaseEdit>
);

export default WorkerSkillEstimationEdit;
