import { Create } from 'react-admin';
import PositionForm from './PositionForm';
import React from 'react';
import HeadTitle from '../base/HeadTitle';

const PositionCreate = (props) => (
    <Create title={<HeadTitle>Creating Position</HeadTitle>} {...props}>
        <PositionForm location={props.location} />
    </Create>
);

export default PositionCreate;
