import Avatar from '@mui/material/Avatar';
import { Typography, Grid } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TypeIcon from '@mui/icons-material/DeviceHub';
import { ArrayField, ReferenceField, TextField } from 'react-admin';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import ShareIcon from '@mui/icons-material/Share';
import UpdateIcon from '@mui/icons-material/Update';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '0.75em',
    },
    spacingBottom: {
        marginBottom: '1em',
    },
    avatar: {
        float: 'left',
        marginTop: 20,
        width: '100%',
        height: 'auto',
    },
    smallIcon: {
        position: 'relative',
        top: '0.25em',
    },
});

const KnowFromField = ({ record }) => {
    const classes = useStyles();
    if (
        (record.know_from && record.know_from.length) ||
        (record.other_info && record.other_info.know_from_direct)
    ) {
        return (
            <Typography>
                <ShareIcon className={classes.smallIcon} /> Knows us from{' '}
                {record.know_from.join(', ')}
                {record.know_from_other && `, (${record.know_from_other})`}
                {record.other_info &&
                    record.other_info.know_from_direct &&
                    `, ${record.other_info.know_from_direct}`}
            </Typography>
        );
    } else {
        return null;
    }
};
KnowFromField.propTypes = {
    record: PropTypes.object,
};

const WorkerCard = ({ record, resource, ...props }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Avatar src={record.avatar} className={classes.avatar} />
            </Grid>
            <Grid item xs={9} className={classes.spacingTop}>
                <Typography variant="title" className={classes.spacingTop}>
                    {record.user.name}
                </Typography>
                {record.user.position ? (
                    <Typography>
                        <AssignmentIndIcon className={classes.smallIcon} />
                        <ReferenceField
                            reference="users/positions"
                            source="user.position"
                            label="Position"
                            record={record}
                            link={null}
                            {...props}
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </Typography>
                ) : null}
                <Typography>
                    <EventIcon className={classes.smallIcon} /> Joined on{' '}
                    {DateTime.fromISO(record.user.date_joined).toFormat('DDD t')}
                </Typography>

                <ArrayField
                    record={record}
                    resource={resource}
                    label="Knows Motius From"
                    source="know_from"
                    {...props}
                >
                    <KnowFromField label="Knows Motius From" record={record} />
                </ArrayField>
                <Typography>
                    <UpdateIcon className={classes.smallIcon} /> State last updated on{' '}
                    {DateTime.fromISO(record.last_state_change).toFormat('DDD')}
                </Typography>
                {record.other_info && record.other_info.applied_for_specific && (
                    <Typography>
                        <TypeIcon className={classes.smallIcon} /> Worker set itself as{' '}
                        {record.other_info.applied_for_specific}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default WorkerCard;
