import React from 'react';
import {
    AutocompleteInput,
    DateField,
    EditButton,
    Filter,
    ReferenceArrayField,
    ReferenceArrayInput,
    SelectArrayInput,
    ShowButton,
    SingleFieldList,
    TextField,
    AutocompleteArrayInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    DatagridConfigurable,
    WrapperField,
    SelectColumnsButton,
    ReferenceField,
} from 'react-admin';
import { ClusterLinkField } from '../cluster/fields';
import { StateExportActions, StateExportBulkActions } from '../base/actions/StateExportActions';
import BaseList from '../base/views/BaseList';
import WorkerNameField from './WorkerNameField';
import WorkerPanel from './WorkerPanel';
import BooleanTrueField from '../base/fields/BooleanTrueField';
import { WORKER_TYPES } from './WorkerForm';
import HighlightToggle from '../base/input/HighlightToggle';
import AutocompleteCustomArrayInput from '../base/input/AutocompleteCustomArrayInput';
import { DateInputField } from '../base/input/DateInput';
import { EditableDatagridConfigurable } from '@react-admin/ra-editable-datagrid';


export const WorkerFilter = ({ children, alwaysOn, ...props }) => (
    <Filter {...props}>
        <ReferenceArrayInput
            reference="users/worker"
            source="id__in"
            alwaysOn={alwaysOn}
            enableGetChoices={({ autocomplete }) => !!autocomplete && autocomplete.length >= 3}
            queryOptions={{ keepPreviousData: false }}
            sort={{ field: 'updated', order: 'DESC' }}
        >
            <AutocompleteCustomArrayInput
                style={{ minWidth: 260 }}
                optionText="label"
                label="Search Name (min 3 characters)"
                shouldRenderSuggestions={(val) => {
                    return val.trim().length >= 3;
                }}
            />
        </ReferenceArrayInput>
        <TextInput source="search" label="Search Properties" alwaysOn />
        <ReferenceArrayInput
            source="state__in"
            reference="enums/worker/WorkerProfileState"
            alwaysOn={alwaysOn}
        >
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="State" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="tools__tool__id__in" reference="tools" alwaysOn={alwaysOn}>
            <AutocompleteArrayInput
                shouldRenderSuggestions={(val) => {
                    return val.trim().length > 0;
                }}
                optionText="name"
                label="Skills (Exact)"
                filterToQuery={(text) => ({ name__icontains: text })}
            />
        </ReferenceArrayInput>
        <BooleanInput source="show_archived" alwaysOn={alwaysOn} />
        <BooleanInput source="active_contract" label="Has active contract" alwaysOn={alwaysOn} />
        <HighlightToggle alwaysOn source="highlight" />
        <TextInput label="Email" source="email" />
        <ReferenceInput source="skill_estimation__entries__evaluation.tool" reference="tools">
            <AutocompleteInput
                label="Evaluation Skill"
                shouldRenderSuggestions={(val) => {
                    return val.trim().length > 0;
                }}
                optionText="name"
            />
        </ReferenceInput>

        <ReferenceArrayInput
            source="skill_estimation__entries__evaluation.estimation"
            reference="enums/project/ProjectRoleSkillEstimation"
        >
            <SelectArrayInput
                name="evaluation.estimation"
                style={{ minWidth: 150 }}
                optionText="label"
                label="Evaluation Level"
            />
        </ReferenceArrayInput>
        <TextInput source="tools__tool__name__istartswith" label="Skills (name starts with)" />
        <SelectInput
            choices={WORKER_TYPES}
            source="applied_for_specific"
            label="Worker applied as"
        />
        <ReferenceInput source="user_type__in" reference="enums/worker/UserType" label="User Type">
            <SelectInput style={{ minWidth: 150 }} optionText="label" label="User Type" />
        </ReferenceInput>

        <ReferenceArrayInput source="clusters__id__in" reference="clusters" label="Cluster">
            <SelectArrayInput style={{ minWidth: 100 }} optionText="name" label="Cluster" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="projects__id__in" reference="projects" label="Projects">
            <AutocompleteCustomArrayInput
                style={{ minWidth: 100 }}
                optionText="label"
                label="Projects"
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="projects__client_profile__id__in"
            reference="users/client"
            label="Clients"
        >
            <AutocompleteArrayInput style={{ minWidth: 100 }} optionText="name" label="Clients" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="department__id__in"
            reference="workers/departments"
            label="Department"
        >
            <SelectArrayInput style={{ minWidth: 200 }} optionText="name" label="Department" />
        </ReferenceArrayInput>
        <BooleanInput source="projects__mine" label="Works in my projects" />
        <ReferenceArrayInput source="tags__id__in" reference="users/workers/tags" label="Tags">
            <AutocompleteArrayInput
                name="tags__id__in"
                style={{ minWidth: 150 }}
                optionText="name"
                label="Tags"
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="contract_type"
            reference="enums/contract/ProjectWorkerContractType"
            label="Contract Type"
        >
            <SelectArrayInput style={{ minWidth: 150 }} optionText="label" label="Contract Type" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="user__position__id__in"
            reference="users/positions"
            label="Position"
        >
            <AutocompleteArrayInput
                style={{ minWidth: 150 }}
                optionText="name"
                filterToQuery={(t) => ({ name__icontains: t })}
                sort={{ field: 'name', order: 'ASC' }}
                label="Position"
            />
        </ReferenceArrayInput>
        <SelectInput
            choices={[
                { id: true, name: 'Core' },
                { id: false, name: 'Talent Pool' },
            ]}
            source="is_core"
            label="Core Contract"
        />

        <ReferenceInput source="nationality" reference="countries">
            <AutocompleteInput
                optionText="name"
                optionValue="code"
                label="Nationality"
                filterToQuery={(text) => ({ name: text })}
            />
        </ReferenceInput>

        <ReferenceInput source="country" reference="countries">
            <AutocompleteInput
                optionText="name"
                optionValue="code"
                label="Country"
                filterToQuery={(text) => ({ name: text })}
            />
        </ReferenceInput>
        <DateInputField
            label="Joined Before"
            format="yyyy-MM-dd HH:mm:ss"
            source="user__date_joined__lte"
        />
        <DateInputField
            label="Joined After"
            format="yyyy-MM-dd HH:mm:ss"
            source="user__date_joined__gte"
        />
        <DateInputField
            label="State Change Before"
            format="yyyy-MM-dd"
            source="last_state_change__lte"
        />
        <DateInputField
            label="State Change After"
            format="yyyy-MM-dd"
            source="last_state_change__gte"
        />
        <DateInputField label="Updated Before" format="yyyy-MM-dd HH:mm:ss" source="updated__lt" />
        <DateInputField label="Updated After" format="yyyy-MM-dd HH:mm:ss" source="updated__gte" />
        <DateInputField label="Available From" format="yyyy-MM-dd" source="available_from" />
        <NumberInput label="Available Hours" source="available_for" step={1} />
        <SelectInput
            label="Social Account"
            options={{ format: 'YYYY-MM-dd', ampm: false, clearable: true }}
            source="user__socialaccount__provider"
            choices={[
                { id: 'linkedin_oauth2', name: 'LinkedIn' },
                { id: 'github', name: 'Github' },
                { id: 'xing', name: 'Xing' },
            ]}
        />
        {children}
    </Filter>
);

const List = ({ ...props }) => (
    <BaseList
        {...props}
        sort={{ field: 'score', order: 'desc' }}
        filters={<WorkerFilter alwaysOn />}
        filterDefaultValues={{ show_archived: false }}
        actions={<StateExportActions stateResource="enums/worker/WorkerProfileState" bulkActions={<SelectColumnsButton />} />}
        bulkActionButtons={
            <StateExportBulkActions
                stateResource="enums/worker/WorkerProfileState"
                forceConfirmation
            />
        }
        sx={{ '& .RaList-actions': { display: 'block' } }}
        hasCreate={true}
    >
        <EditableDatagridConfigurable rowClick="show" actions={<EditButton label="" />} omit={['user.email', 'user.motius_email', 'phone', 'department', 'contract_type', "has_running_contract"]} expand={<WorkerPanel {...props} />}>
            <WorkerNameField label="Name" sortBy="user" {...props} />
            <BooleanTrueField source="user_type" label="Guest" condition="guest" sortable={false} />
            <ReferenceArrayField label="Clusters" reference="clusters" source="clusters">
                <SingleFieldList>
                    <ClusterLinkField
                        filterBasePath={props.basePath}
                        filterName="clusters__id__in"
                    />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="state_label" label="State" sortBy="state" />
            <DateField source="user.date_joined" label="Joined Date" sortBy="id" />
            <DateField source="last_state_change" label="Last State Change" />
            <DateField source="updated" label="Last Updated" />
            <BooleanTrueField
                source="has_running_contract"
                label="Active Contract"
                sortable={false}
            />
            <TextField source="user.email" label="Personal Email" />
            <TextField source="user.motius_email" label="Motius Email" />
            <TextField source="phone" label="Phone" />
            <ReferenceField
                label="Department"
                linkType={false}
                reference="workers/departments"
                source="department"
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Contract Type"
                linkType={false}
                reference="enums/contract/ProjectWorkerContractType"
                source="contract_type"
            >
                <TextField source="label" />
            </ReferenceField>
        </EditableDatagridConfigurable>
    </BaseList >
)

export default List;
