import { WizardFormStep, WizardFormStepProps } from '@react-admin/ra-form-layout';
import {
    ReferenceInput,
    FormDataConsumer,
    TextInput,
    AutocompleteInput,
    SelectInput,
    required,
    useDataProvider,
} from 'react-admin';
import React, { Children, useEffect, useRef, useState } from 'react';
import FormRow from '../../../base/forms/FormRow';
import slugify from 'slugify';
import { useForm, useFormContext } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { DateInputField } from '../../../base/input/DateInput';
import AutocompleteCustomInput from '../../../base/input/AutocompleteCustomInput';
import MainDepartmentInput from '../../../base/input/MainDepartmentInput';
import ClientCreateDialog from '../../../client/ClientCreateDialog';
import makeStyles from '@mui/styles/makeStyles';

export const createSlug = (name: string) =>
    slugify(name, {
        lower: true,
        remove: /[^ \-a-zA-Z0-9]/g,
    });

const WidthWrapper = ({ children, ...props }: any) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
                {Children.map(
                    children as React.ReactElement,
                    (child: React.ReactElement) => child && React.cloneElement(child, { ...props })
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    divider: { margin: '1rem 0', width: '100%' },
    hidden: { display: 'none' },
    fullWidth: { width: '100%' },
});

const Wrapper = ({ children, className, ...props }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.fullWidth} hidden={props.hidden}>
            {Children.map(
                children as React.ReactElement,
                (child: React.ReactElement) => child && React.cloneElement(child, { ...props })
            )}
        </div>
    );
};

const ClientField = (props: any) => {
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    const form = useForm();
    const dataProvider = useDataProvider();
    const initialRender = useRef(false);

    useEffect(() => {
        const setPredecessor = async () => {
            dataProvider
                .getList('users/client', {
                    filter: {
                        client_contract__isnull: false,
                        projects__id: values.predecessor,
                    },
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: 'id', order: 'desc' },
                })
                .then((clients) => {
                    if (clients.data.length > 0) {
                        setValue('client_profile', clients.data[0].id);
                    }
                });
        };

        if (!initialRender.current) {
            initialRender.current = true;
            return;
        }
        if (values.predecessor && !values.client_profile) {
            setPredecessor();
        }
    }, [values.predecessor, props.choices, values.client_profile, form, dataProvider]);

    return (
        <AutocompleteInput
            optionText="name"
            fullWidth
            {...props}
            validate={required()}
            label="Client"
            variant="outlined"
            margin="normal"
        />
    );
};

const ProjectStep: React.FC<Omit<WizardFormStepProps, 'label' | 'children'>> = (props) => {
    const { getValues, setValue } = useFormContext();
    const classes = useStyles();
    const [showMore, setShowMore] = useState(false);
    const values = getValues();
    /**
     * We don't allow modifications to slug since unnecessary, so we just inform them.
     */
    const generateSlugMessage = (name?: string): string | null => {
        return name ? 'Project slug will be: ' + createSlug(name) : null;
    };

    const handleNameInternalChange = (event: { target: { value: any; }; }) => {
        setValue('name', event.target.value);
    };

    const clientFilter = values.client_profile ? { client_profile__id: values.client_profile } : {};
    
    const today = new Date();
    const threeMonthsLater = new Date(today);
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    return (
        <WizardFormStep {...props} label="Project">
            <WidthWrapper>
                <FormRow>
                    {['main', 'follow_up'].includes(values.project_type) ? (
                        <>
                            <ReferenceInput
                            allowEmpty
                            label="Client"
                            reference="users/client"
                            source="client_profile"
                            filter={{
                                client_contract__isnull: false,
                            }}
                            filterToQuery={(t: any) => ({
                                name__icontains: t,
                                client_contract__isnull: false,
                            })}
                            fullWidth
                        >
                            <ClientField validate={required()} />
                        </ReferenceInput>
                        <ClientCreateDialog />
                        </>
                    ) : null}
                    <FormRow>
                        <ReferenceInput source="main_department" reference="workers/departments" fullWidth >
                            <MainDepartmentInput />
                        </ReferenceInput>
                    </FormRow>
                    {values.project_type === 'follow_up' ? (
                        <ReferenceInput
                            allowEmpty
                            reference="projects"
                            source="predecessor"
                            filter={{
                                project_type__in: ['main', 'follow_up'],
                                client_contract__isnull: false,
                                ...clientFilter,
                            }}
                            fullWidth
                        >
                            <AutocompleteInput
                                optionText="label"
                                variant="outlined"
                                margin="normal"
                                label="Predecessor Project"
                                filterToQuery={(t: any) => ({
                                    autocomplete: t,
                                    project_type__in: ['main', 'follow_up'],
                                    client_contract__isnull: false,
                                    ...clientFilter,
                                })}
                                validate={required()}
                            />
                        </ReferenceInput>
                    ) : null}
                    {values.project_type === 'extension' ? (
                        <ReferenceInput reference="projects" source="parent">
                            <AutocompleteCustomInput
                                label="Main Project"
                                filter={{
                                    project_type__in: ['main', 'follow_up'],
                                    client_contract__id__isnull: false,
                                }}
                                optionText="label"
                                variant="outlined"
                                margin="normal"
                                disabled={!!values.id}
                                validate={required()}
                                fullWidth
                            />
                        </ReferenceInput>
                    ) : null}
                </FormRow>
                
                <FormRow columnCount={3}>
                    <FormDataConsumer fullWidth label="Internal Name">
                        {({ formData, ...rest }) => (
                            <TextInput
                                source="name_internal"
                                validate={required()}
                                helperText={generateSlugMessage(formData.name_internal) ?? ''}
                                {...rest}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                onChange={handleNameInternalChange}
                            />
                        )}
                    </FormDataConsumer>
                </FormRow>
                <Button
                    className={classes.fullWidth}
                    color="primary"
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? 'Hide' : 'Show More'}
                </Button>
                <Wrapper hidden={!showMore}>
                    <FormRow>
                        <TextInput
                            source="name"
                            label="Name"
                            validate={required()}
                            helperText="Marketing Name for the Talent Pool (same as internal name)"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                        />                                
                        {values.project_type !== 'extension' && (
                                <ReferenceInput
                                    source="classification"
                                    reference="enums/project/ProjectClassification"
                                    fullWidth
                                >
                                    <SelectInput
                                        label="Classification"
                                        optionText="label"
                                        variant="outlined"
                                        margin="normal"
                                        defaultValue="confidential"
                                        validate={required()}
                                    />
                                </ReferenceInput>
                            )}
                    </FormRow>
                    <FormRow columnCount={3}>
                        <DateInputField
                            variant="outlined"
                            source="start_date"
                            label="Start Date"
                            validate={required()}
                            defaultValue={today.toISOString().slice(0, 10)}
                        />
                        <DateInputField
                            variant="outlined"
                            source="end_date"
                            label="End Date"
                            validate={required()}
                            defaultValue={threeMonthsLater.toISOString().slice(0, 10)}
                        />
                    </FormRow>
                </Wrapper>
            </WidthWrapper>
        </WizardFormStep>
    );
};

export default ProjectStep;
