import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';

const TimelogTagForm = (props) => {
    return (
        <SimpleForm {...props} redirect>
            <TextInput source="name" />
            <TextInput source="description" />
        </SimpleForm>
    );
};

export default TimelogTagForm;
