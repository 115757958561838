import React, { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { AutocompleteInput, ReferenceInput, useGetList } from "react-admin";

const InvoiceClientAddress = (props : any) => {
  const form = useForm();
  const {getValues, setValue} = useFormContext();
  const values = getValues();
  const { data } : any = useGetList(
    "contracts/projects",
    {
        sort: { field: "id", order: "DESC" },
        filter: {project__client_profile__id: values.recipient_id}
    }
  );
 
  useEffect(() => {
    const contracts : Array<any> = data && Object.values(data);
    // Only autofill on Invoices and Cancellations
    const isAutoFillTypes =
      values.type === "R" || values.type === "C";

    if (contracts && contracts.length > 0 && isAutoFillTypes && !values.id) {
        setValue("client_address", contracts[0].client_address);
    } else {
        setValue("client_address", null);
    }
  }, [
    values.recipient_id,
    values.type,
    form,
    values.id,
  ]);

  return (
    <ReferenceInput
      source="client_address"
      reference="users/client/address"
      filter={{ client: values.recipient_id }}
    >
      <AutocompleteInput optionText="address" label="Client Address" {...props}/>
    </ReferenceInput>
  );
};

export default InvoiceClientAddress;
