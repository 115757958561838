import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';
import WorkerTagForm from './WorkerTagForm';

const WorkerTagCreate = (props) => {
    return (
        <BaseCreate {...props}>
            <WorkerTagForm />
        </BaseCreate>
    );
};

export default WorkerTagCreate;
