import React, { Fragment, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import { Button, useGetList, useRecordContext } from 'react-admin';
import Mustache from 'mustache';
import DialogMail from './Dialog';
import { ListItemLink } from '../WorkerPanel';
import CopyIcon from '@mui/icons-material/FileCopy';
import useCustomNotify from '../../base/useCustomNotify';

const useStyles = makeStyles({
    listLink: {
        wordWrap: 'anywhere',
    },
});

const MailTo = (props) => {
    const classes = useStyles();
    const notify = useCustomNotify();
    const [open, setOpen] = useState(false);
    const { email, title } = props;
    const record = useRecordContext();

    const { data, error } = useGetList(
        'templates',
        {},
        {},
        { type: 'template.WorkerMailtoTemplate' }
    );
    const templates = data ? Object.values(data) : [];
    templates.push({ id: 0, name: 'Blank', data: {}, content: '' });
    templates.sort((a, b) => a.id - b.id);

    const handleMenuItemClick = (template) => {
        const content = evaluateTemplate(template.content);
        let dataSubject = '';
        try {
            dataSubject = JSON.parse(template.data).subject;
        } catch (e) {
            console.error('cannot parse subject');
        }
        const subject = evaluateTemplate(dataSubject);
        setOpen(false);
        window.open(`mailto:${email}?subject=${subject}&body=${encodeURI(content)}`);
    };

    const handleOpen = () => {
        setOpen(true);
        if (error) {
            notify('Error: Cannot load contract templates', { type: 'warning' });
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const handleCopyEmailToClipboard = (event, email) => {
        navigator.clipboard.writeText(email);
        notify('Email address copied to clipboard');
        event.stopPropagation();
    };

    const evaluateTemplate = (template) => {
        try {
            return Mustache.render(template, {
                worker: record,
                instance: record,
            }); //using both because sometimes people will call it worker, other times instance
        } catch (e) {
            console.error('Could not load template', e);
        }
        return null;
    };

    return (
        <Fragment>
            <ListItemLink className={classes.listLink} onClick={handleOpen}>
                <ListItemIcon>
                    <SendIcon />
                </ListItemIcon>
                <div>
                    <ListItemText primary={title} secondary={email} />
                    <Button
                        color="primary"
                        onClick={(event) => handleCopyEmailToClipboard(event, email)}
                        label={'Copy Email'}
                    >
                        <CopyIcon />
                    </Button>
                </div>
            </ListItemLink>
            <DialogMail
                title="Choose template"
                email={email}
                templates={templates}
                open={open}
                onHandleClick={(t) => handleMenuItemClick(t)}
                onClose={handleClose}
            ></DialogMail>
        </Fragment>
    );
};

export default MailTo;
