import React from 'react';
import { TextInput, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';

const ClientAddressForm = (props) => {
    return (
        <RowForm {...props}>
            <TextInput source="address" />
            <TextInput source="city" />
            <NumberInput source="postal_code" />
            <ReferenceInput source="country" reference="countries">
                <AutocompleteInput
                    optionText="name"
                    optionValue="code"
                    label="Country"
                    filterToQuery={(text) => ({ name: text })}
                />
            </ReferenceInput>
            <TextInput source="email" />
        </RowForm>
    );
};

export default ClientAddressForm;
