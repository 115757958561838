import {
    ArrayField,
    Datagrid,
    DateField,
    ReferenceField,
    SimpleShowLayout,
    TextField,
    ShowController,
} from 'react-admin';
import React from 'react';
import Grid from '@mui/material/Grid';
import StateDialog from '../../base/StateDialog';
import BaseShowView from '../../base/views/BaseShowView';
import NumeralField from '../../base/fields/NumeralField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DatagridBodyWithFooter from '../../base/lists/DatagridBodyWithFooter';
import PanelLabel from '../../base/show/PanelLabel';
import PDFEmbedField from '../../base/fields/PDFEmbedField';
import VersionShow from '../../base/show/VersionShow';

const DatagridBodyTotal = (props) => (
    <DatagridBodyWithFooter
        footer={
            <TableRow>
                <TableCell style={{ padding: '0 12px' }} component="th">
                    Total (with discounts)
                </TableCell>
                <TableCell style={{ padding: '0 12px' }}>
                    <NumeralField
                        record={props.record}
                        source="amount_discounted_net_signed"
                        options={{ style: 'currency', currency: 'EUR' }}
                        label="Net Total"
                    />
                </TableCell>
                <TableCell style={{ padding: '0 12px' }} colSpan={2}>
                    <NumeralField
                        record={props.record}
                        source="amount_gross"
                        options={{ style: 'currency', currency: 'EUR' }}
                        label="Gross Total"
                    />
                </TableCell>
            </TableRow>
        }
        {...props}
    />
);
const InvoiceShow = (props) => (
    <ShowController {...props}>
        {(controllerProps) => (
            <BaseShowView {...props} {...controllerProps}>
                {controllerProps.record && (
                    <SimpleShowLayout>
                        <Grid container spacing={2}>
                            {controllerProps.record.pdf && (
                                <Grid item md={5}>
                                    <PanelLabel>PDF</PanelLabel>
                                    <PDFEmbedField source="pdf" {...controllerProps} />
                                </Grid>
                            )}
                            <Grid item md={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <PanelLabel>Cost Center</PanelLabel>
                                        <ReferenceField
                                            reference="cost-centers"
                                            source="cost_center"
                                            {...controllerProps}
                                        >
                                            <TextField source="label" />
                                        </ReferenceField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PanelLabel>Client Contract</PanelLabel>
                                        <ReferenceField
                                            reference="contracts/projects"
                                            source="client_contract"
                                            {...controllerProps}
                                        >
                                            <TextField source="label" />
                                        </ReferenceField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <PanelLabel>Start Date</PanelLabel>
                                        <DateField source="start_date" {...controllerProps} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PanelLabel>End Date</PanelLabel>
                                        <DateField source="end_date" {...controllerProps} />
                                    </Grid>
                                    {controllerProps.record.category && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Refund Category</PanelLabel>
                                            <ReferenceField
                                                source="category"
                                                label="Category"
                                                reference="enums/payment/InvoiceCategory"
                                                link={false}
                                                allowEmpty
                                                {...controllerProps}
                                            >
                                                <TextField source="label" />
                                            </ReferenceField>
                                        </Grid>
                                    )}
                                    {controllerProps.record.due_date && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Due Date</PanelLabel>
                                            <DateField source="due_date" {...controllerProps} />
                                        </Grid>
                                    )}
                                    {controllerProps.record.sent_date && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Sent Date</PanelLabel>
                                            <DateField source="sent_date" {...controllerProps} />
                                        </Grid>
                                    )}
                                    {controllerProps.record.billing_date && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Billing Date</PanelLabel>
                                            <DateField source="billing_date" {...controllerProps} />
                                        </Grid>
                                    )}
                                    {controllerProps.record.paid_date && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Paid Date</PanelLabel>
                                            <DateField source="paid_date" {...controllerProps} />
                                        </Grid>
                                    )}
                                    {controllerProps.record.tax && (
                                        <Grid item xs={4}>
                                            <PanelLabel>Tax Rate</PanelLabel>
                                            <NumeralField
                                                source="tax"
                                                label="Tax rate"
                                                align="left"
                                                options={{ style: 'percent' }}
                                                {...controllerProps}
                                                style={{ float: 'left' }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <PanelLabel>Line Items</PanelLabel>
                                <ArrayField record={controllerProps.record} source="line_items">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        body={<DatagridBodyTotal {...controllerProps} />}
                                    >
                                        <TextField
                                            source="message"
                                            label="Message"
                                            sortable={false}
                                        />
                                        <NumeralField
                                            source="amount_net"
                                            label="Net Amount"
                                            options={{
                                                style: 'currency',
                                                currency: 'EUR',
                                            }}
                                            sortable={false}
                                        />
                                        <NumeralField
                                            source="amount_gross"
                                            label="Gross Amount"
                                            options={{
                                                style: 'currency',
                                                currency: 'EUR',
                                            }}
                                            sortable={false}
                                        />
                                    </Datagrid>
                                </ArrayField>
                            </Grid>
                            <Grid item md={3}>
                                <StateDialog
                                    record={controllerProps.record}
                                    basePath="/payment/invoices"
                                    recordLabel={controllerProps.record.public_id}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <PanelLabel>Event Log</PanelLabel>
                                <VersionShow
                                    reference="payment/invoices/versions"
                                    {...controllerProps}
                                />
                            </Grid>
                        </Grid>
                    </SimpleShowLayout>
                )}
            </BaseShowView>
        )}
    </ShowController>
);

export default InvoiceShow;
