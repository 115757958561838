import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { FileInput, ReferenceInput, useRecordContext } from 'react-admin';
import InvoiceGraphs from '../../../contract/project/components/InvoiceGraphs';
import InvoiceList from '../../../contract/project/components/InvoiceList';
import CurrencyInput from '../../../base/input/CurrencyInput';
import FileFieldInput from '../../../base/input/FileFieldInput';
import MainDepartmentInput from '../../../base/input/MainDepartmentInput';

const ApprovedContent = () => {
    const record = useRecordContext();

    return (
        <Box mt={4}>
            <Button
                color="primary"
                endIcon={<OpenInNew />}
                href={record?.client_contract?.contract_signed?.url}
                target="_blank"
            >
                Signed Contract
            </Button>
            <Button
                color="primary"
                endIcon={<OpenInNew />}
                href={record?.client_contract?.order_confirmation?.url}
                target="_blank"
            >
                Order Confirmation
            </Button>
            <Box mt={2}>
                <Typography variant="h5" gutterBottom>
                    Additional Settings
                </Typography>
                <ReferenceInput source="main_department" reference="workers/departments">
                    <MainDepartmentInput />
                </ReferenceInput>
                <Typography variant="h5" gutterBottom>
                    Invoices
                </Typography>
                <InvoiceGraphs net_value={record.client_contract.net_value} />
                <CurrencyInput
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    source="client_contract.unused_value"
                    label="Unused Revenue"
                    helperText="Revenue that is not going to be invoiced"
                />
                <FileInput
                    label="Signed Contract"
                    source="client_contract.contract_signed"
                    multiple={false}
                    isRequired
                >
                    <FileFieldInput />
                </FileInput>
                <FileInput
                    label="Offer Confirmation"
                    source="client_contract.order_confirmation"
                    multiple={false}
                    isRequired
                >
                    <FileFieldInput />
                </FileInput>
                <InvoiceList />
            </Box>
        </Box>
    );
};

export default ApprovedContent;
