import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Info } from '@mui/icons-material';
import { WizardFormStep } from '@react-admin/ra-form-layout';
import React, { useEffect, useMemo } from 'react';
import { RadioButtonGroupInput, ReferenceInput, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import InfoCard from './InfoCard';
import { useStep } from '../wizard-form';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    option: {
        marginLeft: '1rem',
        '& label': {
            border: `1px solid #ACD8E1`,
            padding: '1rem',
            borderRadius: '0.5rem',
            display: 'flex',
            flex: '1',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            cursor: 'pointer',
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
            '& > *:first-child': {
                order: 2,
            },
        },
    },
}));

const INFO_TEXT = [
    {
        type: 'main',
        title: 'Main Project',
        description1: 'A main project is a new project with a new team and budget.',
        description2: 'e.g We develop a PoC of a new use case for a client.',
    },
    {
        type: 'follow_up',
        title: 'Follow-up Project',
        description1:
            'A follow-up project, as its name suggests, is a follow up of another project. A follow-up project also has a new team and budget.',
        description2:
            'e.g We developed a PoC for a client and after a couple of months the client wants to develop a MVP for that use case.',
    },
    {
        type: 'extension',
        title: 'Extension Project',
        description1:
            'An extension project extends the existing team and budget for an existing project. Extension project is a sub-project so it does not have its own team planning and controlling.',
        description2:
            'e.g We are developing a MVP with a team of 2 developers and acquire more budget to keep working with the same team for 2 more sprints.',
    },
];

const ProjectTypeRadioInput = (props: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { getValues } = useFormContext();
    const values = getValues();
    const search = useMemo(() => new URLSearchParams(location.search), [])
    const {
        state: { step },
        dispatch
    } = useStep();


    useEffect(() => {
        if (step === 0 && search.get('project_type')) {
            dispatch({ type: 'set', step: step + 1 })
            search.delete('project_type')
        }
    }, [step, search]);

    return (
        <WizardFormStep label="Project Type" {...props}>
            <Typography variant="h4" style={{ marginBottom: '2rem', width: '100%' }}>
                Select Project Type
            </Typography>
            {/* fullWidth is required for fullWidth for react-admin */}
            {/* @ts-ignore */}
            <Box fullWidth className={classes.option}>
                <ReferenceInput
                    reference="enums/project/ProjectType"
                    source="project_type"
                    row
                >
                    <RadioButtonGroupInput
                        row
                        optionText="label"
                        validate={required()}
                        fullWidth
                        label={''}
                    />
                </ReferenceInput>
            </Box>
            {/* fullWidth is required for fullWidth for react-admin */}
            {/* @ts-ignore */}
            <Box display="flex" fullWidth>
                {INFO_TEXT.map((infoText) => (
                    <InfoCard
                        color={infoText.type === values.project_type ? '#B8E9F4' : 'white'}
                        border={
                            infoText.type === values.project_type
                                ? '1px solid transparent'
                                : `1px solid ${theme.palette.secondary.main}`
                        }
                        textColor={'black'}
                        key={infoText.title}
                        icon={<Info style={{ fontSize: 40 }} />}
                    >
                        <Typography variant="h6" gutterBottom>
                            {infoText.title}
                        </Typography>
                        <Typography gutterBottom style={{ marginTop: 8 }}>
                            {infoText.description1}
                        </Typography>
                        <Typography gutterBottom style={{ marginTop: 8 }}>
                            {infoText.description2}
                        </Typography>
                    </InfoCard>
                ))}
            </Box>
        </WizardFormStep>
    );
};

export default ProjectTypeRadioInput;
