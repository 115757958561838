import * as PropTypes from 'prop-types';
import React from 'react';
import getUser from '../../../../../auth/getUser';
import { apiUrl, httpClient } from '../../../../../dataProvider';
import AtlassianCreateContent from '../AtlassianCreateContent';
import useCustomNotify from '../../../../useCustomNotify';

const SpaceCreateDialogContent = ({ confluenceSpaces, platformProject, onFinished }) => {
    const notify = useCustomNotify();

    const handleCreate = async (key, name) => {
        const options = {
            method: 'POST',
            body: JSON.stringify({
                project: {
                    id: platformProject.id,
                    name_internal: name || platformProject.name_internal,
                    slug: platformProject.slug,
                },
                user_email: getUser().email,
                confluence_key: key,
            }),
        };

        try {
            await httpClient(`${apiUrl}/confluence-spaces/confluence`, options);
            notify(`Create request is successfull. Confluece space will be created.`);
            onFinished();
        } catch (e) {
            notify(`Could not create Confluence space`, { type: 'warning' });
        }
    };

    return (
        <AtlassianCreateContent
            keyLabel="Confluence Space"
            existingOptions={confluenceSpaces}
            onCreate={handleCreate}
            platformProject={platformProject}
        />
    );
};

SpaceCreateDialogContent.propTypes = {
    confluenceSpaces: PropTypes.array.isRequired,
    platformProject: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
};

export default SpaceCreateDialogContent;
