/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (name, data) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data[name].rawFile);

        reader.onload = () => resolve({ name: name, result: reader.result });
        reader.onerror = reject;
    });

const handleFile = (fileKeys, params, promises) => {
    fileKeys.forEach(fileKey => {
        if (params.data[fileKey] && params.data[fileKey].rawFile instanceof File) {
            promises.push(convertFileToBase64(fileKey, params.data));
        } else {
            delete params.data[fileKey];
        }
    })
}

const handleClientContractFile = (fileKeys, params, promises) => {
    fileKeys.forEach(fileKey => {
        if (params.data.client_contract[fileKey] && params.data.client_contract[fileKey].rawFile instanceof File) {
            promises.push(convertFileToBase64(fileKey, params.data.client_contract));
        } else {
            delete params.data.client_contract[fileKey];
        }
    })
}

const handleInvoiceFile = (fileKeys, params, promises) => {
    fileKeys.forEach(fileKey => {
        if (params.data[fileKey] && params.data[fileKey].rawFile instanceof File) {
            promises.push(convertFileToBase64(fileKey, params.data));
        } else if (params.data[fileKey] === null) {
            // #MP-2584 Send the null as it is to delete the file
        } else {
            delete params.data[fileKey];
        }
    })
}

const addUploadFeature = (requestHandler) => (type, resource, params) => {
    let promises = [];

    if (type === 'CREATE' || type === 'UPDATE') {
        switch (resource) {
            case 'projects':
                handleFile(['image'], params, promises);
                break;
            case 'users/client':
                handleFile(['logo'], params, promises);
                break;
            case 'users/workers/enrollment-certificates':
                handleFile(['enrollment_certificate'], params, promises);
                break;
            case 'users/workers/residence-permits':
                handleFile(['residence_permit'], params, promises);
                break;
            case 'contracts/projects':
                handleFile(['contract', 'contract_signed', 'calculation_file', 'order_confirmation'], params, promises);
                break;
            case 'users/worker':
                handleFile(['health_insurance', 'cv', 'residence_permit', 'trade_license', 'picture'], params, promises);
                break;
            case 'projects/wizard':
                if (params.data.client_contract) {
                    handleClientContractFile(['calculation_file', 'contract_signed', 'order_confirmation', 'contract'], params, promises);
                }
                handleFile(['image'], params, promises);
                break;
            case 'payment/invoices':
                handleInvoiceFile(['appendix_pdf', 'pdf'], params, promises);
                break;
            default:
                break;
        };
    }

    if (promises.length) {
        return Promise.all(promises).then((results) => {
            // Calculation file is nested for the wizard form.
            const updatedParams = { ...params };
            results.forEach((result) => {
                if (!['calculation_file', 'contract_signed', 'order_confirmation', 'contract'].includes(result.name)) {
                    updatedParams.data = {
                        ...updatedParams.data,
                        [result.name]: result.result,
                    };
                } else {
                    updatedParams.data.client_contract = {
                        ...updatedParams.data.client_contract,
                        [result.name]: result.result,
                    };
                }
            }


            );
            return requestHandler(type, resource, updatedParams);
        });
    } else {
        // for other request types and resources, fall back to the default request handler
        return requestHandler(type, resource, params);
    }
};

export default addUploadFeature;
