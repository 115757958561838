import Show from './InvoiceShow';
import List from './InvoiceList';
import Create from './InvoiceCreate';
import Edit from './InvoiceEdit';
import InvoiceIcon from '@mui/icons-material/Payment';
import React from 'react';

const resourceName = 'Invoice';
const resourceNamePlural = 'Invoices';

const invoice = (permissions) => {
    return {
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        show: (props) => (
            <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        icon: InvoiceIcon,
    };
};

export default invoice;
