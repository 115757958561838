import React from 'react';
import { Button, Dialog, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useDataProvider, useListContext, useRefresh } from 'react-admin';
import { useForm, Controller, } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import { DateInputPicker } from '../../input/DateInput';
import useCustomNotify from '../../useCustomNotify';

const useStyles = makeStyles({
    title: { padding: '0 1rem', marginTop: '1rem' },
    subtitle: { padding: '0 1rem' },
    content: { padding: '1rem' },
});

type WorkloadCreateDialogProps = {
    open: boolean;
    onCloseDialog: () => void;
    worker_name: string;
    project_name: string;
    worker: number;
    project: number;
    start_date: string;
    id: number;
    project_end_date: string;
    weekly_hours?: number;
};

const WorkloadCreateDialog: React.FC<WorkloadCreateDialogProps> = ({
    open,
    onCloseDialog,
    worker_name,
    project_name,
    worker,
    project,
    start_date,
    id,
    project_end_date,
    weekly_hours,
}) => {
    const classes = useStyles();
    const { refetch } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useCustomNotify();
    const refresh = useRefresh();
    const endDate = DateTime.fromISO(start_date).plus({ days: 6 }).toISODate();

    const {
        control: workloadCreateControl,
        handleSubmit: workloadHandleSubmit,
        formState: { isValid: workLoadFormIsValid, isSubmitting: workloadFormIsSubmitting },
        reset,
    } = useForm({
        values: {
            worker: worker,
            project: project,
            id: id,
            start_date: start_date,
            weekly_hours: weekly_hours,
            end_date: endDate,
        },
    });

    const workloadOnSubmit = (formValues: any) => {
        if (!formValues.end_date) {
            delete formValues.end_date;
        }
        return dataProvider.create('invitations/planned', { data: [formValues] }).then(() => {
            notify('Planned hours updated', { type: 'info' });
            refetch && refetch();
            refresh();
            reset();
            onCloseDialog();
        });
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseDialog}>
            <Typography className={classes.title} variant="h6" color="primary">
                Changing planned hours for
            </Typography>
            <Typography variant="h6" color="primary" className={classes.subtitle}>
                <Typography component="span" variant="h6" color="secondary">
                    {worker_name}
                </Typography>{' '}
                for{' '}
                <Typography component="span" variant="h6" color="secondary">
                    {project_name}
                </Typography>
            </Typography>
            <form key={2} onSubmit={workloadHandleSubmit(workloadOnSubmit)}>
                <div className={classes.content}>
                    <Controller
                        name="start_date"
                        control={workloadCreateControl}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <DateInputPicker
                                label="Change planned hours from the date"
                                variant='outlined'
                                options={{
                                    shouldDisableDate: (date: DateTime) =>
                                        date.weekday !== 1 ||
                                        date > DateTime.fromISO(project_end_date),
                                }}
                                fullWidth
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="end_date"
                        control={workloadCreateControl}
                        render={({ field }) => (
                            <DateInputPicker
                                label="Until the date"
                                variant='outlined'
                                options={{
                                    shouldDisableDate: (date: DateTime) =>
                                        date.weekday !== 7 ||
                                        date > DateTime.fromISO(project_end_date),
                                    //to-do this is not working
                                    // defaultCalendarMonth: DateTime.fromISO(values.start_date).plus({
                                    //   days: 6,
                                    // }),
                                }}
                                helperText="Leave empty to set as project end date."
                                fullWidth
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="weekly_hours"
                        rules={{ required: true }}
                        control={workloadCreateControl}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                label="Weekly hours"
                                fullWidth
                            />
                        )}
                    />
                    <Button
                        variant="contained"
                        onClick={workloadHandleSubmit(workloadOnSubmit)}
                        type="submit"
                        startIcon={<SaveIcon />}
                        color="primary"
                        disabled={!workLoadFormIsValid || workloadFormIsSubmitting}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};
export default WorkloadCreateDialog;
