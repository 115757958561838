import * as React from 'react';
import { useNotify } from 'react-admin';

const useCustomNotify = () => {
  const notify = useNotify();

  // const customNotify = (message, options = {}) => {
  //   const { type } = options;
  //   notify(message, { ...options, type, autoHideDuration: 10000 });
  // };

  return notify;
};

export default useCustomNotify;