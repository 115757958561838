import React, { useMemo } from 'react';
import WorkloadTable from '../../base/workload/table/WorkloadTable';
import { useWorkloadState } from '../../base/workload/WorkloadContext';
import { sortRowsByName } from '../../base/workload/workloadUtils';

const WorkerWorkloadTable = (props) => {
    const plannedData = useWorkloadState((state) => state.data);

    const groupedData = useMemo(() => {
        const grouped = new Map();
        if (plannedData) {
            plannedData.forEach((item) => {
                const existingItems = grouped.get(item.worker) || [];
                grouped.set(item.worker, [...existingItems, item]);
            });
        }
        return Array.from(grouped.values());
    }, [plannedData]);

    const sortedGroupedData = groupedData.map((eachWorkerWorkload) =>
        [...eachWorkerWorkload].sort(sortRowsByName)
    );

    return sortedGroupedData.map((workerItems) => (
        <WorkloadTable
            key={workerItems[0].worker}
            group={workerItems[0].worker}
            data={workerItems}
            {...props}
            isProject={false}
        />
    ));
};

export default WorkerWorkloadTable;
