import React, { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { DialogActions, TextField } from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    inputRow: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '1rem',
    },
});

const suggestKey = (platformName) => {
    const name = platformName
        .replace(/[\W_]/g, ' ') // Replace non-alphanum chars with space
        .toUpperCase()
        .replace(/^[^A-Z]+/, ''); // Remove all non-alphabetic chars until a letter

    // Get first letter of each word after splitting from space
    let key = name
        .split(/\s/)
        .reduce((s, w) => (s + w.slice(0, 1)), '')
        .slice(0, 6);

    if (key.length < 2) {
        key = name.replace(/\s/g, '').slice(0, 6);
    }

    return key;
};

const AtlassianCreateContent = ({
    existingOptions,
    platformProject,
    onCreate,
    keyLabel,
    children,
}) => {
    const classes = useStyles();
    const [keyInput, setKeyInput] = useState(suggestKey(platformProject.name_internal));
    const [keyErrorText, setKeyErrorText] = useState('');

    const [nameInput, setNameInput] = useState(platformProject.name_internal);
    const [nameErrorText, setNameErrorText] = useState('');

    const findProjectFromKey = React.useCallback(
        (key) => existingOptions.find((p) => p.key === key),
        [existingOptions]
    );
    const findProjectFromName = React.useCallback(
        (name) => existingOptions.find((p) => p.name === name),
        [existingOptions]
    );

    useEffect(() => {
        const validateKey = (key) => {
            let errorText = '';
            const keyExists = Boolean(findProjectFromKey(key));
            if (keyExists) {
                errorText = 'This key already exists.';
            } else if (!key.match(/^[A-Z][A-Z0-9]{1,9}$/)) {
                errorText =
                    'Keys must start with a letter, followed by one or more alphanumeric characters.';
            }

            setKeyErrorText(errorText);
        };

        validateKey(keyInput);
    }, [keyInput, findProjectFromKey]);

    useEffect(() => {
        const validateName = (name) => {
            let errorText = '';
            if (findProjectFromName(name)) {
                errorText = 'This name already exists.';
            }
            setNameErrorText(errorText);
        };

        validateName(nameInput);
    }, [nameInput, findProjectFromName]);

    const changeKeyInput = (key) => {
        setKeyInput(key);
    };

    const onKeyInput = (event) => {
        changeKeyInput(event.target.value.toUpperCase());
    };

    const onNameInput = (event) => {
        const newName = event.target.value;
        setNameInput(newName);
    };

    const handleCreate = () => {
        onCreate(keyInput, nameInput);
    };

    return (
        <div>
            <div className={classes.inputRow}>
                <TextField
                    value={keyInput}
                    required
                    label={`${keyLabel} Key`}
                    onChange={onKeyInput}
                    error={!!keyErrorText}
                    helperText={keyErrorText}
                />
                <TextField
                    value={nameInput}
                    required
                    label="Name"
                    onChange={onNameInput}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                />
            </div>
            <div>{children}</div>

            <DialogActions>
                <Button
                    disabled={keyErrorText}
                    label={`Create a New ${keyLabel}`}
                    onClick={handleCreate}
                />
            </DialogActions>
        </div>
    );
};

export default AtlassianCreateContent;
