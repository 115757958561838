import React from "react";
import {
  AutocompleteInput,
  AutocompleteInputProps,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import dataProvider from "../../../dataProvider";

const ClientContractField = (props: any) => {
  const { setValue } = useFormContext();

  const onClientContractChange: AutocompleteInputProps["onChange"] = (
    selectedInput: any,
    stateAndHelpers: any
  ) => {
    if (selectedInput) {
      dataProvider()("GET_ONE", `contracts/projects`, {
        id: selectedInput,
      }).then((result) => {
        setValue("cost_center", result.data.cost_center);
      });
    }
  };

  return (
    <AutocompleteInput
      {...props}
      onChange={onClientContractChange}
      optionText="label_extended"
      resettable
      helperText="Only contracts with a cost center assigned are shown"
    />
  );
};

export default ClientContractField;
