import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
const CLIENT_TENANT = process.env.REACT_APP_AZURE_CLIENT_TENANT;

const userManager = new UserManager({
    userStore: new WebStorageStateStore(),
    authority: `https://login.microsoftonline.com/${CLIENT_TENANT}/oauth2/v2.0`,
    metadataUrl: `https://login.microsoftonline.com/${CLIENT_TENANT}/v2.0/.well-known/openid-configuration`,
    client_id: CLIENT_ID,
    redirect_uri: window.location.origin + '/login',
    response_type: 'code',
    scope: 'openid profile email',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
});

export const signinRedirect = () => {
    userManager.signinRedirect({
        state: {
            redirect_to: window.location.hash
        }
    });
}

export default userManager;
