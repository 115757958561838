import { Theme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

const defaultDarkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

export const lightTheme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: '#23BAD9',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#383E4E',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Inter',
        h1: {
            fontFamily: 'TT Firs Neue',
        },
        h2: {
            fontFamily: 'TT Firs Neue',
        },
        h3: {
            fontFamily: 'TT Firs Neue',
        },
        h4: {
            fontFamily: 'TT Firs Neue',
        },
        h5: {
            fontFamily: 'TT Firs Neue',
        },
        h6: {
            fontFamily: 'TT Firs Neue',
        },
        subtitle1: {
            fontWeight: '500',
        },
        subtitle2: {
            fontWeight: '500',
        },
        body1: {
            // Make it same as body2
            fontSize: '0.875rem',
            lineHeight: '1.43',
            letterSpacing: '0.01071em',
        },
    },
    sidebar: {
        width: 220,
    },
    components: {
        ...defaultTheme.components,
        RaDatagrid: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
    },
};

export const darkTheme = {
    ...lightTheme,
    ...defaultDarkTheme,
    sidebar: {
        ...lightTheme.sidebar,
    },
    palette: {
        ...defaultDarkTheme.palette,
        primary: {
            ...lightTheme.palette.primary,
        },
        secondary: {
            light: '#5f5fc4',
            main: '#7f838d',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
};