import { useEffect, useState } from 'react';
import { SavedQueriesList, FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import dataProvider from '../dataProvider';

const ProjectFilterSidebar = () => {
    const [states, setStates] = useState([]);
    const [types, setTypes] = useState([]);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        dataProvider()('GET_LIST', 'enums/project/ProjectState', []).then((result) => {
            setStates(result.data);
        });
        dataProvider()('GET_LIST', 'enums/project/ProjectType', []).then((result) => {
            setTypes(result.data);
        });
        dataProvider()('GET_LIST', 'workers/departments', []).then((result) => {
            setDepartments(result.data);
        });

    }, []);


    return (
        <Card sx={{ order: -1, mr: 2, width: 300 }}>
            <CardContent>
                <SavedQueriesList />
                <FilterList label="State" icon={<></>}  >
                    {states.length > 0 ? (
                        states.map((enumEntry) => (
                            <FilterListItem label={enumEntry['label']} value={{ state: enumEntry['id'] }} key={enumEntry['id']} />
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </FilterList>
                <FilterList label="Type" icon={<></>}  >
                    {types.length > 0 ? (
                        types.map((enumEntry) => (
                            <FilterListItem label={enumEntry['label']} value={{ project_type: enumEntry['id'] }} key={enumEntry['id']} />
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </FilterList>
                <FilterList label="Main Business Unit" icon={<></>}  >
                    {departments.length > 0 ? (
                        departments.map((enumEntry) => (
                            <FilterListItem label={enumEntry['name']} value={{ main_department__id: enumEntry['id'] }} key={enumEntry['id']} />
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </FilterList>
            </CardContent >
        </Card >)
};

export default ProjectFilterSidebar;