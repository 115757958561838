import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    textField: {
        fontSize: theme.typography.body1.fontSize,
    },
}));

const PTResponsibleField = () => {
    const record = useRecordContext();
    const ptResponsible =
        record && record.invitations
            ? record.invitations.find((invitation) =>
                  invitation.responsibilities.includes('human_resource')
              )
            : undefined;

    const ptResponsibleLabel =
        ptResponsible && ptResponsible.worker
            ? ptResponsible.worker.user.name
            : 'No PT responsible assigned.';
    const classes = useStyles();

    return <span className={classes.textField}>{ptResponsibleLabel}</span>;
};

export default PTResponsibleField;
