import React, { useMemo } from 'react';
import { IconButton, TableCell, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronDown from '@mui/icons-material/ExpandMore';
import { Link, useListContext } from 'react-admin';
import useWorkloadStyles from '../useWorkloadStyles';
import { useWorkloadState } from '../WorkloadContext';

type WorkloadTableTitleCellProps = {
    group?: number
    hideDetails?: boolean
    toggleHideDetails: () => void
}

const WorkloadTableTitleCell: React.FC<WorkloadTableTitleCellProps> = ({ group, hideDetails, toggleHideDetails }) => {
    const classes = useWorkloadStyles();
    const { data: generalData, isProject }= useWorkloadState();
    const {filterValues } = useListContext()
    const hideTracked = String(filterValues.hide_tracked) === 'true'
    const data = group ? generalData.filter((i) => i.worker === group) : generalData;

    const name = useMemo(() => {
        if (data.length > 0) {
            return isProject ? data[0]?.project_name : data[0]?.worker_name;
        } else {
            return '';
        }
    }, [data.length, isProject]);

    const id = useMemo(() => {
        if (data.length > 0) {
            return isProject ? data[0]?.project : data[0]?.worker;
        } else {
            return '';
        }
    }, [data.length, isProject]);

    return (
        <TableCell colSpan={3} style={{ padding: 0 }}>
            <span className={classes.titleCell}>
                <span className={`${classes.projectCell} ${classes.paddedCell}`}>
                    <Typography
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        variant="subtitle1"
                    >
                        <IconButton onClick={toggleHideDetails} size="large">
                            {hideDetails ? <ChevronRight /> : <ChevronDown />}
                        </IconButton>
                        {isProject ? (
                            <Link to={`/projects/${id}/show/workload`}>{name}</Link>
                        ) : (
                            <Link to={`/users/worker/${id}/show/workload`}>{name}</Link>
                        )}
                    </Typography>
                </span>
                <span className={classes.hoursCell}>
                    <span className={classes.paddedCell}>
                        <Typography variant="body1">Planned</Typography>
                    </span>
                    <span className={classes.paddedCell}>
                        <Typography variant="body1">Absences</Typography>
                    </span>
                    {!hideTracked && (
                        <span className={classes.trackedCell}>
                            <Typography variant="body1" className={classes.muted}>
                                Tracked
                            </Typography>
                        </span>
                    )}
                </span>
            </span>
        </TableCell>
    );
};

export default WorkloadTableTitleCell;
