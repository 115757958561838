import React from 'react';
import { TableRow } from '@mui/material';
import WorkloadTableRowTitleCell from './WorkloadTableRowTitleCell';
import WorkloadTableCell from './WorkloadTableCell';
import useWorkloadStyles from '../useWorkloadStyles';
import { RowData, useWorkloadState } from '../WorkloadContext';
import {
    getPlannedWeeklyHoursByDateTime,
    getWeeklyAvailabilityByDate,
    getWeekRangeFromFilterValues,
} from '../workloadUtils';
import { useListContext, useStore } from 'react-admin';
import { Control } from "react-hook-form";

type WorkloadTableRowProps = {
    row: RowData
    disabled: boolean
    control: Control
}

const WorkloadTableRow: React.FC<WorkloadTableRowProps> = ({ row, disabled, control, ...props }) => {
    const classes = useWorkloadStyles();
    const {
        availability
    } = useWorkloadState();
    const { filterValues } = useListContext();
    const weekRange = getWeekRangeFromFilterValues(filterValues);
    const hideTracked = String(filterValues.hide_tracked) === 'true'
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming

    return (
        <TableRow key={row.project_name} className={row.state !== 6 ? classes.trackedCell : ''}>
            <WorkloadTableRowTitleCell row={row} disabled={disabled} />
            {[...Array(weekCount)].map((_, i) => {
                const weekStartDateTime = weekRange.start.plus({ week: i });
                
                const rowValue = getPlannedWeeklyHoursByDateTime(row, weekStartDateTime) || 0;
                return (
                    <WorkloadTableCell
                        key={row.id}
                        row={row}
                        originalValue={rowValue}
                        weekStartDate={weekStartDateTime.toISODate()}
                        projectEndDate={row.project_end_date}
                        availability={getWeeklyAvailabilityByDate(
                            availability,
                            row.worker,
                            weekStartDateTime
                        )}
                        trackedTime={Number(
                            row.tracked.find((t) => t.week === weekStartDateTime.weekNumber)
                                ?.hours || 0
                        )}
                        disabled={disabled}
                        hideTracked={hideTracked}
                        control={control}
                    />
                );
            })}
        </TableRow>
    );
};
export default React.memo(WorkloadTableRow);
