import ProjectRoleForm from './ProjectRoleForm';
import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';
import { useRedirect } from 'react-admin';

const ProjectRoleEdit = (props) => {
    const redirect = useRedirect();
    const onEditSuccess = (response) => {
        if (response?.project) {
            redirect(`/projects/${response.project}/show/team`);
        }
    };
    return (
        <BaseEdit {...props} mutationOptions={{ onSuccess: onEditSuccess }}>
            <ProjectRoleForm location={props.location} />
        </BaseEdit>
    );
};

export default ProjectRoleEdit;
