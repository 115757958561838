import TimelogForm from './TimelogForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const TimelogEdit = (props) => (
    <BaseEdit {...props}>
        <TimelogForm location={props.location} />
    </BaseEdit>
);

export default TimelogEdit;
