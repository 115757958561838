import {
    Filter,
    List,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextField,
    TextInput,
    EditButton,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import React from 'react';
import HeadTitle from '../base/HeadTitle';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

const PositionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name__icontains" alwaysOn />
        <ReferenceArrayInput source="groups__id__in" reference="users/group" alwaysOn>
            <AutocompleteArrayInput
                optionText="name"
                label="Group"
                filterToQuery={(text) => ({ name__icontains: text })}
            />
        </ReferenceArrayInput>
    </Filter>
);

const PositionList = (props) => (
    <List
        title={<HeadTitle>Positions</HeadTitle>}
        filters={<PositionFilter />}
        perPage={25}
        {...props}
    >
        <EditableDatagrid rowClick="show" actions={<EditButton label="" />}>
            <ShowElementLink source="name" obj="users/positions" />
            <TextField source="description" />
            <ReferenceArrayField label="Groups" reference="users/group" source="groups">
                <SingleFieldList link="show">
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </EditableDatagrid>
    </List>
);

export default PositionList;
