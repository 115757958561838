import IframeResizer from 'iframe-resizer-react';
import useGetResource from '../../base/hooks/useGetResource';

const MetabaseEmbed = ({ record, ...props }) => {
    const { data: metabaseUrls } = useGetResource(`/projects/${record.id}/metabase`, {}, {}, {});

    return metabaseUrls['controlling'] ? (
        <IframeResizer
            onload="iFrameResize({}, this)"
            title="metabase"
            src={metabaseUrls['controlling']}
            frameBorder={0}
            width="100%"
            allowTransparency
        />
    ) : null;
};
export default MetabaseEmbed;
