import React from 'react';
import { TextField, Datagrid, Filter, TextInput } from 'react-admin';
import BaseList from '../base/views/BaseList';
import ShowElementLink from '../base/buttons/ShowElementLink';

const PermissionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name__icontains" alwaysOn />
    </Filter>
);

const PermissionList = (props) => {
    return (
        <BaseList {...props} filters={<PermissionFilter />}>
            <Datagrid rowClick="show">
                <ShowElementLink source="name" obj="users/group" />
                <TextField source="permission_count" label="Permissions Assigned" />
            </Datagrid>
        </BaseList>
    );
};

export default PermissionList;
