import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import TableBody from '@mui/material/TableBody';
import classnames from 'classnames';
import { DatagridRow, useListContext } from 'react-admin';
import TableFooter from '@mui/material/TableFooter';

const DatagridBody = ({
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    isLoading,
    onToggleItem,
    resource,
    row,
    rowClick,
    rowStyle,
    styles,
    version,
    footer,
    ...rest
}) => {
    const { selectedIds } = useListContext();
    return (
        <>
            <TableBody className={classnames('datagrid-body', className)} {...rest}>
                {
                    data?.map((rowData, rowIndex) => {
                        return React.cloneElement(
                            row,
                            {
                                basePath,
                                classes,
                                expand,
                                hasBulkActions,
                                hover,
                                id: data[rowIndex].id,
                                key: data[rowIndex].id,
                                onToggleItem,
                                record: rowData,
                                resource,
                                rowClick,
                                selected: selectedIds
                                    ? selectedIds.includes(data[rowIndex].id)
                                    : false,
                                style: rowStyle ? rowStyle(rowData, rowIndex) : null,
                            },
                            children
                        );
                    })}
            </TableBody>
            <TableFooter>{footer}</TableFooter>
        </>
    );
};

DatagridBody.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    data: PropTypes.object.isRequired,
    expand: PropTypes.node,
    hasBulkActions: PropTypes.bool.isRequired,
    hover: PropTypes.bool,
    isLoading: PropTypes.bool,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    row: PropTypes.element.isRequired,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    styles: PropTypes.object,
    version: PropTypes.number,
    footer: PropTypes.object,
};

DatagridBody.defaultProps = {
    data: {},
    hasBulkActions: false,
    row: <DatagridRow />,
};

const PureDatagridBody = shouldUpdate(
    (props, nextProps) =>
        props.version !== nextProps.version ||
        nextProps.isLoading === false ||
        props.data !== nextProps.data
)(DatagridBody);

// trick material-ui Table into thinking this is one of the child type it supports
// @ts-ignore
PureDatagridBody.muiName = 'TableBody';

export default PureDatagridBody;
