import {
    ArrayInput,
    SelectInput,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    required,
    SimpleFormIterator,
    SimpleForm,
} from 'react-admin';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const defaultSkills = [
    { tool: 1172 }, // Communication / Talk to each other
    { tool: 32592 }, // Cultural Fit
    { tool: 20148 }, // Technical Quality
    { tool: 7869 }, // Manage yourself
    { tool: 1763 }, // Solve your issues efficiently
    { tool: 32416 }, // Autonomy
    { tool: 1152 }, // Support your team
];

export const SkillEntryFormIterator = (props) => (
    <SimpleFormIterator {...props}>
        <ReferenceInput source="tool" reference="tools">
            <AutocompleteInput
                optionText="name"
                label="Tool"
                shouldRenderSuggestions={(val) => {
                    return val.trim().length > 0;
                }}
                validate={[required()]}
            />
        </ReferenceInput>
        <ReferenceInput
            label="Estimation"
            source="estimation"
            reference="enums/project/ProjectRoleSkillEstimation"
        >
            <SelectInput style={{ minWidth: 200 }} optionText="label" validate={[required()]} />
        </ReferenceInput>
    </SimpleFormIterator>
);

SkillEntryFormIterator.defaultProps = {
    resource: 'users/workers/skill-estimation-entry',
};

const WorkerSkillEstimationForm = ({ redirect, ...props }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const project = parseInt(searchParams.get('project'));
    const worker = parseInt(searchParams.get('worker'));

    return (
        <SimpleForm
            {...props}
            quickSave={false}
            redirect={(basePath, id, data) => `/users/worker/${data.worker}/show/skill-estimation`}
            defaultValues={{ ...props.initialValues, worker, project }}
        >
            <TextInput source="worker" style={{ display: 'none' }} />
            <TextInput source="title" validate={[required()]} />
            <ReferenceInput
                label="Worker"
                source="worker"
                reference="users/worker"
                default={worker}
            >
                <AutocompleteInput optionText="label" validate={[required()]} />
            </ReferenceInput>

            <ReferenceInput label="Project" source="project" reference="projects" default={project}>
                <AutocompleteInput source="name" />
            </ReferenceInput>
            <ArrayInput source="entries">
                <SkillEntryFormIterator />
            </ArrayInput>
        </SimpleForm>
    );
};

export default WorkerSkillEstimationForm;
