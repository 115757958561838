import ProjectRoleForm from './ProjectRoleForm';
import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';
import { useRedirect } from 'react-admin';

const ProjectRoleCreate = (props) => {
    const redirect = useRedirect();
    const onCreateSuccess = (response) => {
        if (response?.project) {
            redirect(`/projects/${response.project}/show/team`);
        }
    };
    return (
        <BaseCreate {...props} mutationOptions={{ onSuccess: onCreateSuccess }}>
            <ProjectRoleForm location={props.location} />
        </BaseCreate>
    );
};

export default ProjectRoleCreate;
