import makeStyles from '@mui/styles/makeStyles';

export const workloadGraphColors = {
    absences: 'url(#striped)',
    trackedNormal: 'var(--color-primary)',
    trackedOver: '#DE6B48',
    plannedNormal: '#C2E8F2',
    plannedRemaining: '#eeb6a5',
}

const useWorkloadStyles = makeStyles(theme => ({
    root: {
        padding: '1rem',
    },
    iconButton: {
        padding: '8px',
    },
    icon: {
        width: '24px',
        fill: 'var(--color-primary)',
    },
    warningIcon: {
        marginLeft: 8,
        color: theme.palette.warning.dark,
        verticalAlign: "middle"
    },
    nameCell: {
        paddingTop: '3rem',
    },
    dateNav: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    titleCell: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    spacedInCell: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    projectCell: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '70%',

        '& a, & h6': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    hoursCell: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    denseCell: {
        padding: '0',
        "& svg": {
            overflow: 'visible'
        }
    },
    paddedCell: {
        padding: '1rem',
        border: '1px solid transparent',
    },
    plannedCell: {
        position: 'relative',
        padding: '0',
        '&:hover .planned-button': {
            display: 'block'
        },
        '& input': {
            padding: '1rem',
            border: '1px solid transparent',
            '&:focus, &.selected': {
                border: '1px solid var(--color-primary) !important',
            },
            '&:hover': {
                border: '1px solid #B8E9F4',
            },
        },
    },
    plannedCellButton: {
        position: 'absolute',
        right: '0',
        top: '12px',
        display: 'none'
    },
    trackedCell: {
        padding: '1rem',
        background: theme.palette.background.default,
    },
    activeColumn: {
        color: 'black',
    },
    muted: {
        color: theme.palette.text.secondary,
        lineHeight: 1.43,
    },
    loading: {
        width: '100%',
        marginTop: '1rem',
    },
}));

export default useWorkloadStyles;
