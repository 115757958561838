import React, { useState, useEffect } from 'react';
import { Button, useRefresh } from 'react-admin';
import {
    Typography,
    Grid,
    Checkbox,
    Paper,
    FormControlLabel,
    LinearProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { httpClient, apiUrl } from '../dataProvider';
import VersionShow from '../base/show/VersionShow';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router';
import useCustomNotify from '../base/useCustomNotify';

const useStyles = makeStyles({
    progressBar: {
        marginBottom: '1rem',
    },
    paper: {
        padding: '2rem',
    },
    title: {
        color: 'black',
    },
    subtitle: {
        marginTop: '0.5rem',
    },
    list: {
        listStyle: 'none',
        paddingLeft: 0,
        marginTop: '0.5rem',
    },
    checkbox: {
        width: '32px',
        height: '32px',
    },
    filterContainer: {
        marginBottom: '2rem',
    },
});

const PermissionForm = ({ ...rest }) => {
    const { id } = useParams();
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [visibility, setVisibility] = useState('assigned');
    const classes = useStyles();
    const notify = useCustomNotify();
    const refreshView = useRefresh();

    useEffect(() => {
        const groupValues = (_data) => {
            const groupedValues = {};
            Object.values(_data).forEach((datum) => {
                const objectKey = `${datum.content_type.app_label}`;
                if (groupedValues[objectKey]) {
                    const subcategoryKey = datum.content_type.model;
                    if (groupedValues[objectKey][subcategoryKey]) {
                        groupedValues[objectKey][subcategoryKey] = {
                            ...groupedValues[objectKey][subcategoryKey],
                            permissions: [
                                ...groupedValues[objectKey][subcategoryKey].permissions,
                                {
                                    id: datum.id,
                                    label: datum.name.substring(4),
                                },
                            ],
                        };
                    } else {
                        groupedValues[objectKey][subcategoryKey] = {
                            permissions: [
                                {
                                    id: datum.id,
                                    label: datum.name.substring(4),
                                },
                            ],
                        };
                    }
                } else {
                    groupedValues[objectKey] = {
                        [datum.content_type.model]: {
                            permissions: [
                                {
                                    id: datum.id,
                                    label: datum.name.substring(4),
                                },
                            ],
                        },
                    };
                }
            });
            return Object.entries(groupedValues);
        };

        const getPermissions = () => {
            setLoading(true);
            const filters = {
                page_size: '1500',
                group: id,
                visibility: visibility,
            };
            const queryParams = new URLSearchParams(filters).toString();
            return httpClient(`${apiUrl}/users/permission?${queryParams}`).then((data) => {
                setLoading(false);
                return data.json.results;
            });
        };

        const getGroupNameAndPermissions = () => {
            return httpClient(`${apiUrl}/users/group/${id}`).then((data) => {
                setGroupName(data.json.name);
                return data.json.permissions;
            });
        };

        getGroupNameAndPermissions().then((permissions) => {
            let alreadyExistingPermissions = {};
            permissions.forEach((permission) => (alreadyExistingPermissions[permission] = true));
            setSelectedPermissions(alreadyExistingPermissions);
            getPermissions().then((data) => {
                const groupedData = groupValues(data);

                setFilteredData(groupedData);
            });
        });
    }, [id, visibility]);

    const togglePermission = (e) => {
        const newPermissions = {
            ...selectedPermissions,
            [e.target.name]: !selectedPermissions[e.target.name],
        };
        setSelectedPermissions(newPermissions);
    };

    const savePermissions = () => {
        const permissions = Object.keys(selectedPermissions).filter(
            (permission) => selectedPermissions[permission]
        );
        httpClient(`${apiUrl}/users/group/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ permissions }),
        })
            .then((response) => {
                notify('Permissions saved');
                refreshView();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleVisibilityChange = (e) => {
        setVisibility(e.target.value);
    };

    return (
        <Paper className={classes.paper}>
            <div className={classes.filterContainer}>
                <FormControl variant="filled">
                    <InputLabel id="visibility-select-label">Visibility</InputLabel>
                    <Select
                        id="visibility-select"
                        value={visibility}
                        label="Visibility"
                        onChange={handleVisibilityChange}
                    >
                        <MenuItem value="assigned">Assigned</MenuItem>
                        <MenuItem value="useful">Useful</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Typography className={classes.title} variant="display1" gutterBottom>
                Permissions for {groupName}
            </Typography>
            {loading ? (
                <LinearProgress className={classes.progressBar} />
            ) : (
                filteredData.map((data) => (
                    <div key={data[0]}>
                        <Typography variant="title">{data[0]}</Typography>
                        <Grid container>
                            {Object.entries(data[1]).map((permissions) => (
                                <Grid item xs={3} key={permissions[0]}>
                                    <Typography className={classes.subtitle} variant="body2">
                                        {permissions[0]}
                                    </Typography>
                                    <ul className={classes.list}>
                                        {permissions[1].permissions.map((permission) => (
                                            <li key={permission.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={permission.id}
                                                            className={classes.checkbox}
                                                            checked={
                                                                selectedPermissions[permission.id]
                                                            }
                                                            onChange={togglePermission}
                                                            value={
                                                                selectedPermissions[permission.id]
                                                            }
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ))
            )}
            <VersionShow record={{ id }} reference="users/group/versions" basePath="users/group" />
            <Button label="Save Permissions" variant="contained" onClick={savePermissions}>
                <SaveIcon />
            </Button>
        </Paper>
    );
};

export default PermissionForm;
