import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  required,
  TextInput,
  SelectInput,
} from "react-admin";
import { RowForm } from "@react-admin/ra-editable-datagrid";

const WorkerToolForm = ({ ...props }) => {
  const worker = parseInt(props.worker ?? "");
  return (
    <RowForm {...props} defaultValues={{ worker }}>
      <ReferenceInput
        source="tool.id"
        reference="tools"
      >
        <AutocompleteInput
          optionText="name"
          label="Tool"
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 0;
          }}
          validate={[required()]}
        />
      </ReferenceInput>
      <ReferenceInput
        source="rating"
        reference="enums/project/ProjectRoleSkillEstimation"
        validate={[required()]}
      >
        <SelectInput
          style={{ minWidth: 200 }}
          label="Rating"
          optionText="label"
          defaultValue={3}
        />
      </ReferenceInput>
      <TextInput source="worker" style={{ display: "none" }} />
    </RowForm>
  );
};

export default WorkerToolForm;
