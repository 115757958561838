import React from 'react';
import WorkerTagForm from './WorkerTagForm';
import BaseEdit from '../../base/views/BaseEdit';
import VersionShow from '../../base/show/VersionShow';
import { Grid } from '@mui/material';
import PanelLabel from '../../base/show/PanelLabel';

const WorkerTagEdit = (props) => {
    return (
        <BaseEdit {...props} redirect="list">
            <WorkerTagForm />
            <Grid item md={12}>
                <PanelLabel>Event Log</PanelLabel>
                <VersionShow reference="users/workers/tags/versions" />
            </Grid>
        </BaseEdit>
    );
};

export default WorkerTagEdit;
