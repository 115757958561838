import create from 'zustand'

type RowState = {
    editing: boolean,
    setEditing: () => void
    unsetEditing: () => void
}

export const useRowState = create<RowState>((set) => ({
    editing: false,
    setEditing: () => set({editing: true}),
    unsetEditing: () => set({editing: false})
}))