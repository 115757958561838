import React from 'react';
import {
    NumberInput,
    SelectArrayInput,
    ReferenceInput,
    ReferenceArrayInput,
    ChipField,
    TextInput,
    SimpleForm,
    required,
    useRecordContext,
} from 'react-admin';
import StateDialog from '../base/StateDialog';
import InputAdornment from '@mui/material/InputAdornment';
import AutocompleteCustomInput from '../base/input/AutocompleteCustomInput';
import { DateInputField } from '../base/input/DateInput';
import { DateTime } from 'luxon';

const TimelogForm = (props) => {
    const record = useRecordContext();

    const formatCreatedDate = (value) => {
        return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    };

    return (
        <SimpleForm {...props}>
            <StateDialog
                orientation="horizontal"
                record={record}
                basePath="/timelogs"
                recordLabel={record.id}
            />
            <ReferenceInput
                label="Worker"
                source="worker"
                reference="users/worker"
            >
                <AutocompleteCustomInput optionText="label" validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput
                label="Project"
                source="project"
                reference="projects"
            >
                <AutocompleteCustomInput optionText="label" validate={[required()]} />
            </ReferenceInput>
            <ReferenceArrayInput label="Tags" source="tags" reference="timelog-tags">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
            <TextInput source="created" format={(v) => formatCreatedDate(v)} disabled />
            <DateInputField source="date" label="Start Time" validate={required()} />
            <NumberInput
                source="duration_seconds"
                label="Duration"
                InputProps={{
                    startAdornment: <InputAdornment position="start">sec</InputAdornment>,
                }}
            />
            <TextInput source="comment" label="Comment" />
        </SimpleForm>
    );
};

export default TimelogForm;
