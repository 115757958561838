import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import {
  SaveButton,
  Toolbar,
  ToolbarProps,
  useRecordContext,
  Button,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import ContractStateConfirmDialog from "./ContractStateConfirmDialog";
import { useFormState } from "react-hook-form";

const useStyles = makeStyles({
  editButton: {
    paddingTop: "6px",
    paddingBottom: "6px",
    marginRight: "0.5rem",
  },
});

const ContractShowToolbar = (props?: ToolbarProps) => {
  const record = useRecordContext();
  const { isValid, isDirty } = useFormState();
  const classes = useStyles();
  const navigate = useNavigate();
  const isFinalState = useMemo(
    () => record?.client_contract?.state === "paid",
    [record]
  );
  const isEditBtnShown = ["reviewed", "verbal_confirmation"].includes(record?.client_contract.state)
  const nextStates = useMemo(() => {
    switch (record?.client_contract?.state) {
      case "reviewed":
        return [
          { state: "approved", label: "Save and Set as Approved" },
          { state: "verbal_confirmation", label: "Save and Set as Verbal confirmation" }
        ];
      case "verbal_confirmation":
        return [
          { state: "approved", label: "Save and Set as Approved" }
        ];
      case "approved":
        return [{ state: "billed" }];
      case "billed":
        return [{ state: "paid" }];
      default:
        return [{ state: "approved" }];
    }
  }, [record]);

  return (
    <Toolbar {...props}>
      {!isFinalState && (
        <>
          {isEditBtnShown && (
            <Button
              variant="contained"
              label="Edit"
              className={classes.editButton}
              onClick={() =>
                navigate(
                  `/projects/wizard/${record.id}/${isEditBtnShown ? 1 : 2
                  }`
                )
              }
            />
          )}
          <SaveButton
            //handleSubmitWithRedirect={props?.handleSubmitWithRedirect}
            disabled={!isValid || !isDirty}
          //mutationOptions={{ onSuccess: handleOnSucess}}
          />
          {
            nextStates.map((nextState) => (
              <ContractStateConfirmDialog
                // @ts-ignore
                label={nextState.label}
                saveAndTransition={["approved", "verbal_confirmation"].includes(nextState.state)}
                targetState={nextState.state}
                disabled={!isValid && nextState.state === "approved"}
                key={nextState.state}
              />
            ))
          }
        </>
      )}
    </Toolbar>
  );
};

export default ContractShowToolbar;
