import React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { useGetList } from 'react-admin';

const columns = [{ field: 'name', headerName: 'Task Name', width: 450 }];

const useStyles = makeStyles({
    root: {
        marginTop: '0.5rem',
        border: 'none',
    },
    tasks: {
        display: 'flex',
        minHeight: 500,
        marginTop: '0.5rem',
    },
});

const TaskSelector = ({ projectId, setSelectedRows }) => {
    const classes = useStyles();

    const { data, loading } = useGetList(
        'projects/tasks',
        { page: 1, perPage: 500 },
        {},
        { project_id: projectId }
    );
    const tasks = data ? Object.values(data) : [];

    if (loading) {
        return <LinearProgress className={classes.root} />;
    }

    const onTaskCheckToggle = (rowIds) => {
        setSelectedRows(rowIds.map((r) => Number(r)));
    };

    return (
        <div className={classes.root}>
            <Typography variant="caption">Tasks</Typography>
            <div className={classes.tasks}>
                <DataGrid
                    classes={classes}
                    rows={tasks}
                    columns={columns}
                    pageSize={10}
                    checkboxSelection
                    autoHeight
                    density="compact"
                    onSelectionModelChange={onTaskCheckToggle}
                />
            </div>
        </div>
    );
};

export default TaskSelector;
