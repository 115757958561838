import React from 'react';
import Create from './TemplateCreate';
import Edit from './TemplateEdit';
import List from './TemplateList';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const resourceName = 'Template';
const resourceNamePlural = 'Templates';

const template = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    icon: InsertDriveFileIcon,
};

export default template;
