import * as React from 'react';
import { ComponentProps } from 'react';
import { Toolbar as MUIToolbar, Button, Grid } from '@mui/material';
import { SaveButton, Toolbar } from 'react-admin';

/**
 * The Toolbar displayed at the bottom of WizardForm.
 *
 * @prop {boolean} hasPreviousStep Optionnal. Does the wizard have a previous step?
 * @prop {boolean} hasNextStep Optionnal. Does the wizard have a next step?
 * @prop {Function} onPreviousClick Optionnal. Previous button click action
 * @prop {Function} onNextClick Optionnal. Next button click action
 * @prop {...BaseToolbarSubmitProps}
 */
export const WizardToolbar = ({
    hasPreviousStep = false,
    hasNextStep = false,
    onPreviousClick = noop,
    onNextClick = noop,
}: WizardToolbarProps) => {
    return (
        <MUIToolbar>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    {hasPreviousStep ? (
                        <Button variant="text" onClick={onPreviousClick}>
                            Previous
                        </Button>
                    ) : null}
                </Grid>
                <Grid item>
                    {hasNextStep ? (
                        <Button
                            //disabled={invalid}
                            variant="contained"
                            color="primary"
                            onClick={onNextClick}
                        >
                            Next
                        </Button>
                    ) : (
                        <SaveButton />
                    )}
                </Grid>
            </Grid>
        </MUIToolbar>
    );
};

type BaseToolbarSubmitProps = ComponentProps<typeof Toolbar>;

export type WizardToolbarProps = {
    hasPreviousStep?: boolean;
    hasNextStep?: boolean;
    onPreviousClick?: () => void;
    onNextClick?: () => void;
    currentStep?: number;
} & BaseToolbarSubmitProps;

const noop = (): void => { /* ignore sonarqube empty arrow function warning*/ };
