import React from 'react';
import * as PropTypes from 'prop-types';
import AtlassianDialogContent from '../AtlassianDialogContent';
import ProjectConnectDialogContent from './ProjectConnectDialogContent';
import ProjectCreateDialogContent from './ProjectCreateDialogContent';
import useGetResource from '../../../../hooks/useGetResource';

const JiraDialogContent = ({ platformProject, closeDialog }) => {
    const { data: jiraProjects, loading } = useGetResource(
        '/jira-projects/jira',
        { non_connected: true },
        'Could not fetch jira projects.'
    );

    return (
        <AtlassianDialogContent
            loading={loading}
            platformProject={platformProject}
            closeDialog={closeDialog}
            connectContent={
                <ProjectConnectDialogContent
                    jiraProjects={jiraProjects}
                    platformProject={platformProject}
                    onFinished={closeDialog}
                ></ProjectConnectDialogContent>
            }
            createContent={
                <ProjectCreateDialogContent
                    jiraProjects={jiraProjects}
                    platformProject={platformProject}
                    onFinished={closeDialog}
                ></ProjectCreateDialogContent>
            }
        />
    );
};

JiraDialogContent.propTypes = {
    platformProject: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

export default JiraDialogContent;
