import Edit from './TimelogEdit';
import List from './TimelogList';
import Show from './TimelogShow';
import React from 'react';

const resourceName = 'Timelog';
const resourceNamePlural = 'Timelogs';

const timelog = {
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    show: (props) => (
        <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default timelog;
