import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    CircularProgress,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    DialogContent,
} from '@mui/material';

const useStyles = makeStyles({
    root: {
        minHeight: 120,
    },
    radioGroup: {
        flexDirection: 'row',
    },
    loading: {
        textAlign: 'center',
    },
});

const AtlassianDialogContent = ({
    platformProject,
    closeDialog,
    loading,
    connectContent,
    createContent,
    ...props
}) => {
    const [method, setMethod] = useState('connect');
    const classes = useStyles();

    if (loading) {
        return (
            <div className={`${classes.root} ${classes.loading}`}>
                <p>Fetching...</p>
                <CircularProgress size={50} />
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup
                        className={classes.radioGroup}
                        value={method}
                        onChange={(e) => setMethod(e.target.value)}
                    >
                        <FormControlLabel label="Connect" value="connect" control={<Radio />} />
                        <FormControlLabel label="Create New" value="create" control={<Radio />} />
                    </RadioGroup>
                </FormControl>

                {method === 'connect' ? connectContent : createContent}
            </DialogContent>
        </div>
    );
};

export default AtlassianDialogContent;
