import Create from './ClientCreate';
import Edit from './ClientEdit';
import List from './ClientList';
import React from 'react';

const resourceName = 'Client';
const resourceNamePlural = 'Clients';

const client = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default client;
