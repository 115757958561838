import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import React from 'react';
import {
    FormDataConsumer,
    NumberField, TextField, WrapperField
} from 'react-admin';
import CurrencyField from '../../../../base/fields/CurrencyField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, Tooltip } from '@mui/material';

const getTitle = (safety: number, estimated_days_safety: number) => {
    return `With safety factor of ${safety}, the total days are ${estimated_days_safety}`
}

const RoleEditableGrid = (props: any) => {
    return (
        <EditableDatagrid {...props} mutationMode="pessimistic" >
            <TextField source="name" />
            <CurrencyField source="hourly_rate" />
            <NumberField source="hours" label="Weekly Hours" />
            <WrapperField label="Total Hours">
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (Number(formData.client_contract.safety) !== 1.0) {
                            console.log(formData);
                            return (
                                <Tooltip title={getTitle(formData.client_contract.safety, formData.client_contract.estimated_days)}>
                                    <IconButton>
                                        <WarningAmberIcon />
                                    </IconButton>
                                </Tooltip>
                            );
                        }
                    }}
                </FormDataConsumer>
                <NumberField source="total_hours" />
            </WrapperField>
            <CurrencyField source="daily_rate" />
            <CurrencyField source="total_rate" />
        </EditableDatagrid >
    );
};

export default RoleEditableGrid