import { IconButton, TableCell, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronDown from '@mui/icons-material/ExpandMore';
import { Link } from 'react-admin';
import { RowData, useWorkloadState } from '../WorkloadContext';

type WorkloadGraphTitleProps = {
    index: number,
    id: number,
    name: string,
    hideDetails: boolean,
    toggleHideDetails: () => void,
    row: RowData,
}

const get_subtitle = (row: any) => {
    // Show invitation state for all invitations except accepted beside the role name
    if (row.role_name) {
        if (row.state !== 6) {
            return `${row.role_name}(${row.state_label})`;
        }
        return row.role_name;
    } else if (row.state && row.state !== 6) {
        return `(${row.state_label})`;
    }
    return '';
};

const WorkloadGraphRowTitle: React.FC<WorkloadGraphTitleProps> = ({
    index,
    id,
    name,
    hideDetails,
    toggleHideDetails,
    row,
}) => {
    const isProject = useWorkloadState(state => state.isProject)

    return index === 0 ? (
        <TableCell colSpan={3}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                {!isProject && (
                    <IconButton onClick={toggleHideDetails} size="large">
                        {hideDetails ? <ChevronRight /> : <ChevronDown />}
                    </IconButton>
                )}
                <Typography variant="subtitle1">
                    {isProject ? (
                        <Link to={`/projects/${id}/show/workload`}>{name}</Link>
                    ) : (
                        <Link to={`/users/worker/${id}/show/workload`}>{name}</Link>
                    )}
                </Typography>
            </span>
        </TableCell>
    ) : (
        <>
            {!isProject ? <TableCell> </TableCell> : null}
            <TableCell colSpan={isProject ? 3 : 2}>
                <Typography variant="subtitle1">
                    {isProject ? (
                        <Link to={`/users/worker/${row.worker}/show/workload`}>
                            {row.worker_name}
                        </Link>
                    ) : (
                        <Link to={`/projects/${row.project}/show/workload`}>
                            {row.project_name}
                        </Link>
                    )}
                </Typography>
                <Typography variant="subtitle2">{get_subtitle(row)}</Typography>
            </TableCell>
        </>
    );
};

export default WorkloadGraphRowTitle;
