import React from 'react';
import { convertFilterParams } from '../../dataProvider';
import { stringify } from 'query-string';
import DownloadBlobButton from '../buttons/DownloadBlobButton';
import AlarmIcon from '@mui/icons-material/Alarm';
import { useListContext, useResourceContext } from 'react-admin';

const InvoiceReminderAction = ({ selectedIds, ...props }) => {
    const resource = useResourceContext();
    let { filterValues } = useListContext();
    if (selectedIds && selectedIds.length > 0) {
        // Selected IDs should overwrite filters (when selecting items in bulk)
        // Otherwise no IDs are selected and all (filtered) items should be updated
        filterValues = { id__in: selectedIds };
    }
    const url = `${resource}/generate-reminders/?${stringify(convertFilterParams(filterValues))}`;

    return (
        <DownloadBlobButton requireAuth fileUrl={url} label="Invoice Reminders" {...props}>
            <AlarmIcon />
        </DownloadBlobButton>
    );
};

export default InvoiceReminderAction;
