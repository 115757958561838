import React, { useState } from 'react';
import { Button, useRefresh, Link } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { httpClient } from '../../dataProvider';
import getIcon from '../../base/getIcon';
import useCustomNotify from '../../base/useCustomNotify';

const useStyles = makeStyles({
    root: {
        //margin: '0.5rem 1rem 0 0',
        color: 'white',
    },
});

const TodoActionsButton = ({ record, source, size = 'medium' }) => {
    const [updating, setUpdating] = useState(false);
    const refreshView = useRefresh();
    const notify = useCustomNotify();
    const actions = record[source];
    const classes = useStyles();

    const updateState = (action) => {
        if (action.method === 'GET') {
            window.location.replace(action.url);
        } else {
            setUpdating(true);
            httpClient(action.url, {
                method: action.method,
            })
                .then(() => {
                    notify(`Todo completed`);
                })
                .catch((e) => {
                    notify(`Could not complete todo: ${e.message}`, { type: 'warning' });
                })
                .finally(() => {
                    setUpdating(false);
                    refreshView();
                });
        }
    };

    const onClick = (action) => {
        updateState(action);
    };

    const getButton = (item) => {
        return (
            item.url.indexOf('admin.motius.de') >= 0 ? (
                // Internal admin URL
                <Button
                    key={item.id}
                    variant={'contained'}
                    children={<OpenInNewIcon />}
                    label={item.label}
                    component={Link}
                    to={item.url.split('#')[1]}
                    target="_blank"
                    className={classes.root}
                    size={size}
                />
            ) : (
                // External URL
                <Button
                    key={item.index}
                    variant={'contained'}
                    children={<OpenInNewIcon />}
                    label={item.label}
                    component="a"
                    href={item.url}
                    className={classes.root}
                    size={size}
                />
            )
        )

    }

    return (
        <>
            {
                ( actions[0].method === 'GET' ? getButton(actions[0]) : (
                    // POST
                    <Button
                        key={actions[0].index}
                        variant={'contained'}
                        onClick={() => onClick(actions[0])}
                        disabled={updating}
                        children={React.createElement(getIcon(actions[0].icon, SendIcon))}
                        label={actions[0].label}
                        className={classes.root}
                        size={size}
                    />
                ))
            }
        </>
    );
};

export default TodoActionsButton;
