import React from 'react';
import { NumberInput } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';
import _ from 'lodash';

const PercentageInput = (props) => {
    return (
        <NumberInput
            format={(v) => _.round(v * 100, 2)}
            parse={(v) => _.round(parseFloat(v) / 100, 4)}
            InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            {...props}
        />
    );
};

export default PercentageInput;
