import { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { apiUrl, convertFilterParams, httpClient } from '../../dataProvider';
import useCustomNotify from '../useCustomNotify';


const useGetResource = (url, params, errorMessage, initial = []) => {
    const notify = useCustomNotify();
    const [data, setData] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [refetchToggle, setRefetchToggle] = useState(false);
    const [fetchParams, setFetchParams] = useState({ url, params, errorMessage });

    useEffect(() => {
        let isCancelled = false;

        const fetchData = async () => {
            setLoading(true);
            try {
                const resp = await httpClient(
                    `${apiUrl}${fetchParams.url}${fetchParams.params
                        ? `?${qs.stringify(convertFilterParams(fetchParams.params))}`
                        : ''
                    }`
                );
                if (!isCancelled) {
                    setData(resp.json);
                }
            } catch (e) {
                console.error(e);
                notify(fetchParams.errorMessage || 'Could not fetch resource.', {
                    type: 'warning',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            isCancelled = true;
        };
    }, [fetchParams, notify, refetchToggle]);

    const changeParams = useCallback((newParams) => {
        setFetchParams((r) => ({ ...r, params: newParams }));
    }, []);

    const refetch = useCallback(() => setRefetchToggle((r) => !r), []);

    return { data, loading, changeParams, refetch };
};

export default useGetResource;
