import create from 'zustand';

export interface Absence {
    week: number;
    year: number;
    hours: string;
}

export interface Availability {
    start_date: string;
    weekly_hours: number;
}

export interface Planned {
    start_date: string;
    total_planned: number;
}

export interface WorkerAvailability {
    id: number;
    absences: Absence[];
    availability: Availability[];
    planned: Planned[];
}

export interface PlannedHour {
    id: number;
    created: Date;
    weekly_hours: number;
    start_date: string;
}

export interface Tracked {
    week: number;
    year: number;
    hours: string;
}

export interface RowData {
    id: number;
    state: number;
    state_label: string;
    worker: number;
    worker_name: string;
    project_name: string;
    project: number;
    project_end_date: string;
    role_name?: string;
    planned_hours: PlannedHour[];
    tracked: Tracked[];
    warnings?: string[]
    project_state_before_progress?: string
}

type WorkloadState = {
    isProject: boolean
    data: RowData[]
    availability: WorkerAvailability[]
    setIsProject: (isProject: boolean) => void
    setData: (data: RowData[]) => void
    setAvailability: (availability: WorkerAvailability[]) => void
}

export const useWorkloadState = create<WorkloadState>((set) => ({
    isProject: false,
    data: [],
    availability: [],
    changedData: [],
    setIsProject: (isProject: boolean) => set(state => ({ isProject })),
    setData: (newData) => set(state => ({ data: newData })),
    setAvailability: (newAvailability) => set(state => ({ availability: newAvailability })),
}))