import React from 'react';
import { Typography } from '@mui/material';
import { EditButton, Button } from 'react-admin';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Grid from '@mui/material/Grid';

const DocumentationField = (props) => {
    const { record, basePath } = props;

    return (
        <Grid spacing={2} container>
            {record.story && (
                <Grid item md={6}>
                    <Typography variant="title">Story</Typography>
                    <Typography dangerouslySetInnerHTML={{ __html: record.story }} />
                </Grid>
            )}
            {record.challenge && (
                <Grid item md={6}>
                    <Typography variant="title">Challenge</Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.challenge,
                        }}
                    />
                </Grid>
            )}
            {record.solution && (
                <Grid item md={6}>
                    <Typography variant="title">Solution</Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.solution,
                        }}
                    />
                </Grid>
            )}
            {record.timeline && (
                <Grid item md={6}>
                    <Typography variant="title">Timeline</Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.timeline,
                        }}
                    />
                </Grid>
            )}
            {record.technologies && (
                <Grid item md={6}>
                    <Typography variant="title">Technologies</Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.technologies,
                        }}
                    />
                </Grid>
            )}
            {record.feedback_result && (
                <Grid item md={6}>
                    <Typography variant="title">Feedback Score</Typography>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.feedback_result,
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <EditButton variant="contained" basePath={basePath} record={record} />
                {record.link_material && (
                    <Button
                        href={record.link_material}
                        target="_blank"
                        component="a"
                        label="Sales Material"
                    >
                        <InsertDriveFileIcon />
                    </Button>
                )}
                {record.link_team && (
                    <Button
                        href={record.link_team}
                        target="_blank"
                        component="a"
                        label="Team Documentation"
                    >
                        <InsertDriveFileIcon />
                    </Button>
                )}
                {record.link_documentation && (
                    <Button
                        href={record.link_documentation}
                        target="_blank"
                        component="a"
                        label="Project Documentation"
                    >
                        <InsertDriveFileIcon />
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default DocumentationField;
