import TemplateForm from './TemplateForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const TemplateEdit = (props) => (
    <BaseEdit undoable={false} {...props} redirect="list">
        <TemplateForm location={props.location} />
    </BaseEdit>
);

export default TemplateEdit;
