import Create from './ClusterCreate';
import Edit from './ClusterEdit';
import List from './ClusterList';
import React from 'react';
import onlyAdminViews from '../auth/onlyAdminViews';

const resourceName = 'Cluster';
const resourceNamePlural = 'Clusters';

const cluster = (permissions) =>
    onlyAdminViews(permissions, {
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default cluster;
