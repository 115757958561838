import React from 'react';
import BaseEdit from '../base/views/BaseEdit';
import WebhookForm from './WebhookForm';

const WebhookEdit = (props) => {
    return (
        <BaseEdit {...props} redirect="list">
            <WebhookForm />
        </BaseEdit>
    );
};

export default WebhookEdit;
