import Create from './DocumentationCreate';
import Edit from './DocumentationEdit';
import React from 'react';

const resourceName = 'Project Documentation';
const resourceNamePlural = 'Project Documentations';

const documentation = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default documentation;
