import React, { useState } from 'react';
import { Button, useRefresh } from 'react-admin';
import { apiUrl, httpClient } from '../../../dataProvider';
import * as PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import _ from 'underscore';
import Dialog from '@mui/material/Dialog';

import DefaultDialogContent from './DefaultDialogContent';
import JiraDialogContent from './atlassian/jira/JiraDialogContent';
import ConfluenceDialogContent from './atlassian/confluence/ConfluenceDialogContent';
import useCustomNotify from '../../useCustomNotify';

const EnableIntegrationButton = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [recordValue, setRecordValue] = useState({});
    const notify = useCustomNotify();
    const refreshView = useRefresh();

    const { record, label, sourceUrl, action, helperText } = props;
    const isActionAtlassian = ['jira', 'confluence'].includes(action);
    if (!_.isEqual(record, recordValue)) {
        setRecordValue(record);
    }

    const handleClick = () => {
        const options = {
            method: 'POST',
        };
        setLoading(true);
        httpClient(`${apiUrl}/projects/${recordValue.id}/${action}`, options)
            .then(() => {
                notify(`${label} was successfully enabled`);
                refreshView();
            })
            .catch(() => {
                notify('An error occurred', { type: 'warning' });
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getDialogContentByAction = () => {
        switch (action) {
            case 'jira':
                return (
                    <JiraDialogContent platformProject={recordValue} closeDialog={handleClose} />
                );
            case 'confluence':
                return (
                    <ConfluenceDialogContent
                        platformProject={recordValue}
                        closeDialog={handleClose}
                    />
                );
            default:
                return (
                    <DefaultDialogContent
                        helperText={helperText}
                        loading={loading}
                        handleClick={handleClick}
                    />
                );
        }
    };

    if (record[sourceUrl]) {
        return (
            <div>
                <Button
                    href={recordValue[sourceUrl]}
                    target="_blank"
                    component="a"
                    label={`Open ${action}`}
                >
                    <OpenInNewIcon />
                </Button>
            </div >
        );
    }

    return (
        <div>
            <Button onClick={handleOpen} label={label}>
                <AddIcon />
            </Button>
            <Dialog open={open} fullWidth={isActionAtlassian} onClose={handleClose}>
                {getDialogContentByAction()}
            </Dialog>
        </div>
    );
};

EnableIntegrationButton.propTypes = {
    record: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    sourceUrl: PropTypes.string.isRequired,
};

EnableIntegrationButton.defaultProps = {
    record: {},
};

export default EnableIntegrationButton;
