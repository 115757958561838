import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { ProjectNameField, ResponsibilityList } from './fields';
import StateDialog from '../base/StateDialog';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PanelLabel from '../base/show/PanelLabel';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import {
    Button,
    DateField,
    EditButton,
    Link,
    ReferenceField,
    ReferenceManyField,
    TextField,
    WrapperField,
    useRecordContext,
} from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import DocumentationList from './documentation/DocumentationList';
import EnableIntegrationButton from '../base/buttons/integration/EnableIntegrationButton';
import AtlassianIntegrationList from './AtlassianIntegrationList';
import { DateTime } from 'luxon';
import ShowElementLink from '../base/buttons/ShowElementLink';
import { ClusterField } from '../cluster/fields';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '0.5rem',
    },
    extraSpacingTop: {
        marginTop: '2rem',
    },
    alignItems: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
});



const ProjectPanel = (props) => {
    const record = useRecordContext();
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <ProjectNameField record={record} {...props} />
                {!record.parent ? (
                    <div>
                        <div className={classes.extraSpacingTop}>
                            <PanelLabel>Extension Projects</PanelLabel>
                            <ReferenceManyField
                                link="show"
                                target="parent"
                                reference="projects"
                                record={record}
                                {...props}
                            >
                                <EditableDatagrid
                                    actions={<EditButton label="" />}
                                    mutationMode="pessimistic"
                                    rowClick="show"
                                >
                                    <WrapperField label="Name" >
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid item>
                                                <ReferenceField
                                                    reference="workers/departments"
                                                    source="main_department"
                                                    link={false}
                                                >
                                                    <TextField source="name" />
                                                </ReferenceField>
                                            </Grid>
                                            <Grid item >
                                                <ShowElementLink source="name" obj="projects" />
                                            </Grid>
                                        </Grid>
                                    </WrapperField >
                                    <ReferenceField
                                        source="cost_center"
                                        label="Cost Center"
                                        reference="cost-centers"
                                        link={false}
                                    >
                                        <TextField source="public_id" />
                                    </ReferenceField>
                                    <DateField source="start_date" />
                                    <DateField source="end_date" />
                                </EditableDatagrid>
                            </ReferenceManyField>
                            <Button
                                href={`/#/projects/wizard/create/1?project_type=extension&parent=${record.id}`}
                                className={classes.spacingTop}
                                variant="contained"
                                label="Create Extension Project"
                                startIcon={<Add />}
                            />
                        </div>
                        <div className={classes.extraSpacingTop}>
                            <PanelLabel>Follow Up Projects</PanelLabel>
                            <ReferenceManyField
                                link="show"
                                target="predecessor"
                                reference="projects"
                                record={record}
                                {...props}
                            >
                                <EditableDatagrid
                                    actions={<EditButton label="" />}
                                    mutationMode="pessimistic"
                                    rowClick="show"
                                >
                                    <WrapperField label="Name" >
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid item>
                                                <ReferenceField
                                                    reference="workers/departments"
                                                    source="main_department"
                                                    link={false}
                                                >
                                                    <TextField source="name" />
                                                </ReferenceField>
                                            </Grid>
                                            <Grid item >
                                                <ShowElementLink source="name" obj="projects" />
                                            </Grid>
                                        </Grid>
                                    </WrapperField >
                                    <ReferenceField
                                        source="cost_center"
                                        label="Cost Center"
                                        reference="cost-centers"
                                        link={false}
                                    >
                                        <TextField source="public_id" />
                                    </ReferenceField>
                                    <DateField source="start_date" />
                                    <DateField source="end_date" />
                                </EditableDatagrid>
                            </ReferenceManyField>
                            <Button
                                href={`/#/projects/wizard/create/1?project_type=follow_up&predecessor=${record.id}`}
                                className={classes.spacingTop}
                                variant="contained"
                                label="Create Follow-Up Project"
                                startIcon={<Add />}
                            />
                        </div>
                    </div>
                ) : null}
                {record.description && (
                    <>
                        <PanelLabel>Description</PanelLabel>
                        <div dangerouslySetInnerHTML={{ __html: record.description }} />
                    </>
                )}

                <PanelLabel>Integrations</PanelLabel>
                {record.name_short && record.slug_short ? (
                    <>
                        <Typography variant="subtitle2">
                            Groups will be named { }
                            <Button
                                target="_blank"
                                href={`https://outlook.office.com/people/group/motius.de/${record.ldap_group_name}`}
                                label={record.ldap_group_name}
                                component="a"
                            />
                        </Typography>
                        <EnableIntegrationButton
                            action="gitlab"
                            label="Enable Gitlab"
                            sourceUrl="gitlab_url"
                            record={record}
                            helperText="This creates a Gitlab group and shares access to it with the team."
                        />
                        <EnableIntegrationButton
                            action="jira"
                            label="Connect Jira Project"
                            sourceUrl={''}
                            record={record}
                            helperText="This creates a new Jira project or connects to an existing one."
                        />
                        <EnableIntegrationButton
                            action="confluence"
                            label="Connect Confluence Space"
                            sourceUrl={''}
                            record={record}
                            helperText="This creates a new Confluence space or connects to an existing one."
                        />

                        <AtlassianIntegrationList
                            title="Jira"
                            resource="jira-projects/jira"
                            items={record.jira_projects}
                            idKey="platform_jira_project_id"
                            keySource="jira_key"
                            hrefSource="jira_browse_url"
                            record={record}
                        />
                        <AtlassianIntegrationList
                            title="Confluence"
                            resource="confluence-spaces/confluence/"
                            idKey="platform_confluence_space_id"
                            items={record.confluence_spaces}
                            keySource="confluence_key"
                            hrefSource="url"
                            record={record}
                        />
                    </>
                ) : (
                    <Link to={`/projects/${record.id}`}>
                        <div className={classes.alignItems}>
                            <InfoIcon />
                            Add a short name to add integrations, such as Gitlab or Sharepoint.
                        </div>
                    </Link>
                )}

                <PanelLabel>Documentation</PanelLabel>
                <ReferenceManyField
                    reference="projects/documentation"
                    target="project"
                    label={false}
                    record={record}
                    {...props}
                >
                    <DocumentationList
                        id={record.id}
                        basePath="projects/documentation"
                        target="project"
                        {...props}
                    />
                </ReferenceManyField>
            </Grid>
            <Grid item xs={12} md={3}>
                <PanelLabel>Cost Center</PanelLabel>
                <ReferenceField
                    source="cost_center"
                    label="Cost Center"
                    reference="cost-centers"
                    record={record}
                    {...props}
                >
                    <TextField source="public_id" />
                </ReferenceField>
                <PanelLabel>Dates</PanelLabel>
                <Typography variant="body1">
                    {DateTime.fromISO(record.start_date).toFormat('MMMM dd, yyyy')} &ndash;{' '}
                    {DateTime.fromISO(record.end_date).toFormat('MMMM dd, yyyy')}
                </Typography>
                <StateDialog
                    record={record}
                    basePath="/projects"
                    recordLabel={record.name_internal}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                {record.predecessor && (
                    <>
                        <PanelLabel>Predecessor Project</PanelLabel>
                        <ReferenceField
                            source="predecessor"
                            reference="projects"
                            link="show"
                            record={record}
                            {...props}
                        >
                            <TextField source="name_internal" />
                        </ReferenceField>
                    </>
                )}

                {record.parent && (
                    <>
                        <PanelLabel>Main Project</PanelLabel>
                        <ReferenceField
                            source="parent"
                            reference="projects"
                            link="show"
                            record={record}
                            {...props}
                        >
                            <TextField source="name_internal" />
                        </ReferenceField>
                    </>
                )}
                {record.invitations && record.invitations.length > 0 && (
                    <>
                        <PanelLabel>Responsibilities</PanelLabel>
                        <ResponsibilityList invitations={record.invitations} />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default ProjectPanel;
