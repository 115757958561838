import MetabaseEmbed from './MetabaseEmbed';
import { Grid } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

const ControllingPanel = () => {
    const record = useRecordContext();
    return (
        <Grid container id="controlling-container">
            <MetabaseEmbed record={record} />
        </Grid>
    );
};

export default ControllingPanel;
