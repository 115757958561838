import React from 'react';
import { List } from 'react-admin';
import { ListHeadTitle } from '../HeadTitle';

const BaseList = ({ resourceName, resourceNamePlural, ...props }) => (
    <List
        perPage={25}
        title={<ListHeadTitle resourceNamePlural={resourceNamePlural} />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    />
);

export default BaseList;
