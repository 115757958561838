import Grid from '@mui/material/Grid';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NoteField from '../NoteField';
import { useListContext } from 'react-admin';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
});

const NoteGrid = ({ xs, md, lg, collapsedHeight }) => {
    const classes = useStyles();
    const { data } = useListContext();
    return (
        <Grid container spacing={2} className={classes.spacingTop}>
            {data?.map((row, index) => (
                <Grid key={data[index].id} item xs={xs} md={md} lg={lg}>
                    <NoteField record={data[index]} collapsedHeight={collapsedHeight} />
                </Grid>
            ))}
        </Grid>
    );
};

NoteGrid.defaultProps = {
    xs: 12,
    md: 6,
    lg: 6,
};

export default NoteGrid;
