import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import WorkloadCreateDialog from './WorkloadCreateDialog';
import { DateTime } from 'luxon';
import { FastForward } from '@mui/icons-material';

type WorkloadDialogFormProps = {
    project: number
    weekRange: {
        start: DateTime,
        end: DateTime
    }
    worker_name: string
    worker: number
    id: number
    project_name: string
    project_end_date: string
}

const WorkloadDialogForm: React.FC<WorkloadDialogFormProps> = ({ project, weekRange, worker_name, worker, id, project_name, project_end_date }) => {
    const [open, setOpen] = useState(false);

    const onCloseDialog = () => {
        setOpen(false);
    };

    return <>
        <Tooltip title="Change planned hours for more than one week">
            <IconButton color="primary" onClick={() => setOpen(true)} size="large">
                <FastForward />
            </IconButton>
        </Tooltip>
        <WorkloadCreateDialog
            open={open}
            onCloseDialog={onCloseDialog}
            worker={worker}
            worker_name={worker_name}
            project_name={project_name}
            id={id}
            start_date={weekRange.start.toISODate()}
            project={project}
            project_end_date={project_end_date}
        />
    </>;
};

export default WorkloadDialogForm;
