import { EditButton, useRecordContext } from 'react-admin';
import React from 'react';

const EditPermissionButton = (props) => {
    const record = useRecordContext();
    return record.permissions && record.permissions.update ? (
        <EditButton record={record} {...props} />
    ) : (
        <span />
    );
};

export default EditPermissionButton;
