import * as React from 'react';
import { AutocompleteArrayInput } from 'react-admin';

const AutocompleteCustomArrayInput = ({ children, filter, ...props }) => (
    <AutocompleteArrayInput
        filterToQuery={(searchText) => ({
            autocomplete: searchText,
            ...filter,
        })}
        shouldRenderSuggestions={(val) => {
            return val.trim().length > 0;
        }}
        {...props}
    />
);

export default AutocompleteCustomArrayInput;
