import React, { useState } from 'react';
import { TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WorkloadTableRow from './WorkloadTableRow';
import WorkloadTableTitleCell from './WorkloadTableTitleCell';
import WorkloadTableTotalRow from './WorkloadTableTotalRow';
import { RowData } from '../WorkloadContext';
import { useForm } from "react-hook-form";

type WorkloadContentProps = {
    disabled: boolean;
    data: RowData[];
};

type WorkloadTableProps = {
    group?: number;
    data: RowData[];
    defaultHideDetails?: boolean;
    disabled: boolean;
};

const WorkloadContent: React.FC<WorkloadContentProps> = ({ disabled, data }) => {
    const {control} = useForm()
    return (
        <>
            {data.length > 0
                ? data
                      .sort((a, b) => a.worker - b.worker)
                      .map((row) => <WorkloadTableRow row={row} disabled={disabled} control={control} key={row.id}/>)
                : null}
        </>
    );
};

const useStyles = makeStyles({
    totalRow: {
        background: 'white',
    },
});

const WorkloadTable: React.FC<WorkloadTableProps> = ({
    data,
    group,
    defaultHideDetails,
    disabled,
}) => {
    const [hideDetails, setHideDetails] = useState(defaultHideDetails);
    const classes = useStyles();

    return (
        <>
            <TableRow className={classes.totalRow}>
                <WorkloadTableTitleCell
                    group={group}
                    hideDetails={hideDetails}
                    toggleHideDetails={() => setHideDetails((r) => !r)}
                />
                <WorkloadTableTotalRow group={group} />
            </TableRow>
            {!hideDetails && (
                <WorkloadContent
                    data={group ? data.filter((i) => i.worker === group) : data}
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default WorkloadTable;
