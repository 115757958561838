import Create from './ProjectRoleCreate';
import Edit from './ProjectRoleEdit';
import List from './ProjectRoleList';
import Show from './ProjectRoleShow';
import React from 'react';

const resourceName = 'Project Role';
const resourceNamePlural = 'Project Roles';

const role = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    show: (props) => (
        <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default role;
