import React from 'react';
import onlyAdminViews from '../../auth/onlyAdminViews';

import Create from './PublicHolidayCreate';
import List from './PublicHolidayList';

const resourceName = 'Public Holiday';
const resourceNamePlural = 'Public Holidays';

const holidays = (permissions) =>
    onlyAdminViews(permissions, {
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
    });

export default holidays;
