import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    DateField,
    Link,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    ShowController,
    Tab,
    TabbedShowLayout,
    TextField,
    useRecordContext,
    useStore,
} from 'react-admin';
import React from 'react';
import NoteGrid from './note/NoteGrid';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BaseShowView from '../base/views/BaseShowView';
import WorkerPanel from './WorkerPanel';
import VersionShow from '../base/show/VersionShow';
import Workload, { getDefaultWeekRange } from '../base/workload/Workload';
import WorkerWorkloadTable from './workload/WorkerWorkloadTable';
import WorkerWorkloadGraph from './workload/WorkerWorkloadGraph';
import { useLocation } from 'react-router-dom';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ProjectInvitationForm from '../project/invitation/ProjectInvitationForm';
import NumeralField from '../base/fields/NumeralField';
import WorkerToolForm from './tools/WorkerToolForm';
import PanelLabel from '../base/show/PanelLabel';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
    spacingBottom: {
        marginBottom: '1em',
    },
});

export const AddNewNoteButton = () => {
    const classes = useStyles();
    const record = useRecordContext();
    return (
        <Button
            className={classes.spacingTop}
            color="primary"
            component={Link}
            to={{
                pathname: '/users/workers/notes/create',
                search: `?worker=${record.id}`,
            }}
            label="Add Note"
        >
            <NoteAddIcon />
        </Button>
    );
};

// MP-2550 required in future
// const AddNewSkillEstimationButton = ({ record }) => {
//     const classes = useStyles();
//     return (
//         <Button
//             className={classes.spacingTop}
//             color="primary"
//             component={Link}
//             to={{
//                 pathname: '/users/workers/skill-estimation/create',
//                 search: `?worker=${record.id}`,
//             }}
//             label="Add Evaluation"
//         >
//             <NoteAddIcon />
//         </Button>
//     );
// };

const Show = ({ ...props }) => {
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return (
        <ShowController {...props}>
            {(controllerProps) => {
                const isGuestUser = controllerProps?.record?.user_type === 'guest';
                return (
                    <BaseShowView {...props} {...controllerProps}>
                        <TabbedShowLayout>
                            <Tab label="Overview">
                                {controllerProps.record && controllerProps.record.user && (
                                    <WorkerPanel />
                                )}
                            </Tab>
                            <Tab label="Projects" path="projects">
                                {controllerProps.record && (
                                    <ReferenceManyField
                                        pagination={<Pagination />}
                                        reference="projects/invitations"
                                        target="worker__in"
                                        label={false}
                                        sort={{ field: 'created', order: 'DESC' }}
                                    >
                                        <EditableDatagrid
                                            mutationMode="optimistic"
                                            editForm={<ProjectInvitationForm hideWorker />}
                                            createForm={
                                                <ProjectInvitationForm
                                                    hideWorker
                                                    defaultValues={{
                                                        worker: controllerProps.record.id,
                                                    }}
                                                />
                                            }
                                        >
                                            <ReferenceField
                                                label="Project"
                                                reference="projects"
                                                source="project"
                                                link="show"
                                            >
                                                <TextField source="label" />
                                            </ReferenceField>
                                            <TextField source="state_label" label="State" />
                                            <ReferenceField
                                                reference="projects/role"
                                                source="role"
                                                allowEmpty={true}
                                            >
                                                <TextField source="label" />
                                            </ReferenceField>
                                            <NumeralField
                                                source="hourly_rate"
                                                label="Hourly Customer Rate"
                                                options={{
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                }}
                                            />
                                            <DateField source="created" label="Invited on" />
                                            <DateField
                                                source="start_date"
                                                label="Project Start Date"
                                            />
                                            <DateField source="end_date" label="Project End Date" />
                                        </EditableDatagrid>
                                    </ReferenceManyField>
                                )}
                            </Tab>
                            {!isGuestUser && (
                                <Tab label="Workload" path="workload">
                                    <ReferenceManyField
                                        {...props}
                                        reference="users/workers/invitations"
                                        target="id"
                                        filter={
                                            location.search
                                                ? Object.fromEntries(searchParams.entries())
                                                : {
                                                      ...getDefaultWeekRange(weekCount),
                                                      upcoming: true,
                                                      outboarded: false,
                                                      non_billable: true,
                                                  }
                                        }
                                        label={false}
                                    >
                                        <Workload
                                            tableView={<WorkerWorkloadTable contractHours={0} />}
                                            graphView={<WorkerWorkloadGraph />}
                                        />
                                    </ReferenceManyField>
                                </Tab>
                            )}

                            <Tab label="Notes" path="notes">
                                <ReferenceManyField
                                    pagination={<Pagination />}
                                    reference="users/workers/notes"
                                    target="worker__id__in"
                                    resource="users/worker"
                                    record={controllerProps.record}
                                    label={false}
                                    {...props}
                                >
                                    <NoteGrid {...props} lg={4} collapsedHeight="120px" />
                                </ReferenceManyField>
                                <AddNewNoteButton />
                            </Tab>
                            {/* MP-2550 Commented as it not used currently, required in future 
                               {!isGuestUser && (
                                <Tab label="Evaluation" path="skill-estimation">
                                    <ReferenceManyField
                                        pagination={<Pagination />}
                                        reference="users/workers/skill-estimation"
                                        target="worker__in"
                                        resource="users/worker"
                                        record={controllerProps.record}
                                        label={false}
                                        {...props}
                                    >
                                        <Datagrid {...controllerProps}>
                                            <TextField source="title" label="Title" />
                                            <ReferenceField
                                                source="reporter"
                                                reference="users"
                                                link={false}
                                            >
                                                <TextField source="name" />
                                            </ReferenceField>
                                            <DateField source="date" label="Date" />
                                            <ArrayField source="entries">
                                                <SingleFieldList link={false}>
                                                    <ChipField source="label" />
                                                </SingleFieldList>
                                            </ArrayField>
                                            <NumberField source="is_self" label="Self-Evaluation" />
                                            <EditButton />
                                            <DeleteButton redirect={false} undoable={false} />
                                        </Datagrid>
                                    </ReferenceManyField>
                                    <AddNewSkillEstimationButton />
                                </Tab>
                            )} */}
                            <Tab label="Tools" path="tools">
                                <ReferenceManyField
                                    reference="worker-tools"
                                    target="worker"
                                    label={false}
                                    sort={{ field: 'created', order: 'DESC' }}
                                    pagination={<Pagination />}
                                    perPage={25}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        mutationMode="optimistic"
                                        editForm={
                                            <WorkerToolForm worker={controllerProps.record?.id} />
                                        }
                                        createForm={
                                            <WorkerToolForm worker={controllerProps.record?.id} />
                                        }
                                    >
                                        <ReferenceField
                                            label="Tool"
                                            reference="tools"
                                            source="tool.id"
                                            link={false}
                                            sortable={false}
                                        >
                                            <TextField source="label" />
                                        </ReferenceField>
                                        <ReferenceField
                                            label="Rating"
                                            source="rating"
                                            reference="enums/project/ProjectRoleSkillEstimation"
                                            link={false}
                                            sortable={false}
                                        >
                                            <TextField source="label" />
                                        </ReferenceField>
                                        <DateField source="created" label="Created Date" />
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Tab>
                            <Tab label="Activity" path="activity">
                                <PanelLabel>Worker Event Log</PanelLabel>
                                <VersionShow
                                    reference="users/worker/versions"
                                    {...controllerProps}
                                />
                                <PanelLabel style={{ marginTop: '3rem' }}>
                                    User Event Log
                                </PanelLabel>
                                <div>
                                    {controllerProps.record && controllerProps.record.user && (
                                        <VersionShow
                                            label="User Event Log"
                                            reference="users/versions"
                                            {...props}
                                            record={{ id: controllerProps.record.user.id }}
                                        />
                                    )}
                                </div>
                            </Tab>
                        </TabbedShowLayout>
                    </BaseShowView>
                );
            }}
        </ShowController>
    );
};

export default Show;
