import React from 'react';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PanelLabel from '../base/show/PanelLabel';
import { ReferenceField, TextField } from 'react-admin';

export const ResponsibilityList = ({ invitations }) => (
    <List dense={true}>
        {invitations
            .filter((invitation) => invitation.responsibilities_label.length > 0)
            .map((item) =>
                item.responsibilities_label.map((responsibility) => (
                    <ListItem key={item.id} disableGutters={true}>
                        <ListItemAvatar>
                            <Avatar
                                key={item.id}
                                title={item.worker.user.name}
                                alt={item.worker.user.name}
                                src={item.worker.user.picture}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={item.worker.user.name} secondary={responsibility} />
                    </ListItem>
                ))
            )}
    </List>
);

export const ProjectNameField = (props) => (
    <Grid container spacing={16}>
        <Grid item xs={4}>
            <PanelLabel>Internal Name</PanelLabel>
            <TextField source="name_internal" color="secondary" {...props} />
        </Grid>
        <Grid item xs={4}>
            <PanelLabel>Marketing Name</PanelLabel>
            <TextField source="name" color="secondary" {...props} />
        </Grid>
        <Grid item xs={4}>
            <PanelLabel>Client</PanelLabel>
            <ReferenceField
                source="client_profile"
                record={props.record}
                label="Client"
                reference="users/client"
            >
                <TextField source="name" color="primary" />
            </ReferenceField>
        </Grid>
    </Grid>
);
