import React from 'react';
import {
    BooleanInput,
    ImageInput,
    ReferenceManyField,
    required,
    SimpleForm,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import VersionShow from '../base/show/VersionShow';
import ClientAddressForm from './clientAddress/ClientAddressForm';
import ImageFieldInput from '../base/input/ImageFieldInput';

const ClientForm = (props) => {
    const record = useRecordContext();
    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Full Name" validate={[required()]} />
            <TextInput source="name_short" label="Short Name" />
            <TextInput source="vat_number" label="VAT Number" />
            <BooleanInput source="is_internal" label="Internal" />
            <ImageInput source="logo" label="Logo" accept="image/*" multiple={false}>
                <ImageFieldInput />
            </ImageInput>
            {record && record.id && (
                <>
                    <ReferenceManyField
                        fullWidth
                        reference="users/client/address"
                        label="Addresses"
                        target="client"
                    >
                        <EditableDatagrid
                            bulkActionButtons={false}
                            editForm={<ClientAddressForm />}
                            createForm={<ClientAddressForm defaultValues={{ client: record.id }} />}
                        >
                            <TextField source="address" />
                            <TextField source="city" />
                            <TextField source="postal_code" />
                            <TextField source="country" />
                            <TextField source="email" />
                        </EditableDatagrid>
                    </ReferenceManyField>
                    <VersionShow reference="users/client/versions" label="Event Log" />
                </>
            )}
        </SimpleForm>
    );
};

export default ClientForm;
