import React from 'react';
import { Button, ReferenceManyField, useRecordContext } from 'react-admin';
import RoleEditableGrid from './RoleEditableGrid';
import RoleRowForm from './RoleRowForm';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEditableDatagridContext } from '@react-admin/ra-editable-datagrid';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
    customCreateButton: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const CustomCreateButton = () => {
    const classes = useStyles();
    const {
        openStandaloneCreateForm,
    } = useEditableDatagridContext();

    const handleClick = () => {
        openStandaloneCreateForm();
    }

    return (<Button
        className={classes.customCreateButton}
        onClick={handleClick}
        label='Create Roles'
        startIcon={<AddIcon />}
    />)
}

const RoleRowList = (props: any) => {
    const record = useRecordContext();

    return (
        <div>
            <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                Project Roles
            </Typography>
            <ReferenceManyField
                {...props}
                reference="projects/role"
                target="project__id"
                hasCreate
                fullWidth
            >
                <RoleEditableGrid
                    editForm={<RoleRowForm project={record} contractId={record?.client_contract?.id} />}
                    createForm={<RoleRowForm project={record} contractId={record?.client_contract?.id} />}
                    empty={<CustomCreateButton />}
                />
            </ReferenceManyField>
        </div >
    );
};

export default RoleRowList;
