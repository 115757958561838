import { stringify } from 'query-string';
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY,
} from 'react-admin';
import userManager from './auth/oidcUserManager';
import fetchJson from './fetchUtils';

export const GET_ME = 'GET_ME';
export const SEARCH = 'search';

export const apiUrl = process.env.REACT_APP_API_URL;

export const httpClientFetch = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    options.headers.set('Accept-Language', 'en-US');

    return userManager.getUser().then((user) => {
        options.headers.set('Authorization', `Bearer ${user.id_token}`);
        return fetch(url, options);
    });
};

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'en-US',
        });
    }

    const queryPos = url.indexOf('?');
    // API expects a trailing slash
    if (queryPos === -1) {
        // If there's no query params, we add the slash at the end
        if (url.substr(-1) !== '/') {
            // ...unless there already is one
            url += '/';
        }
    } else {
        // If there's query params, we slice, add the trailing slash, then join
        url = [url.slice(0, queryPos), '/', url.slice(queryPos)].join('');
    }

    return userManager.getUser().then((user) => {
        options.headers.set('Authorization', `Bearer ${user.id_token}`);
        return fetchJson(url, options);
    });
};

export const convertFilterParams = (params) => {
    let filter = {};
    if (params) {
        Object.entries(params).map(([key, value]) => {
            if (Array.isArray(value)) {
                filter[key] = Array.isArray(value) ? value.join(',') : value;
            } else if (typeof value === 'object') {
                filter[key] = JSON.stringify(value);
            } else {
                filter[key] = value;
            }
            return filter[key];
        });
    }
    return filter;
};

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const dataProvider = () => {
    const createQuery = (params) => {
        const filter = convertFilterParams(params.filter);
        const { page, perPage } = params.pagination
            ? params.pagination
            : { page: null, perPage: null };
        const { field, order } = params.sort ? params.sort : { field: null, order: null };
        const query = filter;
        if (field) {
            query.ordering = `${order === 'DESC' ? '-' : ''}${field}`;
        }
        else {
            query.ordering = null;
        }
        if (page) {
            query.page = page;
        }
        if (perPage) {
            query.page_size = perPage;
        }
        return query;
    };

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        switch (type) {
            case GET_ME:
                url = `${apiUrl}/users/me`;
                break;
            case GET_LIST: {
                if (params) {
                    const query = createQuery(params);
                    url = `${apiUrl}/${resource}?${stringify(query)}`;
                } else {
                    url = `${apiUrl}/${resource}`;
                }

                break;
            }
            case GET_ONE:
                if (params?.id) {
                    url = `${apiUrl}/${resource}/${params.id}`;
                } else {
                    url = `${apiUrl}/${resource}`;
                }
                break;
            case GET_MANY: {
                const query = {
                    id__in: params.ids.join(','),
                    page_size: 100,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const query = createQuery(params);
                query[params.target] = params.id;
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = params.patch ? 'PATCH' : 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case SEARCH:
                url = `${apiUrl}/search?q=${resource}`;
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const json = response.json;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                return resource !== 'hooksevents'
                    ? {
                        data: json.results,
                        total: json.count,
                    }
                    : { data: json, total: json.length };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            case GET_MANY:
                return resource !== 'hooksevents'
                    ? {
                        data: json.results,
                    }
                    : { data: json };
            case DELETE:
                return { data: { ...params } };
            case SEARCH:
                return { data: json.results, total: json.results.length };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(type, resource, params);
        return httpClient(url, options).then((response) =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};

export default dataProvider;
