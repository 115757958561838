import { RowForm, RowFormProps, useRowContext } from '@react-admin/ra-editable-datagrid';
import React, { useEffect } from 'react';
import { NumberInput, required, TextInput, useRefresh } from 'react-admin';
import { useRowState } from '../../wizard-form/rowState';
import { regeneratePDF } from '../utils';
import useCustomNotify from '../../../../base/useCustomNotify';

const BacklogRowForm = ({ isStoryPointContract, contractId, ...props }: any) => {
    const refresh = useRefresh();
    const notify = useCustomNotify();
    const { setEditing, unsetEditing } = useRowState();
    const { close } = useRowContext();

    /*
     * Using this effect to disable next step on unsaved changes
     */
    useEffect(() => {
        setEditing();
        return () => unsetEditing();
    }, [setEditing, unsetEditing]);

    const transform: RowFormProps['transform'] = (values) => {
        return {
            ...values,
            project: props.project,
            estimated_hours: values.initial_hours,
        };
    };

    const onSuccess = async () => {
        close();
        try {
            await regeneratePDF(contractId, refresh);
        } catch (e) {
            notify('Error during regenerating PDF with contract Id');
        }
    };

    return (
        <RowForm
            {...props}
            mutationMode="pessimistic"
            transform={transform}
            mutationOptions={{ onSuccess: onSuccess }}
        >
            <TextInput source="name" margin="dense" variant="outlined" validate={required()} />
            {isStoryPointContract ? (
                <TextInput
                    source="epic_name"
                    margin="dense"
                    variant="outlined"
                    helperText="Leave empty for default"
                />
            ) : (
                <TextInput
                    source="milestone_name"
                    margin="dense"
                    variant="outlined"
                    helperText="Leave empty for default"
                />
            )}
            {isStoryPointContract ? (
                <NumberInput
                    source="story_points"
                    margin="dense"
                    variant="outlined"
                    validate={required()}
                />
            ) : (
                <NumberInput
                    source="initial_hours"
                    margin="dense"
                    variant="outlined"
                    label="Hours"
                    validate={required()}
                />
            )}
        </RowForm>
    );
};

export default BacklogRowForm;
