import { Chip, Grid, List, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    ChipField,
    ReferenceArrayField,
    ReferenceManyField,
    SingleFieldList,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PanelLabel from '../base/show/PanelLabel';
import StateDialog from '../base/StateDialog';
import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import PhoneIcon from '@mui/icons-material/Phone';
import ListItem from '@mui/material/ListItem';
import WorkerCard from './WorkerCard';
import NoteGrid from './note/NoteGrid';
import { AddNewNoteButton } from './WorkerShow';
import MailTo from './mailto/MailTo';
import PDFNewTab from '../base/fields/PDFNewTab';
import { apiUrl, httpClient } from '../dataProvider';
import useCustomNotify from '../base/useCustomNotify';

const PERSONIO_LINK = 'https://motius.personio.de/staff/details';
const BREEZY_LINK = 'https://app.breezy.hr/app/c/motius/candidates?archived=true&q=';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '0.75em',
    },
    smallIcon: {
        position: 'relative',
        top: '0.25em',
    },
    avatar: {
        float: 'left',
        marginRight: 20,
        marginTop: 25,
        width: 100,
        height: 100,
    },
    chip: {
        margin: '0.75rem 0.25rem 0.25rem 0.25rem',
        color: 'white !important',
    },
});

export const ListItemLink = (props) => <ListItem button component="a" {...props} />;

export const SkillChip = ({ record, ...rest }) =>
    record?.tool ? (
        <Chip {...rest} label={`${record.tool.name} ${record.rating}/5`} />
    ) : null;

const WorkerPanel = (controllerProps) => {
    const { id, ...props } = controllerProps;
    const record = useRecordContext();
    const resource = useResourceContext();
    const classes = useStyles();
    const notify = useCustomNotify();

    const openCVExternalLink = (url, account) => {
        if (account.provider_label === 'LinkedIn') {
            const { extra_data } = account;
            const { firstName, lastName } = extra_data;
            const first = firstName.localized[Object.keys(firstName.localized)[0]];
            const last = lastName.localized[Object.keys(lastName.localized)[0]];
            window.open(`https://www.linkedin.com/search/results/all/?keywords=${first} ${last}`);
        } else {
            window.open(url);
        }
    };

    const onPersonioProfileClick = () => {
        window.open(`${PERSONIO_LINK}/${record.user.personio_id}`);
    };

    const onBreezyClick = () => {
        window.open(`${BREEZY_LINK}${record.label}`);
    };

    const onPersonioCreateClick = () => {
        httpClient(`${apiUrl}/users/worker/${record.id}/create-personio-employee`, {
            method: 'post',
        })
            .then(() => {
                notify(
                    'Worker will be created in Personio. If it is not created after a while, please contact support'
                );
            })
            .catch(() => {
                notify('Could not create a worker in Personio. Contact support.', {
                    type: 'warning',
                });
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <WorkerCard {...{ record, resource, ...props }} />
                    <ReferenceArrayField
                        label="Tags"
                        record={record}
                        resource={resource}
                        source="tags"
                        reference="users/workers/tags"
                        {...props}
                    >
                        <SingleFieldList link={false}>
                            <ChipField source="name" color="primary" className={classes.chip} />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <div>
                        <PanelLabel>Skills</PanelLabel>
                        <ReferenceManyField
                            {...props}
                            label="Skills"
                            reference="worker-tools"
                            target="worker"
                            sort={{ field: 'rating', order: 'DESC' }}
                            record={record}
                            resource={resource}
                        >
                            <SingleFieldList link={false}>
                                <SkillChip
                                    className={classes.chip}
                                    color="primary"
                                    source="semester"
                                />
                            </SingleFieldList>
                        </ReferenceManyField>
                    </div>
                    <div>
                        <PanelLabel>Experience</PanelLabel>
                        <div>
                            <PDFNewTab source="cv" record={record} {...props} />
                        </div>
                        {record.breezy_id && (
                            <div>
                                <Button label="Go to Breezy" onClick={onBreezyClick}>
                                    <OpenInNewIcon />
                                </Button>
                            </div>
                        )}

                        {record?.social_accounts?.map((account, key) => (
                            <div key={account.id}>
                                <Button
                                    onClick={() =>
                                        openCVExternalLink(
                                            account.extra_data.html_url ||
                                            account.extra_data.publicProfileUrl,
                                            account
                                        )
                                    }
                                    label={account.provider_label}
                                    color="primary"
                                >
                                    <OpenInNewIcon />
                                </Button>
                            </div>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <PanelLabel>Location</PanelLabel>
                    {record.address && (
                        <Typography>
                            <MapIcon className={classes.smallIcon} />{' '}
                            {` ${record.address}, 
                            ${record.postal_code} ${record.city}${record.country ? " (" + record.country + ")": ""}`}
                        </Typography>
                    )}
                    {record.nationality && (
                        <Typography>
                            <Tooltip title="Nationality">
                                <span>
                                    <FlagIcon className={classes.smallIcon} /> {record.nationality}
                                </span>
                            </Tooltip>
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={2}>
                    <PanelLabel>Contact</PanelLabel>
                    <List component="nav">
                        <MailTo title="Private Email" record={record} email={record.user.email} />
                        {record.user.motius_email && (
                            <MailTo
                                title="Motius Email"
                                record={record}
                                email={record.user.motius_email}
                            />
                        )}
                        {record.phone && (
                            <ListItemLink href={'tel:' + record.phone}>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Phone" secondary={record.phone} />
                            </ListItemLink>
                        )}
                        {!record.user.personio_id ? (
                            <Button
                                label="Create Personio User"
                                color="primary"
                                className={classes.spacingTop}
                                onClick={onPersonioCreateClick}
                                variant="contained"
                            />
                        ) : (
                            <Button
                                label="See Personio Profile"
                                color="primary"
                                startIcon={<OpenInNewIcon />}
                                className={classes.spacingTop}
                                onClick={onPersonioProfileClick}
                                variant="text"
                            />
                        )}
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <StateDialog
                        record={record}
                        basePath="/users/worker"
                        recordLabel={record.name}
                    />
                </Grid>
            </Grid>
            <div>
                <PanelLabel>Latest Notes</PanelLabel>
                <ReferenceManyField
                    perPage={3}
                    reference="users/workers/notes"
                    target="worker__id__in"
                    resource="users/worker"
                    record={record}
                    label={false}
                    {...props}
                >
                    <NoteGrid {...props} lg={3} />
                </ReferenceManyField>
                <AddNewNoteButton record={record} />
            </div>
        </>
    );
};

export default WorkerPanel;
