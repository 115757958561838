import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, useDataProvider, useRefresh, useUnselectAll } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import useCustomNotify from '../useCustomNotify';

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.error.main,
    },
}));

const VALIDATION_TEXT = 'delete';

const BulkDeleteButton = ({ forceConfirmation, selectedIds, resource }) => {
    const notify = useCustomNotify();
    const refreshView = useRefresh();
    const unselectAll = useUnselectAll(resource);
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [validationInput, setValidationInput] = useState('');

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleInputChange = (e) => {
        setValidationInput(e.target.value);
    };

    const handleBulkDelete = async () => {
        dataProvider
            .deleteMany(resource, { ids: selectedIds })
            .then(() => {
                setOpenDialog(false);
                notify('Resource deleted.');
                unselectAll();
                refreshView();
            })
            .catch(() => {
                notify('Error deleting resource.', { type: 'warning' });
            });
    };

    return (
        <React.Fragment>
            <Button
                className={classes.button}
                onClick={handleOpenDialog}
                label="Delete"
                children={<DeleteIcon />}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{'Are you sure you want to delete selected entries?'}</DialogTitle>
                {forceConfirmation && (
                    <DialogContent>
                        <Typography>
                            If you are sure to delete the selected entries, please write{' '}
                            <strong>"{VALIDATION_TEXT}"</strong> in the field below.
                        </Typography>
                        <TextField fullWidth value={validationInput} onChange={handleInputChange} />
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus label="No" />
                    <Button
                        disabled={forceConfirmation && validationInput !== VALIDATION_TEXT}
                        onClick={handleBulkDelete}
                        color="primary"
                        label="Yes"
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default BulkDeleteButton;
