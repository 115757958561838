import {
    NumberInput,
    required,
    SelectInput,
    useRecordContext,
    ReferenceInput,
    TextInput,
    FormDataConsumer
} from 'react-admin';
import React from 'react';
import CurrencyInput from '../../../base/input/CurrencyInput';
import FullAsideContent from './FullAsideContent';
import makeStyles from '@mui/styles/makeStyles';
import CheckboxInput from '../../../base/input/CheckboxInput';

const useStyles = makeStyles({
    hidden: { display: 'none' },
});

const ExtensionAsideContent = () => {
    const classes = useStyles();
    const record = useRecordContext();
    const isAgile = record?.client_contract?.type === 'agile';
    const isSP = record?.client_contract?.type === 'sp';
    const isServices = record?.client_contract?.type === 'services';

    return (
        <div>
            <ReferenceInput
                source="client_contract.type"
                reference="enums/contract/ProjectClientContractType"
            >
                <SelectInput
                    optionText="label"
                    helperText="For extension projects, contract type is inherited from the main project."
                    label="Contract Type"
                    fullWidth
                    variant="outlined"
                    disabled
                />
            </ReferenceInput>
            <CurrencyInput
                validate={[]}
                fullWidth
                label="Discount"
                source="client_contract.deduction_value"
                variant="outlined"
                margin="normal"
                FormHelperTextProps={{ className: classes.hidden }}
            />
            {isSP ? (
                <CurrencyInput
                    validate={[]}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Story Point Rate"
                    source="client_contract.story_point_rate"
                    FormHelperTextProps={{ className: classes.hidden }}
                />
            ) : null}
            {isAgile || isServices ? (
                <CurrencyInput
                    validate={[]}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Hourly Rate"
                    source="client_contract.hourly_rate"
                    FormHelperTextProps={{ className: classes.hidden }}
                />
            ) : null}
            <NumberInput
                validate={[]}
                fullWidth
                variant="outlined"
                margin="normal"
                label="Safety"
                source="client_contract.safety"
                helperText="Safety factor will be multiplied by total hours and hourly rate to calculate the net value."
            />
            <SelectInput
                fullWidth
                label="Language"
                source="client_contract.language"
                choices={[
                    { id: 'de', name: 'DE' },
                    { id: 'en', name: 'EN' },
                ]}
                variant="outlined"
                margin="normal"
                validate={required()}
                defaultValue="de"
                FormHelperTextProps={{ className: classes.hidden }}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.client_contract.share_url && <TextInput
                    label="Share URL"
                    source="client_contract.share_url"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                />}
            </FormDataConsumer>
            <CheckboxInput
                label="Share with Customer"
                source="client_contract.share_enabled"
            />
            <div style={{ marginTop: 16 }}>
                <FullAsideContent />
            </div>
        </div>
    );
};

export default ExtensionAsideContent;
