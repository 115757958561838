import {
    Filter,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput,
    SelectInput,
} from 'react-admin';
import React from 'react';
import EditPermissionButton from '../base/buttons/EditPermissionButton';
import TemplatePanel from './TemplatePanel';
import BaseList from '../base/views/BaseList';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

const TemplateFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
        <ReferenceInput source="type__in" reference="templates/types" alwaysOn>
            <SelectInput label="Types" optionText="label" />
        </ReferenceInput>
    </Filter>
);

const TemplateList = (props) => (
    <BaseList {...props} filters={<TemplateFilter />}>
        <EditableDatagrid rowClick="show" expand={<TemplatePanel />} actions={<EditPermissionButton label="" />}>
            <ShowElementLink source="name" obj="templates" />
            <ReferenceField label="Type" reference="templates/types" source="type" link={false}>
                <TextField source="label" />
            </ReferenceField>
        </EditableDatagrid>
    </BaseList>
);

export default TemplateList;
