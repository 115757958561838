import React from 'react';
import { NumberField, ReferenceField, TextField } from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';

const WeeklyBillableEditableGrid = (props) => {
    return (
        <EditableDatagrid {...props} mutationMode="pessimistic">
            <NumberField source="value" label="Target Hours" textAlign="left" />
            <TextField source="year" />
            <ReferenceField
                label="Supervision Role"
                reference="users/supervision-role"
                source="supervision_role"
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>
        </EditableDatagrid>
    );
};

export default WeeklyBillableEditableGrid;
