import { TabbedForm } from 'react-admin';
import SaveToolbar from './SaveToolbar';
import React from 'react';

const TabbedSaveForm = ({ customSave, quickSave, label, forceConfirmation, ...props }) => {
    return (
        <TabbedForm
            toolbar={
                <SaveToolbar
                    submitFunction={customSave}
                    quickSave={quickSave}
                    label={label}
                    forceConfirmation={forceConfirmation}
                />
            }
            {...props}
        />
    );
};

export default TabbedSaveForm;
