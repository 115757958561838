import React from 'react';
import {
    Show,
    SimpleShowLayout,
    NumberField,
    TextField,
    DateField,
    ReferenceField,
    ChipField,
    SingleFieldList,
    ReferenceArrayField,
} from 'react-admin';
import VersionShow from '../../base/show/VersionShow';
import { RoleStateField } from './fields/RoleState';

const ProjectRoleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <RoleStateField label="State" />
            <TextField source="name" />
            <NumberField source="budget" />
            <NumberField source="hourly_rate" />
            <DateField label="Start date" source="start_date" />
            <DateField label="End date" source="end_date" />
            <NumberField label="Weekly Hours" source="hours" />
            <ReferenceField label="Project" source="project" reference="projects" link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField
                reference="clusters"
                source="clusters"
                label="Clusters"
                sx={{ display: 'flex' }}
            >
                <SingleFieldList link="show">
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField
                reference="tools"
                source="tools"
                label="Tools"
                sx={{ display: 'flex' }}
            >
                <SingleFieldList link="show">
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>

            <TextField label="Comment" source="admin_comment" />
            <VersionShow reference="projects/role/versions" label="Event Log" />
        </SimpleShowLayout>
    </Show>
);

export default ProjectRoleShow;
