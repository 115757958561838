import WorkerNoteForm from './WorkerNoteForm';
import React from 'react';
import { Aside } from '../skill-estimation/WorkerSkillEstimationEdit';
import BaseCreate from '../../base/views/BaseCreate';

const WorkerNoteCreate = (props) => (
    <BaseCreate aside={<Aside />} {...props}>
        <WorkerNoteForm location={props.location} showSkills={true} />
    </BaseCreate>
);

export default WorkerNoteCreate;
