import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import {
    SaveButton,
    SaveButtonProps,
    Toolbar,
    useGetOne,
    useSaveContext,
} from 'react-admin';
import { useFormState, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Dialog from '../../../contract/project/components/Dialog';
import { useStep, WizardToolbarProps } from '../wizard-form';
import { useRowState } from '../wizard-form/rowState';
import { createSlug } from './ProjectStep';
import useCustomNotify from '../../../base/useCustomNotify';

const useStyles = makeStyles({
    spaceRight: {
        marginRight: '0.5rem !important',
    },
});

const CREATE_STEP_MAPPING = ['type', 'project', 'contract', 'overview'];

const CustomWizardToolbar = ({
    hasPreviousStep,
    hasNextStep,
    onPreviousClick,
    onNextClick,
    currentStep,
    ...props
}: WizardToolbarProps) => {
    const {
        state: { step },
    } = useStep();
    const { isValid, dirtyFields } = useFormState();
    const { getValues } = useFormContext();
    const { editing: rowFormEditing } = useRowState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const classes = useStyles();
    const location = useLocation();
    const isCreate = location.pathname.includes('create');
    const templateId = 100;
    const { data } = useGetOne('templates', { id: templateId });
    const values = getValues();
    const { saving } = useSaveContext();
    const notify = useCustomNotify();

    const isExtensionProjectStep =
        isCreate && values.project_type === 'extension' && CREATE_STEP_MAPPING[step] === 'project';
    const isNormalContractStep =
        isCreate && values.project_type !== 'extension' && CREATE_STEP_MAPPING[step] === 'contract';

    const isCurrentStepDirty: boolean =
        (currentStep === 0 &&
            (dirtyFields['client_profile'] ||
                dirtyFields['name_internal'] ||
                dirtyFields['name'] ||
                dirtyFields['main_department'] ||
                dirtyFields['classification'] ||
                dirtyFields['start_date'] ||
                dirtyFields['end_date'])) ||
        (currentStep === 1 && (dirtyFields['main_department'] || Object.keys(dirtyFields['client_contract'] ?? {}).length > 0)) ||
        (currentStep === 2 && Object.keys(dirtyFields['client_contract'] ?? {}).length > 0);

    const showSaveButton =
        isExtensionProjectStep || isNormalContractStep || (!isCreate && isCurrentStepDirty);

    const requireConfirm =
        values.client_contract?.state === 'draft' && values.client_contract?.technical_reviewer;

    const validateSave = () => {
        if (
            values.client_contract?.state === 'draft' &&
            values.client_contract?.technical_reviewer &&
            !values.main_department
        ) {
            notify(
                'Main Business Unit: This field cannot be blank. Please set the main BU in the previous contract step.',
                { type: 'warning' }
            );
            return;
        }
        if (requireConfirm) {
            setDialogOpen(true);
        } else {
            forceSave();
        }
    };

    const transform: SaveButtonProps['transform'] = (data) => {
        return {
            ...data,
            name: data.name_internal,
            slug: createSlug(data.name_internal),
        };
    };

    const onDialogSubmit = () => {
        setDialogOpen(false);
        forceSave();
    };

    /**
     * We force save so SaveButton handles the side effects.
     */
    const forceSave = () => {
        const saveButton = document.getElementById('my-save');
        saveButton?.click();
    };

    const getButtonLabel = () => {
        return showSaveButton || hasNextStep ? 'Back' : 'Edit';
    }

    const getEditingString = () => {
        return rowFormEditing ? '(You have unsaved items.)' : '';
    }

    const getDescription = () => {
        return data ? data.content.replace(/disabled/g, '') : '';
    }

    const getSavingString = () => {
        return saving ? 'Saving ...' : 'Save';
    }

    return (
        <Toolbar {...props}>
            {hasPreviousStep && (
                <Button
                    className={classes.spaceRight}
                    variant="outlined"
                    disabled={saving}
                    onClick={onPreviousClick}
                >
                    {getButtonLabel()}
                </Button>
            )}
            {hasNextStep && !showSaveButton ? (
                <Button
                    className={classes.spaceRight}
                    variant="contained"
                    color="primary"
                    disabled={!isValid || rowFormEditing || saving}
                    onClick={onNextClick}
                >
                    Next {getEditingString()}
                </Button>
            ) : null}
            {showSaveButton && (
                // Fake save button, we use the actual one since it handles many conveniences.
                <Button
                    className={classes.spaceRight}
                    disabled={!isValid || saving}
                    startIcon={<Save />}
                    color="primary"
                    variant="contained"
                    onClick={validateSave}
                >
                    {getSavingString()}
                </Button>
            )}
            <SaveButton
                id="my-save"
                type="button"
                transform={transform}
                style={{ visibility: 'hidden' }}
            //mutationOptions={{ onSuccess: onSuccess }}
            />
            {requireConfirm && (
                <Dialog
                    title="Send contract to review"
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    description={getDescription()}
                    confirmLabel={'Send to review'}
                    onConfirm={onDialogSubmit}
                />
            )}
        </Toolbar>
    );
};

export default CustomWizardToolbar;
