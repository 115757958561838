import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import useGetResource from '../../base/hooks/useGetResource';

const MetabaseExportTab = ({ record, ...props }) => {
    const { data: metabaseUrls } = useGetResource(`/projects/${record.id}/metabase`, {}, {}, {});

    return metabaseUrls['export'] ? (
        <IframeResizer
            onload="iFrameResize({}, this)"
            title="metabase"
            src={metabaseUrls['export']}
            frameBorder={0}
            width="100%"
            allowTransparency
        />
    ) : null;
};

export default MetabaseExportTab;
