import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InboxIcon from '@mui/icons-material/Inbox';
import IframeResizer from 'iframe-resizer-react';
import { DateTime } from 'luxon';
import React from 'react';
import { Link, SimpleList, List, usePermissions, useStore } from 'react-admin';
import { useLocation } from 'react-router-dom';
import HeadTitle from '../base/HeadTitle';
import useGetResource from '../base/hooks/useGetResource';
import Workload from '../base/workload/Workload';
import ProjectRoleList from '../project/role/ProjectRoleList';
import WorkerWorkloadGraph from '../worker/workload/WorkerWorkloadGraph';
import WorkerWorkloadTable from '../worker/workload/WorkerWorkloadTable';
import TodoActionsButton from './components/TodoActionsButton';
import getIcon from '../base/getIcon';

const PT = '1';

const useStyles = makeStyles({
    todoDiv: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
        display: 'flex',
    },
    iconDiv: {
        width: '3rem',
        display: 'flex',
        alignItems: 'center',
    },
});

const TodoEmpty = () => (
    <Card>
        <CardContent align="center">
            <InboxIcon />
            <Typography variant="subtitle1">No todos, you're all set!</Typography>
        </CardContent>
    </Card>
);

const getTodoItem = (record, classes) => (
    // removed secondaryText and leftIcon as they are not working well with linkType=//{false},hence using custom classes
    <div className={classes.todoDiv}>
        <div className={classes.iconDiv}>
            {React.createElement(record?.icon ? getIcon(record?.icon) : InboxIcon)}
        </div>
        <div>
            <Typography variant="subtitle1">{record.label}</Typography>
            <TodoActionsButton record={record} source="actions" />
        </div>
    </div>
)

const Dashboard = (props) => {
    const location = useLocation();
    const { permissions } = usePermissions();
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming
    const { data: metabaseUrls } = useGetResource('/users/worker/metabase', {}, {}, {});
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Typography variant="h6">Workload</Typography>
                <List
                    disableSyncWithLocation
                    {...props}
                    location={location}
                    basePath="/users/workers/invitations"
                    resource="users/workers/invitations"
                    filterDefaultValues={{
                        me: true,
                        start_date: DateTime.local().minus({ week: 4 }).startOf('week').toISODate(),
                        end_date: DateTime.local()
                            .plus({ week: weekCount - 5 })
                            .startOf('week')
                            .toISODate(),
                        upcoming: true,
                        outboarded: false,
                        non_billable: true,
                    }}
                    actions={false}
                    pagination={false}
                    title="Admin Dashboard"
                >
                    <Workload
                        tableView={<WorkerWorkloadTable contractHours={0} />}
                        graphView={<WorkerWorkloadGraph />}
                        disabled
                    />
                </List>
                {!!metabaseUrls['dashboard'] && (
                    <>
                        <Typography variant="h6">Department Dashboard</Typography>
                        <Card style={{ marginTop: '1rem' }}>
                            <CardContent>
                                <IframeResizer
                                    title="metabase"
                                    src={metabaseUrls['dashboard']}
                                    frameBorder={0}
                                    width="100%"
                                    allowTransparency
                                />
                            </CardContent>
                        </Card>
                    </>
                )}
                {!!metabaseUrls['personal_dashboard'] && (
                    <>
                        <Typography variant="h6">Personal Dashboard</Typography>
                        <Card style={{ marginTop: '1rem' }}>
                            <CardContent>
                                <IframeResizer
                                    title="metabase"
                                    src={metabaseUrls['personal_dashboard']}
                                    frameBorder={0}
                                    width="100%"
                                    allowTransparency
                                />
                            </CardContent>
                        </Card>
                    </>
                )}
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6">Your Todos</Typography>
                <List
                    resource="users/todos"
                    location={props.location}
                    empty={<TodoEmpty />}
                    actions={false}
                    title=" "
                >
                    <SimpleList
                        linkType={false}
                        primaryText={(record) => (
                            getTodoItem(record, classes)
                        )}
                    />
                </List>
            </Grid>
            <Grid item xs={12}>
                {permissions?.includes(PT) && (
                    <React.Fragment>
                        <Typography variant="h5" component="h5" gutterBottom gutterTop>
                            <Link
                                to={
                                    '/projects/role?filter={"project__mine"%3Atrue}&order=DESC&page=1&perPage=25&sort=id'
                                }
                            >
                                Your Project Roles
                            </Link>
                        </Typography>
                        <ProjectRoleList
                            title={<HeadTitle />}
                            basePath="/projects/role"
                            location={props.location}
                            resource="projects/role"
                            pagination={false}
                            filters={false}
                            filterDefaultValues={{
                                project__mine: true,
                            }}
                        />
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
};

export default Dashboard;
