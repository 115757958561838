import React from 'react';
import { NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import PercentageInput from '../../base/input/PercentageInput';

const UtilizationForm = (props) => {
    return (
        <RowForm {...props}>
            <PercentageInput source="value" label="Percentage" />
            <NumberInput source="year" />
            <ReferenceInput
                label="Supervision Role"
                source="supervision_role"
                reference="users/supervision-role"
            >
                <SelectInput style={{ minWidth: 200 }} optionText="name" />
            </ReferenceInput>
        </RowForm>
    );
};

export default UtilizationForm;
