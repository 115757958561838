import React from 'react';
import BaseShowView from '../../base/views/BaseShowView';
import { ReferenceField, TextField, SimpleShowLayout, NumberField, DateField } from 'react-admin';
import BooleanTrueField from '../../base/fields/BooleanTrueField';

const ProjectWorkerContractShow = (props) => {
    return (
        <BaseShowView {...props}>
            <SimpleShowLayout>
                <ReferenceField source="worker" reference="users/worker" link="show">
                    <TextField source="label" />
                </ReferenceField>
                <ReferenceField
                    source="type"
                    reference="enums/contract/ProjectWorkerContractType"
                    link={null}
                >
                    <TextField source="label" />
                </ReferenceField>
                <BooleanTrueField source="is_active" />
                <BooleanTrueField source="is_core" />
                <DateField source="start_date" />
                <DateField source="end_date" />
                <NumberField source="weekly_hours" />
                <ReferenceField
                    source="type_of_salary"
                    reference="enums/contract/TypeOfSalary"
                    link={null}
                >
                    <TextField source="label" />
                </ReferenceField>
                <NumberField source="payment" label="Hourly Salary" />
                <NumberField source="yearly_gross_salary" />
            </SimpleShowLayout>
        </BaseShowView>
    );
};

export default ProjectWorkerContractShow;
