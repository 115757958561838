import BalloonEditorBase from '@ckeditor/ckeditor5-editor-balloon/src/ballooneditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import '@ckeditor/ckeditor5-theme-lark/theme/theme.css';
import './ckeditor-custom.css';
import InsertTemplate from './plugins/InsertTemplate';
import InsertImage from './plugins/InsertImage';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Font from '@ckeditor/ckeditor5-font/src/font';

const customColorPalette = [
    {
        color: '#27B7D9',
        label: 'Motius Cyan',
    },
    {
        color: '#383E4E',
        label: 'Motius Dark Blue',
    },
    {
        color: 'white',
        label: 'White',
    },
    {
        color: 'black',
        label: 'Black',
    },
    {
        color: '#333',
        label: 'Dark Gray',
    },
    {
        color: '#AAA',
        label: 'Light Gray',
    },
    {
        color: 'hsl(4, 90%, 58%)',
        label: 'Red',
    },
    {
        color: 'hsl(291, 64%, 42%)',
        label: 'Purple',
    },
    {
        color: 'hsl(262, 52%, 47%)',
        label: 'Deep Purple',
    },
    {
        color: 'hsl(231, 48%, 48%)',
        label: 'Indigo',
    },
    {
        color: 'hsl(142,81%,29%)',
        label: 'Green',
    },
];

const CKEditorBalloonBlockEditor = (templateParams, placeholder, loadTemplates) => {
    class BalloonEditor extends BalloonEditorBase {}

    // Plugins to include in the build.
    BalloonEditor.builtinPlugins = [
        Essentials,
        UploadAdapter,
        Autoformat,
        BlockToolbar,
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Code,
        Subscript,
        Superscript,
        CodeBlock,
        HorizontalLine,
        Highlight,
        Alignment,
        BlockQuote,
        CKFinder,
        Font,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        ImageResize,
        Indent,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        PageBreak,
        PasteFromOffice,
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties,
        TodoList,
        InsertTemplate(templateParams, loadTemplates),
        InsertImage,
        Autosave,
    ];

    // Editor configuration.
    BalloonEditor.defaultConfig = {
        blockToolbar: [
            'heading',
            'fontColor',
            '|',
            'insertTemplate',
            '|',
            'alignment',
            'blockQuote',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent',
            'pageBreak',
            'horizontalLine',
            '|',
            'imageUpload',
            'insertImage',
            'insertTable',
            'mediaEmbed',
            'codeBlock',
        ],
        toolbar: {
            items: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'code',
                'subscript',
                'superscript',
                'fontColor',
                '|',
                'highlight',
                'codeBlock',
                'link',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'outdent',
                'indent',
                '|',
                'undo',
                'redo',
            ],
        },
        fontColor: {
            colors: customColorPalette,
        },
        image: {
            toolbar: [
                'imageStyle:full',
                'imageStyle:side',
                'imageStyle:alignLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignRight',
                '|',
                'imageTextAlternative',
            ],
            styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph',
                },
                {
                    model: 'heading1',
                    view: 'h3',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1',
                },
                {
                    model: 'heading2',
                    view: 'h4',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2',
                },
                {
                    model: 'heading3',
                    view: 'h5',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3',
                },
                {
                    model: 'heading4',
                    view: 'h6',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4',
                },
                {
                    model: 'heading5',
                    view: 'h7',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5',
                },
            ],
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
            tableProperties: {
                borderColors: customColorPalette,
                backgroundColors: customColorPalette,
            },

            tableCellProperties: {
                borderColors: customColorPalette,
                backgroundColors: customColorPalette,
            },
        },
        mediaEmbed: {
            previewsInData: true,
            extraProviders: [
                {
                    name: 'lucidchart',
                    url: /^www.lucidchart.com\/documents\/edit\/([\w-]+)\/[0-9]/,
                    html: (match) => {
                        const id = match[1];

                        return (
                            '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
                            `<iframe src="https://www.lucidchart.com/documents/embeddedchart/${id}" ` +
                            `id="${id}" ` +
                            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                            'frameborder="0" width="960" height="720" allowfullscreen>' +
                            '</iframe>' +
                            '</div>'
                        );
                    },
                },
            ],
        },
        placeholder: placeholder,
        // This value must be kept in sync with the language defined in webpack.config.js.
        language: 'en',
    };
    return BalloonEditor;
};

export default CKEditorBalloonBlockEditor;
