import {Grid, Typography, Button, Chip, Dialog} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReferenceField, TextField, useRecordContext, useRefresh } from 'react-admin';
import React, { useState } from 'react';
import OverviewHeaderValue from './OverviewHeaderValue';
import { apiUrl, httpClient } from '../../../dataProvider';
import { OpenInNew } from '@mui/icons-material';
import { currencyFormatter } from '../../../base/fields/CurrencyField';
import ContractDetails from './ContractDetails';
const useStyles = makeStyles((theme) => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 8,
        },
    },
    buttons: {
        marginTop: 8,
        '& > *': {
            marginLeft: 4,
        },
    },
    oldPdfButton: {
        color: theme.palette.error.main,
    },
}));

const OverviewHeader: React.FC = () => {
    const classes = useStyles();
    const record = useRecordContext();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const [showContractData, setShowContractData] = useState(false);
    const isPdfOld = !record.client_contract?.contract?.name.includes(
        record.client_contract?.public_id
    );

    const generatePdf = async () => {
        setLoading(true);
        await httpClient(
            `${apiUrl}/contracts/projects/${record?.client_contract?.id}/generate-contract`,
            { method: 'post' }
        );
        setLoading(false);
        refresh();
    };

    const showContractDetails = () => {
        setShowContractData(true);
    };

    const handleClose = () => {
        setShowContractData(false);
    };

    return (
        <>
            <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                    <div className={classes.label}>
                        <Chip
                            label={
                                <Typography variant="subtitle1">
                                    {record?.client_contract?.state_label}
                                </Typography>
                            }
                            color="primary"
                        />
                        <Typography variant="h5">{record?.client_contract?.label}</Typography>
                    </div>
                </Grid>
                <Grid item className={classes.buttons}>
                    {isPdfOld && 'draft' === record?.client_contract.state ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={generatePdf}
                            disabled={loading}
                        >
                            Regenerate PDF
                        </Button>
                    ) : null}
                    <Button color="primary" onClick={showContractDetails}>
                        View Contract
                    </Button>
                    {record?.client_contract?.contract?.url ? (
                        <Button
                            className={isPdfOld ? classes.oldPdfButton : ''}
                            color="primary"
                            endIcon={<OpenInNew />}
                            target="_blank"
                            href={record?.client_contract?.contract?.url}
                        >
                            Download Contract PDF {isPdfOld ? '(Out of Date)' : ''}
                        </Button>
                    ) : null}
                    {record?.client_contract?.calculation_file ? (
                        <Button
                            color="primary"
                            endIcon={<OpenInNew />}
                            target="_blank"
                            href={record?.client_contract?.calculation_file?.url}
                        >
                            Download Calculation File
                        </Button>
                    ) : null}
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{ marginTop: 8, width: '100%', justifyContent: 'space-between' }}
            >
                <Grid item>
                    <OverviewHeaderValue
                        label="Net volume"
                        value={
                            currencyFormatter.format(record?.client_contract?.used_net_value || 0) +
                            (record?.client_contract?.unused_value > 0
                                ? ` (${currencyFormatter.format(
                                      parseFloat(record?.client_contract?.unused_value)
                                  )} unused revenue)`
                                : '')
                        }
                    />
                </Grid>
                <Grid item>
                    <OverviewHeaderValue
                        label="Project"
                        value={
                            <ReferenceField
                                label="Project"
                                source="id"
                                reference="projects"
                                link={(record) => `/projects/${record.id}/show/contract`}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    />
                </Grid>
                <Grid item>
                    <OverviewHeaderValue
                        label="Client"
                        value={
                            <ReferenceField
                                label="Project"
                                source="client_profile"
                                reference="users/client"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    />
                </Grid>
                <Grid item>
                    {record?.client_contract?.technical_reviewer ? (
                        <OverviewHeaderValue
                            label="Reviewer"
                            value={
                                <ReferenceField
                                    label="Reviewer"
                                    source="client_contract.technical_reviewer"
                                    reference="users/worker/reviewers"
                                >
                                    <TextField source="label" />
                                </ReferenceField>
                            }
                        />
                    ) : null}
                </Grid>
            </Grid>
            <Dialog open={showContractData} maxWidth="xl" onClose={handleClose}>
                <ContractDetails />
            </Dialog>
        </>
    );
};

export default OverviewHeader;
