import React, { useEffect } from 'react';
import { ReferenceInput, required, SelectInput } from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';

const ClientAddressInput = (props) => {
    const form = useForm();

    const { getValues, setValue } = useFormContext();
    const values = getValues();
    useEffect(() => {
        if (values.project && !values.id) {
            // Reset client address when project gets changed
            setValue('client_contract.client_address', null);
        }
    }, [values.project, form, values.id]);

    return values.id ? (
        <ReferenceInput
            source="client_contract.client_address"
            reference="users/client/address"
            filter={{ projects: values.id }}
            filterToQuery={(text) => ({
                name: text,
                projects: values.id,
            })}
        >
            <SelectInput
                optionText="address"
                label="Client Address"
                fullWidth
                variant="outlined"
                margin="normal"
                validate={[required()]}
            />
        </ReferenceInput>
    ) : null;
};

export default ClientAddressInput;
