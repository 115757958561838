import WorkerSkillEstimationForm from './WorkerSkillEstimationForm';
import React from 'react';
import { Aside } from './WorkerSkillEstimationEdit';
import BaseCreate from '../../base/views/BaseCreate';

const WorkerSkillEstimationCreate = (props) => (
    <BaseCreate aside={<Aside />} {...props}>
        <WorkerSkillEstimationForm
            initialValues={{ entries: [{ tool: null, estimation: null }] }}
            {...props}
        />
    </BaseCreate>
);

export default WorkerSkillEstimationCreate;
