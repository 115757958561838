import { Filter, TextInput } from 'react-admin';
import React from 'react';

const SearchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export default SearchFilter;
