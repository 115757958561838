import React, { Children } from "react";
import { Box } from "@mui/material";

/**
 * When using react-admin forms some props are automatically passed
 *
 * This allows us to create column layout via automatically passing the props
 */
const FormRow: React.FC<any> = ({
  columnCount = 2,
  children,
  input,
  className,
  ...props
}) => {
  return (
    <Box
      display="grid"
      gap="1rem"
      gridTemplateColumns={`repeat(${columnCount}, 1fr)`}
    >
      {Children.map(
        children as React.ReactElement,
        (child: React.ReactElement) => child && React.cloneElement(child, { ...props })
      )}
    </Box>
  );
};

export default FormRow;
