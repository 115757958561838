import React from 'react';
import {
    EditButton,
    Filter,
    FormDataConsumer,
    ReferenceArrayInput,
    ReferenceField,
    SelectArrayInput,
    TextField,
    TextInput,
    AutocompleteInput,
    DateField,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    useResourceDefinition,
} from 'react-admin';
import { Tooltip, Typography } from '@mui/material';
import { contentTypes } from './InvoiceForm';
import { StateExportActions, StateExportBulkActions } from '../../base/actions/StateExportActions';
import BaseList from '../../base/views/BaseList';
import InvoiceRerminderAction from '../../base/actions/InvoiceReminderAction';
import NumeralField from '../../base/fields/NumeralField';
import TextFilterLinkField from '../../base/fields/TextFilterLinkField';
import InvoiceRecipient from './components/InvoiceRecipient';
import { DateInputField } from '../../base/input/DateInput';
import AutocompleteCustomInput from '../../base/input/AutocompleteCustomInput';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../../base/buttons/ShowElementLink';

export const InvoiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
        <ReferenceArrayInput source="state__in" reference="enums/payment/InvoiceState" alwaysOn>
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="State" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="type__in" reference="enums/payment/InvoiceType" alwaysOn>
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="Type" />
        </ReferenceArrayInput>
        <ReferenceInput source="cost_center" reference="cost-centers" alwaysOn>
            <AutocompleteInput optionText="label" label="Cost Center" />
        </ReferenceInput>
        <ReferenceArrayInput
            source="category__in"
            reference="enums/payment/InvoiceCategory"
            alwaysOn
        >
            <SelectArrayInput
                style={{ minWidth: 200 }}
                optionText="label"
                label="Refund Category"
            />
        </ReferenceArrayInput>
        <ReferenceInput
            source="recipient_ct"
            reference="content-types"
            filter={{ model__in: ['workerprofile', 'clientprofile'] }}
            alwaysOn
        >
            <SelectInput label="Recipient Type" style={{ minWidth: 150 }} optionText="label" />
        </ReferenceInput>
        <FormDataConsumer alwaysOn>
            {({ formData, ...rest }) => {
                if (formData.recipient_ct === contentTypes.workerprofile) {
                    return (
                        <ReferenceInput
                            source="recipient_id"
                            reference="users/worker"
                            allowEmpty={true}
                        >
                            <AutocompleteCustomInput label="Worker Profile" optionText="label" />
                        </ReferenceInput>
                    );
                } else if (formData.recipient_ct === contentTypes.clientprofile) {
                    return (
                        <ReferenceInput
                            source="recipient_id"
                            reference="users/client"
                            allowEmpty={true}
                        >
                            <AutocompleteInput label="Client Profile" optionText="name" />
                        </ReferenceInput>
                    );
                }
            }}
        </FormDataConsumer>
        <BooleanInput
            source="include_rejected"
            label={
                <Tooltip title="Also shows invoices in state rejected">
                    <Typography>Show rejected</Typography>
                </Tooltip>
            }
            alwaysOn
        />
        <BooleanInput
            source="include_planned"
            label={
                <Tooltip title="Also shows planned invoices">
                    <Typography>Show planned</Typography>
                </Tooltip>
            }
            alwaysOn
        />
        <ReferenceInput label="Contract" source="client_contract" reference="contracts/projects">
            <AutocompleteInput optionText="label" />
        </ReferenceInput>
        <DateInputField label="Start Date Before" source="start_date__lt" />
        <DateInputField label="Start Date After" source="start_date__gte" />
        <DateInputField label="End Date Before" source="end_date__lt" />
        <DateInputField label="End Date After" source="end_date__gte" />
        <DateInputField label="Due Date Before" source="due_date__lt" />
        <DateInputField label="Due Date After" source="due_date__gte" />
        <DateInputField label="Paid Date Before" source="paid_date__lt" />
        <DateInputField label="Paid Date After" source="paid_date__gte" />
    </Filter>
);

const InvoiceActions = (props) => (
    <StateExportActions stateResource="enums/payment/InvoiceState" {...props}>
        <InvoiceRerminderAction {...props} />
    </StateExportActions>
);

const InvoiceBulkActions = (props) => (
    <StateExportBulkActions stateResource="enums/payment/InvoiceState" {...props}>
        <InvoiceRerminderAction {...props} />
    </StateExportBulkActions>
);

const InvoiceList = (props) => {
    const { hasEdit } = useResourceDefinition();
    return (
        <BaseList
            //disableSyncWithLocation
            filters={<InvoiceFilter />}
            actions={<InvoiceActions />}
            bulkActionButtons={<InvoiceBulkActions forceConfirmation />}
            filterDefaultValues={{ include_planned: false, include_rejected: false }}
            sx={{ '& .RaList-actions': { display: 'block' } }}
            {...props}
            hasCreate={true}
        >
            <EditableDatagrid rowClick="show" actions={hasEdit && <EditButton label='' />}>
                <ShowElementLink source="public_id" label="Bookkeeping ID" obj="payment/invoices" />
                <TextField source="project_name" label="Project" />
                <ReferenceField
                    source="cost_center"
                    label="Cost Center"
                    reference="cost-centers"
                    link={false}
                >
                    <TextFilterLinkField source="public_id" filterName="cost_center" />
                </ReferenceField>
                <InvoiceRecipient label="Recipient" />
                <TextField source="type_label" label="Type" sortBy="type" />
                <ReferenceField
                    source="category"
                    label="Category"
                    reference="enums/payment/InvoiceCategory"
                    link={false}
                    allowEmpty
                >
                    <TextFilterLinkField source="label" filterName="category__in" multiple />
                </ReferenceField>
                <TextField source="state_label" label="State" sortBy="state" />
                <NumeralField
                    source="amount_discounted_net_signed"
                    options={{ style: 'currency', currency: 'EUR' }}
                    label="Net Total"
                />
                <NumeralField
                    source="amount_gross"
                    options={{ style: 'currency', currency: 'EUR' }}
                    label="Gross Total"
                />
                <DateField source="created" label="Created on" />
            </EditableDatagrid>
        </BaseList>
    );
};

export default InvoiceList;
