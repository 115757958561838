import React from "react";
import { Edit, EditProps, useRedirect, useRefresh } from "react-admin";
import { regeneratePDFWithProjectId } from "./components/utils";
import ProjectContractWizardForm from "./ProjectContractWizardForm";
import useCustomNotify from "../../base/useCustomNotify";

const ProjectContractEdit = (props: EditProps) => {
  const notify = useCustomNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onEditSuccess = async (response: any) => {
    if (response?.id) {
      try {
        await regeneratePDFWithProjectId(response?.id, refresh);
      } catch (e) {
        notify("Error during regenerating PDF with project Id");
      }
      redirect(`/projects/wizard/${response?.id}/2`);
    }
  };

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: onEditSuccess }}
      title="Edit Project and Contract"
      actions={false} /* hides the show button */
    >
      <ProjectContractWizardForm />
    </Edit>
  );
};

export default ProjectContractEdit;
