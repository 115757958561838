import {
    ArrayInput,
    SelectInput,
    FormDataConsumer,
    required,
    AutocompleteInput,
    ReferenceInput,
    SimpleForm,
} from 'react-admin';
import React from 'react';
import { SkillEntryFormIterator } from '../skill-estimation/WorkerSkillEstimationForm';
import CKEditorInput from '../../base/ckeditor/CKEditorInput';
import { useLocation } from 'react-router-dom';
import { DateInputField } from '../../base/input/DateInput';

export const evaluationTypes = [
    'interview',
    'technical_interview',
    'evaluation',
    'team_evaluation',
];

const WorkerNoteForm = ({ showSkills, redirect, ...props }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const project = searchParams.get('project') ? parseInt(searchParams.get('project')) : null;
    const worker = parseInt(searchParams.get('worker'));
    const dueDate = searchParams.get('due_date');

    return (
        <SimpleForm
            defaultValues={{
                worker,
                project,
                dueDate,
                type: project ? 'project_note' : null,
            }}
            redirect={(basePath, id, data) => `/users/worker/${data.worker}/show/notes`}
            quickSave={false}
            {...props}
        >
            <ReferenceInput source="type" reference="enums/worker/WorkerNoteType">
                <SelectInput
                    style={{ minWidth: 100 }}
                    optionText="label"
                    validate={[required()]}
                    label="Type"
                    sx={{ width: '260px' }}
                />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <CKEditorInput
                        source="description"
                        label="Description"
                        validate={[required()]}
                        defaultValueFromTemplate={
                            formData.type ? formData.type.replace('-', '_') : null
                        }
                        templateParams={{
                            filter: { type: 'template.WorkerNoteTemplate' },
                        }}
                    />
                )}
            </FormDataConsumer>
            <ReferenceInput source="project" reference="projects" allowEmpty={true}>
                <AutocompleteInput
                    optionText="label"
                    optionValue="id"
                    label="Project"
                    filterToQuery={(text) => ({ name__icontains: text })}
                    sx={{ width: '260px' }}
                />
            </ReferenceInput>
            <DateInputField source="due_date" label="Due date" />
            {showSkills && (
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ArrayInput label="Skills" source="skills">
                            <SkillEntryFormIterator />
                        </ArrayInput>
                    )}
                </FormDataConsumer>
            )}
        </SimpleForm>
    );
};

export default WorkerNoteForm;
