import React from 'react';
import {
    required,
    FormDataConsumer,
    SelectInput,
    BooleanInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    useListContext,
    AutocompleteInput,
} from 'react-admin';
import { RowForm } from '@react-admin/ra-editable-datagrid';
import CurrencyInput from '../../base/input/CurrencyInput';
import InvitationRoleField from './fields/InvitationRoleField';
import AutocompleteCustomInput from '../../base/input/AutocompleteCustomInput';

const ProjectInvitationForm = ({ hideProject, hideWorker, ...props }) => {
    const { data: teamList } = useListContext();
    const handleTransform = (data) => {
        let state = data.state;
        if (!state) {
            //initially when a new member is added, the source called 'state' is not added into form when using create_accepted,
            //hence source is undefined when edit row form is clicked. 6 is accepted and 1 is selected (hardcoded as of now)
            state = data.create_accepted ? '6' : '1';
        }
        if (data.id) {
            const record = data.id && teamList.filter((x) => x.id === data.id)[0];
            const state_transitions = (data.state_transitions || record?.state_transitions)?.filter(
                (x) => x.can_proceed
            );
            //In edit mode, backend validates state transitions by using old state value, hence
            //need to delete updated state param and state_transition param is enough to know new state change - MP-3067
            delete data.state;
            //pass state_transition only when the State dropwdown is changed
            if (
                state_transitions?.filter((item) => item.target === state).length > 0 &&
                data.state_label !==
                state_transitions?.filter((item) => item.target === state)[0].custom.button_name
            ) {
                return {
                    ...data,
                    state_transition: state_transitions.filter((item) => item.target === state)[0]
                        .name,
                };
            }
            return {
                ...data,
            };
        }
        return {
            ...data,
            state: state,
        };
    };
    return (
        <RowForm {...props} transform={handleTransform}>
            {!hideProject && (
                <ReferenceInput source="project" reference="projects" alwaysOn>
                    <AutocompleteCustomInput optionText="label" label="Project" />
                </ReferenceInput>
            )}
            {!hideWorker && (
                <ReferenceInput
                    source="worker.id"
                    reference="users/worker"
                    enableGetChoices={({ autocomplete }) =>
                        !!autocomplete && autocomplete.length >= 3
                    }
                    queryOptions={{ keepPreviousData: false }}
                >
                    <AutocompleteInput
                        label="Worker"
                        helperText="Please enter at least 3 characters. Only shows accepted workers/guests."
                        optionText="label"
                        filterToQuery={(searchText) => ({
                            autocomplete: searchText,
                            state__in: ['accepted'],
                        })}
                        validate={[required()]}
                        shouldRenderSuggestions={(val) => {
                            return val.trim().length >= 3;
                        }}
                    />
                </ReferenceInput>
            )}
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    const record = formData.id && teamList.filter((x) => x.id === formData.id)[0];
                    const state_transitions =
                        formData.state_transitions || record?.state_transitions;
                    const choices =
                        state_transitions
                            ?.filter((item) => !item.completed)
                            ?.map((item) => ({
                                id: item.target,
                                name: item.custom.button_name,
                            })) ?? [];

                    //MP-2938 In state_transitions, selected choice is not present. Hence added selected choice also to list to make it selected when clicked on edit
                    if (record) {
                        const selectedChoice = {
                            id: record.state,
                            name: record.state_label,
                        };
                        choices.push(selectedChoice);
                    }
                    return formData.id ? (
                        <SelectInput
                            key={formData.id}
                            label="State"
                            source="state"
                            choices={choices}
                            {...rest}
                        />
                    ) : (
                        <BooleanInput
                            label="Accepted"
                            helperText="Leave unchecked, to preselect someone"
                            source="create_accepted"
                        />
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return <InvitationRoleField formData={formData} />;
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return <CurrencyInput source="hourly_rate" label="Hourly Client Rate" validate={formData.create_accepted || (formData.state && formData.state === 6) ? required() : null} />;
                }}
            </FormDataConsumer>
            <ReferenceArrayInput
                source="responsibilities"
                reference="enums/project/ProjectResponsibilityType"
                link={false}
            >
                <SelectArrayInput optionText="label" style={{ minWidth: 200 }} />
            </ReferenceArrayInput>
        </RowForm>
    );
};

export default ProjectInvitationForm;
