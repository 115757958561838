import React from 'react';
import { EditButton, ReferenceField, TextField } from 'react-admin';
import SearchFilter from '../base/SearchFilter';
import BaseList from '../base/views/BaseList';
import { ClusterField } from './fields';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

const ClusterList = (props) => (
    <BaseList {...props} filters={<SearchFilter />}>
        <EditableDatagrid rowClick='show' actions={<EditButton label="" />}>
            <ShowElementLink source='name' label="Name" obj="clusters" />
            <ClusterField label="Code" sortBy="code" />
            <ReferenceField source="owner" reference="users" link={false}>
                <TextField source="name" />
            </ReferenceField>
        </EditableDatagrid>
    </BaseList>
);

export default ClusterList;
