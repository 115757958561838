import React from 'react';
import { Datagrid } from 'react-admin';
import BaseList from '../../base/views/BaseList';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const WorkerTagList = (props) => {
    return (
        <BaseList {...props}>
            <Datagrid rowClick="edit" sx={{ marginTop: '-50px' }}>
                <ShowElementLink source="name" obj="users/workers/tags" />
            </Datagrid>
        </BaseList>
    );
};

export default WorkerTagList;