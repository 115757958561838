import React, { useEffect, useState } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import CKEditorComponent from './CKEditorComponent';
import nestedGet from 'lodash/get';

const stripHtmlTags = (value) => value.slice(3, -4);

const CKEditorInput = (props) => {
    const { maxTextLength = undefined, disabled } = props;
    const { field } = useInput(props);
    const record = useRecordContext();

    const [currentLength, setCurrentLength] = useState(0);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (maxTextLength) {
            setCurrentLength(stripHtmlTags(field.value).length);
        }
    }, [maxTextLength, field.value]);

    useEffect(() => {
        setValue(field.value);
    }, []);

    const updateField = (value) => {
        field.onChange(value);
    };

    return (
        <div style={{ width: '100%' }}>
            <CKEditorComponent
                fullWidth
                value={value}
                defaultValue={record ? nestedGet(record, props.source) : ''}
                disabled={disabled}
                updateField={updateField}
                {...props}
            />
            {maxTextLength && (
                <Typography
                    className={`letter-indicator ${currentLength > maxTextLength && 'invalid'}`}
                    variant="caption"
                >
                    {currentLength}/{maxTextLength} letters
                </Typography>
            )}
        </div>
    );
};

CKEditorInput.defaultProps = {
    fullWidth: true,
    placeholder: 'Start typing or pick a template from the ¶ icon.',
    templateParams: {},
    defaultValueFromTemplate: null,
};

export default CKEditorInput;
