import React from 'react';
import { ReferenceManyField, Datagrid, ReferenceField, TextField, DateField } from 'react-admin';
import OpenJsonDataButton from '../buttons/OpenJsonDataButton';

const VersionShow = ({ reference, label, ...props }) => {
    return (
        <>
            <ReferenceManyField reference={reference} target="object_id" label={false} {...props}>
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField reference="users" source="user">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="comment" label="Changed" />
                    <DateField source="date_created" />
                    <OpenJsonDataButton
                        label="See Revision"
                        encoding="data:application/json"
                        source="version"
                    />
                </Datagrid>
            </ReferenceManyField>
        </>
    );
};

export default VersionShow;
