import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useRecordContext } from 'react-admin';

const NumeralField = ({ source, locales, options, unit = '', ...props }) => {
    const record = useRecordContext();
    if (!record) {
        return '';
    }

    if (record[source] === null || record[source] === undefined) {
        return '';
    }
    const localeString = parseFloat(record[source]).toLocaleString(locales, options);
    return <Typography variant="body2" {...props}>{`${localeString} ${unit}`}</Typography>;
};

NumeralField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    options: PropTypes.object,
    align: PropTypes.string,
    textAlign: PropTypes.string,
    source: PropTypes.string.isRequired,
};

NumeralField.defaultProps = {
    align: 'right',
    textAlign: 'right',
    locales: 'de-DE',
    variant: 'body1',
    options: {},
};

export default NumeralField;
