import React from 'react';
import { Inbox } from '@mui/icons-material';
import {
    IconButton,
    Badge,
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGetList, Button, useRedirect } from 'react-admin';
import TodoActionsButton from '../dashboard/components/TodoActionsButton';
import getIcon from './getIcon';

const useStyles = makeStyles({
    container: {
        maxWidth: '20vw',
    },
    button: {
        margin: '0 1rem',
        float: 'right',
        width: '100%',
    },
    spacingTop: {
        marginTop: '1rem',
    },
    actionButton: {
        '& span': {
            whiteSpace: 'nowrap',
        },
    },
    todoDiv: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'flex',
    },
    iconDiv: {
        width: '3rem',
        display: 'flex',
        alignItems: 'center',
    },
});

const TodoNotifications = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { data, loading, error, total } = useGetList('users/todos', { page: 1, perPage: 10 });

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSeeAll = () => {
        redirect('/');
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <IconButton color="primary" onClick={handleClick} size="large">
                <Badge badgeContent={total} color="primary">
                    <Inbox />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
                // transformOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
            >
                {error && <div>Could not load notifications</div>}
                {loading && <LinearProgress className={classes.spacingTop} />}
                {data ? (
                    <List>
                        {Object.keys(data).map((key) => {
                            const todo = data[key];
                            return (
                                <ListItem key={key}>
                                    <div className={classes.todoDiv}>
                                        <div className={classes.iconDiv}>
                                            <ListItemIcon>
                                                {React.createElement(
                                                    todo.icon ? getIcon(todo.icon) : Inbox
                                                )}
                                            </ListItemIcon>
                                        </div>
                                        <div>
                                            <ListItemText primary={todo.label} />
                                            <div className={classes.actionButton}>
                                                <TodoActionsButton
                                                    record={todo}
                                                    source="actions"
                                                    size="small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ListItem>
                            );
                        })}
                    </List>
                ) : null}
                <Button label="See All Todos" className={classes.button} onClick={onSeeAll} />
            </Popover>
        </div>
    );
};

export default TodoNotifications;
