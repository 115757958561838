import React, { useState } from 'react';
import { Button } from 'react-admin';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

const AtlassianConnectContent = ({ options, onConnect, dialogTitle }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectOption = (event, value) => {
        setSelectedOption(value);
    };

    const handleConnect = () => {
        onConnect(selectedOption);
    };

    return (
        <>
            <DialogContentText>{dialogTitle}</DialogContentText>
            <FormControl style={{ minWidth: 400 }}>
                <Autocomplete
                    value={selectedOption}
                    onChange={handleSelectOption}
                    options={options}
                    getOptionLabel={(option) => `${option.key} - ${option.name}`}
                    renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
            <DialogActions>
                <Button
                    disabled={!Boolean(selectedOption)}
                    label="Connect"
                    onClick={handleConnect}
                ></Button>
            </DialogActions>
        </>
    );
};

export default AtlassianConnectContent;
