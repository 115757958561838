import { useLocation, useResolvedPath } from 'react-router-dom';
import * as React from 'react';
import { useMemo } from 'react';

type Action = { type: 'next' } | { type: 'previous' } | { type: 'set'; step: number };
type Dispatch = (action: Action) => void;
type State = { step: number };
type StepProviderProps = { children: React.ReactNode };

const StepStateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(
    undefined
);

function stepReducer(state: State, action: Action) {
    switch (action.type) {
        case 'next': {
            return { step: state.step + 1 };
        }
        case 'previous': {
            return { step: state.step - 1 };
        }
        case 'set': {
            return { step: action.step };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function StepProvider({ children }: StepProviderProps) {
    const location = useLocation();
    const formRootPathname = useResolvedPath("").pathname?? location.pathname;
    const currentHash = Number(location.pathname.replace(formRootPathname + '/', ''))
    const initStep = Number.isNaN(currentHash) || formRootPathname.includes('create') ? 0 : currentHash 
    
    const [state, dispatch] = React.useReducer(stepReducer, { step: initStep });
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
    return <StepStateContext.Provider value={value}>{children}</StepStateContext.Provider>;
}

function useStep() {
    const context = React.useContext(StepStateContext);
    if (context === undefined) {
        throw new Error('useStep must be used within a StepProvider');
    }
    return context;
}

export { StepProvider, useStep };
