import React from 'react';
import * as PropTypes from 'prop-types';
import { apiUrl, httpClient } from '../../../../../dataProvider';
import AtlassianConnectContent from '../AtlassianConnectContent';
import useCustomNotify from '../../../../useCustomNotify';

const SpaceConnectDialogContent = ({ confluenceSpaces, platformProject, onFinished }) => {
    const notify = useCustomNotify();

    const handleConnect = async (selectedSpace) => {
        const confluenceOptions = {
            method: 'PATCH',
            body: JSON.stringify({
                confluence_key: selectedSpace.key,
                project_id: platformProject.id,
                slug: platformProject.slug,
            }),
        };

        try {
            await httpClient(`${apiUrl}/confluence-spaces/confluence`, confluenceOptions);
            notify(`Confluence space is being connected.`);
        } catch (e) {
            notify(`Error when connecting Confluence space`, { type: 'warning' });
        }

        onFinished();
    };

    return (
        <AtlassianConnectContent
            dialogTitle="Connect to an existing Confluence space"
            onConnect={handleConnect}
            options={confluenceSpaces}
        />
    );
};

SpaceConnectDialogContent.propTypes = {
    confluenceSpaces: PropTypes.array.isRequired,
    platformProject: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
};

export default SpaceConnectDialogContent;
