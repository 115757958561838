import React from 'react';
import Icon from '@mui/icons-material/CallSplit';
import Create from './WebhookCreate';
import Edit from './WebhookEdit';
import List from './WebhookList';
import onlyAdminViews from '../auth/onlyAdminViews';

const resourceName = 'Webhook';
const resourceNamePlural = 'Webhooks';

const webhook = (permissions) =>
    onlyAdminViews(permissions, {
        icon: () => <Icon />,
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default webhook;
