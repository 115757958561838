import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { Link, useListContext } from 'react-admin';
import WorkloadDialogForm from './WorkloadDialogForm';
import useWorkloadStyles from '../useWorkloadStyles';
import { DateTime } from 'luxon';
import { getWeekRangeFromFilterValues } from '../workloadUtils';
import Chip from "@mui/material/Chip";
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import { RowData, useWorkloadState } from '../WorkloadContext';

type WorkloadTableRowTitleCellProps = {
    row: RowData
    disabled: boolean
}

const WorkloadTableRowTitleCell: React.FC<WorkloadTableRowTitleCellProps> = ({ row, disabled }) => {
    const isProject = useWorkloadState(state => state.isProject)
    const classes = useWorkloadStyles();
    const { filterValues } = useListContext();
    const weekRange = getWeekRangeFromFilterValues(filterValues);
    const name = isProject ? row.worker_name : row.project_name;
    const id = isProject ? row.worker : row.project;

    const get_subtitle = (row: RowData) => {
        // Show invitation state for all invitations except accepted beside the role name
        if (row.role_name) {
            if (row.state !== 6) {
                return `${row.role_name}(${row.state_label})`;
            }
            return row.role_name;
        } else if (row.state && row.state !== 6) {
            return `(${row.state_label})`;
        }
        return '';
    };

    const get_warnings = (warnings: string[]) => {
        return (
            <ul>
                {warnings.map((warning) => {
                    return (
                        <li key={warning}>
                            <span>{warning}</span>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <TableCell colSpan={3} style={{ padding: 0 }}>
            <span className={classes.titleCell}>
                <span className={classes.spacedInCell}>
                    <span className={`${classes.projectCell} ${classes.paddedCell}`}>
                        <Typography variant="subtitle1">
                            {isProject ? (
                                <Link to={`/users/worker/${id}/show/workload`}>
                                    {name}
                                </Link>
                            ) : (
                                <Link to={`/projects/${id}/show/workload`}>{name}</Link>
                            )}
                            { !isProject && row.warnings && row.warnings.length > 0 ?
                                <Tooltip title={get_warnings(row.warnings || [])}>
                                    <WarningIcon className={classes.warningIcon}/>
                                </Tooltip> : null
                            }
                        </Typography>
                        {
                            !isProject && row.project_state_before_progress ? (
                                    <Typography variant="subtitle2">
                                        <Chip variant="outlined" size="small" color="primary" label={row.project_state_before_progress} />
                                    </Typography>
                                ) : null
                        }
                        <Typography variant="subtitle2">{get_subtitle(row)}</Typography>
                        {!isProject && (
                            <Typography color="textSecondary" variant="subtitle2">
                                Ends:{' '}
                                {DateTime.fromISO(row.project_end_date).toLocaleString(
                                    DateTime.DATE_MED
                                )}
                            </Typography>
                        )}
                    </span>
                    <div className={classes.dateNav}>
                        {!disabled ? (
                            <WorkloadDialogForm
                                weekRange={weekRange}
                                project={row.project}
                                worker_name={row.worker_name}
                                worker={row.worker}
                                id={row.id}
                                project_name={row.project_name}
                                project_end_date={row.project_end_date}
                            />
                        ) : null}
                    </div>
                </span>
            </span>
        </TableCell>
    );
};

export default WorkloadTableRowTitleCell;
