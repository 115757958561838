import React from 'react';
import { BooleanInput, useListFilterContext } from 'react-admin';

const HighlightToggle = (props) => {
    const {
        filterValues: { search },
    } = useListFilterContext();

    return !!search ? (
        <BooleanInput label="Highlight Results" source="highlight" alwaysOn {...props} />
    ) : null;
};

export default HighlightToggle;
