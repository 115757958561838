import React from 'react';
import { Datagrid, TextField, Filter, TextInput } from 'react-admin';
import BaseList from '../../base/views/BaseList';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const TimelogTagFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name__icontains" label="Name" alwaysOn />
    </Filter>
);

const TimelogTagList = (props) => {
    return (
        <BaseList {...props} filters={<TimelogTagFilter />}>
            <Datagrid rowClick="edit">
                <ShowElementLink source="name" obj="timelog-tags" />
                <TextField source="description" />
            </Datagrid>
        </BaseList>
    );
};

export default TimelogTagList;
