import { useRecordContext } from 'react-admin'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ContractRedirect = () => {
    const navigate = useNavigate()
    const record = useRecordContext()

    useEffect(() => {
        if (navigate && record) {
          navigate(`/projects/wizard/${record.project}`)
        }

    }, [navigate, record])
    
  return (
    <div>Loading...</div>
  )
}

export default ContractRedirect