import React from 'react';
import {
    DeleteWithConfirmButton,
    maxLength,
    regex,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import CKEditorInput from '../../base/ckeditor/CKEditorInput';
import { useLocation } from 'react-router-dom';
import useCustomNotify from '../../base/useCustomNotify';

const maxTextLengthInChars = 700;
const placeholders = {
    story: 'Briefly outline and describe what happened in the project and what it is about (the use case & the big picture) (2-3 sentences), e.g. This Project is about proving a new experience to customers in car showrooms. Instead of being bothered by a sales person, the customer can inform himself about the car by simply talking to it.',
    challenge:
        'What were the main challenges the company faced and wanted to solve? Why did it need innovation/R&D and Motius? Why was this interesting from a tech point of view? (4-6 sentences, max. 700 characters) e.g. "To tackle problems within the car dealerships of a renowned German car manufacturer, we started a two-day Design Thinking Workshop. Those two days surfaced two main problems and a potential idea to solve it. On the one hand, sales representatives of dealerships usually don’t have enough time to take care of every potential customer entering the dealership. On the other hand, potential customers usually want to inform themselves independently about cars and their features, before they talk to sales representatives. The Design Thinking Workshop resulted in the bot based interaction solution to tackle this issue.',
    solution:
        'How did Motius solve the company\'s problem (what did we invent)? What was the approach? How did the "new technologies" help solve the challenge? What were the benefits of our solution? (4-6 sentences, max. 700 characters) e.g. "We developed a solution, where potential customers can inform themselves about car features and prices and schedule test drives based on natural language interaction. The iPad-based solution is easily integrable within the head unit via a self-developed mount and connects via Bluetooth to the car. As soon as a customer enters the car and sits in the driving seat, the iPad camera detects a person and starts the interaction. After the conversation, all requested features by the customer are stored and made available to the sales rep for follow-ups. An admin interface lets users add new car features and dialogue modules. The Knight Rider solution is currently rolled out at different car dealerships within Germany.',
    timeline:
        'What were the main milestones of the project? (3-5 steps)\n' +
        'e.g.\n' +
        '1. Kick-off, Beginning of September\n' +
        '2. Base implementation, End of September\n' +
        '3. etc. ',
    technologies:
        ' Please list the Technologies that were used in that project,\n' +
        'e.g. "WiFi Mesh, 3D Printing, Video Analysis, InfluxDB, AWS, Ansible, Django, React',
};
const validateText = [
    maxLength(
        maxTextLengthInChars,
        `Text cannot have more than ${maxTextLengthInChars} characters`
    ),
];


const validateURL = regex(
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/,
    'URL must be in the format http(s)://domain.com'
);

const EditToolbar = (props) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useCustomNotify();

    const onDeleteSuccess = () => {
        redirect(`/projects/${record.project}/show`);
        notify('Resource deleted.');
    };

    return (
        <Toolbar {...props}>
            <SaveButton />
            {record && (
                <DeleteWithConfirmButton
                    sx={{ right: 0, position: 'absolute', marginRight: '10px' }}
                    mutationOptions={{ onSuccess: onDeleteSuccess }}
                />
            )}
        </Toolbar>
    );
};

const DocumentationForm = ({ redirect, ...props }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const project = parseInt(searchParams.get('project'));

    return (
        <SimpleForm
            toolbar={<EditToolbar />}
            quickSave={false}
            redirect={`/projects/${project}/show/documentation`}
            defaultValues={{ project }}
            {...props}
        >
            <CKEditorInput
                source="story"
                label="Story"
                placeholder={placeholders.story}
                validate={validateText}
                maxTextLength={maxTextLengthInChars}
            />
            <CKEditorInput
                source="challenge"
                label="Challenge"
                placeholder={placeholders.challenge}
                validate={validateText}
                maxTextLength={maxTextLengthInChars}
            />
            <CKEditorInput
                source="solution"
                label="Solution"
                placeholder={placeholders.solution}
                validate={validateText}
                maxTextLength={maxTextLengthInChars}
            />
            <CKEditorInput
                source="timeline"
                label="Timeline"
                placeholder={placeholders.timeline}
                validate={validateText}
                maxTextLength={maxTextLengthInChars}
            />
            <CKEditorInput
                source="technologies"
                label="Technologies"
                placeholder={placeholders.technologies}
                validate={validateText}
                maxTextLength={maxTextLengthInChars}
            />
            <TextInput
                source="link_material"
                label="Link to Sales Material"
                type="url"
                validate={[required(), validateURL]}
                fullWidth
                variant="outlined"
            />
            <TextInput
                source="link_team"
                label="Link to Team Documentation (TZT)"
                type="url"
                validate={[required(), validateURL]}
                fullWidth
                variant="outlined"
            />
            <TextInput
                source="link_documentation"
                label="Link to Project Documentation"
                type="url"
                validate={[required(), validateURL]}
                fullWidth
                variant="outlined"
            />
        </SimpleForm>
    );
};

export default DocumentationForm;
