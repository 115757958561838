import { Title, useRecordContext } from 'react-admin';
import * as React from 'react';

const HeadTitle = (props) => {
    React.useEffect(() => {
        document.title = `${props.children} | ${process.env.REACT_APP_DEFAULT_TITLE}`;
    }, [props.children]);

    return <Title title={props.children} />;
};

export default HeadTitle;

export const EditHeadTitle = (props) => (
    <HeadTitle {...props}>{`Editing ${props.resourceName} ${
        props.record && (props.record.label || props.record.name || '')
    }`}</HeadTitle>
);
export const CreateHeadTitle = (props) => (
    <HeadTitle {...props}>{`Creating ${props.resourceName}`}</HeadTitle>
);
export const ShowHeadTitle = (props) => {
    const record = useRecordContext();
    return <HeadTitle {...props}>{`${props.resourceName} ${record && record.label}`}</HeadTitle>;
};
export const ListHeadTitle = (props) => (
    <HeadTitle {...props}>{`${props.resourceNamePlural}`}</HeadTitle>
);
