import { ADMIN_ROLE } from './index';

/**
 *
 * @param {string[]} permissions
 * @param {object} views
 * @returns
 */
const onlyAdminViews = (permissions, views) => {
    return permissions?.includes(ADMIN_ROLE) ? views : {};
};

export default onlyAdminViews;
