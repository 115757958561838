import {
    ArrayField,
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    CreateButton,
    ChipField,
    DateField,
    Filter,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    RefreshButton,
    SelectArrayInput,
    SelectInput,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
    WrapperField,
    useListContext,
    useResourceDefinition,
    Button,
    SelectColumnsButton,
} from 'react-admin';
import React, { useState } from 'react';
import ProjectPanel from './ProjectPanel';
import EditPermissionButton from '../base/buttons/EditPermissionButton';
import { ClusterLinkField } from '../cluster/fields';
import { StateExportBulkActions } from '../base/actions/StateExportActions';
import BaseList from '../base/views/BaseList';
import TextFilterLinkField from '../base/fields/TextFilterLinkField';
import CountField from '../base/CountField';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DateInputField } from '../base/input/DateInput';
import MoreActionsMenu from '../base/actions/MoreActionsMenu';
import ExportAction from '../base/actions/ExportAction';
import StateUpdateAction from '../base/actions/StateUpdateAction';
import AutocompleteCustomArrayInput from '../base/input/AutocompleteCustomArrayInput';
import ProjectFilterSidebar from './ProjectFilterSidebar';
import Grid from '@mui/material/Grid';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { EditableDatagridConfigurable } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

export const ProjectFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
        <ReferenceArrayInput source="roles__cluster__id__in" reference="clusters" alwaysOn>
            <SelectArrayInput label="Cluster" style={{ minWidth: 100 }} optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput source="client_profile__id" reference="users/client" alwaysOn>
            <AutocompleteInput label="Client" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="cost_center__id__in" reference="cost-centers" alwaysOn>
            <AutocompleteInput optionText="label" label="Cost Center" />
        </ReferenceInput>
        <BooleanInput
            source="active"
            label={
                <Tooltip title="Hides projects that are New, Rejected, or Paid">
                    <Typography>Only active</Typography>
                </Tooltip>
            }
            alwaysOn
        />
        <BooleanInput source="mine" label="Only mine" alwaysOn />
        <ReferenceArrayInput
            source="responsible"
            reference="users"
            filter={{
                responsibilities__isnull: false,
                worker_profile__isnull: false,
            }}
            sort="first_name"
        >
            <AutocompleteArrayInput
                label="Responsible"
                style={{ minWidth: 100 }}
                optionText="name"
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="responsibilities"
            reference="enums/project/ProjectResponsibilityType"
            link={false}
        >
            <SelectArrayInput
                label="Responsibilities"
                optionText="label"
                style={{ minWidth: 200 }}
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="invitations__worker__in"
            reference="users/worker"
            label="Worker"
        >
            <AutocompleteCustomArrayInput
                style={{ minWidth: 100 }}
                optionText="label"
                label="Worker"
            />
        </ReferenceArrayInput>
        <TextInput label="Name" source="name__icontains" />
        <DateInputField label="Start Date Before" source="start_date__lt" />
        <DateInputField label="Start Date After" source="start_date__gte" />
        <DateInputField label="End Date Before" source="end_date__lt" />
        <DateInputField label="End Date After" source="end_date__gte" />
        <ReferenceArrayInput
            source="invitations__worker__department__id__in"
            reference="workers/departments"
            label="Team Member Department"
        >
            <SelectArrayInput
                style={{ minWidth: 200 }}
                optionText="name"
                label="Team Member Department"
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="invitations__worker__user__position__id__in"
            reference="users/positions"
            label="Team Member Position"
        >
            <AutocompleteArrayInput
                style={{ minWidth: 150 }}
                optionText="name"
                filterToQuery={(t) => ({ name__icontains: t })}
                sort={{ field: 'name', order: 'ASC' }}
                label="Team Member Position"
            />
        </ReferenceArrayInput>

        <SelectInput
            label="Documentation"
            source="documentation__isnull"
            choices={[
                { id: false, name: 'Yes' },
                { id: true, name: 'No' },
            ]}
        />
        {props.children}
    </Filter>
);

const ProjectListAction = (props) => {
    const { filters, setShowSidebar, showSideBar } = props;
    const { showFilter, displayedFilters, filterValues, setFilters } = useListContext();
    const resource = useResourceDefinition();

    const toogleSideBar = () => {
        setShowSidebar(!showSideBar)
    }

    const removeAllFilters = () => {
        setFilters({});
    };

    return (
        <TopToolbar>
            <CreateButton color="primary" resource="/projects/wizard" />
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <SelectColumnsButton />
            <RefreshButton />
            <Button onClick={toogleSideBar}>
                <FilterAltIcon />
                {showSideBar ? 'Hide sidebar' : 'Show sidebar'}
            </Button>
            {filterValues && Object.keys(filterValues).length > 0 && (
                <Button color="secondary" onClick={removeAllFilters}><FilterAltOffIcon />Remove all Filters</Button>
            )}
            <MoreActionsMenu>
                <ExportAction resource={resource.name} filterValues={filterValues} />
                <StateUpdateAction resource={resource.name} filterValues={filterValues} stateResource="enums/project/ProjectState" {...props} />
            </MoreActionsMenu>
        </TopToolbar>
    );
};

const ProjectList = (props) => {
    const [showSideBar, setShowSidebar] = useState(true);

    return (

        <BaseList
            filters={<ProjectFilter />}
            actions={<ProjectListAction showSideBar={showSideBar} setShowSidebar={setShowSidebar} />}
            aside={showSideBar ? < ProjectFilterSidebar /> : null}
            bulkActionButtons={
                <StateExportBulkActions forceConfirmation stateResource="enums/project/ProjectState" />
            }
            sort={{ order: 'DESC', field: 'id' }}
            sx={{ '& .RaList-actions': { display: 'block' } }}
            {...props}
        >
            <EditableDatagridConfigurable rowClick="show" actions={<EditPermissionButton label="" />} expand={<ProjectPanel />} omit={['invitations']}>

                <WrapperField label="Internal Name">
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}>
                        <Grid item>
                            <ReferenceField
                                reference="workers/departments"
                                source="main_department"
                                link={false}
                            >
                                <ChipField size="small" source="name_short" />
                            </ReferenceField>
                        </Grid>
                        <Grid item >
                            <ShowElementLink source="name" obj="projects" />
                        </Grid>
                    </Grid>
                </WrapperField >
                <ArrayField source="clusters" sortable={false}>
                    <SingleFieldList link={false} sx={{ padding: '5px' }}>
                        <ClusterLinkField filterName="roles__cluster__id__in" />
                    </SingleFieldList>
                </ArrayField>
                <ReferenceField
                    source="client_profile"
                    label="Client"
                    reference="users/client"
                    link={false}
                >
                    <TextField source="name_short" emptyText={<TextField source="name" />} />
                </ReferenceField>
                <ReferenceField
                    source="cost_center"
                    label="Cost Center"
                    reference="cost-centers"
                    link={false}
                >
                    <TextFilterLinkField source="public_id" filterName="cost_center__id__in" />
                </ReferenceField>
                <TextFilterLinkField
                    source="state_label"
                    label="State"
                    filterName="state__in"
                    filterSource="state"
                    multiple={true}
                    sortBy="state"
                />
                <ReferenceField
                    reference="enums/project/ProjectType"
                    source="project_type"
                    label="Type"
                    linkType={false}
                >
                    <TextField source="label" />
                </ReferenceField>
                <DateField source="start_date" />
                <DateField source="end_date" />
                <CountField source="invitations" />
            </EditableDatagridConfigurable >
        </BaseList >
    )
}

export default ProjectList;
