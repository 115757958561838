import React from 'react';
import { ContractLayout } from './ContractStep';
import ExtensionMainContent from './ExtensionMainContent';
import ExtensionAsideContent from './ExtensionAsideContent';
import BacklogRowList from './BacklogRowList';
import RoleRowList from './RoleRowList'
import { LinearProgress, useRecordContext } from 'react-admin';

const ExtensionContractStep = (props: any) => {
    const record = useRecordContext();
    const loading = !record?.client_contract?.type
    const isTMContract = record?.client_contract.type === 'consultancy';

    return <ContractLayout
        mainSection={
            <div>
                {!loading ? <div>
                    {isTMContract ? <RoleRowList {...props} /> : <BacklogRowList {...props} />}
                </div> : <LinearProgress />}
            </div>}
        leftSection={<ExtensionMainContent />}
        asideSection={<ExtensionAsideContent />}
    />
};

export default ExtensionContractStep;
