import { Grid } from '@mui/material';
import React from 'react';
import { FormDataConsumer } from 'react-admin';
import CKEditorInput from '../../../base/ckeditor/CKEditorInput';
import CheckboxInput from '../../../base/input/CheckboxInput';
import CurrencyInput from '../../../base/input/CurrencyInput';


const ExtensionMainContent = (props: any) => {

    return <div>
        <Grid container spacing={2} >
            <Grid item>
                <CurrencyInput variant='outlined' margin='normal' source="client_contract.additional_expenses" label="Additional Expenses" fullWidth />
            </Grid>
            <Grid item>
                <CurrencyInput variant='outlined' margin='normal' source="client_contract.travel_expenses" label="Travel Expenses" fullWidth />
            </Grid>
        </Grid>
        <div>
            <CKEditorInput
                source="client_contract.body"
                label="Content (For Project Plan section in the contract PDF)"
                templateParams={{
                    filter: { type: 'template.ContractTemplate' },
                }}
                fullWidth
                validate={[]}
            />
            <CheckboxInput
                source="client_contract.legal_overwrite"
                label="Overwrite legal text"
            />
            <FormDataConsumer fullWidth label="Internal Name">
                {({ formData, ...rest }) =>
                    formData.client_contract.legal_overwrite ? (
                        <CKEditorInput
                            source="client_contract.legal_text"
                            label="Legal Text"
                            templateParams={{
                                filter: { type: 'template.LegalTemplate' },
                            }}
                            validate={[]}
                        />
                    ) : null
                }
            </FormDataConsumer>
            <CheckboxInput
                source="client_contract.hide_pricing"
                label="Overwrite generated pricing table"
            />
            <FormDataConsumer fullWidth label="Internal Name">
                {({ formData, ...rest }) =>
                    formData.client_contract.hide_pricing ? (
                        <CKEditorInput
                            source="client_contract.pricing"
                            label="Pricing"
                            templateParams={{
                                filter: { type: 'template.ContractTemplate' },
                            }}
                            validate={[]}
                        />
                    ) : null
                }
            </FormDataConsumer>
        </div>
    </div>;
};

export default ExtensionMainContent;
