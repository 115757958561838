// in src/MyMenu.js
import DashboardIcon from '@mui/icons-material/Dashboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DefaultIcon from '@mui/icons-material/ViewList';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import React from 'react';
import { useResourceDefinitions, Logout } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    menu: {
        height: 40,
        padding: '5px !important'
    },
});

export const customLogout = () => <Logout redirectTo={'/'} />;

const navigateToPersonalDashboard = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL, '_blank');
};

const CustomMenuAlternative = () => {
    const classes = useStyles();
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
    return (
        <MultiLevelMenu initialOpen={true}>
            <MultiLevelMenu.Item
                name="dashboard"
                to="/"
                label="Dashboard"
                icon={<DashboardIcon />}
                className={classes.menu}
            />
            {resources
                .filter((r) => r.hasList && !r.options.parent)
                .map((resource) => (
                    <MultiLevelMenu.Item
                        name={resource.name}
                        to={`/${resource.name}`}
                        label={resource.options.label}
                        icon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                        className={classes.menu}
                        key={resource.name}
                    >
                        {resources
                            .filter((r) => r.options?.parent === resource.name && r.hasList)
                            .map((nestedResource) => (
                                <MultiLevelMenu.Item
                                    name={nestedResource.name}
                                    to={`/${nestedResource.name}`}
                                    label={nestedResource.options.label}
                                    className={classes.menu}
                                    key={nestedResource.name}
                                />
                            ))}
                    </MultiLevelMenu.Item>
                ))}
            <MultiLevelMenu.Item
                name="personal"
                to="/"
                label="Personal Dashboard"
                icon={<OpenInNewIcon />}
                onClick={navigateToPersonalDashboard}
                className={classes.menu}
            />
        </MultiLevelMenu>
    );
};

export default CustomMenuAlternative;
