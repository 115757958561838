import React from 'react';
import WebhookForm from './WebhookForm';
import BaseCreate from '../base/views/BaseCreate';

const WebhookCreate = (props) => {
    return (
        <BaseCreate {...props} redirect="list">
            <WebhookForm />
        </BaseCreate>
    );
};

export default WebhookCreate;
