import {
    Button,
    ChipField,
    DateField,
    DeleteWithConfirmButton,
    Link,
    Pagination,
    ReferenceArrayField,
    ReferenceField,
    ReferenceManyField,
    ShowController,
    SingleFieldList,
    Tab,
    useStore,
    useRecordContext,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React, { useEffect, useState } from 'react';
import ProjectPanel from './ProjectPanel';
import ContractList from '../contract/ContractList';
import { ClusterField } from '../cluster/fields';
import BaseShowView from '../base/views/BaseShowView';
import ControllingPanel from './controlling/ControllingPanel';
import PanelLabel from '../base/show/PanelLabel';
import NumeralField from '../base/fields/NumeralField';
import VersionShow from '../base/show/VersionShow';
import ProjectWorkloadManagement from './workload/ProjectWorkloadManagement';
import MetabaseExportTab from './export/MetabaseExportTab';
import { getDefaultWeekRange } from '../base/workload/Workload';
import { useLocation } from 'react-router-dom';
import WorkerNameField from '../worker/WorkerNameField';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import ProjectInvitationForm from './invitation/ProjectInvitationForm';
import InfoIcon from '@mui/icons-material/Info';
import dataProvider from '../dataProvider';
import { Typography } from '@mui/material';
import ProjectRoleForm from './role/ProjectRoleForm';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
    alignItems: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
});

const ShowProjectGroupInfo = () => {
    const record = useRecordContext();
    const classes = useStyles();
    const [mainProjectName, setMainProjectName] = useState('');
    const id = record.project_type === 'follow_up' ? record.predecessor : record.parent;
    const projectType = record.project_type === 'follow_up' ? 'a follow-up' : 'an extension';

    useEffect(() => {
        dataProvider()('GET_ONE', `projects`, {
            id: id,
        }).then((result) => {
            setMainProjectName(result.data.name);
        });
    }, [record]);

    if (!mainProjectName) {
        return <></>;
    }

    return (
        <Typography variant="body1">
            <div className={classes.alignItems}>
                <InfoIcon />
                <p>
                    This project is {projectType} of project
                    <Button
                        target="_blank"
                        href={`/#/projects/${id}/show`}
                        label={mainProjectName}
                        component="a"
                    />
                    . Only team members added to the main project gain access to the group{' '}
                    <Button
                        target="_blank"
                        href={`https://outlook.office.com/people/group/motius.de/${record.ldap_group_name}`}
                        label={record.ldap_group_name}
                        component="a"
                    /><br />
                    Guests are only added to <Button
                        target="_blank"
                        href={`https://outlook.office.com/people/group/motius.de/${record.ldap_group_name}-guest`}
                        label={`${record.ldap_group_name}-guest`}
                        component="a"
                    /> and can be given access to certain resources (Confluence pages, etc) by adding this group.
                </p>
            </div>
        </Typography>
    );
};

const AddNewProjectRoleButton = () => {
    const record = useRecordContext();
    const classes = useStyles();
    return (
        <Button
            className={classes.spacingTop}
            color="primary"
            component={Link}
            to={{
                pathname: '/projects/role/create',
                search: `?project=${record.id}`,
            }}
            label="Add a Role"
        >
            <GroupAddIcon />
        </Button>
    );
};

const CustomAction = () => (
    <>
        <EditInDialogButton label="" >
            <ProjectRoleForm />
        </EditInDialogButton >
        <DeleteWithConfirmButton
            redirect={false}
            mutationMode="undoable"
            label=""
        />
    </>
);

const Show = ({ ...props }) => {
    const [weekCount] = useStore('workload.weekCount', 10);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    return (
        <ShowController {...props}>
            {(controllerProps) => (
                <BaseShowView {...props} {...controllerProps}>
                    <TabbedShowLayout>
                        <Tab label="Overview">
                            <ProjectPanel />

                            <PanelLabel>Versions</PanelLabel>
                            <VersionShow reference="projects/versions" />
                        </Tab>
                        <Tab label="Contract" path="contract">
                            <ReferenceManyField
                                reference="contracts/projects"
                                target="project"
                                label={false}
                            >
                                <ContractList
                                    source="contract_base64"
                                    basePath="contracts/projects"
                                    target="project"
                                    {...props}
                                />
                            </ReferenceManyField>
                        </Tab>
                        <Tab label="Team" path="team">
                            {controllerProps.record && (
                                <>
                                    <PanelLabel>Staffed Team</PanelLabel>
                                    {['extension', 'follow_up'].includes(
                                        controllerProps.record.project_type
                                    ) && <ShowProjectGroupInfo />}
                                    <ReferenceManyField
                                        reference="projects/invitations"
                                        target="project"
                                        label={false}
                                        sort={{ field: 'state,-id', order: 'ASC' }}
                                        pagination={<Pagination />}
                                        perPage={25}
                                    >
                                        <EditableDatagrid
                                            mutationMode="pessimistic"
                                            editForm={<ProjectInvitationForm hideProject />}
                                            createForm={
                                                <ProjectInvitationForm
                                                    hideProject
                                                    defaultValues={{
                                                        project: controllerProps.record.id,
                                                    }}
                                                />
                                            }
                                        >
                                            <ReferenceField
                                                label="Worker"
                                                reference="users/worker"
                                                source="worker.id"
                                                link="show"
                                                allowEmpty
                                            >
                                                <WorkerNameField
                                                    label="Name"
                                                    sortBy="user"
                                                    showState={true}
                                                />
                                            </ReferenceField>
                                            <ReferenceField
                                                reference="enums/project/ProjectInvitationState"
                                                source="state"
                                                label="State"
                                                sortBy="state,-id"
                                                link={false}
                                            >
                                                <TextField source="label" />
                                            </ReferenceField>
                                            <ReferenceField
                                                label="Role"
                                                reference="projects/role"
                                                source="role"
                                                link="edit"
                                                allowEmpty
                                            >
                                                <TextField source="label" />
                                            </ReferenceField>
                                            <NumeralField
                                                source="hourly_rate"
                                                label="Hourly Customer Rate"
                                                options={{
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                }}
                                            />
                                            <ReferenceArrayField
                                                source="responsibilities"
                                                reference="enums/project/ProjectResponsibilityType"
                                                link={false}
                                            >
                                                <SingleFieldList linkType={false}>
                                                    <ChipField source="label" />
                                                </SingleFieldList>
                                            </ReferenceArrayField>
                                        </EditableDatagrid>
                                    </ReferenceManyField>
                                </>
                            )}
                            <PanelLabel>Planned Team</PanelLabel>
                            <ReferenceManyField
                                reference="projects/role"
                                target="project"
                                label={false}
                            >
                                <EditableDatagrid
                                    mutationMode="pessimistic"
                                    actions={<CustomAction />}
                                >
                                    <TextField source="name" label="Name" />
                                    <ReferenceField
                                        label="Cluster"
                                        reference="clusters"
                                        source="cluster"
                                        allowEmpty
                                    >
                                        <ClusterField />
                                    </ReferenceField>
                                    <ReferenceArrayField
                                        label="Tools"
                                        reference="tools"
                                        source="tools"
                                        sortable={false}
                                    >
                                        <SingleFieldList link={false}>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                    <DateField source="start_date" label="Start Date" />
                                    <DateField source="end_date" label="End Date" />
                                    <NumeralField
                                        source="hourly_rate"
                                        label="Hourly Customer Rate"
                                        options={{
                                            style: 'currency',
                                            currency: 'EUR',
                                        }}
                                    />
                                    <NumeralField
                                        source="budget"
                                        label="Hourly Cost Budget"
                                        options={{
                                            style: 'currency',
                                            currency: 'EUR',
                                        }}
                                    />
                                    <NumeralField source="hours" label="Planned Weekly Hours" />
                                </EditableDatagrid>
                            </ReferenceManyField>
                            <AddNewProjectRoleButton />
                        </Tab>

                        {controllerProps.record && controllerProps.record.invitations && controllerProps.record.invitations.length > 0 && (
                            <Tab label="Workload" path="workload">
                                <ReferenceManyField
                                    reference="invitations/planned"
                                    target="project"
                                    label={false}
                                    filter={
                                        location.search
                                            ? Object.fromEntries(searchParams.entries())
                                            : {
                                                ...getDefaultWeekRange(weekCount),
                                                upcoming: true,
                                                outboarded: false,
                                            }
                                    }
                                    perPage={1000}
                                    {...controllerProps}
                                >
                                    <ProjectWorkloadManagement />
                                </ReferenceManyField>
                            </Tab>
                        )}

                        <Tab label="Controlling" path="controlling">
                            <ControllingPanel {...controllerProps} />
                        </Tab>
                        <Tab label="Export" path="export">
                            <MetabaseExportTab {...controllerProps} />
                        </Tab>
                    </TabbedShowLayout>
                </BaseShowView>
            )}
        </ShowController>
    );
};

export default Show;
