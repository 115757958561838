import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import React, { Children, useState } from 'react';
import {
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
} from 'react-admin';
import CheckboxInput from '../../../base/input/CheckboxInput';
import { DateInputField } from '../../../base/input/DateInput';
import MainDepartmentInput from '../../../base/input/MainDepartmentInput';
import ClientAddressInput from '../../../contract/project/components/ClientAddressInput';

const useStyles = makeStyles({
    divider: { margin: '1rem 0', width: '100%' },
    hidden: { display: 'none' },
    fullWidth: { width: '100%' },
});

const Wrapper = ({ children, className, ...props }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.fullWidth} hidden={props.hidden}>
            {Children.map(
                children as React.ReactElement,
                (child: React.ReactElement) => child && React.cloneElement(child, { ...props })
            )}
        </div>
    );
};

const FullAsideContent = () => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <Typography variant="subtitle1">Additional Settings</Typography>
            <ReferenceInput source="main_department" reference="workers/departments">
                <MainDepartmentInput />
            </ReferenceInput>
            <ClientAddressInput fullWidth FormHelperTextProps={{ className: classes.hidden }} />
            <Divider className={classes.divider} />
            <Button
                className={classes.fullWidth}
                color="primary"
                onClick={() => setShowMore(!showMore)}
            >
                {showMore ? 'Hide' : 'Show More'}
            </Button>
            <Wrapper hidden={!showMore}>
                <ReferenceInput
                    source="client_contract.billing"
                    reference="enums/contract/ProjectClientContractBillingType"
                >
                    <SelectInput
                        optionText="label"
                        label="Billing"
                        validate={[required()]}
                        defaultValue="milestone"
                        fullWidth
                        FormHelperTextProps={{ className: classes.hidden }}
                        variant="outlined"
                        margin="normal"
                    />
                </ReferenceInput>
                <NumberInput
                    InputProps={{
                        endAdornment: <InputAdornment position="start">days</InputAdornment>,
                    }}
                    variant="outlined"
                    margin="normal"
                    source="client_contract.payment_period"
                    label="Payment Period"
                    FormHelperTextProps={{ className: classes.hidden }}
                    defaultValue={14}
                    fullWidth
                />
                <DateInputField
                    variant="outlined"
                    source="client_contract.deadline"
                    label="Valid Until"
                    fullWidth
                    defaultValue={DateTime.local().plus({ days: 30 }).toISODate()}
                />
                <NumberInput
                    variant="outlined"
                    margin="normal"
                    source="client_contract.safety"
                    label="Safety Factor"
                    FormHelperTextProps={{ className: classes.hidden }}
                    defaultValue={1.2}
                    step={0.1}
                    min={0}
                    fullWidth
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.client_contract.share_url && (
                            <TextInput
                                label="Share URL"
                                source="client_contract.share_url"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled
                            />
                        )
                    }
                </FormDataConsumer>
                <CheckboxInput
                    label="Share with Customer"
                    source="client_contract.share_enabled"
                />
                <CheckboxInput
                    source="client_contract.two_client_signatures"
                    label="Generate two signature places for the client"
                />
                <CheckboxInput
                    source="client_contract.hide_rd_tax_text"
                    label="Hide the 15% Forschungszulage text"
                />
                <CheckboxInput
                    source="client_contract.hide_project_stages_text"
                    label="Hide Motius Project Stages Text"
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <CheckboxInput
                            variant="outlined"
                            margin="normal"
                            source="client_contract.include_rate_card"
                            label="Include Rate Card in Pricing"
                        />
                    )}
                </FormDataConsumer>
            </Wrapper>
        </>
    );
};

export default FullAsideContent;
