import { TableCell, TableRow } from '@mui/material';
import { useStore } from 'react-admin';
import { useMemo, useState } from 'react';
import useWorkloadStyles from '../useWorkloadStyles';
import { RowData, useWorkloadState } from '../WorkloadContext';
import WorkloadGraphItem from './WorkloadGraphItem';
import WorkloadGraphRowTitle from './WorkloadGraphRowTitle';
import WorkloadGraphTotalRow, { TOTAL_ROW } from './WorkloadGraphTotalRow';

type WorkloadGraphProps = {
    plannedData: RowData[]
    defaultHideDetails: boolean
}

const WorkloadGraph: React.FC<WorkloadGraphProps> = ({ defaultHideDetails, plannedData }) => {
    const classes = useWorkloadStyles();
    const [weekCount] = useStore('workload.weekCount', 10); // For zooming
    const { isProject } = useWorkloadState()
    const [hideDetails, setHideDetails] = useState(defaultHideDetails);

    const name = useMemo(
        () => (isProject ? plannedData[0]?.project_name : plannedData[0]?.worker_name),
        [isProject, plannedData]
    );
    const id = useMemo(
        () => (isProject ? plannedData[0]?.project : plannedData[0]?.worker),
        [isProject, plannedData]
    );

    return (
        <>
            {[TOTAL_ROW, ...plannedData]
                .filter((row, index) => (hideDetails ? index === 0 : true))
                .map((row, index) => {
                    return (
                        <TableRow
                            key={row.project}
                            className={row.state !== 6 && index !== 0 ? classes.trackedCell : ''}
                        >
                            <WorkloadGraphRowTitle
                                hideDetails={hideDetails}
                                toggleHideDetails={() => setHideDetails(r => !r)}
                                id={id}
                                name={name}
                                index={index}
                                row={row}
                                key={row.project}
                            />

                            <TableCell
                                align="center"
                                colSpan={weekCount}
                                className={classes.denseCell}
                            >
                                {index === 0 ? (
                                    <WorkloadGraphTotalRow plannedData={plannedData} />
                                ) : (
                                    <WorkloadGraphItem
                                        row={row}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
        </>
    );
};

export default WorkloadGraph;
