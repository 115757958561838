import ClientForm from './ClientForm';
import React from 'react';
import BaseCreate from '../base/views/BaseCreate';

const ClientCreate = (props) => (
    <BaseCreate {...props}>
        <ClientForm />
    </BaseCreate>
);

export default ClientCreate;
