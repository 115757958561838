import * as React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { useInput, FieldTitle } from 'react-admin';

export type Variant = 'standard' | 'outlined' | 'filled';

interface DatePickerProps {
    label: string;
    required?: boolean;
    className?: string;
    value: string | null;
    format?: string;
    onChange(arg0: string | null): void;
    error?: boolean;
    helperText?: string;
    options?: { shouldDisableDate: ((day: DateTime) => boolean) | undefined };
    fullWidth?: boolean;
    variant?: Variant;
}

const dateFormat = 'yyyy-MM-dd';

export const DateInputPicker = (props: DatePickerProps) => {
    return (
        //use LocalizationProvider only once in the application, if adding another picker like datetime,
        //then declare LocalizationProvider in the root of the application and remove below
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
                label={<FieldTitle label={props.label} isRequired={props.required ?? false} />}
                componentsProps={{
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                className={props.className}
                value={props.value ?? null}
                onChange={(date: DateTime | null) => {
                    const format = props.format ?? dateFormat;
                    return props.onChange(
                        date ? DateTime.fromISO(date.toString()).toFormat(format) : ''
                    );
                }}
                shouldDisableDate={props.options?.shouldDisableDate}
                renderInput={(params) => (
                    <TextField
                        {...params} 
                        inputProps={{ ...params.inputProps, value: props.value, readOnly: true }}
                        helperText={props.helperText}
                        error={false}
                        variant={props.variant || 'filled'}
                        {...(!props.fullWidth && { sx: { width: '260px' } })}
                        fullWidth={props.fullWidth}
                    />
                )}
                inputFormat={props.format ?? dateFormat}
                //defaultCalendarMonth={props.options?.defaultCalendarMonth || DateTime.now()}
            />
        </LocalizationProvider>
    );
};

interface DateProps {
    source: string;
    label: string;
    className?: string;
    variant?: Variant;
    fullWidth?: boolean;
    validate?: any;
    defaultValue?: string;
    format?: string;
}

export const DateInputField = ({
    source,
    label,
    variant = 'filled',
    className,
    fullWidth,
    validate,
    defaultValue,
    format,
}: DateProps) => {
    const { field } = useInput({ source, validate, defaultValue });

    return (
        <DateInputPicker
            {...field}
            label={label}
            variant={variant}
            className={className}
            required={validate?.isRequired}
            fullWidth={fullWidth}
            format={format}
        />
    );
};
