import React, { useState, useEffect, useRef } from 'react';
import BlobFileField from './BlobFileField';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        border: `1px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loading: {
        marginRight: theme.spacing(),
    },
    object: {},
    objectFixed: {
        position: 'fixed',
        marginTop: theme.spacing(4),
    },
}));

const PDFEmbedField = (props) => {
    const { record, source } = props;
    const container = useRef();
    const [dimensions, setDimensions] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(null);
    const [loadedValue, setLoadedValue] = useState(null);
    const classes = useStyles();

    if (record[source] !== value) {
        setValue(record[source]);
    }

    useEffect(() => {
        const resource = record[source];
        setDimensions({
            width: container.current?.offsetWidth,
            height: container.current?.offsetHeight,
        });
        if (resource && resource !== loadedValue) {
            setLoadedValue(resource);
            fetch(resource.url)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        setData(reader.result);
                    };
                })
                .catch((ex) => {
                    console.log(ex);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const renderContent = () => {
        const { record, source, heightRatio, settings, fixed, downloadLabel } = props;
        const height = dimensions.width * heightRatio;

        if (!value) {
            // Field is null
            return '';
        }

        if (loading) {
            return (
                <div style={{ height }} className={classes.loadingContainer}>
                    <CircularProgress className={classes.loading} size={24} />{' '}
                    <Typography variant="body1">Loading {value.name}</Typography>
                </div>
            );
        }

        return (
            <>
                <object
                    className={fixed ? classes.objectFixed : classes.object}
                    width={fixed ? dimensions.width : '100%'}
                    height={dimensions.width * heightRatio}
                    type="application/pdf"
                    data={`${data}${settings}`}
                    {...props}
                >
                    <Typography color="error">
                        Your browser does not support showing PDFs. You can download the file below.
                    </Typography>
                </object>
                <BlobFileField
                    variant="raised"
                    label={downloadLabel}
                    record={record}
                    source={source}
                />
            </>
        );
    };

    return <div ref={(el) => (container.current = el)}>{dimensions && renderContent()}</div>;
};

PDFEmbedField.defaultProps = {
    label: 'PDF',
    downloadLabel: null,
    fixed: false,
    record: {},
    source: null,
    heightRatio: 297 / 210 - 0.1,
    settings: '#pagemode=none',
};

PDFEmbedField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    heightRatio: PropTypes.number,
};

export default PDFEmbedField;
