import React from 'react';
import {
    Datagrid,
    DateField,
    ReferenceManyField,
    TextField,
    required,
    Link,
    Button,
    useRefresh,
    useRecordContext,
    ShowButton,
} from 'react-admin';
import { Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import Chip from '@mui/material/Chip';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DownloadBlobButton from '../base/buttons/DownloadBlobButton';
import NumeralField from '../base/fields/NumeralField';
import { EditableDatagrid, RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import PlanInvoicesButton from './project/components/PlanInvoicesButton';
import { currencyFormatter } from '../base/fields/CurrencyField';
import ConvertInvoiceButton from '../payment/invoice/components/ConvertInvoiceButton';
import CurrencyInput from '../base/input/CurrencyInput';
import UpdateUnusedValueButton from './project/components/UpdateUnusedValueButton';
import { DateInputField } from '../base/input/DateInput';
import useCustomNotify from '../base/useCustomNotify';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    chip: {
        marginRight: theme.spacing(),
    },
    chipPlanned: {
        marginRight: theme.spacing(),
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark,
    },
    chipUnused: {
        marginRight: theme.spacing(),
        borderColor: theme.palette.warning.dark,
        color: theme.palette.warning.dark,
    },
    actionButton: {
        marginTop: theme.spacing(),
        marginRight: theme.spacing(),
    },
}));

const AddInvoiceButton = ({ record }) => (
    <Button
        color="primary"
        variant="text"
        component={Link}
        to={{
            pathname: '/payment/invoices/create',
            search: `?cost_center=${record.cost_center}&client_contract=${record.id}&type=R`,
        }}
        label="Create invoice"
    >
        <AddIcon />
    </Button>
);

const InvoiceShowField = ({ source }) => {
    const record = useRecordContext();
    return (
        <TextField
            component={Link}
            // to={`/payment/invoices/${record.id}/show`}
            source={source}
            record={record}
        />
    );
};

const PlannedInvoiceForm = (props) => {
    // Refresh view so revenue amounts update
    const refreshView = useRefresh();
    const { close } = useRowContext();
    const notify = useCustomNotify();

    const onSuccess = () => {
        close();
        refreshView();
        notify('Element updated.');
    };

    const onError = () => {
        close();
        refreshView();
        notify('An error occurred', { type: 'warning' });
    };

    return (
        <RowForm {...props} mutationOptions={{ onSuccess: onSuccess, onError: onError }}>
            <TextField source="label" disabled />
            <TextField source="state_label" disabled />
            <DateInputField source="start_date" validate={required()} />
            <DateInputField source="end_date" validate={required()} />
            <CurrencyInput label="Net Amount" source="amount_net" validate={required()} />
        </RowForm>
    );
};

// Cannot convert to FC with useRecordContext because
// it connects to the wrong RecordContext.
const ContractField = (props) => {
    const { record } = props;
    const classes = useStyles();
    const contractNetValue = record.used_net_value || 0;
    const plannedNetValue = record.invoice_revenue.total_planned_invoices;
    const invoicedNetValue = record.invoice_revenue.invoiced_revenue;
    const openValue = record.invoice_revenue.open_revenue;
    const newUnusedValue = (parseFloat(record.unused_value) + parseFloat(openValue)).toFixed(2);

    // Build query params for links to invoice lists
    let invoiceSearch = {
        filter: JSON.stringify({
            include_planned: false,
            include_rejected: false,
            client_contract: record.id,
            type__in: ['R', 'C'],
        }),
        order: 'DESC',
        page: 1,
        perPage: 25,
        sort: '-id',
    };
    const invoiceSearchParams = new URLSearchParams(invoiceSearch);
    invoiceSearch.filter = JSON.stringify({
        include_planned: true,
        include_rejected: false,
        client_contract: record.id,
        type__in: ['P'],
    });
    const plannedInvoiceSearchParams = new URLSearchParams(invoiceSearch);

    return (
        <Card className={classes.root} variant="outlined">
            <CardHeader
                title={
                    <Link
                        to={`/projects/wizard/${record.project}/${record.state === 'draft' ? '2' : 'show'
                            }`}
                    >
                        {record.public_id}
                    </Link>
                }
                subheader={
                    <>
                        <Chip
                            className={classes.chip}
                            color="primary"
                            label={record.state_label || 'Draft'}
                        />
                        <Chip
                            className={classes.chip}
                            variant="outlined"
                            label={`${currencyFormatter.format(contractNetValue)} contract revenue`}
                        />
                        {record.unused_value > 0 && (
                            <Chip
                                className={classes.chipUnused}
                                variant="outlined"
                                label={`${currencyFormatter.format(
                                    record.unused_value
                                )} unused revenue`}
                            />
                        )}
                    </>
                }
                action={
                    <>
                        <ShowButton
                            variant="contained"
                            className={classes.actionButton}
                            to={`/projects/wizard/${record.project}/${record.state === 'draft' ? '2' : 'show'
                                }`}
                        />
                        {record.contract && !record.contract_signed && (
                            <DownloadBlobButton
                                variant="outlined"
                                className={classes.actionButton}
                                fileUrl={record.contract.url}
                                fileName={record.contract.name.split('/').pop()}
                                label="Download Contract"
                            />
                        )}
                        {record.contract_signed && (
                            <DownloadBlobButton
                                variant="outlined"
                                className={classes.actionButton}
                                fileUrl={record.contract_signed.url}
                                fileName={record.contract_signed.name.split('/').pop()}
                                label="Download Signed Contract"
                            />
                        )}
                    </>
                }
            />
            {invoicedNetValue > 0 && (
                <CardContent>
                    <Typography variant="h6">Invoices</Typography>
                    <Chip
                        className={classes.chip}
                        variant="outlined"
                        label={`${currencyFormatter.format(invoicedNetValue)} invoiced revenue`}
                    />
                    <Button
                        color="primary"
                        variant="text"
                        component={Link}
                        to={`/payment/invoices?${invoiceSearchParams.toString()}`}
                        label="Open full list"
                    >
                        <ListIcon />
                    </Button>
                    <AddInvoiceButton record={record} />
                    <ReferenceManyField
                        reference="payment/invoices"
                        source="client_contract"
                        target="client_contract"
                        filter={{
                            'state!': 'rejected',
                            type__in: ['R', 'C'],
                        }}
                    >
                        <Datagrid rowClick="show" bulkActionButtons={false}>
                            <InvoiceShowField source="label" />
                            <TextField label="Type" source="type_label" sortBy="type" />
                            <TextField label="State" source="state_label" sortBy="state" />
                            <DateField source="start_date" />
                            <DateField source="end_date" />
                            <NumeralField
                                source="amount_discounted_net_signed"
                                options={{ style: 'currency', currency: 'EUR' }}
                                label="Net Total (Discounted)"
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </CardContent>
            )}
            {record.state !== 'draft' && (
                <CardContent>
                    <Typography variant="h6">Planned Invoices</Typography>
                    {plannedNetValue !== 0 && (
                        <Chip
                            className={classes.chip}
                            variant="outlined"
                            label={`${currencyFormatter.format(plannedNetValue)} planned revenue`}
                        />
                    )}
                    {openValue > 0 && (
                        <Chip
                            variant="outlined"
                            className={classes.chipPlanned}
                            label={`${currencyFormatter.format(openValue)} unplanned revenue`}
                        />
                    )}
                    {openValue < 0 && (
                        <Chip
                            variant="outlined"
                            className={classes.chipPlanned}
                            label={`${currencyFormatter.format(
                                -openValue
                            )} planned over contract value`}
                        />
                    )}
                    <PlanInvoicesButton record={record} className={classes.chip} />
                    {openValue > 0 && (
                        <UpdateUnusedValueButton
                            label="Set open amount as unused"
                            record={record}
                            unusedValue={newUnusedValue}
                            className={classes.chip}
                        />
                    )}
                    {openValue < 0 && (
                        <UpdateUnusedValueButton
                            label="Lower unused amount"
                            record={record}
                            unusedValue={newUnusedValue}
                            className={classes.chip}
                        />
                    )}
                    {plannedNetValue > 0 && (
                        <Button
                            className={classes.chip}
                            color="primary"
                            variant="text"
                            component={Link}
                            to={`/payment/invoices?${plannedInvoiceSearchParams.toString()}`}
                            label="Open full list"
                        >
                            <ListIcon />
                        </Button>
                    )}
                    <ReferenceManyField
                        reference="payment/invoices"
                        source="client_contract"
                        target="client_contract"
                        filter={{
                            'state!': 'rejected',
                            type: 'P',
                        }}
                    >
                        <EditableDatagrid rowClick="show" bulkActionButtons={false}
                            mutationMode="pessimistic"
                            editForm={
                                <PlannedInvoiceForm defaultValues={{ overwrite_amount: true }} />
                            }
                            createForm={
                                <PlannedInvoiceForm
                                    defaultValues={{
                                        overwrite_amount: true,
                                        client_contract: record.id,
                                        cost_center: record.cost_center,
                                        type: 'P',
                                        amount_net: openValue,
                                    }}
                                />
                            }
                        >
                            <InvoiceShowField source="label" />
                            <TextField label="State" source="state_label" sortBy="state" />
                            <DateField source="start_date" />
                            <DateField source="end_date" />
                            <NumeralField
                                source="amount_discounted_net_signed"
                                options={{ style: 'currency', currency: 'EUR' }}
                                label="Net Total"
                            />
                            <ConvertInvoiceButton />
                        </EditableDatagrid>
                    </ReferenceManyField>
                </CardContent>
            )}
        </Card>
    );
};

export default ContractField;
