import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useInput } from 'react-admin';

const CheckboxInput = (props) => {
    const { disabled = false, label } = props;
    const { field } = useInput(props);
    const [value, setValue] = React.useState(false);

    const handleOnChange = (event) => {
        setValue(event.target.checked);
        field.onChange(event.target.checked);
    };

    return (
        <FormControlLabel
            {...field}
            control={
                <Checkbox
                    color="primary"
                    {...field}
                    checked={field.value || value}
                    readOnly={disabled}
                    onChange={handleOnChange}
                />
            }
            label={label}
        />
    );
};

export default CheckboxInput;
