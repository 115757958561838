import React, { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import slugify from 'slugify';

const AutoSlugInput = ({ field, ...props }) => {
    const form = useForm();
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    const fieldValue = values[field];

    useEffect(() => {
        if (fieldValue) {
            setValue(
                props.source,
                slugify(fieldValue, {
                    lower: true,
                    remove: /[^ \-a-zA-Z0-9]/g,
                })
            );
        } else {
            setValue(props.source, '');
        }
    }, [fieldValue, form, props.source]);

    return null;
};

export default AutoSlugInput;
