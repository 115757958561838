import React from 'react';
import {
    ReferenceArrayInput,
    required,
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    TextInput,
    SimpleForm,
} from 'react-admin';
import { RoleStateInput } from './fields/RoleState';
import CurrencyInput from '../../base/input/CurrencyInput';
import { useLocation } from 'react-router-dom';
import { DateInputField } from '../../base/input/DateInput';
import AutocompleteCustomArrayInput from '../../base/input/AutocompleteCustomArrayInput';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    spacer: {
        marginBottom: '30px',
    },
});

const validateForm = (values) => {
    const errors = {};
    if (parseFloat(values.budget) > parseFloat(values.hourly_rate)) {
        errors.budget = 'Value cannot be more than Hourly Client Rate';
    }
    return errors;
};

const ProjectRoleForm = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const project = parseInt(searchParams.get('project'));

    return (
        <SimpleForm
            quickSave={false}
            defaultValues={{ project }}
            {...props}
            validate={validateForm}
        >
            <TextInput
                source="name"
                label="Name"
                validate={[required()]}
                style={{ minWidth: '260px' }}
            />
            <ReferenceInput source="project" reference="projects">
                <AutocompleteInput
                    optionText="label"
                    label="Project"
                    validate={[required()]}
                    filterToQuery={(text) => ({
                        autocomplete: text,
                    })}
                    style={{ minWidth: '260px' }}
                />
            </ReferenceInput>
            <CurrencyInput source="budget" label="Hourly Cost Budget" />
            <CurrencyInput source="hourly_rate" label="Hourly Client Rate" />
            <NumberInput
                label="Weekly Hours"
                source="hours"
                validate={[required()]}
                style={{ minWidth: '260px' }}
            />
            <DateInputField source="start_date" label="Start date" className={classes.spacer} />
            <DateInputField source="end_date" label="End date" className={classes.spacer} />
            <ReferenceInput label="Cluster" source="cluster" reference="clusters">
                <AutocompleteInput optionText="name" style={{ minWidth: '260px' }} />
            </ReferenceInput>
            <ReferenceArrayInput source="tools" reference="tools">
                <AutocompleteInput optionText="label" style={{ minWidth: '260px' }} />
            </ReferenceArrayInput>
            <TextInput
                multiline
                source="admin_comment"
                label="Comment"
                style={{ minWidth: '260px' }}
            />
            <RoleStateInput />
        </SimpleForm>
    );
};

export default ProjectRoleForm;
