import React from 'react';
import { FileField, useRecordContext } from 'react-admin';

const FileFieldInput = () => {
    const record = useRecordContext();
    if (record?.name) {
        return <FileField record={record} source="url" title="name" />;
    }
    return <FileField record={record} source="src" title="title" />;
};

export default FileFieldInput;
