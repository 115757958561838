import React from 'react';
import {
    DateField,
    ReferenceField,
    SimpleShowLayout,
    TextField,
    useRecordContext,
} from 'react-admin';
import NoteContent from './fields/NoteContent';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BaseShowView from '../../base/views/BaseShowView';

const useAsideStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '300px',
        marginLeft: '1rem',
        height: 'max-content',
    },
});

const NoteAside = ({ basePath }) => {
    const classes = useAsideStyles();
    const record = useRecordContext();

    return (
        <Paper className={classes.root}>
            <SimpleShowLayout record={record}>
                <ReferenceField
                    label="Author"
                    source="author"
                    reference="users"
                    link={false}
                    basePath={basePath}
                >
                    <TextField source="name" label="Author" />
                </ReferenceField>
                <ReferenceField
                    label="Worker"
                    source="worker"
                    reference="users/worker"
                    link="show"
                    basePath={basePath}
                >
                    <TextField source="label" />
                </ReferenceField>
                <DateField emptyText="No due date set" source="due_date" />
                <ReferenceField
                    label="Project"
                    source="project"
                    reference="projects"
                    link="show"
                    emptyText="Not specified"
                    basePath={basePath}
                >
                    <TextField source="label" />
                </ReferenceField>
            </SimpleShowLayout>
        </Paper>
    );
};

const WorkerNoteShow = (props) => {
    return (
        <BaseShowView {...props} aside={<NoteAside basePath={props.basePath} />}>
            <SimpleShowLayout>
                <TextField source="label" />
                <TextField source="type_label" label="type" />
                <NoteContent source="description" />
            </SimpleShowLayout>
        </BaseShowView>
    );
};

export default WorkerNoteShow;
