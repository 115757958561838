import React from 'react';
import { ReferenceManyField, useRecordContext } from 'react-admin';
import BacklogEditableGrid from './BacklogEditableGrid';
import BacklogRowForm from './BacklogRowForm';

const BacklogRowList = (props: any) => {
    const record = useRecordContext();
    const isStoryPointContract = record?.client_contract?.type === 'sp';

    return (
        <ReferenceManyField
            {...props}
            reference="projects/tasks"
            target="project_id"
            fullWidth
            hasCreate
            isStoryPointContract={isStoryPointContract}
        >
            <BacklogEditableGrid editForm={
                <BacklogRowForm
                    contractId={record?.client_contract?.id}
                    isStoryPointContract={isStoryPointContract}
                />
            }
            createForm={
                <BacklogRowForm
                    contractId={record?.client_contract?.id}
                    project={record.id}
                    isStoryPointContract={isStoryPointContract}
                />
            }/>
        </ReferenceManyField>
    );
};

export default BacklogRowList;
