import React from 'react';
import { convertFilterParams } from '../../dataProvider';
import { stringify } from 'query-string';
import DownloadBlobButton from '../buttons/DownloadBlobButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useListContext } from 'react-admin';

const ExportAction = ({ resource, filterValues, ...props }) => {
    const { selectedIds } = useListContext();
    if (selectedIds && selectedIds.length > 0) {
        // Selected IDs should overwrite filters (when selecting items in bulk)
        // Otherwise no IDs are selected and all (filtered) items should be updated
        filterValues = { id__in: selectedIds };
    }
    const url = `${resource}/export/xlsx/?${stringify(convertFilterParams(filterValues))}`;

    return (
        <DownloadBlobButton
            requireAuth
            fileUrl={url}
            fileName={`${resource}.xlsx`}
            label="Export"
            variant="text"
            {...props}
        >
            <ArchiveIcon />
        </DownloadBlobButton>
    );
};

export default ExportAction;
