import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectClientContractCreate = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) {
            navigate('/projects/wizard/create');
        }
    }, [navigate]);

    return <div>Loading...</div>;
};

export default ProjectClientContractCreate;
