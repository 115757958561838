import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';
import TimelogTagForm from './TimelogTagForm';

const TimelogTagCreate = (props) => {
    return (
        <BaseCreate {...props} redirect="list">
            <TimelogTagForm />
        </BaseCreate>
    );
};

export default TimelogTagCreate;
