import React from 'react';
import { SimpleForm, ReferenceField, TextField, Labeled } from 'react-admin';
import WorkerProfileLink from './fields/WorkerProfileLink';
import BaseEdit from '../../base/views/BaseEdit';
import CurrencyInput from '../../base/input/CurrencyInput';

const ReferralEdit = (props) => {
    return (
        <BaseEdit {...props}>
            <SimpleForm>
                <ReferenceField
                    source="referred"
                    label="Project"
                    reference="users"
                    link={false}
                    basePath="/users"
                >
                    <Labeled label="Referred">
                        <WorkerProfileLink />
                    </Labeled>
                </ReferenceField>
                <ReferenceField
                    source="referred_by"
                    reference="users"
                    link={false}
                    basePath="/users"
                >
                    <Labeled label="Referred by" sx={{ marginTop: '20px' }}>
                        <WorkerProfileLink />
                    </Labeled>
                </ReferenceField>
                <Labeled label="State of Referred" sx={{ marginTop: '20px' }}>
                    <TextField source="referred_state" />
                </Labeled>
                <Labeled label="State" sx={{ marginTop: '20px' }}>
                    <TextField source="state" />
                </Labeled>
                <CurrencyInput source="reward" sx={{ marginTop: '20px' }} />
            </SimpleForm>
        </BaseEdit>
    );
};

export default ReferralEdit;
