import posthog from 'posthog-js';

export const startPosthogTracking = () => {
    posthog.init(process.env.REACT_APP_POSTHOG_ID, { api_host: process.env.REACT_APP_POSTHOG_URL });

    window.addEventListener('hashchange', () => {
        posthog.capture('$pageview');
    });
};

export const setUserDepartment = (departmentId) => {
    posthog.capture('Set User Department', { $set_once: { Department: departmentId } });
};

export const setUserPosition = (positionId) => {
    posthog.capture('Set User Position', { $set: { Position: positionId } });
};
