import Create from './CostCenterCreate';
import Edit from './CostCenterEdit';
import List from './CostCenterList';
import React from 'react';

const resourceName = 'Cost Center';
const resourceNamePlural = 'Cost Centers';

const costCenter = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default costCenter;
