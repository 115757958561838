import ToolForm from './ToolForm';
import React from 'react';
import BaseCreate from '../base/views/BaseCreate';

const ToolCreate = (props) => (
    <BaseCreate {...props} redirect="list">
        <ToolForm />
    </BaseCreate>
);

export default ToolCreate;
