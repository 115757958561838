import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type OverviewTableItemProps = {
    label: string;
    value: string | number | React.ReactElement;
    helperText?: string;
};

const useStyles = makeStyles({
    label: {
        color: '#7A98A4',
    },
});

const OverviewTableItem: React.FC<OverviewTableItemProps> = ({ label, value, helperText }) => {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.label} variant="body1">
                {label}:
            </Typography>
            <Typography variant="subtitle1">{value}</Typography>
       	    <Typography variant="caption" color="textSecondary">{helperText}</Typography>
	</div>
    );
};

export default OverviewTableItem;
