import { makeStyles } from '@mui/styles';
import { RowForm, useRowContext } from '@react-admin/ra-editable-datagrid';
import React, { useEffect } from 'react';
import {
    NumberInput,
    required,
    TextInput,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import CurrencyInput from '../../../../base/input/CurrencyInput';
import { useRowState } from '../../wizard-form/rowState';
import { regeneratePDF } from '../utils';
import useCustomNotify from '../../../../base/useCustomNotify';

const useStyles = makeStyles({
    small: {
        '& td': {
            padding: '0.5rem',
        },
        '& input': {
            minWidth: '100px',
        },
    },
});

const RoleRowForm = ({ project, contractId, ...props }: any) => {
    const notify = useCustomNotify();
    const classes = useStyles();
    const record = useRecordContext(); // Project or Role
    const refresh = useRefresh();
    const { setEditing, unsetEditing } = useRowState();
    const { close } = useRowContext();

    /*
     * Using this effect to disable next step on unsaved changes
     */
    useEffect(() => {
        setEditing();

        return () => unsetEditing();
    }, [setEditing, unsetEditing]);

    const transform = (values: any) => {
        if (!record?.id) {
            // On create record is project, which we need.
            return { ...values, project: project.id };
        } else {
            // Project is already in, we don't need it
            return values;
        }
    };

    const onSuccess = async () => {
        close();
        try {
            await regeneratePDF(contractId, refresh);
        } catch (e) {
            notify('Error during regenerating PDF with contract Id');
        }
    };

    return (
        <RowForm
            {...props}
            className={classes.small}
            transform={transform}
            mutationOptions={{ onSuccess: onSuccess }}
        >
            <TextInput source="name" variant="outlined" margin="normal" validate={[required()]} />
            <CurrencyInput
                source="hourly_rate"
                variant="outlined"
                margin="normal"
                validate={[required()]}
            />
            <NumberInput
                variant="outlined"
                margin="normal"
                label="Weekly Hours"
                source="hours"
                validate={[required()]}
            />
            <NumberInput
                variant="outlined"
                margin="normal"
                label="Total Hours"
                source="total_hours"
                validate={[required()]}
            />
            <CurrencyInput source="daily_rate" disabled helperText="Auto Calculated." />
            <CurrencyInput source="total_rate" disabled helperText="Auto Calculated." />
        </RowForm>
    );
};

export default RoleRowForm;
