import { Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
});

const PanelLabel = ({ ...props }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.spacingTop} color="textSecondary" gutterBottom {...props} />
    );
};

export default PanelLabel;
