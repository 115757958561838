import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { setPermissions } from './auth/aadAuthProvider';
import userManager, { signinRedirect } from './auth/oidcUserManager';
import { startPosthogTracking } from './posthog';
import { identifySentryUser, startSentryLogging } from './sentry';

startSentryLogging();
startPosthogTracking();

const container = document.getElementById('root')!;
const root = createRoot(container);
const render = () => root.render(<App />);

const isCallback = window.location.pathname === '/login';

if (isCallback) {
    userManager.signinRedirectCallback().then((user) => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user.profile));
            
            const redirectQuery = (user.state as unknown as Record<string, string>)?.redirect_to || null
            const redirectTo = redirectQuery ? decodeURIComponent(redirectQuery) : ''

            window.history.replaceState({}, document.title, `/${redirectTo}`);
            setPermissions().then((res) => {
                identifySentryUser(user.profile.email, (user.profile as any).preferred_username)
                render();
            });
        } else {
            signinRedirect()

        }
    });
} else {
    userManager.getUser().then((user) => {
        if (user) {
            if (user.expired) {
              localStorage.removeItem('user')
              signinRedirect()
            }
            else {
              identifySentryUser(user.profile.email, (user.profile as any).preferred_username)
              render();
            }
        } else {
            signinRedirect()
        }
    });
}
