import React from 'react';
import List from './PermissionList';
import PermissionForm from './PermissionForm';
import onlyAdminViews from '../auth/onlyAdminViews';

const resourceName = 'Permission';
const resourceNamePlural = 'Permissions';

const list = (props) => (
    <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
);

const permission = (permissions) =>
    onlyAdminViews(permissions, {
        list,
        edit: PermissionForm,
    });

export default permission;
