import React, { useEffect, useRef } from 'react';
import { required, SelectInput, useDataProvider } from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';

const MainDepartmentInput = () => {
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    const form = useForm();
    const dataProvider = useDataProvider();
    const initialRender = useRef(false);
    useEffect(() => {
        const setMainDepartment = async () => {
            const mainProjectId =
                values.project_type === 'follow_up' ? values.predecessor : values.parent;
            if (mainProjectId) {
                dataProvider.getOne('projects', { id: mainProjectId }).then((result) => {
                    if (result.data) {
                        setValue('main_department', result.data.main_department);
                    }
                });
            }
        };

        if (!initialRender.current) {
            initialRender.current = true;
            return;
        }
        if ((values.project_type === 'follow_up' || values.project_type === 'extension') && !values.main_department) {
            setMainDepartment();
        }
    }, [values.predecessor, values.parent, values.main_department, form, dataProvider]);

    return (
        <SelectInput
            label="Main Department"
            optionText="name"
            variant="outlined"
            margin="normal"
            validate={required()}
            helperText="The main business unit or internal department this project is assigned to"
            fullWidth
        />
    );
};

export default MainDepartmentInput;
