import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import useWorkloadStyles from './useWorkloadStyles';
import classnames from 'classnames';
import { getWeekRangeFromFilterValues } from './workloadUtils';
import { useListContext } from 'react-admin';

const WorkloadTableHead = () => {
    const classes = useWorkloadStyles();
    const { filterValues } = useListContext();
    const weekRange = getWeekRangeFromFilterValues(filterValues);
    const currentWeekNumber = DateTime.local().weekNumber;
    const interval = Interval.fromDateTimes(weekRange.start, weekRange.end.plus({ day: 1 }));

    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={3} />
                {interval.splitBy({ week: 1 }).map((intv, i) => {
                    const startOfTheWeek = intv.start.startOf('week');
                    return (
                        <TableCell
                            align="center"
                            className={classnames(
                                startOfTheWeek.weekNumber !== currentWeekNumber ? classes.muted : ''
                            )}
                            data-week-no={startOfTheWeek.weekNumber}
                            key = {intv.start.toMillis()}
                        >
                            <Typography variant="subtitle2">
                                W{startOfTheWeek.weekNumber}
                            </Typography>
                            <Typography variant="caption">
                                {startOfTheWeek.toFormat('dd.LL')} {''}
                            </Typography>
                            <div />
                            <Typography variant="caption">
                                {startOfTheWeek.endOf('week').toFormat('dd.LL')}
                            </Typography>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default WorkloadTableHead;
