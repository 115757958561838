import React from 'react';
import { Search, SearchResultsPanel } from '@react-admin/ra-search';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ProjectIcon from '@mui/icons-material/Work';
import { Subject as DefaultIcon } from '@mui/icons-material';
import WorkerIcon from '@mui/icons-material/Group';
import ContractIcon from '@mui/icons-material/Assignment';
import InvoiceIcon from '@mui/icons-material/Payment';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
});

const getIconForItem = (resource) => {
    switch (resource) {
        case 'projects':
            return <ProjectIcon />;
        case 'workers':
            return <WorkerIcon />;
        case 'client_contracts':
            return <ContractIcon />;
        case 'invoices':
            return <InvoiceIcon />;
        default:
            return <DefaultIcon />;
    }
};

const SearchItem = ({ data, onClose }) => {
    const classes = useStyles();

    const onItemClick = () => {
        window.location.replace(data.url);
        onClose();
    };

    return (
        <ListItem button onClick={onItemClick} className={classes.root}>
            <ListItemIcon>{getIconForItem(data.type)}</ListItemIcon>
            <ListItemText
                primary={data.content.label}
                secondary={data.content.description}
            ></ListItemText>
        </ListItem>
    );
};

const MotiusSearch = () => {
    return (
        <Search>
            <SearchResultsPanel>
                <SearchItem />
            </SearchResultsPanel>
        </Search>
    );
};

export default MotiusSearch;
