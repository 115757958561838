import React, { useState } from 'react';
import classnames from 'classnames';
import { Card, CardContent, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { ReferenceField, TextField, DateField, EditButton, Labeled, ShowButton } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    boxShadow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
}));

// Note Field Component
const NoteField = (props) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const { record, basePath, collapsedHeight } = props;
    const classes = useStyles();
    return (
        <Card className={classes.boxShadow}>
            <CardHeader
                title={
                    record.author ? (
                        <Typography color="textSecondary" component="div" gutterBottom>
                            <ReferenceField
                                label={false}
                                source="author"
                                reference="users"
                                record={record}
                                basePath={basePath}
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </Typography>
                    ) : (
                        <Typography color="textSecondary" gutterBottom>
                            Unknown
                        </Typography>
                    )
                }
                subheader={`${record.type_label} on ${DateTime.fromISO(record.created).toFormat(
                    'DDD'
                )}`}
            />
            <Collapse in={expanded} timeout="auto" collapsedSize={collapsedHeight}>
                <CardContent className={classes.cardContent}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: record.description,
                        }}
                    />
                    <Grid container>
                        {record.due_date && (
                            <Grid item xs={6}>
                                <Labeled label="Due Date">
                                    <DateField record={record} source="due_date" />
                                </Labeled>
                            </Grid>
                        )}
                        {record.project && (
                            <Grid item xs={6}>
                                <Labeled label="Project">
                                    <ReferenceField
                                        label="Project"
                                        source="project"
                                        reference="projects"
                                        basePath="/projects"
                                        record={record}
                                        link="show"
                                    >
                                        <TextField source="label" />
                                    </ReferenceField>
                                </Labeled>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Collapse>
            <CardActions>
                <EditButton resource="/users/workers/notes" record={record} />
                <ShowButton resource="/users/workers/notes" record={record} />
                <IconButton
                    className={classnames(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="Show more"
                    size="large"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

NoteField.defaultProps = {
    collapsedHeight: null,
};

export default NoteField;
