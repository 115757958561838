import React from 'react';
import classnames from 'classnames';
import { DateTime, Interval } from 'luxon';
import { TableCell, Typography } from '@mui/material';
import {
    getPlannedWeeklyHoursByDateTime,
    getWeeklyAvailabilityByDate,
    getWeekRangeFromFilterValues,
} from '../workloadUtils';
import useWorkloadStyles from '../useWorkloadStyles';
import { useListContext } from 'react-admin';
import { useWorkloadState } from '../WorkloadContext';


type WorkloadTableTotalRowProps = {
    group?: number
}

const WorkloadTableTotalRow: React.FC<WorkloadTableTotalRowProps> = ({ group }) => {
    const classes = useWorkloadStyles();
    const { data: generalData, availability, isProject } = useWorkloadState();

    const data = group ? generalData.filter((i) => i.worker === group) : generalData;

    const { filterValues } = useListContext();
    const weekRange = getWeekRangeFromFilterValues(filterValues);
    const hideTracked = String(filterValues.hide_tracked) === 'true'
    const interval = Interval.fromDateTimes(weekRange.start, weekRange.end.plus({ day: 1 }));

    const getAbsenceCellColor = (isPastWeek : boolean) => {
        return isPastWeek ? 'textSecondary' : 'textPrimary';
    };

    const getPlannedCellColor = (isPastWeek : boolean, intv : Interval) => {
        if (isPastWeek) {
            return 'textSecondary';
        }
        if (isOutOfAvailability(intv)) {
            return 'error';
        }
        return 'textPrimary';
    };

    const isOutOfAvailability = (intv : Interval ) => {
        if (isProject || availability.length === 0) return false;
        const weeklyAvailability = getWeeklyAvailabilityByDate(
            availability,
            data[0]?.worker,
            intv.start
        );
        return weeklyAvailability < 0;
    };


    return <>
        {interval.splitBy({ week: 1 }).map((intv, i) => {
            const weekNumber = intv.start.weekNumber;
            const isPastWeek = intv.start.plus({ week: 1 }) < DateTime.local();
            const isFuture = DateTime.local().toMillis() - intv.start.toMillis() < 0;
            const weeklyPlanned = data.reduce(
                (acc, val) => Number(getPlannedWeeklyHoursByDateTime(val, intv.start)) + acc,
                0
            );

            const weeklyTrackedNum = Number(
                data.reduce(
                    (acc, val) =>
                        Number(val.tracked.find((t) => t.week === weekNumber)?.hours || 0) + acc,
                    0
                )
            );

            const weeklyTracked = Number.isInteger(weeklyTrackedNum)
                ? weeklyTrackedNum
                : weeklyTrackedNum.toFixed(2);

            const weeklyAbsence = () => {
                let absence;
                if (isProject) {
                    absence = availability.reduce((acc, val) => {
                        return (
                            acc +
                            val.absences.reduce(
                                (acc2, val2) =>
                                    val2.week === weekNumber ? acc2 + Number(val2.hours) : acc2,
                                0
                            )
                        );
                    }, 0);
                } else {
                    absence =
                        Number(
                            availability
                                .find((a) => a.id === data[0]?.worker)
                                ?.absences.find((a) => a.week === weekNumber)?.hours
                        ) || 0;
                }

                if (Number.isInteger(absence)) return absence;
                return Number(absence).toFixed(2);
            };


            return (
                <TableCell align="center" key={weekNumber} style={{ padding: 0 }}>
                    <span className={classes.hoursCell}>
                        <span className={classes.paddedCell}>
                            <Typography color={getPlannedCellColor(isPastWeek, intv)}>{weeklyPlanned}h</Typography>
                        </span>
                        <span className={classes.paddedCell}>
                            <Typography color={getAbsenceCellColor(isPastWeek)}>{weeklyAbsence()}h</Typography>
                        </span>
                        {!hideTracked && (
                            <span className={classnames(classes.trackedCell, classes.muted)}>
                                <Typography>
                                    &nbsp;
                                    {!isFuture && `${weeklyTracked}h`}
                                </Typography>
                            </span>
                        )}
                    </span>
                </TableCell>
            );
        })}</>
};

export default WorkloadTableTotalRow;
