import ClientForm from './ClientForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const ClientEdit = (props) => (
    <BaseEdit {...props} redirect="list">
        <ClientForm />
    </BaseEdit>
);

export default ClientEdit;
