import React from 'react';
import { TextInput, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
import { DateInputField } from '../../base/input/DateInput';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    spacer: {
        marginTop: '32px',
    },
});

const PublicHolidayForm = (props) => {
    const classes = useStyles();
    return (
        <SimpleForm {...props} redirect>
            <TextInput source="name" sx={{ minWidth: 255 }} />
            <ReferenceInput source="location" reference="enums/worker/WorkLocation">
                <SelectInput optionText="label" sx={{ minWidth: 255 }} />
            </ReferenceInput>
            <DateInputField source="start_date" label="Start date" />
            <DateInputField source="end_date" label="End date" className={classes.spacer} />
        </SimpleForm>
    );
};

export default PublicHolidayForm;
