import React, { useState } from 'react';
import { Button, useRefresh } from 'react-admin';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { apiUrl, httpClient } from '../../../dataProvider';
import useCustomNotify from '../../../base/useCustomNotify';

const UpdateUnusedValueButton = ({ record, unusedValue, color, variant, label, ...props }) => {
    const [updating, setUpdating] = useState(false);
    const refreshView = useRefresh();
    const notify = useCustomNotify();

    const updateValue = () => {
        if (record) {
            setUpdating(true);
            httpClient(`${apiUrl}/contracts/projects/${record.id}`, {
                method: 'PUT',
                body: JSON.stringify({ unused_value: unusedValue, project: record.project }),
            })
                .then(() => {
                    notify(`Unused value updated`);
                })
                .catch((e) => {
                    notify(`Could not updated unused value: ${e.message}`, { type: 'warning' });
                })
                .finally(() => {
                    setUpdating(false);
                    refreshView();
                });
        }
    };

    const onClick = () => {
        updateValue();
    };

    return (
        <Button
            color={color || 'primary'}
            variant={variant || 'text'}
            onClick={onClick}
            disabled={updating}
            children={<MoneyOffIcon />}
            label={label || 'Set open amount as unused'}
            {...props}
        />
    );
};

export default UpdateUnusedValueButton;
