import { Typography, useTheme, Box, LinearProgress } from '@mui/material';
import { CheckCircleRounded, Warning } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useRecordContext, ReferenceInput, SelectInput } from 'react-admin';
import getUser from '../../../auth/getUser';
import ContractStateConfirmDialog from './ContractStateConfirmDialog';
import InfoCard from './InfoCard';

const OverviewStepCard = () => {
    const record = useRecordContext();
    const theme = useTheme();
    const reviewerId = useMemo(() => record.client_contract.technical_reviewer, [record]);
    const hasReviewer = !!reviewerId;
    const currentUserIsReviewer = record?.client_contract.can_review;

    if (record?.client_contract?.state === 'rejected') {
        return (
            <InfoCard color={theme.palette.error.main} icon={<Warning style={{ fontSize: 40 }} />}>
                <Typography variant="h6" gutterBottom>
                    This contract is rejected!
                </Typography>
                <Typography gutterBottom style={{ marginTop: 8 }}>
                    Please revise this contract.
                </Typography>
            </InfoCard>
        );
    }

    if (!hasReviewer) {
        return (
            <InfoCard
                color={theme.palette.warning.main}
                icon={<Warning style={{ fontSize: 40 }} />}
            >
                <Typography variant="h6" gutterBottom>
                    This contract does not have a reviewer!
                </Typography>
                <Typography gutterBottom style={{ marginTop: 8 }}>
                    Please use{' '}
                    <Typography component="span" color="secondary">
                        #proposals
                    </Typography>{' '}
                    channel to ask for someone to review this contract.
                </Typography>
                <ReferenceInput
                    allowEmpty
                    source="client_contract.technical_reviewer"
                    reference="users/worker/reviewers"
                    perPage={100}
                    sort={{ field: 'user', order: 'ASC' }}
                >
                    <SelectInput 
                        optionText="label" 
                        fullWidth label="Reviewer" 
                        FormHelperTextProps={{ style: { display: 'none' } }} 
                        variant="outlined" 
                        margin="normal" 
                        isRequired />
                </ReferenceInput>
            </InfoCard>
        );
    }

    if (hasReviewer && !currentUserIsReviewer) {
        return (
            <InfoCard icon={<CheckCircleRounded style={{ fontSize: 40 }} />}>
                <Typography variant="h6" gutterBottom>
                    Yoo-Hoo! Your part is finished!
                </Typography>
                <Typography gutterBottom style={{ marginTop: 8 }}>
                    The reviewing person will see this page and finish the creation process.{' '}
                </Typography>
                <Typography style={{ marginTop: 8 }}>
                    You can leave the page and relax now.
                </Typography>
                <ReferenceInput
                    allowEmpty
                    source="client_contract.technical_reviewer"
                    reference="users/worker/reviewers"
                    perPage={100}
                    sort={{ field: 'user', order: 'ASC' }}
                    
                >
                    <SelectInput optionText="label" 
                        fullWidth
                        label="Reviewer"
                        FormHelperTextProps={{ style: { display: 'none' } }}
                        variant="outlined"
                        margin="normal"
                        isRequired
                    />
                </ReferenceInput>
            </InfoCard>
        );
    }

    if (hasReviewer && currentUserIsReviewer) {
        return (
            <InfoCard
                color={theme.palette.warning.main}
                icon={<Warning style={{ fontSize: 40 }} />}
            >
                <Typography variant="h6" gutterBottom>
                    Hi {getUser()?.given_name}!
                </Typography>
                <Typography gutterBottom style={{ marginTop: 8 }}>
                    You are assigned as the reviewer for this contract.
                </Typography>
                <Typography style={{ marginTop: 8 }}>
                    Please review it accordingly and approve/reject.
                </Typography>
                <Box display="flex" mt={2}>
                    <ContractStateConfirmDialog
                        variant="text"
                        color="secondary"
                        targetState="rejected"
                        disabled={false}
                    />
                    <ContractStateConfirmDialog targetState="reviewed" disabled={false} />
                </Box>
            </InfoCard>
        );
    }

    return (
        <InfoCard icon={<CheckCircleRounded style={{ fontSize: 40 }} />}>
            <LinearProgress /><></>
        </InfoCard>
    );
};

export default OverviewStepCard;
