import WorkerNoteForm from './WorkerNoteForm';
import React from 'react';
import { Aside } from '../skill-estimation/WorkerSkillEstimationEdit';
import BaseEdit from '../../base/views/BaseEdit';

const WorkerNoteEdit = (props) => (
    <BaseEdit aside={<Aside />} {...props}>
        <WorkerNoteForm location={props.location} />
    </BaseEdit>
);

export default WorkerNoteEdit;
