import React from 'react';
import { Edit, useRecordContext } from 'react-admin';
import { EditHeadTitle } from '../HeadTitle';

const BaseEditTitle = ({ resourceName }) => {
    const record = useRecordContext();
    return <EditHeadTitle resourceName={resourceName} record={record} />;
};

const BaseEdit = ({ resourceName, resourceNamePlural, ...props }) => (
    <Edit
        mutationMode="pessimistic"
        redirect="show"
        title={<BaseEditTitle resource={props.resource} resourceName={resourceName} />}
        {...props}
    />
);

export default BaseEdit;
