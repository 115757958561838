import React, { useState, Fragment } from 'react';
import { Button, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { apiUrl, convertFilterParams, httpClient } from '../../dataProvider';
import { stringify } from 'query-string';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import useCustomNotify from '../useCustomNotify';

const MergeToolAction = (props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useCustomNotify();
    const refreshView = useRefresh();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        const { resource, selectedIds } = props;
        let { filterValues } = props;
        if (selectedIds.length > 0) {
            // Selected IDs should overwrite filters (when selecting items in bulk)
            // Otherwise no IDs are selected and all (filtered) items should be updated
            filterValues = { id__in: selectedIds };
        }
        const url = `${apiUrl}/${resource}/merge?${stringify(convertFilterParams(filterValues))}`;
        setLoading(true);
        httpClient(url, { method: 'POST' })
            .then(() => {
                setOpen(false);
                notify('Merged skills');
                refreshView();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            <Button onClick={handleOpen} {...props}>
                <MergeTypeIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">Merge Skills</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        This action will merge all selected skills with the most used one. Related
                        data will migrated to the remaining skill
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button label="Merge" onClick={handleClick}>
                        {loading ? <CircularProgress size={24} /> : <MergeTypeIcon />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

MergeToolAction.defaultProps = {
    label: 'Merge Skills',
    selectedIds: [],
};

export default MergeToolAction;
