import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const startSentryLogging = () => {
    if (process.env.REACT_APP_SENTRY_DSN)
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new BrowserTracing()],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 0.25,
            release: process.env.REACT_APP_PLATFORM_VERSION,
            environment: process.env.REACT_APP_ENVIRONMENT
        });
}

export const identifySentryUser = (email?: string, username?: string) => {
    if (process.env.REACT_APP_SENTRY_DSN)
        Sentry.setUser({ email, username })
}
