import DownloadBlobButton from '../buttons/DownloadBlobButton';
import React from 'react';

const BlobFileField = ({ record, label, source }) =>
    record[source] ? (
        <DownloadBlobButton
            fileUrl={record[source].url}
            label={label}
            fileName={record[source].name}
        />
    ) : (
        ''
    );

export default BlobFileField;
