import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import {
    List,
    ListItemText,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { apiUrl, httpClient } from '../dataProvider';
import DialogTitle from '@mui/material/DialogTitle';
import StepButton from '@mui/material/StepButton';
import PanelLabel from './show/PanelLabel';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button, useRefresh } from 'react-admin';
import _ from 'underscore';
import { DateTime } from 'luxon';
import makeStyles from '@mui/styles/makeStyles';
import useCustomNotify from './useCustomNotify';

const useStyles = makeStyles((theme) => ({
    stepButton: {
        backgroundColor: theme.palette.primary.main,
    },
    stepAction: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(0.5),
    },
    stepError: {
        cursor: 'pointer',
    },
    warning: {
        fontWeight: 'bold',
    },
}));

const StateDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transition, setTransition] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [record, setRecord] = useState(props.record);
    const refreshView = useRefresh();
    const notify = useCustomNotify();
    // The completed transitions list sometimes also contains the transitions that failed to save
    // but the transition was already logged in the db. So find the latestState by finding the latest log
    // whose target matches with the current state of the record.
    const latestState =
        record.state_transitions
            ?.filter((item) => item.completed)
            ?.reverse()
            .find((item) => item.target === record.state) || record.state_label;
    const currentStep = activeStep + (latestState ? 1 : 0);
    const { orientation } = props;
    const classes = useStyles();

    if (!_.isEqual(props.record, record)) {
        setRecord(props.record);
    }

    const handleClick = () => {
        const { basePath } = props;
        setLoading(true);
        httpClient(`${apiUrl}${basePath}/${record.id}/state/${transition.name}`, {
            method: 'POST',
        })
            .then((data) => {
                notify('State updated');
                setRecord(data.json);
                handleClose();
                refreshView();
            })
            .catch((e) => {
                if (e.message) {
                    notify(`State could not be updated (${e.message})`, { type: 'error' });
                } else {
                    notify('Error: State could not be updated', { type: 'error' });
                }
                handleClose();
            });
    };

    const handleStep = (index) => {
        setActiveStep(index);
    };

    const handleOpen = (transition) => {
        setOpen(true);
        setTransition(transition);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    if (!record.state_transitions) {
        return <div />;
    }
    return (
        <div style={{ width: '100%' }}>
            <PanelLabel>State Information</PanelLabel>
            <Stepper nonLinear={true} activeStep={currentStep} orientation={orientation}>
                {latestState && latestState.timestamp && (
                    <Step completed={true}>
                        <StepLabel
                            optional={
                                <Typography color="inherit">
                                    Current status, since{' '}
                                    {DateTime.fromISO(latestState.timestamp).toFormat('DDDD t')}
                                </Typography>
                            }
                        >
                            <Typography>
                                {latestState.custom
                                    ? latestState.custom.button_name
                                    : latestState.name}
                            </Typography>
                        </StepLabel>
                    </Step>
                )}

                {latestState && !latestState.timestamp && (
                    <Step completed={true}>
                        <StepLabel
                            optional={<Typography color="inherit">Current status</Typography>}
                        >
                            <Typography>{latestState}</Typography>
                        </StepLabel>
                    </Step>
                )}
                {record.state_transitions
                    .filter((item) => !item.completed)
                    .map((item, index) => (
                        <Step key={item.target}>
                            {item.can_proceed ? (
                                <StepButton
                                    disableRipple={true}
                                    icon={<PlayCircleFilledIcon color="primary" />}
                                    onClick={() => handleStep(index)}
                                >
                                    <Typography color="primary">
                                        {item.custom ? item.custom.button_name : item.name}
                                    </Typography>
                                </StepButton>
                            ) : (
                                <StepLabel
                                    error={true}
                                    className={classes.stepError}
                                    icon={<ErrorIcon color="error" />}
                                    onClick={() => handleStep(index)}
                                    optional={<Typography color="error">Can't proceed</Typography>}
                                >
                                    {item.custom ? item.custom.button_name : item.name}
                                </StepLabel>
                            )}
                            <StepContent>
                                {item.can_proceed && (
                                    <Typography color="inherit">
                                        {item.custom.description}
                                    </Typography>
                                )}
                                <List dense={true} disablePadding>
                                    {!item.can_proceed &&
                                        item.conditions.map((t, k) => (
                                            <ListItem key={t} color="error" disableGutters>
                                                <ListItemIcon color="error">
                                                    <ErrorOutlineIcon />
                                                </ListItemIcon>
                                                <ListItemText color="error">{t[2]}</ListItemText>
                                            </ListItem>
                                        ))}
                                </List>
                                {item.can_proceed && (
                                    <Button
                                        label={item.custom ? item.custom.button_name : item.name}
                                        variant="contained"
                                        className={classes.stepAction}
                                        color="primary"
                                        onClick={() => handleOpen(item)}
                                    />
                                )}
                            </StepContent>
                        </Step>
                    ))}
            </Stepper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Update state of ${props.recordLabel}?`}</DialogTitle>
                {transition && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {transition.custom.description}
                            {transition.name === 'approve' && (
                                <div>
                                    This will create an IPA user for the worker (This requires
                                    Motius email set)!
                                </div>
                            )}
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        label={transition ? transition.custom.button_name : 'Submit'}
                        disabled={loading}
                        onClick={handleClick}
                        autoFocus
                    >
                        {loading && <CircularProgress size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

StateDialog.defaultProps = {
    orientation: 'vertical',
};

StateDialog.propTypes = {
    record: PropTypes.object,
    recordLabel: PropTypes.string,
    orientation: PropTypes.string,
    open: PropTypes.bool,
    basePath: PropTypes.string,
};

export default StateDialog;
