import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles((theme) => ({
    content: {
        color: 'black',
        '& > ul': {
            listStyle: 'none',
            padding: 0,
        },
        '& label': {
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '0.25rem',
        },
        '& label:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        '& label > span': {
            marginLeft: '0.25rem',
        },
    },
}));

const CustomDialog = (props) => {
    const { title, open, description, confirmLabel, cancelLabel } = props;
    const classes = useStyles();

    const [ref, setRef] = useState(null);
    const [isvalid, setIsValid] = useState(false);

    const onRefChange = (node) => {
        // same as Hooks example
        setRef(node);
    };

    useEffect(() => {
        // Get all todo checkboxes
        const docCheckboxes = document.querySelectorAll(
            '#contract-dialog .todo-list__label > input[type=checkbox]'
        );
        setIsValid(false);

        // only run validations if there are checkboxes
        if (docCheckboxes.length > 0) {
            const checkboxesArray = Array.from(docCheckboxes);
            checkboxesArray.forEach((checkbox) => {
                // Add an event listener to switch the state when checked. Kind of state bounding.
                checkbox.addEventListener('input', (e) => {
                    setIsValid(
                        document.querySelectorAll(
                            '#contract-dialog .todo-list__label > input[type=checkbox]:checked'
                        ).length === docCheckboxes.length
                    );
                });
            });
        } else {
            setIsValid(true);
        }
    }, [ref]);

    const handleClose = () => {
        props.onClose();
    };

    const handleConfirm = () => {
        handleClose();
        props.onConfirm();
    };

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div
                            className={classes.content}
                            id="contract-dialog"
                            ref={onRefChange}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {cancelLabel || 'Cancel'}
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        autoFocus
                        disabled={!isvalid}
                        //disabled={checkboxes.length !== 0 && checkboxes.some((box) => !box)} // Disable if there are checkboxes and if any of them unchecked
                    >
                        {confirmLabel || 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustomDialog;
