import React from 'react';
import ProjectWorkloadTable from './ProjectWorkloadTable';
import Workload from '../../base/workload/Workload';
import ProjectWorkloadGraph from './ProjectWorkloadGraph';

const ProjectWorkloadManagement = (props) => {
    return (
        <Workload
            {...props}
            isProject
            type="project"
            tableView={<ProjectWorkloadTable />}
            graphView={<ProjectWorkloadGraph />}
        />
    );
};

export default ProjectWorkloadManagement;
