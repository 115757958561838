import ToolForm from './ToolForm';
import React from 'react';
import BaseEdit from '../base/views/BaseEdit';

const ToolEdit = (props) => (
    <BaseEdit {...props} redirect="list">
        <ToolForm />
    </BaseEdit>
);

export default ToolEdit;
