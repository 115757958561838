import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';
import WorkerDepartmentForm from './WorkerDepartmentForm';

const WorkerDepartmentEdit = (props) => {
    return (
        <BaseEdit {...props} redirect="list">
            <WorkerDepartmentForm />
        </BaseEdit>
    );
};

export default WorkerDepartmentEdit;
