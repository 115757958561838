import React from 'react';
import ProjectBaseForm from './ProjectBaseForm';
import BaseCreate from '../base/views/BaseCreate';

const ProjectCreate = ({ redirect, ...props }) => {
    return (
        <BaseCreate {...props}>
            <ProjectBaseForm
                location={props.location}
                redirect={(basePath, id, data) => {
                    return `/contracts/projects/create?project=${id}`;
                }}
            />
        </BaseCreate>
    );
};

export default ProjectCreate;
