import React from 'react';
import List from './ProjectWorkerContractList';
import Show from './ProjectWorkerContractShow';

const resourceName = 'Worker Contract';
const resourceNamePlural = 'Worker Contracts';

const workerContracts = {
    list: (props) => (
        <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    show: (props) => (
        <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default workerContracts;
