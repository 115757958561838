import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';
import MotiusSearch from './MotiusSearch';
import TodoNotifications from './TodoNotifications';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: '32px',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        marginTop: '5px',
    },
    appBar: {
        backgroundColor: process.env.REACT_APP_DEVELOPMENT_ENV ? '#ffb300' : '#383E4E',
        color: process.env.REACT_APP_DEVELOPMENT_ENV ? '#000' : '#fff',
    },
});

const MyAppBar = ({ ...props }) => {
    const classes = useStyles();
    return (
        <AppBar className={classes.appBar} userMenu={<UserMenu />} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Link to="/">
                <img
                    className={classes.logo}
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    alt="Motius"
                    height={32}
                />
            </Link>
            <span className={classes.spacer} />

            <MotiusSearch />
            <TodoNotifications />
        </AppBar>
    );
};

export default MyAppBar;
