import React, { useState } from 'react';
import { Button, useRefresh, useGetOne } from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { apiUrl, httpClient } from '../../../dataProvider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTime } from 'luxon';
import useCustomNotify from '../../../base/useCustomNotify';

const PlanInvoicesButton = ({ record, color, variant, ...props }) => {
    const [updating, setUpdating] = useState(false);
    const [deleteExisting, setDeleteExisting] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const refreshView = useRefresh();
    const { data: project, isLoading } = useGetOne('projects', { id: record.project });
    const notify = useCustomNotify();
    const alreadyPlanned = record.invoice_revenue.total_planned_invoices > 0;
    const docsUrl =
        'https://motius.atlassian.net/wiki/spaces/PO/pages/7964786979/Invoicing+Acceptance+Reports';

    const updateState = () => {
        if (record) {
            setUpdating(true);
            httpClient(`${apiUrl}/contracts/projects/${record.id}/plan-invoices`, {
                method: 'POST',
                body: JSON.stringify({ delete_existing: deleteExisting }),
            })
                .then(() => {
                    notify(`Planned invoices generated`);
                })
                .catch((e) => {
                    notify(`Could not generate invoices: ${e.message}`, { type: 'warning' });
                })
                .finally(() => {
                    setUpdating(false);
                    refreshView();
                    setDialogOpen(false);
                });
        }
    };

    const onClick = () => {
        setDialogOpen(true);
    };

    const onDialogSubmit = () => {
        updateState();
    };

    return (
        <div style={{ display: 'inline-block' }}>
            <Button
                color={color || 'primary'}
                variant={variant || 'text'}
                onClick={onClick}
                disabled={updating || isLoading}
                children={<FileCopyIcon />}
                label="Generate planned invoices"
                {...props}
            />
            <Dialog
                open={dialogOpen}
                maxWidth="md"
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Generate Planned Invoices</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>
                            This will generate a monthly schedule of planned invoices from the
                            project's start on the{' '}
                            {DateTime.fromISO(project.start_date).toLocaleString(DateTime.DATE_MED)}
                            .
                        </p>
                        <p>
                            The amount per invoice is based on the workload planning{' '}
                            <strong>or</strong> the planned roles and their weekly hours if no team
                            is planned, yet.
                        </p>
                        <p>
                            Check{' '}
                            <a href={docsUrl} rel="noreferrer" target="_blank">
                                our invoicing documentation
                            </a>{' '}
                            for more details.
                        </p>
                        {alreadyPlanned && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={deleteExisting}
                                        onChange={() => setDeleteExisting(!deleteExisting)}
                                    />
                                }
                                label="Delete existing planned invoices"
                            />
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary" label="Cancel" />
                    <Button
                        onClick={onDialogSubmit}
                        color="primary"
                        disabled={updating}
                        label="Generate"
                        autoFocus
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PlanInvoicesButton;
