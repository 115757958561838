import ClusterForm from './ClusterForm';
import React from 'react';
import BaseCreate from '../base/views/BaseCreate';

const ClusterCreate = (props) => (
    <BaseCreate {...props}>
        <ClusterForm />
    </BaseCreate>
);

export default ClusterCreate;
