import {
    List,
    TextField,
    ReferenceField,
    DateField,
    Filter,
    TextInput,
    NumberField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectArrayInput,
} from 'react-admin';
import React from 'react';
import PTResponsibleField from './fields/PTResponsibleField';
import EditPermissionButton from '../../base/buttons/EditPermissionButton';
import { StateExportActions, StateExportBulkActions } from '../../base/actions/StateExportActions';
import BooleanTrueField from '../../base/fields/BooleanTrueField';
import { DateInputField } from '../../base/input/DateInput';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const ProjectRoleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name__icontains" alwaysOn />
        <ReferenceInput source="project" reference="projects" alwaysOn>
            <AutocompleteInput
                optionText="label"
                optionValue="id"
                filterToQuery={(text) => ({ name__icontains: text })}
                label="Project"
            />
        </ReferenceInput>
        <ReferenceArrayInput reference="clusters" source="clusters__in" label="Clusters" alwaysOn>
            <SelectArrayInput style={{ minWidth: 100 }} optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            filterToQuery={(input) => ({ q: input })}
            source="tools__in"
            label="Tools"
            reference="tools"
            alwaysOn
        >
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <DateInputField label="Start Date" source="start_date__in" />
        <DateInputField label="End Date" source="end_date__in" />
        <ReferenceArrayInput
            filter={{ is_active: true, worker_profile__isnull: false }}
            source="project__responsible"
            reference="users"
        >
            <AutocompleteArrayInput
                label="Responsible"
                style={{ minWidth: 100 }}
                optionText="name"
            />
        </ReferenceArrayInput>
        <BooleanInput label="Only mine" source="project__mine" />
        <BooleanInput label="Only Public" source="only_public" />
    </Filter>
);

const ProjectRoleList = (props) => (
    <List
        title="Project Roles"
        filterDefaultValues={{ is_staffed: false }}
        filters={<ProjectRoleFilter />}
        actions={<StateExportActions stateResource="enums/project/ProjectState" />}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        hasCreate
        {...props}
    >
        <EditableDatagrid
            rowClick={'show'}
            actions={<EditPermissionButton
                label=""
            bulkActionButtons={
                <StateExportBulkActions stateResource="enums/project/ProjectState" useDescription />
            } />}>
            <ShowElementLink source="name" obj="projects/role" />
            <ReferenceField reference="projects" source="project" link="show">
                <TextField source="name" />
            </ReferenceField>
            <BooleanTrueField source="is_public" label="Public" />
            <DateField
                label="Start Date"
                source="start_date"
                locales="en-GB"
                options={{ year: 'numeric', month: 'short', day: 'numeric' }}
            />
            <DateField
                label="End Date"
                source="end_date"
                locales="en-GB"
                options={{ year: 'numeric', month: 'short', day: 'numeric' }}
            />
            <NumberField label="Weekly Hours" source="hours" />
            <ReferenceField label="PT Responsible" reference="projects" source="project">
                <PTResponsibleField />
            </ReferenceField>
        </EditableDatagrid>
    </List>
);

export default ProjectRoleList;
