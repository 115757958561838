import Create from './ToolCreate';
import Edit from './ToolEdit';
import List from './ToolList';
import React from 'react';
import onlyAdminViews from '../auth/onlyAdminViews';

const resourceName = 'Skill';
const resourceNamePlural = 'Skills';

const tools = (permissions) =>
    onlyAdminViews(permissions, {
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default tools;
