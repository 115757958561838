import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

export const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});

const CurrencyField = ({ source }) => {
    const record = useRecordContext();
    if (!record && !source) {
        return null;
    }

    const numberValue = Number(record[source]) || 0;

    const mutatedRecord = { label: currencyFormatter.format(numberValue) };

    return <TextField record={mutatedRecord} source="label" />;
};

export default CurrencyField;
