import React, { Fragment, useState } from 'react';
import { useRefresh, Button, useListContext, useResourceDefinition } from 'react-admin';
import { apiUrl, convertFilterParams, httpClient } from '../../dataProvider';
import InboxIcon from '@mui/icons-material/Inbox';
import { stringify } from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import useCustomNotify from '../useCustomNotify';

const PayrollAction = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const notify = useCustomNotify();
    const refreshView = useRefresh();
    let { selectedIds, filterValues } = useListContext();
    const resource = useResourceDefinition();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        if (selectedIds.length > 0) {
            // Selected IDs should overwrite filters (when selecting items in bulk)
            // Otherwise no IDs are selected and all (filtered) items should be updated
            filterValues = { id__in: selectedIds };
        }
        const url = `${apiUrl}/${resource.name}/payroll?${stringify(
            convertFilterParams(filterValues)
        )}`;
        setLoading(true);
        httpClient(url, { method: 'POST' })
            .then((data) => {
                const { __all__ } = data.json;
                notify(__all__);
            })
            .catch((e) => {
                notify(`There was an error creating the payroll export: ${e.message}`, {
                    type: 'warning',
                });
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
                refreshView();
            });
    };

    return (
        <Fragment>
            <Button label="Payroll Export" onClick={handleOpen} {...props}>
                <InboxIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">Payroll Export</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        This will set the selected timelogs to invoiced and create a payroll export.
                        Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button label="Export" onClick={handleClick}>
                        {loading ? <CircularProgress size={24} /> : <InboxIcon />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default PayrollAction;
