import React, { useState } from 'react';
import { Button, } from 'react-admin';
import { apiUrl, httpClientFetch } from '../../dataProvider';
import * as PropTypes from 'prop-types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import { formatErrors } from '../../fetchUtils';
import useCustomNotify from '../useCustomNotify';

const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

export const openSaveFileDialog = (data, filename, mimetype) => {
    if (!data) return;
    let blob =
        data.constructor !== Blob
            ? new Blob([data], { type: mimetype || 'application/octet-stream' })
            : data;

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
        return;
    }

    let lnk = document.createElement('a'),
        url = window.URL,
        objectURL;

    if (mimetype) {
        lnk.type = mimetype;
    }

    lnk.download = filename || 'untitled';
    lnk.href = objectURL = url.createObjectURL(blob);
    lnk.dispatchEvent(new MouseEvent('click'));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

const DownloadBlobButton = (props) => {
    const [loading, setLoading] = useState(false);
    const { requireAuth, fileUrl, fileName, label, children } = props;
    const notify = useCustomNotify();

    const handleClick = () => {
        let contentType = '';
        let url = fileUrl;
        if (!isValidUrl(url)) {
            url = `${apiUrl}/${fileUrl}`;
        }
        setLoading(true);
        const fetchType = requireAuth ? httpClientFetch : fetch;
        fetchType(url)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }

                contentType = response.headers.get('Content-Type');
                return response.blob();
            })
            .catch((error) => {
                if ([400, 403].includes(error.status)) {
                    error.json().then((json) => {
                        notify(formatErrors(json).join(', '), { type: 'warning' });
                    });
                } else if (error.status === 500) {
                    notify('Server error, please contact support.', { type: 'warning' });
                } else {
                    notify('An error occurred', { type: 'warning' });
                }
            })
            .then((blob) => {
                openSaveFileDialog(blob, fileName, contentType);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            disabled={loading}
            children={loading ? <CircularProgress size={20} /> : children || <CloudDownloadIcon />}
            label={label || `Download ${fileName ? fileName.split('/').pop() : ''}`}
            {...props}
        />
    );
};

DownloadBlobButton.propTypes = {
    fileUrl: PropTypes.string.isRequired,
};

export default DownloadBlobButton;
