import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { TooltipProps } from 'recharts';
import { workloadGraphColors } from '../useWorkloadStyles';
import { isFuture } from '../workloadUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(),
        zIndex: 999,
        background: theme.palette.background.paper,
    },
    list: {
        textAlign: 'start',
        marginTop: parseInt(theme.spacing()) / 2,
    },
    chip: {
        margin: parseInt(theme.spacing()) / 4,
    }
}));

const signFormatter = new Intl.NumberFormat("en-US", {
    signDisplay: "exceptZero"
});

const WorkloadTooltip: React.FC<TooltipProps<any, any>> = ({ payload, label }) => {
    const classes = useStyles();
    const data = payload?.length ? payload[0].payload : {}
    const isDateFuture = isFuture(data.start)
    const difference = data.diff || 0
    const differenceFormatted = signFormatter.format(difference)
    const tracked = data.totalTracked
    const planned = data.totalPlanned
    const absences = data.absences

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="subtitle1">{label}</Typography>
            <div className={classes.list}>
                {!isDateFuture && <div>
                    {difference !== 0 ? <Typography
                        variant='h5'
                        style={{ color: difference > 0 ? workloadGraphColors.trackedOver : workloadGraphColors.plannedRemaining }}>
                        {differenceFormatted}h {difference > 0 ? 'over' : "under"}
                    </Typography> : <Typography color='primary' variant='h5'>
                        Good work!
                    </Typography>}
                </div>}
                <ul style={{ paddingLeft: '1rem', marginTop: '0.5rem' }}>
                    {!isDateFuture && <li>
                        <Typography variant='subtitle2' style={{ color: workloadGraphColors.trackedNormal }}>
                            {tracked}h tracked
                        </Typography>
                    </li>}
                    <li>
                        <Typography variant='subtitle2'>
                            {planned}h planned
                        </Typography>
                    </li>
                    {absences > 0 && <li>
                        <Typography variant='subtitle2'>
                            {absences}h absences
                        </Typography>
                    </li>}
                </ul>
            </div>
        </Paper>
    );
};

export default WorkloadTooltip;
