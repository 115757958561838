import {
    required,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleForm,
} from 'react-admin';
import React from 'react';

const ToolForm = (props) => (
    <SimpleForm defaultValues={{ source: 1 }} {...props}>
        <TextInput source="name" label="Name" validate={[required()]} />
        <TextInput
            source="keyword_regex"
            label="Keyword Regex"
            helperText="Auto-assign tools to roles matching this RegEx"
        />
        <ReferenceInput
            source="source"
            reference="enums/tool/WorkerToolSource"
        >
            <SelectInput optionText="label" validate={[required()]} />
        </ReferenceInput>
        <BooleanInput source="user_created" />
        <BooleanInput source="default_evaluated" />
    </SimpleForm>
);

export default ToolForm;
