import React from 'react';
import { Button, Link, useListContext } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import BookIcon from '@mui/icons-material/Book';
import DocumentationField from './DocumentationField';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
});

export const AddDocumentationButton = ({ record, target, basePath }) => {
    const classes = useStyles();
    return (
        <Button
            className={classes.spacingTop}
            color="primary"
            component={Link}
            to={{
                pathname: `/${basePath}/create`,
                search: `?${target}=${record.id}`,
            }}
            label="Create Documentation"
        >
            <BookIcon />
        </Button>
    );
};

const DocumentationList = (props) => {
    const { data } = useListContext();
    const { id, basePath, target } = props;
    return (
        <div>
            {data?.map((row, index) => (
                    <div key={data[index].id}>
                        <DocumentationField record={data[index]} basePath={basePath} />
                    </div>
                ))}
            {data && !data.length && (
                <AddDocumentationButton record={{ id: id }} basePath={basePath} target={target} />
            )}
        </div>
    );
};

DocumentationList.defaultProps = {
    data: {},
    ids: [],
};

export default DocumentationList;
