import InvoiceForm from './InvoiceForm';
import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';

const InvoiceEdit = (props) => (
    <BaseEdit {...props}>
        <InvoiceForm redirect="show" />
    </BaseEdit>
);

export default InvoiceEdit;
