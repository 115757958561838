import Create from './WorkerNoteCreate';
import Edit from './WorkerNoteEdit';
import Show from './WorkerNoteShow';
import React from 'react';

const resourceName = 'Note';
const resourceNamePlural = 'Notes';

const workerNote = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    show: (props) => <Show resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />

};

export default workerNote;
