import React, { useState } from 'react';
import { Button, useRecordContext, useRefresh } from 'react-admin';
import MoneyIcon from '@mui/icons-material/Euro';
import { apiUrl, httpClient } from '../../../dataProvider';
import useCustomNotify from '../../../base/useCustomNotify';

const ConvertInvoiceButton = ({ variant }) => {
    const record = useRecordContext();
    const [updating, setUpdating] = useState(false);
    const refreshView = useRefresh();
    const notify = useCustomNotify();

    const updateState = () => {
        if (record) {
            setUpdating(true);
            httpClient(`${apiUrl}/payment/invoices/${record.id}/convert-invoice`, {
                method: 'POST',
            })
                .then((data) => {
                    notify(`Planned invoices generated`);
                })
                .catch((e) => {
                    notify(`Could not generate invoices: ${e.message}`, { type: 'warning' });
                })
                .finally(() => {
                    setUpdating(false);
                    refreshView();
                });
        }
    };

    const onClick = () => {
        updateState();
    };

    if (record.state === 'converted' || record.state === 'rejected') {
        // Can't convert twice, and can't convert rejected
        return null;
    } else {
        return (
            <>
                <Button
                    variant={variant || 'text'}
                    onClick={onClick}
                    disabled={updating}
                    children={<MoneyIcon />}
                    label="Convert to Invoice"
                />
            </>
        );
    }
};

export default ConvertInvoiceButton;
