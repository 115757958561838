import React from 'react';
import Menu from '@mui/material/Menu';
import { Button } from 'react-admin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    menuButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'left',
    },
});

const MoreActionsMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { children } = props;
    const classes = useStyles();

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function renderChildren() {
        return React.Children.map(
            children,
            (child) =>
                child &&
                React.cloneElement(child, {
                    className: classes.menuButton,
                })
        );
    }

    return (
        <div>
            <Button
                aria-controls="more-actions-menu"
                aria-haspopup="true"
                onClick={handleClick}
                label="More"
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="more-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {renderChildren()}
            </Menu>
        </div>
    );
};

export default MoreActionsMenu;
