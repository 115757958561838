import { TopToolbar, CreateButton, RefreshButton } from 'react-admin';
import BulkDeleteButton from '../buttons/BulkDeleteButton';
import React, { Fragment } from 'react';
import MoreActionsMenu from './MoreActionsMenu';

export const DefaultActions = ({
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    children,
    hasCreate,
}) => (
    <TopToolbar>
        {hasCreate && <CreateButton basePath={basePath} />}
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <MoreActionsMenu>{children}</MoreActionsMenu>
        <RefreshButton />
    </TopToolbar>
);

export const DefaultBulkActions = (props) => (
    <Fragment>
        <BulkDeleteButton {...props} />
        {props.children}
    </Fragment>
);
