import CostCenterForm from './CostCenterForm';
import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';

const CostCenterCreate = (props) => (
    <BaseCreate {...props}>
        <CostCenterForm />
    </BaseCreate>
);

export default CostCenterCreate;
