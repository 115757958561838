import React from "react";
import { Create, CreateProps, useRedirect, useRefresh } from "react-admin";
import { regeneratePDFWithProjectId } from "./components/utils";
import ProjectContractWizardForm from "./ProjectContractWizardForm";
import useCustomNotify from "../../base/useCustomNotify";

const ProjectContractCreate = (props: CreateProps) => {
  const notify = useCustomNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onCreateSuccess = async (response: any) => {
    if (response?.id) {
      try {
        await regeneratePDFWithProjectId(response?.id, refresh);
      } catch (e) {
        notify("Error during regenerating PDF with project Id");
      }
      redirect(`/projects/wizard/${response?.id}/2`);
    }
  };
  return (
    <Create {...props} mutationOptions={{ onSuccess: onCreateSuccess }}>
      <ProjectContractWizardForm />
    </Create>
  );
};

export default ProjectContractCreate;
