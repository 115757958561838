import React from 'react';
import {
    SimpleShowLayout,
    ShowController,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextField,
    ReferenceManyField,
    Show,
} from 'react-admin';
import OverheadForm from './overheadFactor/OverheadBillableForm';
import OverheadEditableGrid from './overheadFactor/OverheadEditableGrid';
import UtilizationEditableGrid from './utilizationTarget/UtilizationEditableGrid';
import UtilizationForm from './utilizationTarget/UtilizationForm';
import WeeklyBillableEditableGrid from './weeklyTargetHours/WeeklyBillableEditableGrid';
import WeeklyBillableForm from './weeklyTargetHours/WeeklyBillableForm';

const PositionShow = ({ ...props }) => {
    return (
        <ShowController {...props}>
            {(controllerProps) => (
                <Show
                    {...props}
                    {...controllerProps}
                    title={`${props.resourceName} ${
                        controllerProps.record && controllerProps.record.name
                    }`}
                >
                    {controllerProps.record && (
                        <SimpleShowLayout>
                            <TextField source="name" />
                            <TextField source="description" />
                            <ReferenceArrayField
                                label="Groups"
                                reference="users/group"
                                source="groups"
                            >
                                <SingleFieldList link="show">
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>

                            <ReferenceManyField
                                reference="users/utilization-target"
                                label="Utilization Targets"
                                target="position"
                            >
                                <UtilizationEditableGrid
                                    bulkActionButtons={false}
                                    editForm={<UtilizationForm />}
                                    createForm={
                                        <UtilizationForm
                                            defaultValues={{ position: controllerProps.record.id }}
                                        />
                                    }
                                    style={{ width: '50%' }}
                                />
                            </ReferenceManyField>
                            <ReferenceManyField
                                reference="users/weekly-billable-target"
                                label="Weekly Billable Hour Targets"
                                target="position"
                            >
                                <WeeklyBillableEditableGrid
                                    bulkActionButtons={false}
                                    editForm={<WeeklyBillableForm />}
                                    createForm={
                                        <WeeklyBillableForm
                                            defaultValues={{ position: controllerProps.record.id }}
                                        />
                                    }
                                    style={{ width: '50%' }}
                                />
                            </ReferenceManyField>
                            <ReferenceManyField
                                reference="users/overhead-factor"
                                label="Overhead Factors"
                                target="position"
                            >
                                <OverheadEditableGrid
                                    bulkActionButtons={false}
                                    editForm={<OverheadForm />}
                                    createForm={
                                        <OverheadForm
                                            defaultValues={{ position: controllerProps.record.id }}
                                        />
                                    }
                                    style={{ width: '50%' }}
                                />
                            </ReferenceManyField>
                        </SimpleShowLayout>
                    )}
                </Show>
            )}
        </ShowController>
    );
};

export default PositionShow;
