import React from 'react';
import BaseEdit from '../base/views/BaseEdit';
import WorkerForm from './WorkerForm';

const WorkerEdit = (props) => {
    console.log('component Worker Edit');
    return (
        <BaseEdit {...props}>
            <WorkerForm redirect="show" />
        </BaseEdit>
    );
};

export default WorkerEdit;
