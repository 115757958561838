import React, { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { IconButton, TableCell, TextField, Tooltip, Typography } from '@mui/material';
import MaskedInput from 'react-text-mask';
import useWorkloadStyles from '../useWorkloadStyles';
import { createNumberMask } from 'text-mask-addons';
import { DateTime } from 'luxon';
import {
    createPlannedHoursData,
    isFuture,
    isPastEndDate,
    isPastWeek,
    useWorkloadDialogState,
} from '../workloadUtils';
import { useController, Control } from 'react-hook-form';
import { FastForward } from '@mui/icons-material';
import classNames from 'classnames';
import WorkloadCreateDialog from './WorkloadCreateDialog';
import { RowData } from '../WorkloadContext';
import { apiUrl, httpClient } from '../../../dataProvider';
import { useRefresh } from 'react-admin';
import useCustomNotify from '../../useCustomNotify';

const durationMask = createNumberMask({
    prefix: '',
    suffix: 'h',
    allowDecimal: true,
    integerLimit: 2,
});

type WorkloadTableCellProps = {
    row: RowData;
    disabled: boolean;
    hideTracked: boolean;
    trackedTime: number;
    weekStartDate: string;
    projectEndDate: string;
    availability: number;
    originalValue: number;
    control: Control;
};

const WorkloadTableCell: React.FC<WorkloadTableCellProps> = ({
    row,
    disabled,
    hideTracked,
    weekStartDate,
    projectEndDate,
    trackedTime,
    availability,
    originalValue,
    control,
}) => {
    const [value, setValue] = useState(0)
    const refreshView = useRefresh();
    const [submitting, setSubmitting] = useState(false);
    const { isOpen, selectedWeeklyHours, closeDialog, openDialog } = useWorkloadDialogState();
    const { id, worker, project } = row;
    const classes = useWorkloadStyles();
    const weekStartDateTime = DateTime.fromISO(weekStartDate);
    const notify = useCustomNotify();
    const name = `${id}-${worker}-${project}-${weekStartDateTime.weekNumber}-${weekStartDateTime.year}`;
    const {
        field,
        fieldState: { isDirty },
    } = useController({
        name,
        control,
        //when using changed planned hours for more than a week feature and then save,
        //values are not refreshed, hence handling using useEffect :(
        //defaultValue: originalValue,
    });
    useEffect(() => {
      setValue(originalValue)
    }, [originalValue])
    
    const disabledCell =
        isPastEndDate(weekStartDateTime, projectEndDate) ||
        isPastWeek(weekStartDateTime) ||
        submitting;
    const isOutOfAvailability = availability - (value - originalValue) < 0;

    const getCellColor = () => {
        if (submitting) {
            return 'white';
        } else if (disabledCell) {
            return '#4c5257';
        } else if (isOutOfAvailability) {
            return '#f44336';
        }
        return 'initial';
    };

    const onButtonClick = () => {
        if (disabledCell) {
            return;
        }

        openDialog(weekStartDate, field.value);
    };

    const onSubmit = () => {
        setSubmitting(true);
        const newPlanned = createPlannedHoursData(
            id,
            worker,
            project,
            Number(String(field.value).replace('h', '')),
            Number(weekStartDateTime.weekNumber),
            Number(weekStartDateTime.year)
        );
        httpClient(`${apiUrl}/invitations/planned`, {
            method: 'post',
            body: JSON.stringify([newPlanned]),
        }).then(() => {
            setSubmitting(false);
            refreshView();
        });
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        if (isDirty) {
            onSubmit();
        }
        field.onBlur();
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === '.') {
            notify('Only full hours are accepted', { type: 'warning' });
            e.preventDefault();
        }
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <TableCell align="center" style={{ padding: 0 }}>
            <span className={classes.hoursCell}>
                <span className={classes.plannedCell}>
                    <TextField
                        type="tel"
                        {...field}
                        value={value}
                        onChange={(event) => {
                          field.onChange(Number(event.target.value.replace('h', ''))) // data send back to hook form
                          setValue(Number(event.target.value.replace('h', ''))) // UI state
                        }}
                        disabled={disabledCell}
                        onKeyDown={onKeyDown}
                        InputProps={{
                            disableUnderline: true,
                            inputComponent: MaskedInput as any,
                        }}
                        inputProps={{
                            style: {
                                background: submitting ? 'var(--color-secondary)' : 'white',
                                textAlign: 'center',
                                pointerEvents: disabled ? 'none' : 'auto',
                                color: getCellColor(),
                                fontWeight: isOutOfAvailability ? '800' : '400',
                            },
                            mask: durationMask,
                        }}
                        onBlur={onBlur}
                    />
                    {!disabled && !disabledCell && (
                        <Tooltip title="Change planned hours for multiple weeks">
                            <IconButton
                                tabIndex={-1}
                                onClick={onButtonClick}
                                className={classNames('planned-button', classes.plannedCellButton)}
                                color="primary"
                                size="small"
                            >
                                <FastForward style={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <WorkloadCreateDialog
                        id={id}
                        worker={worker}
                        project={project}
                        project_end_date={projectEndDate}
                        open={isOpen}
                        onCloseDialog={closeDialog}
                        project_name={row.project_name}
                        start_date={weekStartDate}
                        weekly_hours={selectedWeeklyHours}
                        worker_name={row.worker_name}
                    />
                </span>
                {!hideTracked && (
                    <span className={classes.trackedCell}>
                        <Typography className={classes.muted}>
                            &nbsp;
                            {!isFuture(weekStartDateTime) ? `${trackedTime}h` : ''}
                        </Typography>
                    </span>
                )}
            </span>
        </TableCell>
    );
};
export default WorkloadTableCell;
