import {
    Datagrid,
    DateField,
    Filter,
    ReferenceInput,
    ReferenceField,
    ReferenceArrayInput,
    ShowButton,
    TextField,
    SelectArrayInput,
    BooleanInput,
} from 'react-admin';
import React from 'react';
import BaseList from '../../base/views/BaseList';
import BooleanTrueField from '../../base/fields/BooleanTrueField';
import AutocompleteCustomArrayInput from '../../base/input/AutocompleteCustomArrayInput';
import { DateInputField } from '../../base/input/DateInput';

const ProjectWorkerContractFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="worker__id__in" reference="users/worker" alwaysOn>
            <AutocompleteCustomArrayInput optionText="label" label="Worker" />
        </ReferenceInput>
        <ReferenceArrayInput
            reference="enums/contract/ProjectWorkerContractType"
            source="type__in"
            alwaysOn
            label="Contract Type"
        >
            <SelectArrayInput optionText="label" style={{ minWidth: 200 }} label="Contract Type" />
        </ReferenceArrayInput>
        <DateInputField source="start_date__gte" label="Start Date >=" />
        <DateInputField source="start_date__lte" label="Start Date <=" />
        <DateInputField source="end_date__gte" label="End Date >=" />
        <DateInputField source="end_date__lte" label="End Date <=" />
        <BooleanInput source="is_active" />
        <BooleanInput source="is_core" />
    </Filter>
);

const ProjectWorkerContractList = (props) => (
    <BaseList
        {...props}
        filters={<ProjectWorkerContractFilter />}
        sort={{ field: 'start_date', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <ReferenceField source="worker" reference="users/worker" link="show">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceField
                source="type"
                reference="enums/contract/ProjectWorkerContractType"
                link={null}
            >
                <TextField source="label" />
            </ReferenceField>
            <BooleanTrueField source="is_active" />
            <BooleanTrueField source="is_core" />
            <DateField source="start_date" />
            <DateField source="end_date" />
        </Datagrid>
    </BaseList>
);

export default ProjectWorkerContractList;
