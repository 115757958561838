import { Box, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { FileInput, NumberInput, ReferenceInput, required } from 'react-admin';
import CurrencyInput from '../../../base/input/CurrencyInput';
import { DateInputField } from '../../../base/input/DateInput';
import FileFieldInput from '../../../base/input/FileFieldInput';
import MainDepartmentInput from '../../../base/input/MainDepartmentInput';

const ReviewedFormContent = () => {
    return (
        <Box px={4}>
            <Typography variant="h5" gutterBottom>
                Additional Settings
            </Typography>
            <ReferenceInput source="main_department" reference="workers/departments">
                <MainDepartmentInput />
            </ReferenceInput>
            <Typography variant="h5" gutterBottom>
                Finance Data
            </Typography>
            <FileInput
                label="Signed Contract"
                source="client_contract.contract_signed"
                multiple={false}
                isRequired
            >
                <FileFieldInput />
            </FileInput>
            <FileInput
                label="Offer Confirmation"
                source="client_contract.order_confirmation"
                multiple={false}
                isRequired
            >
               <FileFieldInput />
            </FileInput>
            <DateInputField
                label="Contract Signed On"
                source="client_contract.contract_signed_on"
                validate={required()}
                variant="outlined"
                fullWidth={true}
            />
            <NumberInput
                label="Payment Period"
                source="client_contract.payment_period"
                validate={[required()]}
                InputProps={{
                    endAdornment: <InputAdornment position="start">days</InputAdornment>,
                }}
                fullWidth
                variant="outlined"
                margin="normal"
            />
            <CurrencyInput
                label="Net Value Override"
                source="client_contract.net_value_override"
                fullWidth
                variant="outlined"
                margin="normal"
                helperText="Please only use when absolutely necessary."
            />
            <CurrencyInput
                variant="outlined"
                fullWidth
                margin="normal"
                source="client_contract.unused_value"
                label="Unused Revenue"
                helperText="Revenue that is not going to be invoiced"
            />
        </Box>
    );
};

export default ReviewedFormContent;
