import { Step, StepLabel, Stepper } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React, { useMemo } from 'react';

const ContractShowStepper = () => {
    const record = useRecordContext();
    const currentStep = useMemo(() => {
        switch (record?.client_contract?.state) {
            case 'reviewed':
                return 0
            case 'verbal_confirmation':
                return 1
            case 'approved':
                return 2;
            case 'billed':
                return 3;
            case 'paid':
                return 4;
            default:
                return 0;
        }
    }, [record]);

    return (
        <Stepper activeStep={currentStep} style={{width : '100%'}}>
            <Step>
                <StepLabel>Reviewed</StepLabel>
            </Step>
            <Step>
                <StepLabel>Verbal Confirmation</StepLabel>
            </Step>
            <Step>
                <StepLabel>Approved</StepLabel>
            </Step>
            <Step>
                <StepLabel>Billed</StepLabel>
            </Step>
            <Step>
                <StepLabel>Paid</StepLabel>
            </Step>
        </Stepper>
    );
};

export default ContractShowStepper;
