import React from 'react';
import { Show } from 'react-admin';
import { ShowHeadTitle } from '../HeadTitle';

const BaseShowView = ({ resourceName, resourceNamePlural, ...props }) => {
    return (
        <Show
            title={<ShowHeadTitle resourceName={resourceName} record={props.record} />}
            {...props}
        />
    );
};

export default BaseShowView;
