import { apiUrl, httpClient } from '../../../dataProvider';

export const regeneratePDF = async (contractId: number, refresh?: () => void) => {
    await httpClient(`${apiUrl}/contracts/projects/${contractId}/generate-contract`, {
        method: 'post',
    });
    if (refresh) refresh();
};

export const regeneratePDFWithProjectId = async (projectId: number, refresh?: () => void) => {
    await httpClient(`${apiUrl}/projects/${projectId}/generate-contract`, {
        method: 'post',
    });
    if (refresh) refresh();
};
