import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useDataProvider,
    Button,
    useRedirect,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import { DELETE } from '../../../node_modules/ra-core/dist/esm/index';
import useCustomNotify from '../useCustomNotify';

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.error.main,
    },
}));

const VALIDATION_TEXT = 'delete';

const ConfirmDeleteButton = (props) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const [openDialog, setOpenDialog] = useState(false);
    const [validationInput, setValidationInput] = useState('');
    const { forceConfirmation } = props;
    const classes = useStyles();
    const notify = useCustomNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleInputChange = (e) => {
        setValidationInput(e.target.value);
    };

    const handleBulkDelete = () => {
        dataProvider(DELETE, resource, { id: record.id })
            .then(() => {
                notify('Resource deleted.');
                redirect(`/${resource}`);
            })
            .catch(() => {
                notify('Error in deleting resource, please check if you have enough permissions.', {
                    type: 'warning',
                });
            });
    };

    return (
        <React.Fragment>
            <Button
                className={classes.button}
                onClick={handleOpenDialog}
                label="Delete"
                children={<DeleteIcon />}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{'Are you sure you want to delete this entry?'}</DialogTitle>
                {forceConfirmation && (
                    <DialogContent>
                        <Typography>
                            If you are sure to delete this entry, please write{' '}
                            <strong>"{VALIDATION_TEXT}"</strong> in the field below.
                        </Typography>
                        <TextField fullWidth value={validationInput} onChange={handleInputChange} />
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus label="No" />
                    <Button
                        disabled={forceConfirmation && validationInput !== VALIDATION_TEXT}
                        onClick={handleBulkDelete}
                        color="primary"
                        label="Yes"
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ConfirmDeleteButton;
