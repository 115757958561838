import React from "react";
import {
  FileInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  useChoicesContext,
} from "react-admin";
import CKEditorInput from "../../../base/ckeditor/CKEditorInput";
import FormRow from "../../../base/forms/FormRow";
import CheckboxInput from "../../../base/input/CheckboxInput";
import FileFieldInput from "../../../base/input/FileFieldInput";
import Typography from '@mui/material/Typography';
import { OpenInNew } from '@mui/icons-material';
import { Button } from '@mui/material';

const ContractTypeSelect = (props: any) => {
  const choices = useChoicesContext();
  return (
    <SelectInput
      {...props}
      choices={choices.allChoices?.filter((ch: any) => ch.id !== "extension")}
      source={choices.source}
      defaultValue="sp"
      variant="outlined"
      margin="normal"
    />
  );
};

const FullMainContent = () => {
  return (
    <>
      <Button
        color="primary"
        endIcon={<OpenInNew />}
        target="_blank"
        href={"https://motius.sharepoint.com/:x:/s/vertrieb/EVy_k8SFNFdJhCKqNzf6f8wByfvBbCXsuei2MZsFmjpuUA?e=XaWInP"}
      >
        Calculation TM beta
      </Button>
      <Button
        color="primary"
        endIcon={<OpenInNew />}
        target="_blank"
        href={"https://motius.sharepoint.com/:x:/s/vertrieb/ER64iSzJ9L1Bn6NjNk_aK6MBpvV3Vtz0xDN5P61rwPitsA?e=IvD4NX"}
      >
        Calculation SP TM
      </Button>
      <FileInput
        label="Calculation file"
        source="client_contract.calculation_file"
        multiple={false}
        validate={required()}
      >
        <FileFieldInput />
      </FileInput>
      <FormRow columnCount={2}>
        <SelectInput
          fullWidth
          label="Language"
          source="client_contract.language"
          choices={[
            { id: "de", name: "DE" },
            { id: "en", name: "EN" },
          ]}
          variant="outlined"
          margin="normal"
          validate={required()}
          defaultValue="de"
          FormHelperTextProps={{ style: { display: "none" } }}
        />
        <ReferenceInput
          source="client_contract.type"
          reference="enums/contract/ProjectClientContractType"
        >
          <ContractTypeSelect
            optionText="label"
            fullWidth
            label="Contract Type"
            validate={required()}
            FormHelperTextProps={{ style: { display: "none" } }}
          />
        </ReferenceInput>
      </FormRow>

      <CKEditorInput
        source="client_contract.body"
        label="Content"
        templateParams={{
          filter: { type: "template.ContractTemplate" },
        }}
        fullWidth
        validate={[]}
      />
      <CheckboxInput
        source="client_contract.legal_overwrite"
        label="Overwrite legal text"
      />
      <FormDataConsumer fullWidth label="Internal Name">
        {({ formData, ...rest }) =>
          formData.client_contract.legal_overwrite ? (
            <CKEditorInput
              source="client_contract.legal_text"
              label="Legal Text"
              templateParams={{
                filter: { type: "template.LegalTemplate" },
              }}
              validate={[]}
            />
          ) : null
        }
      </FormDataConsumer>
      <CheckboxInput
        source="client_contract.hide_pricing"
        label="Overwrite generated pricing table"
      />
      <FormDataConsumer fullWidth label="Internal Name">
        {({ formData, ...rest }) =>
          formData.client_contract.hide_pricing ? (
            <CKEditorInput
              source="client_contract.pricing"
              label="Pricing"
              templateParams={{
                filter: { type: "template.ContractTemplate" },
              }}
              validate={[]}
            />
          ) : null
        }
      </FormDataConsumer>
    </>
  );
};

export default FullMainContent;
