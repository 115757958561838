import React from 'react';
import { Datagrid, TextField, ReferenceField } from 'react-admin';
import BaseList from '../../base/views/BaseList';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const WorkerDepartmentList = (props) => {
    return (
        <BaseList {...props}>
            <Datagrid rowClick="edit" sx={{ marginTop: '-50px' }}>
                <ShowElementLink source="name" obj="workers/departments" />
                <ReferenceField reference="users/worker" source="responsible">
                    <TextField source="label" />
                </ReferenceField>
            </Datagrid>
        </BaseList>
    );
};

export default WorkerDepartmentList;
