import React from 'react';
import { CreateProps, EditProps, ShowProps } from 'react-admin';
import ProjectContractCreate from './ProjectContractCreate';
import ProjectContractEdit from './ProjectContractEdit';
import ProjectContractShow from './ProjectContractShow';

const projectWizard = {
    create: (props: CreateProps) => <ProjectContractCreate {...props} />,
    edit: (props: EditProps) => <ProjectContractEdit {...props} />,
    show: (props: ShowProps) => <ProjectContractShow {...props} />
};

export default projectWizard;
