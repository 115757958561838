import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const DialogMail = ({ title, open, onClose, templates, onHandleClick, cancelLabel }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event) => {
        setValue(parseInt(event.target.value));
    };
    const submit = () => {
        onHandleClick(templates[value]);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <RadioGroup
                        aria-label="template"
                        name="template"
                        value={value}
                        onChange={handleChange}
                    >
                        {templates.map((option, index) => (
                            <FormControlLabel
                                color="primary"
                                key={option.id}
                                value={index}
                                control={<Radio />}
                                label={option.name}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {cancelLabel || 'Cancel'}
                    </Button>
                    <Button onClick={submit} color="primary">
                        {'OK'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogMail;
