import React from 'react';
import {
    ReferenceField,
    TextField,
    Filter,
    ReferenceArrayInput,
    SelectArrayInput,
    EditButton,
} from 'react-admin';
import CurrencyField from '../../base/fields/CurrencyField';
import BaseList from '../../base/views/BaseList';
import WorkerProfileLink from './fields/WorkerProfileLink';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';

const ReferralFilter = (props) => (
    <Filter {...props}>
        <ReferenceArrayInput
            source="state__in"
            reference="enums/worker/WorkerReferralStatus"
            alwaysOn
        >
            <SelectArrayInput style={{ minWidth: 200 }} optionText="label" />
        </ReferenceArrayInput>
    </Filter>
);

const ReferralList = (props) => (
    <BaseList
        filters={<ReferralFilter />}
        {...props}
    >
        <EditableDatagrid rowClick="show" actions={<EditButton label="" />}>
            <ReferenceField source="referred" reference="users" link={false} basePath="/users">
                <WorkerProfileLink />
            </ReferenceField>
            <ReferenceField source="referred_by" reference="users" link={false} basePath="/users">
                <WorkerProfileLink />
            </ReferenceField>
            <TextField source="referred_state" label="State of Referred" />
            <TextField source="state" />
            <CurrencyField source="reward" />
        </EditableDatagrid>
    </BaseList>
);

export default ReferralList;
