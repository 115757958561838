import * as React from "react";
import { KeyboardEvent } from "react";
import classnames from "classnames";
import {
  CardContentInner,
} from "react-admin";
import { WizardProgress } from "./WizardProgress";
import { WizardToolbar } from "./WizardToolbar";
import { StepProvider, useStep } from "./StepContext";
import { useLocation, useResolvedPath } from "react-router-dom";
import {
  WizardForm,
} from "@react-admin/ra-form-layout";

/**
 * Form component rendering a wizard form with stepper
 *
 * Alternative to <SimpleForm>, to be used as child of <Create>.
 * Expects <WizardFormStep> elements as children.
 *
 * @param {ComponentType} toolbar An alternative toolbar element (to customize form buttons)
 * @param {ComponentType} progress An alternative progress bar element (to customize stepper)
 *
 * @example
 *
 * import React, { FC } from 'react';
 * import { Create, TextInput, required } from 'react-admin';
 * import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout';
 *
 * const PostCreate: FC = props => (
 *   <Create {...props}>
 *       <WizardForm>
 *           <WizardFormStep label="First step">
 *               <TextInput source="title" validate={required()} />
 *           </WizardFormStep>
 *           <WizardFormStep label="Second step">
 *               <TextInput source="description" />
 *           </WizardFormStep>
 *           <WizardFormStep label="Third step">
 *               <TextInput source="fullDescription" validate={required()} />
 *           </WizardFormStep>
 *       </WizardForm>
 *   </Create>
 * );
 */
export const WizardFormInput = (props: any) => (
    <StepProvider>
      <WizardFormView {...props} />
    </StepProvider>
);

const WizardFormView = ({
  children,
  className,
  defaultValues,
  toolbar = WizardToolbar,
  progress = WizardProgress,
  ...rest
}: any) => {
  const {
    state: { step: currentStep },
    dispatch,
  } = useStep();
  const location = useLocation();
  const formRootPathname = useResolvedPath("").pathname ?? location.pathname;
  const stepInLocation = Number(
    location.pathname.replace(formRootPathname, "").replace("/", "")
  );

  React.useEffect(() => {
    if (
      stepInLocation &&
      !Number.isNaN(stepInLocation) &&
      !formRootPathname.includes("create")
    ) {
      dispatch({ type: "set", step: stepInLocation });
    }
  }, [stepInLocation, formRootPathname, dispatch]);

  const nextStep = () => {
    dispatch({ type: "next" });
    window.location.hash =
      formRootPathname.replace("/#", "") + "/" + String(currentStep + 1);
  };

  const previousStep = () => {
    dispatch({ type: "previous" });
    window.location.hash =
      formRootPathname.replace("/#", "") + "/" + String(currentStep - 1);
  };

  const setCurrentStep = (newStep: number) => {
    dispatch({ type: "set", step: newStep });
    window.location.hash =
      formRootPathname.replace("/#", "") + "/" + String(newStep);
  };

  const handleNext = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    nextStep();
  };

  const handlePrevious = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    previousStep();
  };

  // We can't go forward using the progress stepper
  // So we don't need extra checks here
  const handleIndexNavigation = (index: number): void => {
    setCurrentStep(index);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>): void => {
    if (
      event.key === "Enter" &&
      // @ts-ignore
      typeof event.target.tagName !== "undefined" &&
      // @ts-ignore
      event.target.tagName !== "button"
    ) {
      // Even if we don't have a next step, we should prevent the default form submit
      event.preventDefault();
    }
  };

  const steps = React.Children.toArray(children);

  const hasPreviousStep = currentStep > 0;
  const hasNextStep = currentStep < steps.length - 1;
  return (
    <WizardForm
      defaultValues={defaultValues}
      progress={React.createElement(progress, {
        steps,
        currentStep,
        onStepClick: handleIndexNavigation,
      })}
      toolbar={
        toolbar &&
        React.createElement(toolbar, {
          hasNextStep,
          hasPreviousStep,
          onNextClick: handleNext,
          onPreviousClick: handlePrevious,
          currentStep: currentStep,
        })
      }
    >
      {/* eslint-disable-next-line */}
      <div
        className={classnames("wizard-form", className)}
        onKeyPress={handleKeyPress}
        {...sanitizeRestProps(rest)}
      >
        <CardContentInner>
          {React.cloneElement(steps[currentStep] as React.ReactElement)}
        </CardContentInner>
      </div>
    </WizardForm>
  );
};

const sanitizeRestProps = ({
  onKeyPress,
  ...props
}: any): any => props;