import { HttpError } from 'react-admin';

const createHeadersFromOptions = (options) => {
    const requestHeaders =
        options.headers ||
        new Headers({
            Accept: 'application/json',
        });
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    return requestHeaders;
};

const convertKeyToReadable = (key) =>
    key
        .split('_')
        .map((val) => `${val.charAt(0).toUpperCase()}${val.substring(1)}`)
        .join(' ');

/*
 * @param errors JSON object
 */
export const formatErrors = (errors) => {
    return Object.keys(errors).map((key) => {
        if (
            Array.isArray(errors[key]) &&
            typeof errors[key][0] === 'string' &&
            errors[key][0] !== null
        ) {
            return key !== '__all__'
                ? `${convertKeyToReadable(key)}: ${errors[key].join(', ')}`
                : `${errors[key].join(', ')}`;
        } else if (
            Array.isArray(errors[key]) &&
            typeof errors[key][0] === 'object' &&
            errors[key][0] !== null
        ) {
            let errorStrings = [];
            errors[key].forEach((value, index) => {
                const errorKey = `${key}_${index}`;
                errorStrings = [
                    ...errorStrings,
                    `${convertKeyToReadable(errorKey)}: ${formatErrors(value)}`,
                ];
                return;
            });
            return errorStrings.join('\n ');
        } else if (typeof errors[key] === 'string') {
            return errors[key];
        }
        return 'Unknown Error';
    });
};

const fetchJson = (url, options = {}) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then((response) =>
            response.text().then((text) => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                if (status === 400 || status === 403) {
                    const errors = formatErrors(json);

                    return Promise.reject(
                        new HttpError(
                            errors.join('\n') || (json && json.message) || statusText,
                            status,
                            json
                        )
                    );
                }

                return Promise.reject(
                    new HttpError((json && json.message) || statusText, status, json)
                );
            }
            return Promise.resolve({ status, headers, body, json });
        });
};

export default fetchJson;
