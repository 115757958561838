import { Box, Grid } from '@mui/material';
import { useRecordContext, useRedirect } from 'react-admin';
import React, { useEffect } from 'react';
import { useStep, WizardFormStep } from '../wizard-form';
import OverviewHeader from './OverviewHeader';
import OverviewStepCard from './OverviewStepCard';
import OverviewTable from './OverviewTable';
import PanelLabel from '../../../base/show/PanelLabel';
import VersionShow from '../../../base/show/VersionShow';
import CustomSaveButton from './CustomSaveButton';

const OverviewStep = (props: any) => {
    const {
        state: { step },
    } = useStep();
    const record = useRecordContext();
    const redirect = useRedirect();

    useEffect(() => {
        if (step === 2 && record && record.client_contract?.state === 'reviewed') {
            redirect('show', '/projects/wizard', record.id);
        }
    }, [record, redirect, step]);

    return (
        <WizardFormStep {...props} label="Overview">
            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={9}>
                    <Box p={4}>
                        <OverviewHeader />
                        <OverviewTable />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <OverviewStepCard />
                    <CustomSaveButton />
                </Grid>
                <PanelLabel>Versions</PanelLabel>
                {record && (
                    <VersionShow
                        reference="contracts/projects/versions"
                        label="Versions"
                        record={{ id: record.client_contract?.id }}
                    />
                )}
            </Grid>
        </WizardFormStep>
    );
};

export default OverviewStep;
