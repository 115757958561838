import React from 'react';
import { EditButton, TextField } from 'react-admin';
import SearchFilter from '../base/SearchFilter';
import BaseList from '../base/views/BaseList';
import { DefaultBulkActions } from '../base/actions/DefaultActions';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import ShowElementLink from '../base/buttons/ShowElementLink';

const ClientList = (props) => (
    <BaseList
        {...props}
        filters={<SearchFilter />}
        bulkActionButtons={<DefaultBulkActions forceConfirmation />}
    >
        <EditableDatagrid rowClick="show" actions={<EditButton label="" />}>
            <ShowElementLink source="name" label="Name" obj="users/client" />
            <TextField source="name_short" label="Short Name" />
            <TextField source="addresses[0].city" label="City" />
            <TextField source="addresses[0].country" label="Country" />
        </EditableDatagrid>
    </BaseList>
);

export default ClientList;
