import React from 'react';
import { SaveButton, Toolbar, useRecordContext } from 'react-admin';
import ConfirmDeleteButton from '../buttons/ConfirmDeleteButton';
import { useFormState } from 'react-hook-form';

const SaveToolbar = ({
    classes,
    redirect,
    quickSave = true,
    label = 'Save',
    cancelPath,
    white = false,
    noDelete = false,
    needsConfirmation,
    needsConfirmationToCancel,
    confirmMessage,
    confirmTitle,
    cancelText,
    additionalFunction,
    submitFunction,
    cancelFunction,
    saveDisabled,
    confirmLabel,
    cancelLabel,
    hidden,
    iconless,
    ...props
}) => {
    const record = useRecordContext();
    const { isValid } = useFormState();
    const editing = record && typeof record.id !== 'undefined';
    return (
        <Toolbar {...props}>
            <SaveButton disabled={!isValid} />
            {editing && !noDelete && (
                <ConfirmDeleteButton
                    undoable={false}
                    forceConfirmation={props.forceConfirmation}
                    redirect={redirect}
                />
            )}
        </Toolbar>
    );
};

export default SaveToolbar;
