import * as React from 'react';
import { AutocompleteInput } from 'react-admin';

const AutocompleteCustomInput = ({ filter, ...props }) => (
    <AutocompleteInput
        filterToQuery={(searchText) => ({
            autocomplete: searchText,
            ...filter,
        })}
        shouldRenderSuggestions={(val) => {
            return val.trim().length > 0;
        }}
        {...props}
    />
);

export default AutocompleteCustomInput;
