import React from 'react';
import { apiUrl, httpClient } from '../../dataProvider';
import { Labeled } from 'react-admin';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CKEditorBalloonBlockEditor from './CKEditorBalloonBlockEditor';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

const styles = {
    label: {
        display: 'flex',
    },
};

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    httpClient(`${apiUrl}/contracts/upload`, {
                        method: 'POST',
                        body: file,
                        headers: new Headers({
                            'Content-Type': 'multipart/form-data',
                            'Content-Disposition': `attachment; filename=${file.name}`,
                        }),
                    })
                        .then((res) => {
                            let resData = res.json;
                            resData.default = resData.url;
                            resolve(resData);
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error);
                        });
                })
        );
    }

    abort() {
        // Reject promise returned from upload() method.
    }
}

class CKEditorComponent extends React.Component {
    state = {
        hasChanged: false,
        defaultLoaded: false,
        value: '',
    };

    constructor(props) {
        super(props);

        // State update happens once every (props.dataUpdateDelay)ms to reduce latency on large documents
        this.onChange = this.onChange.bind(this);
        this.debouncedOnChange = _.debounce(
            this.debouncedOnChange.bind(this),
            props.dataUpdateDelay
        );
    }

    UNSAFE_componentWillMount() {
        const { templateParams, placeholder, loadTemplates } = this.props;
        this.setState({
            editor: CKEditorBalloonBlockEditor(templateParams, placeholder, loadTemplates),
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { value } = nextProps;

        this.setState({
            value: value,
        });

        // Commenting this code as it is not working
        // const { hasChanged, defaultLoaded } = this.state;
        // - This code is used in worker notes which has prop defaultValueFromTemplate supplied
        // - But defaultLoaded is always false and never true to pass this condition, it is made true only inside this if
        // if (
        //     defaultValueFromTemplate &&
        //     !hasChanged &&
        //     defaultLoaded &&
        //     defaultLoaded !== defaultValueFromTemplate
        // ) {
        //     dataProvider()(GET_LIST, 'templates', {
        //         filter: { slug: defaultValueFromTemplate },
        //     })
        //         .then((e) => {
        //             this.setState({ value: e.data[0].content });
        //             updateField(e.data[0].content);
        //         })
        //         .catch(() => {
        //             //showNotification('Error: Cannot load default template', 'warning');
        //             console.error('Cannot load default template');
        //         })
        //         .finally(() => this.setState({ defaultLoaded: defaultValueFromTemplate }));
        // }
    }

    onChange(event, editor) {
        this.debouncedOnChange(event, editor);
    }

    debouncedOnChange(event, editor) {
        const { defaultValueFromTemplate, updateField } = this.props;
        const { defaultLoaded, hasChanged } = this.state;
        if (defaultValueFromTemplate && !hasChanged && defaultLoaded === defaultValueFromTemplate) {
            this.setState({ hasChanged: true });
        }
        updateField(editor.data.get() ? editor.data.get() : '<p></p>'); // Ternary prevents defaultValue when deleting everything
    }

    render() {
        const { classes, defaultValue, disabled } = this.props;
        const { editor } = this.state;
        let data = this.state.value;
        if (data === undefined || data === null) {
            data = defaultValue;
            if (defaultValue === undefined || defaultValue === null) {
                data = '';
            }
        }

        return (
            <Labeled className={classes.label} {...this.props}>
                <CKEditor
                    disabled={disabled}
                    data={data}
                    editor={editor}
                    onChange={this.onChange}
                    onReady={(editor) => {
                        editor.plugins.get('FileRepository').createUploadAdapter = function (
                            loader
                        ) {
                            return new UploadAdapter(loader);
                        };
                    }}
                />
            </Labeled>
        );
    }
}

CKEditorComponent.defaultProps = {
    value: null,
    defaultValue: null,
    templateParams: {},
    defaultValueFromTemplate: null,
    dataUpdateDelay: 250,
    loadTemplates: true,
};

export default withStyles(styles)(CKEditorComponent);
