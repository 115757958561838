import React from 'react';
import { Link, useRecordContext } from 'react-admin';

const ShowElementLink = (props) => {
    const record = useRecordContext();

    return (
        <Link to={`/${props.obj}/${record.id}/show`} label={props.label}>
            {record[props.source]}
        </Link>
    )
}

export default ShowElementLink;