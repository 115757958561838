import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import React from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList, useRecordContext, Link } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import HighlightTooltip from '../base/fields/HighlightTooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    avatar: {
        float: 'left',
        marginTop: 2,
        marginRight: 10,
        width: 16,
        height: 16,
    },
    avatarInTooltip: {
        width: 64,
        height: 64,
    },
    avatarInactive: {
        opacity: 0.5,
    },
    tooltip: {
        backgroundColor: 'transparent',
    },
    labelInactive: {
        opacity: 0.5,
    },
    chip: {
        height: '20px',
        fontSize: '0.65rem',
        margin: '2px 0',
        color: 'white !important',  // TODO: ChipField inside anchor is primary text on primary bg
    },
});

const WorkerNameField = ({ showState, ...props }) => {
    const classes = useStyles();
    const record = useRecordContext();
    return record ? (
        <>
            <Tooltip
                className={`${classes.tooltip} ${showState && record.state !== 'accepted' && classes.avatarInactive
                    }`}
                title={record.state_label}
            >
                <Avatar className={classes.avatar} src={record.avatar} />
            </Tooltip>
            <>
                <Typography
                    variant="body2"
                    className={`${showState && record.state !== 'accepted' && classes.labelInactive
                        }`}
                >
                    <Link to={`/users/worker/${record.id}/show`}>{record.label}</Link>
                </Typography>
                {record.highlight ? <HighlightTooltip record={record} /> : null}
            </>
            {props.showTags && (
                <ReferenceArrayField
                    label="Tags"
                    record={record}
                    source="tags"
                    reference="users/workers/tags"
                    {...props}
                    sx={{ paddingTop: '5px' }}
                >
                    <SingleFieldList link={false}>
                        <ChipField source="name" color="primary" className={classes.chip} />
                    </SingleFieldList>
                </ReferenceArrayField>
            )}
        </>
    ) : null;
};

WorkerNameField.defaultProps = {
    showTags: true,
};

export default WorkerNameField;
