import JSONInputComponent from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import React from 'react';
import { useInput } from 'react-admin';

const JSONInput = ({ source, input, ...props }) => {
    const { field } = useInput({
        source,
    });

    return (
        <JSONInputComponent
            {...field}
            locale={locale}
            width="100%"
            id={`json_input`}
            onChange={(data) => field.onChange(data.json)}
        />
    );
};

JSONInput.defaultProps = {
    fullWidth: true,
};

export default JSONInput;
