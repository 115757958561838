import {
    AutocompleteInput,
    AutocompleteArrayInput,
    Filter,
    List,
    NumberField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    TextField,
    TextInput,
    BooleanInput,
    DatagridConfigurable,
    DateField,
    TopToolbar,
    SelectColumnsButton,
    RefreshButton,
    useListContext,
    useResourceDefinition,
    WrapperField,
    FunctionField,
    Button
} from 'react-admin';
import React from 'react';
import { StateExportBulkActions } from '../base/actions/StateExportActions';
import HeadTitle from '../base/HeadTitle';
import PayrollAction from '../base/actions/PayrollAction';
import NumeralField from '../base/fields/NumeralField';
import HtmlField from '../base/show/HtmlField';
import CopyTimelogLinkButton from './fields/CopyTimelogLinkButton';
import BooleanTrueField from '../base/fields/BooleanTrueField';
import { DateInputField } from '../base/input/DateInput';
import AutocompleteCustomInput from '../base/input/AutocompleteCustomInput';
import MoreActionsMenu from '../base/actions/MoreActionsMenu';
import ExportAction from '../base/actions/ExportAction';
import StateUpdateAction from '../base/actions/StateUpdateAction';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    button: {
      textTransform: 'none',
      textDecoration: 'none', 
      '&:hover': {
        textDecoration: 'none',
        background: 'none', 
      },
      cursor: 'pointer', 
      border: 'none', 
      background: 'none', 
      outline: 'none', 
      textAlign: 'left'
    },
  }));

const TimelogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
        <ReferenceInput source="project__id__in" reference="projects" alwaysOn>
            <AutocompleteCustomInput optionText="label" label="Project" />
        </ReferenceInput>
        <ReferenceInput source="worker__id__in" reference="users/worker" alwaysOn>
            <AutocompleteCustomInput optionText="label" label="Worker" />
        </ReferenceInput>
        <ReferenceArrayInput source="state__in" reference="enums/timelog/TimeLogState" alwaysOn>
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="State" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="timelog_type__in"
            reference="enums/timelog/TimeLogType"
            alwaysOn
        >
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="Type" />
        </ReferenceArrayInput>
        <BooleanInput label="Only my projects" source="project__mine" alwaysOn />
        <BooleanInput label="Is payroll relevant" source="is_payroll_relevant" />
        <DateInputField label="Logged After (and including)" source="start_date" />
        <DateInputField label="Logged Before (and including)" source="end_date" />
        <DateInputField
            label="Paid Before (and including)"
            source="paid_date__lte"
            format="yyyy-MM-dd HH:mm:ss"
            options={{
                ampm: false,
                clearable: true,
            }}
        />
        <DateInputField
            label="Paid After (and including)"
            source="paid_date__gte"
            format="yyyy-MM-dd HH:mm:ss"
            options={{
                ampm: false,
                clearable: true,
            }}
        />
        <ReferenceInput source="project__cost_center__id__in" reference="cost-centers">
            <AutocompleteInput optionText="label" label="Cost Center" />
        </ReferenceInput>
        <ReferenceArrayInput source="tag__id__in" reference="timelog-tags">
            <AutocompleteArrayInput
                style={{ minWidth: 100 }}
                optionText="name"
                label="Tags"
                filterToQuery={(text) => ({
                    name__icontains: text,
                })}
            />
        </ReferenceArrayInput>
    </Filter>
);

const TimelogActions = (props) => {
    const { filters } = props;
    const { showFilter, displayedFilters, filterValues } = useListContext();
    const resource = useResourceDefinition();

    return (
        <TopToolbar>
            <SelectColumnsButton />
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <MoreActionsMenu>
                <ExportAction resource={resource.name} filterValues={filterValues} />
                <StateUpdateAction resource={resource.name} filterValues={filterValues} stateResource="enums/timelog/TimeLogState" {...props} />
                <PayrollAction {...props} />
            </MoreActionsMenu>
            <RefreshButton />
        </TopToolbar>
    );
};

const TimelogBulkActions = (props) => (
    <StateExportBulkActions stateResource="enums/timelog/TimeLogState" {...props}>
        <PayrollAction {...props} />
    </StateExportBulkActions>
);

const TimelogList = (props) => {
    const classes = useStyles();

    return (
        <List
            title={<HeadTitle>Timelogs</HeadTitle>}
            actions={<TimelogActions />}
            bulkActionButtons={<TimelogBulkActions forceConfirmation />}
            filters={<TimelogFilter />}
            perPage={25}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}
        >
            <DatagridConfigurable rowClick="show"  omit={['is_payroll_relevant', 'paid_date', 'payment_controlling_ranged', 'created']}>
                <HtmlField source="comment" />
                <ReferenceField label="Worker" reference="users/worker" source="worker">
                    <TextField source="label" />
                </ReferenceField>
                <WrapperField label= "Project" style={{ color: 'primary' }}  >
                    <Grid container direction="column" >
                        <Grid item>
                            <ReferenceField reference="projects" source="project">
                                <TextField source="name_internal" />
                            </ReferenceField>
                        </Grid>
                        <Grid item onClick={e => { e.stopPropagation() }}>
                            <FunctionField render={record =>
                                <Link style={{ textDecoration: 'none'}} 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`https://crm.zoho.eu/crm/org20060819597/tab/Potentials/${record.crm_opportunity_id}`}
                                    >
                                        <Button className={classes.button}>
                                            {record.crm_opportunity_name}
                                        </Button>
                                    
                                </Link>}
                            />
                        </Grid>
                    </Grid>                   
                </WrapperField>
                <ReferenceField
                    label="Type"
                    reference="enums/timelog/TimeLogType"
                    source="timelog_type"
                >
                    <TextField source="label" />
                </ReferenceField>
                <BooleanTrueField source="is_payroll_relevant" sortable={false} />
                <DateField source="created" label="Created Date" />
                <DateField source="date" label="Date" />
                <NumberField source="duration_hours" sortBy="duration_seconds" label="Duration (h)" />
                <DateField source="paid_date" />
                <NumeralField
                    source="payment_controlling_ranged"
                    options={{ style: 'currency', currency: 'EUR' }}
                    label="Estimated Payment"
                />
                <TextField source="state_label" label="State" sortBy="state" />
                <CopyTimelogLinkButton />
            </DatagridConfigurable>
        </List>
    );
};                                

export default TimelogList;
