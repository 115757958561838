import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';
import PublicHolidayForm from './PublicHolidayForm';

const PublicHolidayCreate = (props) => {
    return (
        <BaseCreate {...props}>
            <PublicHolidayForm />
        </BaseCreate>
    );
};

export default PublicHolidayCreate;
