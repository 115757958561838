import TemplateForm from './TemplateForm';
import React from 'react';
import BaseCreate from '../base/views/BaseCreate';

const TemplateCreate = (props) => (
    <BaseCreate {...props} redirect="list">
        <TemplateForm location={props.location} />
    </BaseCreate>
);

export default TemplateCreate;
