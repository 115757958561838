import {
    FormDataConsumer,
    required,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    SimpleForm,
    SelectInput,
} from 'react-admin';
import React from 'react';
import { DateInputField } from '../base/input/DateInput';
import SlugInput from '../base/input/SlugInput.js';
import makeStyles from '@mui/styles/makeStyles';

const maxLength =
    (max, message = 'Too long') =>
    (value) =>
        value && value.length > max ? message : undefined;

const useStyles = makeStyles({
    spacer: {
        marginBottom: '24px',
    },
});

const ProjectBaseForm = (props) => {
    const classes = useStyles();
    return (
        <SimpleForm quickSave={false} toolbarOptions={{ label: 'Continue to offer' }} {...props}>
            <TextInput
                source="name_internal"
                label="Internal Name"
                helperText="Shown in the admin interface"
                validate={[required()]}
            />
            <SlugInput source="slug" field="name_internal" />
            <TextInput
                source="name"
                label="Marketing Name"
                helperText="Shown to members of the talent pool"
                validate={[required()]}
            />
            <TextInput
                source="name_short"
                label="Short Name"
                helperText="Used as a display name in Gitlab etc."
                validate={[required(), maxLength(25)]}
            />
            <SlugInput source="slug_short" field="name_short" />
            <ReferenceInput
                label="Classification"
                source="classification"
                reference="enums/project/ProjectClassification"
            >
                <SelectInput optionText="label" validate={[required()]} />
            </ReferenceInput>
            <DateInputField
                source="start_date"
                label="Start Date"
                validate={required()}
                className={classes.spacer}
            />
            <DateInputField
                source="end_date"
                label="End Date"
                validate={required()}
                className={classes.spacer}
            />
            <ReferenceInput
                source="client_profile"
                reference="users/client"
            >
                <AutocompleteInput optionText="name" label="Client" validate={[required()]} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData &&
                    formData.client_profile && (
                        <ReferenceInput
                            source="parent"
                            reference="projects"
                            allowEmpty
                            filter={{
                                'id!': formData.id,
                                client_profile__id: formData.client_profile,
                            }}
                        >
                            <AutocompleteInput
                                label="Main Project"
                                style={{ minWidth: 100 }}
                                optionText="name_internal"
                                helperText="Set this for follow-up projects"
                                filterToQuery={(searchText) => ({
                                    name_internal__icontains: searchText,
                                    'id!': formData.id,
                                    client_profile__id: formData.client_profile,
                                })}
                            />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    );
};

export default ProjectBaseForm;
