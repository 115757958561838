import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import {
  useGetOne,
  useRecordContext,
  useRefresh,
  useSaveContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import Dialog from "../../../contract/project/components/Dialog";
import { apiUrl, httpClient } from "../../../dataProvider";
import useCustomNotify from "../../../base/useCustomNotify";

type ContractStateConfirmDialogProps = {
  targetState: string;
  disabled: boolean;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  saveAndTransition?: boolean;
  label?: string;
};

const useStyles = makeStyles({
  button: {
    marginLeft: 8,
  },
});

const APPROVED_TEMPLATE = 105;
const FALLBACK_TEMPLATE = 100;

const ContractStateConfirmDialog: React.FC<ContractStateConfirmDialogProps> = ({
  targetState,
  disabled,
  color = "primary",
  variant = "contained",
  saveAndTransition = false,
  label,
}) => {
  const classes = useStyles();
  const { save } = useSaveContext();
  const record = useRecordContext();
  const { getValues } = useFormContext();
  const values = getValues();
  const [updating, setUpdating] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const refreshView = useRefresh();
  const notify = useCustomNotify();
  const { data: checklist, isLoading } = useGetOne("templates", {
    id: targetState === "approved" ? APPROVED_TEMPLATE : FALLBACK_TEMPLATE,
  });

  const requireChecklist =
    targetState === "approved" ||
    targetState === "reviewed" ||
    targetState === "rejected" ||
    targetState === "billed" ||
    targetState === "paid";

  const updateState = () => {
    if (record) {
      setUpdating(true);
      httpClient(
        `${apiUrl}/contracts/projects/${record.client_contract.id}/state/${targetState}`,
        {
          method: "POST",
        }
      )
        .then(() => {
          notify(`State updated to ${targetState}`);
        })
        .catch((e) => {
          notify(`Could not change state: ${e.message}`, { type: "warning" });
        })
        .finally(() => {
          setUpdating(false);
          refreshView();
        });
    }
  };

  const onClick = () => {
    if (["reviewed", "approved"].includes(targetState)) {
      if (!values.main_department) {
        notify(
          'Main Business Unit: This field cannot be blank. Please edit the contract and set the main BU.',
          { type: 'warning' }
        );
        return;
      }
    }
    if (requireChecklist) {
      setDialogOpen(true);
    } else {
      updateState();
    }
  };

  const onDialogSubmit = async () => {
    setDialogOpen(false);
    if (save && saveAndTransition) {
      await save(values);
    }
    updateState();
  };

  return (
    <>
      <Button
        className={classes.button}
        color={color}
        variant={variant}
        onClick={onClick}
        disabled={updating || disabled}
      >
        {label || `Set Contract as ${targetState}`}
      </Button>
      {!isLoading ? (
        <Dialog
          title="Are you sure?"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          description={
            ["approved", "reviewed"].includes(targetState)
              ? checklist?.content.replace(/disabled/g, "") // when content is loaded, for weird reasons the checkboxes are marked as disabled. This is the fix.
              : `Are you sure to set state as ${targetState}?`
          }
          confirmLabel="Submit"
          onConfirm={onDialogSubmit}
        />
      ) : null}
    </>
  );
};

export default ContractStateConfirmDialog;
