import React from 'react';
import WorkloadGraph from '../../base/workload/graph/WorkloadGraph';
import { useWorkloadState } from '../../base/workload/WorkloadContext';

const ProjectWorkloadGraph = ({ defaultHideDetails }) => {
    const { data: plannedData  } = useWorkloadState()
    
    return (
        <WorkloadGraph
            isProject
            plannedData={plannedData.sort((a, b) => a.worker - b.worker)}
            defaultHideDetails={defaultHideDetails}
        />
    );
};

export default ProjectWorkloadGraph;
