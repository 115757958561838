import React from 'react';
import PropTypes from 'prop-types';
import TrueIcon from '@mui/icons-material/Done';
import { useRecordContext } from 'react-admin';

const BooleanTrueField = ({ source, condition }) => {
    const record = useRecordContext();
    const isFieldTrue = condition
        ? record && record[source] === condition
        : record && record[source];

    return <div>{isFieldTrue && <TrueIcon />}</div>;
};

BooleanTrueField.propTypes = {
    record: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
};

export default BooleanTrueField;
