import React from 'react';
import { Datagrid, TextField, UrlField, DateField, ReferenceField } from 'react-admin';
import BaseList from '../base/views/BaseList';
import HeadTitle from '../base/HeadTitle';

const WebhookList = (props) => {
    return (
        <BaseList {...props} title={<HeadTitle>Webhooks List</HeadTitle>}>
            <Datagrid rowClick="edit">
                <TextField source="event" />
                <UrlField source="target" />
                <DateField source="created" />
                <ReferenceField reference="users" source="user">
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </BaseList>
    );
};

export default WebhookList;
