import {
    TextInput,
    SimpleForm,
    BooleanInput,
    required,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
} from 'react-admin';
import React from 'react';
import AutocompleteCustomArrayInput from '../../base/input/AutocompleteCustomArrayInput';

const CostCenterForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" label="Full Name" validate={[required()]} />
        <TextInput source="public_id" label="Bookkeeping ID" validate={[required()]} />
        <ReferenceArrayInput source="projects" reference="projects">
            <AutocompleteCustomArrayInput
                label="Projects"
                style={{ minWidth: 100 }}
                optionText="label"
                helperText="Only projects without existing contracts are shown"
                validate={[required()]}
            />
        </ReferenceArrayInput>
        <ReferenceInput
            source="category"
            reference="enums/payment/CostCenterCategory"
            validate={[required()]}
        >
            <SelectInput label="Category" style={{ minWidth: 100 }} optionText="label" />
        </ReferenceInput>
        <BooleanInput source="internal" label="Internal" />
    </SimpleForm>
);

export default CostCenterForm;
