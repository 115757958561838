import React, { Fragment } from 'react';
import CKEditorInput from '../base/ckeditor/CKEditorInput';
import {
    FormDataConsumer,
    required,
    AutocompleteInput,
    ReferenceInput,
    TextInput,
    SimpleForm,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import JSONInput from '../base/input/JSONInput';
import SlugInput from '../base/input/SlugInput';

const useStyles = makeStyles({
    hint: {
        fontSize: 10,
        color: '#999',
        padding: 5,
        '& pre': {
            display: 'inline-block',
            padding: 5,
            background: '#efefef',
        },
    },
});

const TemplateForm = (props) => {
    const classes = useStyles();
    const updateDataSubject = (formData, value) => {
        let jsonData = {};
        try {
            jsonData = JSON.parse(formData.data);
        } catch (e) {
            console.error('cannot parse json data');
        }
        jsonData.subject = value;
        return JSON.stringify(jsonData);
    };

    const format = (v) => {
        try {
            return JSON.parse(v).subject;
        } catch (e) {
            return '';
        }
    };

    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Name" validate={[required()]} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.id ? (
                        <TextInput disabled source="slug" />
                    ) : (
                        <SlugInput source="slug" field="name" />
                    );
                }}
            </FormDataConsumer>

            <ReferenceInput source="type" reference="templates/types">
                <AutocompleteInput label="Type" optionText="label" sx={{ minWidth: 245 }} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.type !== 'template.WorkerMailtoTemplate' ? (
                        <Fragment>
                            <CKEditorInput
                                source="content"
                                label="Content"
                                validate={[required()]}
                            />
                            <JSONInput source="data" label="Data" />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <p className={classes.hint}>
                                Hint: You can use Subject and content with placeholders and then use
                                these MailTo template inside worker profile page to get prefilled
                                email.<br></br>
                                All the worker fields are availabe. Here is an example:<br></br>
                            </p>
                            <pre>
                                Dear &#123;&#123;worker.full_name&#125;&#125;. This is your email:
                                &#123;&#123;worker.user.email&#125;&#125;. <br></br>
                            </pre>
                            <TextInput
                                source="data"
                                label="Subject"
                                format={format}
                                parse={(value) => updateDataSubject(formData, value)}
                            />
                            <br></br>
                            <TextInput
                                multiline
                                fullWidth
                                source="content"
                                label="Content"
                                validate={[required()]}
                            />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    );
};

export default TemplateForm;
