import React from 'react';
import {
    AutocompleteArrayInput,
    FileInput,
    FormTab,
    ReferenceArrayInput,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import TabbedSaveForm from '../base/forms/TabbedSaveForm';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import FileFieldInput from '../base/input/FileFieldInput';

export const WORKER_TYPES = [
    { id: 'Student', name: 'Student' },
    { id: 'Professional/Graduate', name: 'Professional/Graduate' },
    { id: 'Freelancer', name: 'Freelancer' },
    { id: 'Core', name: 'Core' },
];

const UserEmailForm = (props) => {
    return (
        <RowForm {...props}>
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="motius_email" resettable parse={(x) => (x ? x : null)} />
        </RowForm>
    );
};

const WorkerForm = (props) => {
    return (
        <TabbedSaveForm {...props}>
            <FormTab label="Information">
                <ReferenceManyField
                    reference="users"
                    label="Email Addresses"
                    target="worker_profile"
                >
                    <EditableDatagrid
                        noDelete
                        editForm={<UserEmailForm />}
                        bulkActionButtons={false}
                    >
                        <TextField source="email" />
                        <TextField source="motius_email" />
                    </EditableDatagrid>
                </ReferenceManyField>
                <ReferenceArrayInput source="tags" reference="users/workers/tags">
                    <AutocompleteArrayInput
                        label="Tags"
                        style={{ minWidth: 100 }}
                        optionText="name"
                    />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="clusters" reference="clusters">
                    <AutocompleteArrayInput label="Clusters" optionText="name" />
                </ReferenceArrayInput>
                <TextInput source="phone" />
                <ReferenceInput source="work_location" reference="enums/worker/WorkLocation">
                    <SelectInput label="Office Location" optionText="label" />
                </ReferenceInput>
                <FileInput label="CV" source="cv" multiple={false}>
                    <FileFieldInput />
                </FileInput>
                <FileInput label="Picture" source="picture" multiple={false}>
                    <FileFieldInput />
                </FileInput>
                <SelectInput
                    source="work_mode"
                    label="Work Mode"
                    choices={WORKER_TYPES}
                    optionText="id"
                />
            </FormTab>
        </TabbedSaveForm>
    );
};

export default WorkerForm;
