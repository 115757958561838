import aadAuthProvider from './aadAuthProvider';

// We used to have multiple providers. Keeping this in case we need to add more
const authProviders = {
    aad: aadAuthProvider,
};

export const authProviderType = 'aad';
export const ADMIN_ROLE = 'admin';

export default authProviders.aad;
