import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

type OverviewHeaderValueProps = {
    label: string
    value: string | number | React.ReactElement
}

const useStyles = makeStyles({
    label: {
        color: "#7A98A4"
    },
    valueField: {
        marginLeft: 4
    }
})

const OverviewHeaderValue: React.FC<OverviewHeaderValueProps> = ({label, value}) => {
    const classes = useStyles()
    return (
        <>
            <Typography className={classes.label} component="span">{label}:</Typography>
            <Typography className={classes.valueField} variant="subtitle2" component="span">
                {value}
            </Typography>
        </>
    );
};

export default OverviewHeaderValue;
