import { Link, TextField, useRecordContext } from 'react-admin';
import React from 'react';

export const linkFilter = (record, filterName, filterValue) => {
    // TODO: Replace this with the built-in way of modifying filters in react-admin
    let searchParams = new URLSearchParams(window.location.hash.split('?').slice(-1)[0]);
    let filters = JSON.parse(searchParams.get('filter')) || {};
    filters[filterName] = filterValue;
    searchParams.set('filter', JSON.stringify(filters));
    searchParams.set('page', '1');
    return searchParams.toString();
};

const TextFilterLinkField = ({ filterName, filterSource = 'id', multiple = false, ...props }) => {
    const record = useRecordContext();
    const filterValue = record && (multiple ? [record[filterSource]] : record[filterSource]);

    return (
        <Link
            to={`?${linkFilter(record, filterName, filterValue)}`}
            onClick={(e) => e.stopPropagation()}
        >
            <TextField color="primary" {...props} />
        </Link>
    );
};

export default TextFilterLinkField;
