import React from 'react';
import WorkloadTable from '../../base/workload/table/WorkloadTable';
import { useWorkloadState } from '../../base/workload/WorkloadContext';

const ProjectWorkloadTable = (props) => {
    const data = useWorkloadState(state => state.data)
    return <WorkloadTable {...props} data={data} />;
};

export default ProjectWorkloadTable;
