import { Button } from '@mui/material';
import { useGetOne, useSaveContext } from 'react-admin';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useStep } from '../wizard-form';
import useCustomNotify from '../../../base/useCustomNotify';
import Dialog from '../../../contract/project/components/Dialog';
import { useFormState, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Save } from '@mui/icons-material';

const useStyles = makeStyles({
    spaceRight: {
        marginTop: '0.7rem !important',
    },
});

const CREATE_STEP_MAPPING = ['type', 'project', 'contract', 'overview'];

const CustomSaveButton = (props: any) => {
    const {
        state: { step },
    } = useStep();

    const { isValid, dirtyFields } = useFormState();
    const classes = useStyles();
    const notify = useCustomNotify();
    const [dialogOpen, setDialogOpen] = useState(false);
    const templateId = 100;
    const { data } = useGetOne('templates', { id: templateId });
    const { getValues } = useFormContext();
    const location = useLocation();
    const values = getValues();
    const { saving } = useSaveContext();
    const isCreate = location.pathname.includes('create');


    const isExtensionProjectStep =
        isCreate && values.project_type === 'extension' && CREATE_STEP_MAPPING[step] === 'project';
    const isNormalContractStep =
        isCreate && values.project_type !== 'extension' && CREATE_STEP_MAPPING[step] === 'contract';

    const isCurrentStepDirty: boolean =
        Object.keys(dirtyFields['client_contract'] ?? {}).length > 0;

    const showSaveButton =
        isExtensionProjectStep || isNormalContractStep || (!isCreate && isCurrentStepDirty);

    const requireConfirm =
        values.client_contract?.state === 'draft' && values.client_contract?.technical_reviewer;

    const validateSave = () => {
        if (
            values.client_contract?.state === 'draft' &&
            values.client_contract?.technical_reviewer &&
            !values.main_department
        ) {
            notify(
                'Main Business Unit: This field cannot be blank. Please set the main BU in the previous contract step.',
                { type: 'warning' }
            );
            return;
        }
        if (requireConfirm) {
            setDialogOpen(true);
        } else {
            forceSave();
        }
    };

    const onDialogSubmit = () => {
        setDialogOpen(false);
        forceSave();
    };

    const forceSave = () => {
        const saveButton = document.getElementById('my-save');
        saveButton?.click();
    };

    return (
        <>
            {showSaveButton ? <Button
                className={classes.spaceRight}
                disabled={!isValid || saving}
                startIcon={<Save />}
                color="primary"
                variant="contained"
                onClick={validateSave}
            >
                {saving ? 'Saving ...' : 'Save'}
            </Button> : null}
            {requireConfirm ? (
                <Dialog
                    title="Send contract to review"
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    description={data ? data.content.replace(/disabled/g, '') : ''}
                    confirmLabel={'Send to review'}
                    onConfirm={onDialogSubmit}
                />
            ) : null}
        </>

    )



}

export default CustomSaveButton;