import { DateTime } from 'luxon';
import { useState } from 'react';
import { RowData, WorkerAvailability } from './WorkloadContext';

/**
 * Sorts invitations with planned hours to alphabetic order.
 *
 * @param row1
 * @param row2
 * @returns {number}
 */
export const sortRowsByName = (row1: RowData, row2: RowData) => (row1.project_name.toLowerCase() > row2.project_name.toLowerCase() ? 1 : -1);

/**
 * Returns the planned hours for the given date if it exists, else 0.
 *
 * @param row Invitation Data
 * @param {DateTime} dateTime Luxon DateTime object for the wanted date
 * @returns {number} Planned Hours
 */
export const getPlannedWeeklyHoursByDate = (row: RowData, dateTime: DateTime) => {
    const item = row.planned_hours.find((p) => p.start_date === dateTime.toISODate());
    return Number(item?.weekly_hours || 0);
};

/**
 * Returns the planned hours for the given week if it exists, else 0.
 *
 * @param row Invitation Data
 * @param {DateTime} date DateTime.
 * @returns {number} Planned Hours
 */
export const getPlannedWeeklyHoursByDateTime = (row: RowData, date: DateTime) => {
    const dateTime = date.startOf('week');
    return getPlannedWeeklyHoursByDate(row, dateTime);
};

/**
 *
 * @param availability Availability array of all workers
 * @param {number} workerId workerId
 * @param {DateTime} date DateTime of the week
 * @returns {number} Weekly Availability
 */
export const getWeeklyAvailabilityByDate = (availability: WorkerAvailability[], workerId: number, date: DateTime) => {
    const obj = availability.find((a) => a.id === workerId);
    const weeklyHours =
        obj?.availability.find((a) => DateTime.fromISO(a.start_date)?.hasSame(date, 'week'))
            ?.weekly_hours || 0;
    const plannedHours =
        obj?.planned.find((a) => DateTime.fromISO(a.start_date)?.hasSame(date, 'week'))
            ?.total_planned || 0;
    const weeklyAbsence = Number(obj?.absences.find((a) => a.week === date.weekNumber)?.hours || 0);

    return weeklyHours - plannedHours - weeklyAbsence;
};

export const getWeeklyAvailabilityByDateTime = (availability: WorkerAvailability[], workerId: number, date: DateTime) => {
    const dateTime = date.startOf('week');
    return getWeeklyAvailabilityByDate(availability, workerId, dateTime);
};

/**
 * Creates the payload to send to the backend.
 * It also adds changed boolean to indicated this is a changed value.
 *
 * @param {number | string} id Invitation ID
 * @param {number | string} worker Worker ID
 * @param {number | string} project Project ID
 * @param {number | string} weekly_hours Planned hours
 * @param {number} weekNumber Week number to change
 * @returns Payload to send to the backend
 */
export const createPlannedHoursData = (id: number | string, worker: number | string, project: number | string, weekly_hours: number | string, weekNumber: number, year: number ) => {
    return {
        id: Number(id),
        worker: Number(worker),
        project: Number(project),
        weekly_hours: Number(weekly_hours),
        start_date: DateTime.local().set({ year }).set({ weekNumber }).startOf('week').toISODate(),
        end_date: DateTime.local().set({ year }).set({ weekNumber }).endOf('week').toISODate(),
    };
};

/**
 *
 * @param filterValues: from list context
 * @return {{start: DateTime, end: DateTime}}
 */
export const getWeekRangeFromFilterValues = (filterValues: any) => ({
    start: DateTime.fromISO(filterValues?.start_date),
    end: DateTime.fromISO(filterValues?.end_date),
});

/**
 *
 * @param date {DateTime}
 * @param projectEndDate {string}
 * @return {boolean}
 */
export const isPastEndDate = (date: DateTime, projectEndDate: string) => date > DateTime.fromISO(projectEndDate);

/**
 *
 * @param date {DateTime}
 * @return {boolean}
 */
export const isPastWeek = (date: DateTime) => date < DateTime.local().startOf('week');

/**
 *
 * @param date {DateTime}
 * @return {boolean}
 */
export const isFuture = (date: DateTime) => DateTime.local().startOf('week') < date;

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const useWorkloadDialogState = () => {
    const [isOpen, setOpen] = useState(false)
    const [selectedWeeklyHours, setSelectedWeeklyHours] = useState<number>()
    const [selectedStartDate, setSelectedStartDate] = useState<string>(DateTime.local().toISODate())

    const openDialog = (startDate: string, weeklyHours?: number) => {
        setOpen(true)
        setSelectedWeeklyHours(weeklyHours)
        setSelectedStartDate(startDate)
    }

    const closeDialog = () => {
        setOpen(false)
        setSelectedWeeklyHours(undefined)
    }

    return {isOpen, selectedWeeklyHours, selectedStartDate, openDialog, closeDialog}
}