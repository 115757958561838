import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';
import TimelogTagForm from './TimelogTagForm.js';

const TimelogTagEdit = (props) => {
    return (
        <BaseEdit {...props} redirect="list">
            <TimelogTagForm />
        </BaseEdit>
    );
};

export default TimelogTagEdit;
