import DocumentationForm from './DocumentationForm';
import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';

const DocumentationCreate = (props) => (
    <BaseCreate {...props}>
        <DocumentationForm location={props.location} />
    </BaseCreate>
);

export default DocumentationCreate;
