import InvoiceForm from './InvoiceForm';
import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';

const InvoiceCreate = (props) => (
    <BaseCreate {...props}>
        <InvoiceForm redirect="show" location={props.location} />
    </BaseCreate>
);

export default InvoiceCreate;
