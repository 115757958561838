import React from 'react';
import * as PropTypes from 'prop-types';
import useGetResource from '../../../../hooks/useGetResource';
import SpaceConnectDialogContent from './SpaceConnectDialogContent';
import SpaceCreateDialogContent from './SpaceCreateDialogContent';
import AtlassianDialogContent from '../AtlassianDialogContent';

const ConfluenceDialogContent = ({ platformProject, closeDialog }) => {
    const { data: confluenceSpaces, loading } = useGetResource(
        '/confluence-spaces/confluence',
        {},
        'Error loading confluence spaces.'
    );

    return (
        <AtlassianDialogContent
            loading={loading}
            platformProject={platformProject}
            closeDialog={closeDialog}
            connectContent={
                <SpaceConnectDialogContent
                    confluenceSpaces={confluenceSpaces}
                    platformProject={platformProject}
                    onFinished={closeDialog}
                ></SpaceConnectDialogContent>
            }
            createContent={
                <SpaceCreateDialogContent
                    confluenceSpaces={confluenceSpaces}
                    platformProject={platformProject}
                    onFinished={closeDialog}
                ></SpaceCreateDialogContent>
            }
        />
    );
};

ConfluenceDialogContent.propTypes = {
    platformProject: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

export default ConfluenceDialogContent;
