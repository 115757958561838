import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import dataProvider from '../../../dataProvider';
import { GET_LIST } from 'react-admin';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor';

const InsertTemplatePlugin = (templateParams, loadTemplates) => {
    class InsertTemplate extends Plugin {
        init() {
            const editor = this.editor;
            const items = new Collection();

            editor.ui.componentFactory.add('insertTemplate', (locale) => {
                const dropdownView = createDropdown(locale);

                dropdownView.buttonView.set({
                    withText: true,
                    label: 'Template',
                });

                if (loadTemplates) {
                    dataProvider()(GET_LIST, 'templates', templateParams)
                        .then((e) => {
                            e.data.map((item) =>
                                items.add({
                                    type: 'button',
                                    model: new Model({
                                        withText: true,
                                        label: item.name,
                                        item: item,
                                    }),
                                })
                            );
                            addListToDropdown(dropdownView, items);
                        })
                        .catch((e) => {
                            console.error('Cannot load default template');
                        });

                    dropdownView.on('execute', (e) => {
                        const htmlDP = new HtmlDataProcessor(editor.data);
                        const viewFragment = htmlDP.toView(e.source.item.content);
                        const modelFragment = editor.data.toModel(viewFragment);
                        editor.model.insertContent(modelFragment);
                    });
                }

                return dropdownView;
            });
        }
    }
    return InsertTemplate;
};

export default InsertTemplatePlugin;
