import React from 'react';
import { Button, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { ReactComponent as ForwardOne } from './assets/forward_1.svg';
import { ReactComponent as ForwardTen } from './assets/forward_10.svg';
import { ReactComponent as BackOne } from './assets/replay_1.svg';
import { ReactComponent as BackTen } from './assets/replay_10.svg';
import CalendarToday from '@mui/icons-material/Today';
import ChartIcon from '@mui/icons-material/ShowChartSharp';
import GridIcon from '@mui/icons-material/GridOn';
import useWorkloadStyles from './useWorkloadStyles';
import { DateTime } from 'luxon';
import { useStore, useListContext } from 'react-admin';
import { getWeekRangeFromFilterValues } from './workloadUtils';
import { useWorkloadState } from './WorkloadContext';
import { getDefaultWeekRange } from './Workload';
import { useNavigate } from 'react-router-dom';
import { Forward5, Replay5 } from '@mui/icons-material';

const WorkloadActions = () => {
    const classes = useWorkloadStyles();
    const [mode, setMode] = useStore('workload.mode', 'graph');
    const { isProject } = useWorkloadState();
    const { setFilters, filterValues } = useListContext();
    const navigate = useNavigate();
    const weekRange = getWeekRangeFromFilterValues(filterValues);
    const [weekCount] = useStore('workload.weekCount', 10);

    const setFiltersWithLocation: typeof setFilters = (filters, displayedFilters) => {
        navigate({
            search: new URLSearchParams(filters).toString(),
        });
        setFilters(filters, displayedFilters);
    };

    const shiftTimeWindowByWeek = (shift: number) => {
        setFiltersWithLocation(
            {
                ...filterValues,
                start_date: weekRange.start.plus({ week: shift }).toISODate(),
                end_date: weekRange.end.plus({ week: shift }).toISODate(),
            },
            null
        );
    };

    const navigateToToday = () => {
        setFiltersWithLocation(
            {
                ...filterValues,
                ...getDefaultWeekRange(weekCount),
            },
            null
        );
    };

    //filterValues from useListContext are sometimes boolean values and few other times they are string,
    //hence using String function in comparing the values
    const toggleShowUpcoming = () => {
        setFiltersWithLocation(
            {
                ...filterValues,
                upcoming: (String(filterValues.upcoming) !== 'true'),
            },
            null
        );
    };

    const toggleShowOutboarded = () => {
        setFiltersWithLocation(
            {
                ...filterValues,
                outboarded: (String(filterValues.outboarded) !== 'true'),
            },
            null
        );
    };

    const toggleHideTracked = () => {
        setFiltersWithLocation(
            {
                ...filterValues,
                hide_tracked: (String(filterValues.hide_tracked) !== 'true'),
            },
            null
        );
    };

    const toggleShowNonBillable = () => {
        // Filter for billable or non billable project invitations. This filter has no effect in project view
        if (!isProject) {
            setFiltersWithLocation(
                {
                    ...filterValues,
                    non_billable: (String(filterValues.non_billable) !== 'true'),
                },
                null
            );
        }
    };

    return (
        <div className={classes.titleCell}>
            <div className={classes.dateNav}>
                <Button startIcon={<CalendarToday />} color="primary" onClick={navigateToToday}>
                    Today
                </Button>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(-1 * weekCount)}
                    size="large"
                >
                    <BackTen className={classes.icon} />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(-5)}
                    size="large"
                >
                    <Replay5 className={classes.icon} />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(-1)}
                    size="large"
                >
                    <BackOne className={classes.icon} />
                </IconButton>
                <Typography variant="subtitle2">
                    {weekRange.start.toLocaleString(DateTime.DATE_MED)} -{' '}
                    {weekRange.end.endOf('week').toLocaleString(DateTime.DATE_MED)}
                </Typography>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(1)}
                    size="large"
                >
                    <ForwardOne className={classes.icon} />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(5)}
                    size="large"
                >
                    <Forward5 className={classes.icon} />
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={() => shiftTimeWindowByWeek(weekCount)}
                    size="large"
                >
                    <ForwardTen className={classes.icon} />
                </IconButton>
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={String(filterValues.upcoming) === 'true'}
                            onClick={toggleShowUpcoming}
                        />
                    }
                    label={isProject ? 'Show pre-selected' : 'Show upcoming projects'}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={String(filterValues.outboarded) === 'true'}
                            onClick={toggleShowOutboarded}
                        />
                    }
                    label="Show outboarded"
                />
                {!isProject && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={(String(filterValues.non_billable) !== 'true')}
                                onClick={toggleShowNonBillable}
                            />
                        }
                        label="Hide non-billable"
                    />
                )}

                <FormControlLabel
                    control={
                        <Switch
                            checked={String(filterValues.hide_tracked) === 'true'}
                            onClick={toggleHideTracked}
                        />
                    }
                    label="Hide Tracked"
                />

                {mode === 'table' ? (
                    <Button
                        startIcon={<ChartIcon />}
                        color={'primary'}
                        onClick={() => setMode('graph')}
                    >
                        Switch to Graph View
                    </Button>
                ) : (
                    <Button
                        startIcon={<GridIcon />}
                        color={'primary'}
                        onClick={() => setMode('table')}
                    >
                        Switch to Table View
                    </Button>
                )}
            </div>
        </div>
    );
};

export default WorkloadActions;
