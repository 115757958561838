import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import OpenInNew from '@mui/icons-material/OpenInNew';

const OpenJsonDataButton = ({ label, encoding, source }) => {
    const record = useRecordContext();
    const data = record && record[source];

    const onClick = () => {
        const string = `${encoding},${encodeURIComponent(
            JSON.stringify(JSON.parse(data), null, 2)
        )}`;
        const iframe =
            "<iframe style='border: none;' width='100%' height='100%' src='" +
            string +
            "'></iframe>";
        const x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
    };

    return <Button color="primary" label={label} onClick={onClick} children={<OpenInNew />} />;
};

export default OpenJsonDataButton;
