import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Breadcrumb, BreadcrumbItem, ResourceBreadcrumbItems } from '@react-admin/ra-navigation';
import { useResourceDefinitions, linkToRecord } from 'react-admin';
import { useLocation } from 'react-router-dom';

const useBreadcrumbStyles = makeStyles({
    root: {
        margin: '1rem 0',
    },
});

const CUSTOM_BREADCRUMB_LOCATIONS = [
    'projects',
    'users/worker',
    'users/client',
    'users/positions',
    'clusters',
    'payment/invoices',
    'projects/role',
    'templates',
    'projects/wizard',
    'users/workers/notes',
    'projects/documentation',
];

const CustomBreadcrumbResource = ({ name, label, nameSource, location = null }) => {
    const link = name === 'projects/wizard' ? 'contracts/projects' : name;

    return (
        <BreadcrumbItem
            name={name}
            label={label}
            to={({ record }) => {
                if (name === 'users/workers/notes') {
                    if (record) return `users/worker/${record.worker}/show/notes`;
                    else {
                        const searchParams = new URLSearchParams(location?.search);
                        const worker = searchParams.get('worker');
                        return `users/worker/${worker}/show/notes`;
                    }
                } else if (name === 'projects/documentation') {
                    if (record) return `projects/${record.project}/show`;
                    else {
                        const searchParams = new URLSearchParams(location?.search);
                        const project = searchParams.get('project');
                        return `projects/${project}/show`;
                    }
                }
                return `/${link}`;
            }}
        >
            <BreadcrumbItem
                name="edit"
                label={({ record }) => record && `Edit "${record[nameSource]}"`}
                to={({ record }) => {
                    if (record) {
                        const res = `/${link}`;
                        return `${linkToRecord(res, record.id)}/edit`;
                    }
                }}
            />
            <BreadcrumbItem
                name="show"
                label={({ record }) => record && record[nameSource]}
                to={({ record }) => {
                    if (record) {
                        const res = `/${link}`;
                        return `${linkToRecord(res, record.id)}/show`;
                    }
                }}
            />
            <BreadcrumbItem name="list" label={label} />
            <BreadcrumbItem name="create" label="Create" />
        </BreadcrumbItem>
    );
};

const CustomBreadcrumbContent = (props) => {
    // Can't find a better way to get all resource names, this is how react-admin does
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
    const location = useLocation();
    return (
        <>
            <ResourceBreadcrumbItems
                {...props}
                resources={resources
                    .map((r) => r.name)
                    .filter((r) => !CUSTOM_BREADCRUMB_LOCATIONS.includes(r))}
            />
            <CustomBreadcrumbResource label="Projects" name="projects" nameSource="name_internal" />
            <CustomBreadcrumbResource label="Contracts" name="projects/wizard" nameSource="name" />
            <CustomBreadcrumbResource label="Workers" name="users/worker" nameSource="label" />
            <CustomBreadcrumbResource label="Clusters" name="clusters" nameSource="name" />
            <CustomBreadcrumbResource label="Clients" name="users/client" nameSource="name" />
            <CustomBreadcrumbResource
                label="Invoices"
                name="payment/invoices"
                nameSource="public_id"
            />
            <CustomBreadcrumbResource label="Role" name="projects/role" nameSource="name" />
            <CustomBreadcrumbResource label="Positions" name="users/positions" nameSource="name" />
            <CustomBreadcrumbResource label="Templates" name="templates" nameSource="name" />
            <CustomBreadcrumbResource
                label="Worker Notes"
                name="users/workers/notes"
                nameSource="label"
                location={location}
            />
            <CustomBreadcrumbResource
                label="Project Documentation"
                name="projects/documentation"
                nameSource="label"
                location={location}
            />
        </>
    );
};

export const CustomBreadcrumb = (props) => {
    const classes = useBreadcrumbStyles();
    return (
        <Breadcrumb {...props} classes={classes} dashboard={false}>
            <CustomBreadcrumbContent />
        </Breadcrumb>
    );
};
