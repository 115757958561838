/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useListContext, useStore } from 'react-admin';
import { DateTime } from 'luxon';
import React from 'react';
import { useFetchWorkloadData } from './useFetchWorkloadData';
import useWorkloadStyles from './useWorkloadStyles';
import WorkloadActions from './WorkloadActions';
import WorkloadTableHead from './WorkloadTableHead';

type WorkloadProps = {
    tableView: React.ReactNode;
    graphView: React.ReactNode;
    data: Object;
    loading: boolean;
    disabled: boolean;
    defaultHideDetails?: boolean;
    isProject?: boolean;
};

export const getDefaultWeekRange = (weekCount: number) => ({
    start_date: DateTime.local().minus({ week: 4 }).startOf('week').toISODate(),
    end_date: DateTime.local()
        .plus({ week: weekCount - 5 })
        .startOf('week')
        .toISODate(),
});

const ViewContent = ({ view, ...props }: any) => {
    return React.cloneElement(view, props);
};

const Workload: React.FC<WorkloadProps> = ({
    tableView,
    graphView,
    disabled,
    isProject = false,
}) => {
    const { data: result, isLoading } = useListContext();
    const classes = useWorkloadStyles();
    const [mode] = useStore('workload.mode', 'graph');
    const [weekCount] = useStore('workload.weekCount', 10);
    const { data, refetchAll } = useFetchWorkloadData(result, isProject);

    return (
        <div className={classes.root}>
            <WorkloadActions />

            <form
                key={1}
                style={{ maxHeight: 'calc(100vh - 180px)', overflow: 'auto' }}
            >
                <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                    <WorkloadTableHead />
                    <TableBody>
                        {isLoading && data.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={weekCount + 3}>
                                    <LinearProgress
                                        className={classes.loading}
                                        variant="indeterminate"
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <ViewContent
                                view={mode === 'table' ? tableView : graphView}
                                refetch={refetchAll}
                                disabled={disabled}
                            />
                        )}
                    </TableBody>
                </Table>
            </form>
        </div>
    );
};
export default Workload;
