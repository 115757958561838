import React from 'react';
import {
    AutocompleteArrayInput,
    ReferenceArrayInput,
    TextInput,
    SimpleForm,
    ReferenceManyField,
    required,
    useRecordContext,
    Labeled,
} from 'react-admin';
import OverheadForm from './overheadFactor/OverheadBillableForm';
import OverheadEditableGrid from './overheadFactor/OverheadEditableGrid';
import UtilizationEditableGrid from './utilizationTarget/UtilizationEditableGrid';
import UtilizationForm from './utilizationTarget/UtilizationForm';
import WeeklyBillableEditableGrid from './weeklyTargetHours/WeeklyBillableEditableGrid';
import WeeklyBillableForm from './weeklyTargetHours/WeeklyBillableForm';

const PositionForm = (props) => {
    const record = useRecordContext();
    return (
        <SimpleForm {...props}>
            <TextInput label="Name" source="name" validate={[required()]} />
            <TextInput label="Description" source="description" />
            <ReferenceArrayInput
                label="Groups"
                source="groups"
                reference="users/group"
                validate={[required()]}
            >
                <AutocompleteArrayInput
                    style={{ minWidth: 200 }}
                    optionText="name"
                    filterToQuery={(text) => ({ name__icontains: text })}
                />
            </ReferenceArrayInput>
            {record && record.id && (
                <>
                    <ReferenceManyField reference="users/utilization-target" target="position">
                        <Labeled label="Utilization Targets">
                            <UtilizationEditableGrid
                                bulkActionButtons={false}
                                editForm={<UtilizationForm />}
                                createForm={
                                    <UtilizationForm defaultValues={{ position: record.id }} />
                                }
                            />
                        </Labeled>
                    </ReferenceManyField>
                    <ReferenceManyField reference="users/weekly-billable-target" target="position">
                        <Labeled label="Weekly Billable Hour Targets" sx={{ marginTop: '20px' }}>
                            <WeeklyBillableEditableGrid
                                bulkActionButtons={false}
                                editForm={<WeeklyBillableForm />}
                                createForm={
                                    <WeeklyBillableForm defaultValues={{ position: record.id }} />
                                }
                            />
                        </Labeled>
                    </ReferenceManyField>
                    <ReferenceManyField reference="users/overhead-factor" target="position">
                        <Labeled label="Overhead Factors" sx={{ marginTop: '20px' }}>
                            <OverheadEditableGrid
                                bulkActionButtons={false}
                                editForm={<OverheadForm />}
                                createForm={
                                    <OverheadForm defaultValues={{ position: record.id }} />
                                }
                            />
                        </Labeled>
                    </ReferenceManyField>
                </>
            )}
        </SimpleForm>
    );
};
export default PositionForm;
