import React from 'react';
import onlyAdminViews from '../../auth/onlyAdminViews';

import Create from './WorkerDepartmentCreate';
import Edit from './WorkerDepartmentEdit';
import List from './WorkerDepartmentList';

const resourceName = 'Worker Department';
const resourceNamePlural = 'Worker Departments';

const workerDepartments = (permissions) =>
    onlyAdminViews(permissions, {
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default workerDepartments;
