import React from 'react';
import onlyAdminViews from '../../auth/onlyAdminViews';

import Create from './TimelogTagCreate';
import Edit from './TimelogTagEdit';
import List from './TimelogTagList';

const resourceName = 'Timelog Tag';
const resourceNamePlural = 'TimelogTags';

const timelogTags = (permissions) =>
    onlyAdminViews(permissions, {
        list: (props) => (
            <List resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
        create: (props) => (
            <Create
                resourceName={resourceName}
                resourceNamePlural={resourceNamePlural}
                {...props}
            />
        ),
        edit: (props) => (
            <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
        ),
    });

export default timelogTags;
