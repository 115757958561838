import React from 'react';
import BaseCreate from '../../base/views/BaseCreate';
import WorkerDepartmentForm from './WorkerDepartmentForm';

const WorkerDepartmentCreate = (props) => {
    return (
        <BaseCreate {...props} redirect="list">
            <WorkerDepartmentForm />
        </BaseCreate>
    );
};

export default WorkerDepartmentCreate;
