import React from 'react';
import BaseEdit from '../base/views/BaseEdit';
import ProjectTabbedForm from './ProjectTabbedForm';

const ProjectEdit = (props) => (
    <BaseEdit {...props}>
        <ProjectTabbedForm location={props.location} redirect="show" />
    </BaseEdit>
);

export default ProjectEdit;
