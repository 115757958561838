import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FileInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  FormDataConsumer,
  required,
  SimpleFormIterator,
  usePermissions,
  ReferenceArrayInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { ADMIN_ROLE } from "../../auth/index";
import CKEditorInput from "../../base/ckeditor/CKEditorInput";
import Grid from "@mui/material/Grid";
import TabbedSaveForm from "../../base/forms/TabbedSaveForm";
import PercentageInput from "../../base/input/PercentageInput";
import InvoiceClientAddress from "./components/InvoiceClientAddress";
import CurrencyInput from "../../base/input/CurrencyInput";
import { Alert } from "@mui/lab/index";
import ClientContractField from "./components/ClientContractField";
import { DateInputField } from "../../base/input/DateInput";
import AutocompleteCustomInput from "../../base/input/AutocompleteCustomInput";
import FileFieldInput from "../../base/input/FileFieldInput";

// TODO: Don't hardcode this
export const contentTypes = {
  workerprofile: 14,
  clientprofile: 47,
};

const InvoiceForm = (props: any) => {
  const location = useLocation();
  const { permissions } = usePermissions();
  const searchParams = new URLSearchParams(location?.search);
  const client_contract = parseInt(searchParams.get("client_contract") ?? "0");
  const cost_center = parseInt(searchParams.get("cost_center") ?? "0");
  const type = searchParams.get("type") ?? "";
  const isAdmin = permissions?.includes(ADMIN_ROLE);
  const costCenterFilter = isAdmin ? {} : { mine: true };

  return (
    <TabbedSaveForm
      defaultValues={{
        client_contract,
        cost_center,
        type: isAdmin && type ? type : "S",
      }}
      {...props}
      forceConfirmation
    >
      <FormTab label="Recipient" path="">
        <ReferenceInput source="type" reference="enums/payment/InvoiceType">
          <SelectInput
            style={{ minWidth: 400 }}
            optionText="label"
            validate={[required()]}
            label="Type"
            readOnly={!isAdmin}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.type && formData.type === "E") {
              return (
                <ReferenceInput
                  source="category"
                  reference="enums/payment/InvoiceCategory"
                  {...rest}
                >
                  <SelectInput
                    label="Refund Category"
                    style={{ minWidth: 200 }}
                    optionText="label"
                  />
                </ReferenceInput>
              );
            }
            if (formData.type && formData.type === "R" && !formData.id) {
              return (
                <Alert severity="warning">
                  Please create invoices by converting from planned invoices in
                  the project's contract view.
                  <br />
                  <a
                    href="https://motius.atlassian.net/wiki/spaces/PO/pages/7964786979/Invoices+Planning+Invoices+Acceptance+Reports"
                    rel="noreferrer"
                    target="_blank"
                  >
                    More details on Confluence
                  </a>
                  .
                </Alert>
              );
            }
          }}
        </FormDataConsumer>
        <ReferenceInput
          source="client_contract"
          reference="contracts/projects"
          filter={{ project__cost_center__id__isnull: false }}
        >
          <ClientContractField
            label="Client Contract"
            validate={[required()]}
            style={{ minWidth: 400 }}
          />
        </ReferenceInput>
        <ReferenceInput
          source="cost_center"
          reference="cost-centers"
          filter={costCenterFilter}
        >
          <AutocompleteInput
            optionText="label"
            readOnly
            label="Cost Center"
            style={{ minWidth: 400 }}
            validate={[required()]}
          />
        </ReferenceInput>
        <ReferenceInput
          source="recipient_ct"
          reference="content-types"
          filter={{ model__in: ["workerprofile", "clientprofile"] }}
        >
          <SelectInput
            label="Recipient"
            style={{ minWidth: 400 }}
            optionText="label"
            defaultValue={
              isAdmin ? contentTypes.clientprofile : contentTypes.workerprofile
            }
            disabled={!isAdmin}
            validate={[required()]}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.recipient_ct === contentTypes.workerprofile ? (
              <ReferenceArrayInput
                source="recipient_id"
                reference="users/worker"
                alwaysOn={true}
                enableGetChoices={({ autocomplete }) => !!autocomplete && autocomplete.length >= 3}
                queryOptions={{ keepPreviousData: false }}
                sort={{ field: 'updated', order: 'DESC' }}
              >
                <AutocompleteCustomInput
                  optionText="label"
                  filter={{ show_archived: false }}
                  label="Worker Profile (Min 3 characters)"
                  style={{ minWidth: 400 }}
                  validate={[required()]}
                />
              </ReferenceArrayInput>
            ) : (
              <ReferenceInput
                label="Client Profile"
                source="recipient_id"
                reference="users/client"
              >
                <AutocompleteInput
                  optionText="name"
                  helperText="Leave empty to set the client based on cost center"
                  style={{ minWidth: 400 }}
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.recipient_ct === contentTypes.clientprofile &&
              formData.recipient_id &&
              !formData.id ? (
              <InvoiceClientAddress style={{ minWidth: 400 }} />
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.id && (
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TextInput
                    source="billing_email"
                    helperText="Auto-filled from the recipient's billing address on save"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextInput
                    source="address"
                    helperText="Auto-filled from the recipient's billing address on save"
                  />
                </Grid>
                <Grid item md={2}>
                  <TextInput
                    source="additional_address"
                    helperText="Auto-filled from the recipient's billing address on save"
                  />
                </Grid>
                <Grid item md={2}>
                  <TextInput
                    source="postal_code"
                    helperText="Auto-filled from the recipient's billing address on save"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextInput
                    source="city"
                    helperText="Auto-filled from the recipient's billing address on save"
                  />
                </Grid>
                <Grid item md={2}>
                  <ReferenceInput
                    source="country"
                    label="Country"
                    reference="countries"
                    filterToQuery={(text: any) => ({ name: text })}
                    allowEmpty
                  >
                    <AutocompleteInput optionText="name" optionValue="code" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            )
          }
        </FormDataConsumer>
      </FormTab>

      <FormTab label="Content" path="content">
        <SelectInput
          source="language"
          defaultValue="de"
          choices={[
            { id: "de", name: "German" },
            { id: "en", name: "English" },
          ]}
          validate={[required()]}
        />
        <CKEditorInput source="appendix" label="Appendix" validate={[]} />
        <FileInput label="Appendix PDF" source="appendix_pdf" multiple={false}>
          <FileFieldInput />
        </FileInput>
        <FileInput label="Invoice PDF" source="pdf" multiple={false}>
          <FileFieldInput />
        </FileInput>
      </FormTab>

      <FormTab label="Dates" path="dates">
        <DateInputField
          source="start_date"
          label="Start Date"
          validate={required()}
        />
        <DateInputField
          source="end_date"
          label="End Date"
          validate={required()}
        />
        <DateInputField source="due_date" label="Due Date" />
        <DateInputField source="sent_date" label="Sent Date" />
        <DateInputField source="billing_date" label="Billing Date" />
        <DateInputField source="paid_date" label="Paid Date" />
      </FormTab>

      <FormTab label="Payment" path="payment">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.id && (
              <Grid container>
                <Grid item md={3}>
                  <TextInput
                    source="account_holder"
                    helperText="Auto-filled on save for refunds"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextInput
                    source="iban"
                    helperText="Auto-filled on save for refunds"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextInput
                    source="bic"
                    helperText="Auto-filled on save for refunds"
                  />
                </Grid>
                <Grid item md={3}>
                  <TextInput
                    source="tax_id"
                    helperText="Auto-filled on save for refunds"
                  />
                </Grid>
              </Grid>
            )
          }
        </FormDataConsumer>

        <CurrencyInput source="discount" label="Discount" />
        <PercentageInput label="Tax Rate" source="tax" />

        <ArrayInput label="Line Items" source="line_items">
          <SimpleFormIterator>
            <TextInput source="message" label="Item Description" />
            <CurrencyInput label="Net Amount" source="amount_net" />
            <ReferenceInput
              label="Category"
              source="category"
              reference="enums/payment/LineItemCategory"
            >
              <SelectInput style={{ minWidth: 100 }} optionText="label" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedSaveForm>
  );
};

export default InvoiceForm;
