import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

const InvoiceRecipient = () => {
    const record = useRecordContext();

    if (!record?.recipient) {
        return null;
    }
    const source = record.recipient.label ? 'recipient.label' : 'recipient.name';
    return <TextField record={record} source={source} sortBy="recipient_id" />;
};

export default InvoiceRecipient;
