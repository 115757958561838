import React from 'react';
import { ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const WebhookForm = (props) => {
    return (
        <SimpleForm {...props}>
            <ReferenceInput reference="hooksevents" source="event" allowEmpty>
                <SelectInput optionText="value" optionValue="id" sx={{ minWidth: 245 }} />
            </ReferenceInput>
            <TextInput source="target" fullWidth />
        </SimpleForm>
    );
};

export default WebhookForm;
