import { debounce } from "@mui/material";
import { useGetList, useListContext } from "react-admin";
import { useEffect, useMemo } from "react";
import {
  RowData,
  useWorkloadState,
  WorkerAvailability,
} from "./WorkloadContext";
import { sortRowsByName } from "./workloadUtils";

export const useFetchWorkloadData = (response: Object, isProject: boolean) => {
  const { setAvailability, setData, setIsProject } = useWorkloadState();
  const { filterValues, refetch } = useListContext();

  /*
   * We have two endpoints for the workload, one of them sends the items grouped. Need to flatten them.
   * This grouping is necessary for making sure every invitation is sent per worker.
   */
  const data = useMemo(() => {
    let dataArray: RowData[] = [];
    const dataArr = Object.values(response || {}) || ([] as any[]);
    if (isProject) {
      dataArray = dataArr as RowData[];
    } else {
      dataArray = ([] as any[])
        .concat(dataArr.map((a) => a.invitations))
        .flat()
    }
    setData(dataArray);
    return dataArray;
  }, [response, isProject, setData]);

  const workerIds = useMemo(() => {
    const plannedData = data;
    if (!plannedData?.length) return [];
    const ids = new Set();
    plannedData.forEach((d) => ids.add(d.worker));
    return Array.from(ids.values());
  }, [data]);

  const { data: availabilityResponse, refetch: refetchAvailability } =
    useGetList(
      "users/workers/availability",
      {
        pagination: { perPage: 1000, page: 1 },
        sort: { field: "id", order: "desc" },
        filter: {
          id__in: workerIds,
          start_date: filterValues?.start_date,
          end_date: filterValues?.end_date,
        },
      },
      { enabled: workerIds.length > 0 }
    );

  useEffect(() => {
    setIsProject(isProject);
  }, [isProject, setIsProject]);

  const availability = useMemo(() => {
    const arrayAvailability = Object.values(
      availabilityResponse || {}
    ) as WorkerAvailability[];
    setAvailability(arrayAvailability);
  }, [availabilityResponse, setAvailability]);

  const refetchAll = debounce(() => {
    refetch();
    refetchAvailability();
  }, 1000);

  return { data, availability, refetchAll };
};
