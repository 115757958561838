import React, { Fragment, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from 'react-admin';

const useStyles = makeStyles({
    spacingTop: {
        marginTop: '1em',
    },
});

const PDFNewTab = (props) => {
    const { record, source } = props;
    const [value, setValue] = useState(null);

    if (record[source] !== value) {
        setValue(record[source]);
    }

    const handleClick = () => {
        window.open(value.url, '_blank');
    };

    const classes = useStyles();
    return (
        <Fragment>
            <Button
                className={classes.spacingTop}
                disabled={!value || !value.url}
                color="primary"
                onClick={handleClick}
                label={'Open CV'}
            >
                <OpenInNewIcon />
            </Button>
        </Fragment>
    );
};

PDFNewTab.defaultProps = {
    record: {},
    source: null,
};

PDFNewTab.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PDFNewTab;
