import Create from './WorkerSkillEstimationCreate';
import Edit from './WorkerSkillEstimationEdit';
import React from 'react';

const resourceName = 'Worker Evaluation';
const resourceNamePlural = 'Worker Evaluations';

const skillEstimation = {
    create: (props) => (
        <Create resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
    edit: (props) => (
        <Edit resourceName={resourceName} resourceNamePlural={resourceNamePlural} {...props} />
    ),
};

export default skillEstimation;
