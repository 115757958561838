import React from 'react';
import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

const HtmlField = ({ source }) => {
    const record = useRecordContext();
    return (
        <Typography
            style={{ '& *': { margin: 0, padding: 0 } }}
            dangerouslySetInnerHTML={{ __html: record[source] }}
        />
    );
};

export default HtmlField;
