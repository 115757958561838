import React, { useEffect, useState, Fragment } from 'react';
import { ReferenceManyField, useListContext, useRecordContext } from 'react-admin';
import { Cell, Pie, PieChart } from 'recharts';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { currencyFormatter } from '../../../base/fields/CurrencyField';

const COLORS = ['#61DAFB', '#0F5267', '#ABABAB'];

const InvoiceGraphs = ({ net_value }) => {
    const record = useRecordContext();

    return (
        <ReferenceManyField
            record={record.client_contract}
            label="Projects"
            reference="payment/invoices"
            filter={{ cost_center: record.cost_center, type__in: ['R', 'C'] }}
            target="client_contract"
        >
            <Middle net_value={record?.client_contract?.net_value_override || net_value} />
        </ReferenceManyField>
    );
};
export default InvoiceGraphs;

const Middle = (props) => {
    const { data: invoiceData } = useListContext();
    const [data, setData] = useState(null);
    useEffect(() => {
        let paid = { name: 'Paid', value: 0 };
        let billed = { name: 'Billed', value: 0 };
        let rest = { name: 'Rest', value: parseFloat(props.net_value) };
        if (invoiceData) {
            invoiceData.forEach((invoice) => {
                const element = invoice;
                const amount = parseFloat(element.amount_discounted_net);

                // The cancellation invoice amount should be deducted from the total
                if (element.state === 'paid') {
                    if (element.type === 'C') {
                        paid.value -= amount;
                        rest.value += amount;
                    } else {
                        paid.value += amount;
                        rest.value -= amount;
                    }
                } else if (element.state === 'sent') {
                    if (element.type === 'C') {
                        billed.value -= amount;
                        rest.value += amount;
                    } else {
                        billed.value += amount;
                        rest.value -= amount;
                    }
                }
            });

            setData([
                { name: 'Paid', value: paid.value },
                { name: 'Billed', value: billed.value },
                {
                    name: 'Rest',
                    value: Math.max(parseFloat(rest.value.toFixed(2)), 0),
                },
            ]);
        }
    }, [invoiceData, props.net_value]);
    if (invoiceData === undefined) {
        return null;
    }
    return <Graphs net_value={props.net_value} data={data} />;
};

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: theme.spacing(),
    },
    knob: {
        width: 15,
        height: 15,
        backgroundColor: COLORS[1],
        borderRadius: 10,
    },
    paidKnob: {
        marginLeft: theme.spacing(2),
        backgroundColor: COLORS[0],
    },
}));

const Graphs = ({ data, ...props }) => {
    const classes = useStyles();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {data ? (
                <Fragment>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={`${classes.knob}`} />
                        <Typography className={classes.label}>
                            Billed: {currencyFormatter.format(data[1].value + data[0].value)}
                        </Typography>
                        <div className={`${classes.knob} ${classes.paidKnob}`} />
                        <Typography className={classes.label}>
                            Paid: {currencyFormatter.format(data[0].value)}
                        </Typography>
                    </div>
                    <PieChart width={200} height={300}>
                        <Pie
                            data={data}
                            cx={100}
                            cy={100}
                            startAngle={90}
                            endAngle={-270}
                            innerRadius={65}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${entry.label}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </Fragment>
            ) : null}
        </div>
    );
};
