import React from 'react';
import { TextInput, SimpleForm, ReferenceInput } from 'react-admin';
import AutocompleteCustomInput from '../../base/input/AutocompleteCustomInput';

const WorkerDepartmentForm = (props) => {
    return (
        <SimpleForm {...props}>
            <TextInput source="name" style={{ minWidth: '20%' }} />
            <TextInput label="Short Name" source="name_short" style={{ minWidth: '20%' }} />
            <ReferenceInput reference="users/worker" source="responsible">
                <AutocompleteCustomInput
                    optionText="label"
                    style={{ minWidth: '20%' }}
                    filterToQuery={(searchText) => ({
                        autocomplete: searchText,
                        show_archived: false,
                    })}
                    shouldRenderSuggestions={(val) => {
                        return val.trim().length > 3;
                    }}
                />
            </ReferenceInput>
        </SimpleForm>
    );
};

export default WorkerDepartmentForm;
