import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import { List, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PanelLabel from '../base/show/PanelLabel';
import { ListItemLink } from '../worker/WorkerPanel';
import { apiUrl, httpClient } from '../dataProvider';
import Dialog from '../contract/project/components/Dialog';
import useCustomNotify from '../base/useCustomNotify';

const AtlassianIntegrationList = ({
    items,
    resource,
    title,
    keySource,
    hrefSource,
    idKey,
    record,
}) => {
    const refresh = useRefresh();
    const notify = useCustomNotify();
    const [indexToDelete, setIndexToDelete] = useState(0);
    const [loading, setLoading] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const onDeleteClick = (index) => {
        setIndexToDelete(index);
        setDialogOpen(true);
    };

    const onDeleteConfirm = async () => {
        try {
            setLoading(items[indexToDelete][keySource]);
            await httpClient(`${apiUrl}/${resource}`, {
                method: 'DELETE',
                body: JSON.stringify({
                    [idKey]: items[indexToDelete].id,
                    [keySource]: items[indexToDelete][keySource],
                    platform_project_id: record.id,
                }),
            });
            notify('Integration removed.');
            refresh();
        } catch (e) {
            console.log(e);
            notify('Error when removing integration.', 'warning');
        } finally {
            setLoading(false);
        }
    };

    return items && items.length > 0 ? (
        <>
            <PanelLabel>Connected {title} Projects</PanelLabel>

            <List>
                {items.map((item, index) => (
                    <ListItemLink key={item[keySource]} href={item[hrefSource]} target="_blank">
                        <ListItemText>{item[keySource]}</ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                disabled={loading === items[index][keySource]}
                                onClick={() => onDeleteClick(index)}
                                size="large"
                            >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItemLink>
                ))}
            </List>
            <Dialog
                open={dialogOpen}
                title="Are you sure?"
                description="Are you sure to remove this integration?"
                onClose={() => setDialogOpen(false)}
                onConfirm={onDeleteConfirm}
            />
        </>
    ) : null;
};

export default AtlassianIntegrationList;
