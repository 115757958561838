import React from 'react';
import {
    AutocompleteArrayInput,
    Datagrid,
    AutocompleteInput,
    BooleanInput,
    Filter,
    NumberField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    TextField,
    TextInput,
    TopToolbar,
    CreateButton,
    useRedirect,
} from 'react-admin';
import AutocompleteCustomInput from '../../base/input/AutocompleteCustomInput';
import BaseList from '../../base/views/BaseList';
import ShowElementLink from '../../base/buttons/ShowElementLink';

const ProjectClientContractFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
        <ReferenceInput source="project__id__in" reference="projects" alwaysOn>
            <AutocompleteCustomInput optionText="label" label="Project" />
        </ReferenceInput>
        <ReferenceInput source="project__client_profile__id__in" reference="users/client" alwaysOn>
            <AutocompleteInput optionText="name" label="Client" />
        </ReferenceInput>
        <ReferenceInput source="project__cost_center__id__in" reference="cost-centers" alwaysOn>
            <AutocompleteInput optionText="label" label="Cost Center" />
        </ReferenceInput>
        <ReferenceArrayInput
            source="state__in"
            reference="enums/contract/ProjectClientContractState"
            alwaysOn
        >
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="State" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="type__in"
            reference="enums/contract/ProjectClientContractType"
            alwaysOn
        >
            <SelectArrayInput style={{ minWidth: 100 }} optionText="label" label="Type" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="project__responsible" reference="users" label="Responsible">
            <AutocompleteArrayInput
                style={{ minWidth: 100 }}
                optionText="name"
                filter={{ responsibilities__isnull: false, is_active: true }}
                sort="first_name"
                label="Responsible"
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            label="Technical Reviewer"
            source="technical_reviewer"
            reference="users/worker"
            perPage={100}
        >
            <AutocompleteArrayInput
                style={{ minWidth: 100 }}
                optionText="label"
                label="Technical Reviewer"
            />
        </ReferenceArrayInput>
        <BooleanInput label="Me as reviewer" source="me" alwaysOn />
    </Filter>
);

const ProjectClientContractActions = (props) => {
    const { filters, resource, showFilter, displayedFilters, filterValues } = props;

    return (
        <TopToolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton basePath="/projects/wizard/create" />
        </TopToolbar>
    );
};

const ProjectClientContractList = (props) => {
    const redirect = useRedirect();

    return (
        <BaseList
            actions={<ProjectClientContractActions />}
            filters={<ProjectClientContractFilter />}
            {...props}
        >
            <Datagrid
                rowClick={(id, resource, record) => {
                    return redirect(
                        `/projects/wizard/${record.project}/${record.state === 'draft' ? '2' : 'show'
                        }`
                    );
                }}
            >
                <ShowElementLink label="Public Id" source="public_id" obj="contracts/projects" />
                <ReferenceField source="project" reference="projects" link="show">
                    <TextField source="name_internal" />
                </ReferenceField>
                <ReferenceField
                    source="state"
                    reference="enums/contract/ProjectClientContractState"
                    link={false}
                >
                    <TextField source="label" />
                </ReferenceField>
                <ReferenceField
                    source="type"
                    reference="enums/contract/ProjectClientContractType"
                    link={false}
                >
                    <TextField source="label" />
                </ReferenceField>
                <ReferenceField
                    source="billing"
                    reference="enums/contract/ProjectClientContractBillingType"
                    link={false}
                >
                    <TextField source="label" />
                </ReferenceField>
                <NumberField source="hourly_rate" />
            </Datagrid>
        </BaseList>
    );
};

export default ProjectClientContractList;
