import CostCenterForm from './CostCenterForm';
import React from 'react';
import BaseEdit from '../../base/views/BaseEdit';

const CostCenterEdit = (props) => (
    <BaseEdit {...props}>
        <CostCenterForm />
    </BaseEdit>
);

export default CostCenterEdit;
